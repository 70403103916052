/** @format */

import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile, isIOS } from "react-device-detect";
import {
  showInviteFriendsPopUpHandler,
  inviteFriendsPopUpScheduleIdHandler,
} from "../../../store/slices/user";
import "../../../index.css";

import { userService } from "../../../_services";
import { showService } from "../../../_services/admin";
import Global from "../../../config/Global";

import {
  getProfileImageOrFirstAndLastChars,
  firstNameLastInitial,
  getFormattedDateTime,
} from "../../../_helpers";

import { InlineShareButtons } from "sharethis-reactjs";
import { useSnackbar } from "notistack";
import OutsideClickHandler from "react-outside-click-handler";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import classNames from "classnames/bind";

// assests
import "../../../index.css";
import styles from "./inviteFriendsPopUp.module.css";
import appleMsgIcon from "../../../images/apple_msg.png";
import androidMsgIcon from "../../../images/android_msg.png";

const cx = classNames.bind(styles);

let ourprimeMemberInvitePayload = {};

const InviteFriendsPopUp = () => {
  const inputRef = useRef();

  const { enqueueSnackbar } = useSnackbar();

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      enqueueSnackbar("Copied!", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Failed to copy!", {
        variant: "error",
      });
    }
  };

  const dispatch = useDispatch();
  const { inviteFriendsPopUpScheduleId, user } = useSelector(
    (state) => state.user
  );

  const [loadingScheduleShowData, setLoadingScheduleShowData] = useState(true);
  const [errorScheduleShowData, setErrorScheduleShowData] = useState(false);
  const [scheduleShowData, setScheduleShowData] = useState({});
  const [hostsAndAttendees, setHostsAndAttendees] = useState([]);
  const [hostsSuggestions, setHostsSuggestions] = useState([]);
  const [filteredHostsSuggestions, setFilteredHostsSuggestions] = useState([]);
  const [showUrl, setShowUrl] = useState("");
  const [usersTags, setUsersTags] = useState([]);
  const [invitedHosts, setInvitedHosts] = useState([]);

  const hideInviteFriendsPopUp = () => {
    dispatch(showInviteFriendsPopUpHandler(false));
    dispatch(inviteFriendsPopUpScheduleIdHandler(0));
  };

  const handleChange = (e) => {
    const userInput = e.target.value;

    const unLinked = hostsSuggestions.filter(
      (suggestion) =>
        suggestion.full_name.toLowerCase().indexOf(userInput.toLowerCase()) ===
        0
    );

    setFilteredHostsSuggestions(unLinked);

    if (!userInput) {
      setFilteredHostsSuggestions([]);
    }
  };

  const removeTags = (index) => {
    setUsersTags([
      ...usersTags.filter((tag) => usersTags.indexOf(tag) !== index),
    ]);
  };

  const handleTag = (host) => {
    const userTag = {
      user_id: host?.id,
      schedule_id: inviteFriendsPopUpScheduleId,
      email: host?.email,
      full_name: host?.full_name,
      first_name: host?.first_name,
      last_name: host?.last_name,
      profile_image: host?.profile_image,
    };
    setFilteredHostsSuggestions([]);
    setUsersTags([...usersTags, userTag]);
    inputRef.current.value = "";
  };

  const handleInviteOurPrimeMembers = () => {
    const payload = {
      schedule_id: inviteFriendsPopUpScheduleId,
      user_details: usersTags,
      sender_name: user.full_name,
      scheduled_time: getFormattedDateTime(scheduleShowData.utc_datetime),
    };
    userService
      .inviteMemberOurPrime(payload)
      .then((data) => {
        enqueueSnackbar(data.message, {
          variant: data.success ? "success" : "error",
        });
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
    let newArray = [...invitedHosts, ...usersTags];
    let uniqueArray = newArray.filter(
      (v, i, a) => a.findIndex((v2) => v2.user_id === v.user_id) === i
    );
    setInvitedHosts(uniqueArray);
    setUsersTags([]);
  };

  const getScheduleShowData = () => {
    const payload = {
      schedule_id: inviteFriendsPopUpScheduleId,
    };
    userService
      .getInviteDataViaScheduleId(payload)
      .then((data) => {
        if (data.success) {
          setScheduleShowData(data?.data);
          let hosts = data?.data?.AdminShow?.AdminShowHosts;

          let uniqueHosts = hosts.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) => t.UserBasicDetail.id === value.UserBasicDetail.id
              )
          );
          uniqueHosts.sort(function (a, b) {
            if (a.UserBasicDetail.full_name < b.UserBasicDetail.full_name) {
              return -1;
            }
            if (a.UserBasicDetail.full_name > b.UserBasicDetail.full_name) {
              return 1;
            }
            return 0;
          });
          setHostsAndAttendees(uniqueHosts);
          setShowUrl(Global.ShowUrl + "/" + data?.data?.show_id);
          setLoadingScheduleShowData(false);
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
          setErrorScheduleShowData(true);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    getScheduleShowData();

    showService
      .searchHosts({
        existingHostIds: [],
        search: "",
      })
      .then((data) => {
        if (data.data?.hosts?.length > 0) {
          setHostsSuggestions((hostsSuggestions) => {
            let array = data.data.hosts.map((host) => {
              return {
                id: host?.id,
                email: host?.email,
                full_name: `${host?.first_name} ${host?.last_name}`,
                first_name: host?.first_name,
                last_name: host?.last_name,
                profile_image: host?.profile_image,
              };
            });
            return [...hostsSuggestions, ...array];
          });
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="popUpBg popUpCentred inviteFriendsPopUp">
      <div className="popUp">
        <div className="popMobile inviteFreinds p-4">
          {loadingScheduleShowData ? (
            <div className="text-center my-5">
              <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : errorScheduleShowData ? (
            <h3 className="mt-3 text-center text-white">Response not found</h3>
          ) : (
            <div className="col-xl-6 col-lg-10 col-md-10 col-sm-10 col-12 m-auto ">
              <OutsideClickHandler onOutsideClick={hideInviteFriendsPopUp}>
                <main className="bg-white popShadow p-2">
                  <div className="container ">
                    <div className="SubCont">
                      <div className="row ">
                        <div className="col-sm-12">
                          <div className="d-flex align-items-center justify-content-between my-3">
                            <h5 className="fw-bold mb-0 invitePopUpTitle PopUpTitle">
                              {scheduleShowData?.AdminShow?.title}
                            </h5>
                            <div
                              onClick={hideInviteFriendsPopUp}
                              className="mobileClose inviteCross"
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 26 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M26 1.53183L24.4682 0L13 11.4682L1.53183 0L0 1.53183L11.4682 13L0 24.4682L1.53183 26L13 14.5318L24.4682 26L26 24.4682L14.5318 13L26 1.53183Z"
                                  fill="rgba(55, 73, 87, 1)"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="socialMedia d-flex align-items-center mb-3 shareHeadMain">
                            <div className="flex-shrink-0 h5 me-2 mb-0 shareHead">
                              Share:
                            </div>
                            {isMobile && (
                              <div
                                className="st-btn st-first st-remove-label "
                                style={{
                                  display: "inline-block",
                                  marginLeft: "-2px",
                                  transition:
                                    "opacity 0.2s ease-in, top 0.2s ease-in",
                                }}
                              >
                                <a
                                  href={
                                    isIOS
                                      ? `sms:&body=I%E2%80%99m%20pretty%20excited%20about%20this%20program.%20%20I%E2%80%99m%20for%20sure%20attending.%20%20Check%20it%20out%20and%20see%20if%20you%E2%80%99d%20be%20interested%20in%20joining%20me.
${showUrl}`
                                      : `sms:?body=I%E2%80%99m%20pretty%20excited%20about%20this%20program.%20%20I%E2%80%99m%20for%20sure%20attending.%20%20Check%20it%20out%20and%20see%20if%20you%E2%80%99d%20be%20interested%20in%20joining%20me.
${showUrl}`
                                  }
                                >
                                  <img
                                    alt="sms sharing button"
                                    src={isIOS ? appleMsgIcon : androidMsgIcon}
                                    style={{
                                      width: isIOS ? "38px" : "37px",
                                    }}
                                  />
                                </a>
                              </div>
                            )}
                            <InlineShareButtons
                              config={{
                                alignment: "center", // alignment of buttons (left, center, right)
                                color: "social", // set the color of buttons (social, white)
                                enabled: true, // show/hide buttons (true, false)
                                font_size: 35, // font size for the buttons
                                labels: null, // button labels (cta, counts, null)
                                language: "en", // which language to use (see LANGUAGES)
                                networks: [
                                  // which networks to include
                                  "facebook",
                                  "twitter",
                                  "whatsapp",
                                  "email",
                                  "messenger",
                                  "messenger",
                                ],
                                padding: 10, // padding within buttons (INTEGER)
                                radius: 4, // the corner radius on each button (INTEGER)
                                show_total: false,
                                size: 35, // the size of each button (INTEGER)

                                // OPTIONAL PARAMETERS
                                url: `${process.env.REACT_APP_BASE_URL}/admin/show-detail/${scheduleShowData.show_id}`, // (defaults to current url)
                                title: `I'am pretty excited about this programme.I'am for sure attending.Check it out and see if you'd be interested in joining me.`,
                                message: `
                                      I'am pretty excited about this programme.I'am for sure attending.Check it out and see if you'd be interested in joining me.

                                      Title: ${scheduleShowData.AdminShow.title}

                                      image: https://uptalk.s3.amazonaws.com/image_1651753917918.png

                                      Link: ${process.env.REACT_APP_BASE_URL}/admin/show-detail/${scheduleShowData.show_id}`, // (only for email sharing)

                                subject: "Via Ourprime", // (only for email sharing)
                              }}
                            />
                          </div>
                          <div className="flex-shrink-0 h6 me-2 mb-1 inpuHeading">
                            Invite OurPrime Members
                          </div>
                          <div className="position-relative">
                            <div
                              className="col-lg-10 col-md-10 col-sm-12 col-12 mb-2"
                              style={{
                                position: "absolute",
                                background: "white",
                                top: "46px",
                                borderBottom:
                                  filteredHostsSuggestions.length &&
                                  "1px solid grey",
                                borderLeft: "1px solid grey",
                                zIndex: 5000,
                              }}
                            >
                              <OutsideClickHandler
                                onOutsideClick={() => {
                                  setFilteredHostsSuggestions([]);
                                  // setSearchedHosts([]);
                                }}
                              >
                                <SimpleBar className={cx("simplebar")}>
                                  <div data-simplebar>
                                    {filteredHostsSuggestions.map((host) => {
                                      return (
                                        <div
                                          key={host.id}
                                          className={cx(
                                            "hoverColor border-dark p-2 cursor-pointer"
                                          )}
                                          onClick={() => {
                                            handleTag(host);
                                            // handleInviteOurPrimeMember(host);
                                            setFilteredHostsSuggestions([]);
                                          }}
                                        >
                                          <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
                                              <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0">
                                                  <div className="img-44 rounded-circle bg-accent-blue d-flex align-items-center justify-content-center">
                                                    {getProfileImageOrFirstAndLastChars(
                                                      host?.profile_image,
                                                      host?.full_name,
                                                      host?.last_name
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="w-100">
                                                  <div className="d-flex align-items-center justify-content-start">
                                                    <p className="mb-0 mx-3">
                                                      {host?.full_name}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </SimpleBar>
                              </OutsideClickHandler>
                            </div>
                            <div className="row mt-2 InviteDiv">
                              <div className="d-flex form-inline flex-sm-row flex-column col-sm-12 ">
                                <div
                                  style={{
                                    border: "1px solid",
                                    borderRadius: "3px",
                                    flexGrow: 1,
                                  }}
                                >
                                  <div className={cx("tags-input")}>
                                    {usersTags.length ? (
                                      <ul id={cx("tags")}>
                                        {usersTags.map((tag, index) => (
                                          <li key={index} className={cx("tag")}>
                                            <span className={cx("tag-title")}>
                                              {firstNameLastInitial(
                                                tag?.first_name,
                                                tag?.last_name
                                              )}
                                            </span>
                                            <span
                                              className={cx("tag-close-icon")}
                                              onClick={() => removeTags(index)}
                                            >
                                              x
                                            </span>
                                          </li>
                                        ))}
                                      </ul>
                                    ) : (
                                      <></>
                                    )}

                                    <input
                                      ref={inputRef}
                                      type="search"
                                      placeholder={
                                        usersTags.length
                                          ? ""
                                          : "Enter First and Last Names, separated with commas"
                                      }
                                      aria-label="Search"
                                      autoCorrect="off"
                                      autoComplete="off"
                                      onChange={handleChange}
                                      className="InputFi"
                                    />
                                  </div>
                                </div>
                                <button
                                  disabled={
                                    usersTags.length === 0 ? true : false
                                  }
                                  className="btn btn-danger red-shadow px-4 my-2 my-sm-0 ms-2 InputBtn"
                                  type="button"
                                  onClick={handleInviteOurPrimeMembers}
                                >
                                  Invite
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex-shrink-0 h6 me-2 mt-3 inpuHeading">
                            Share this link to invite anyone
                          </div>
                          <div className="d-flex form-inline flex-sm-row flex-column col-sm-12  ">
                            <input
                              className="form-control mr-sm-2 border-dark bg-white InputFi"
                              type="text"
                              defaultValue={showUrl}
                              aria-label="Search"
                              readOnly
                            />
                            <button
                              className="btn btn-danger red-shadow px-4 my-2 my-sm-0 ms-2 InputBtn"
                              onClick={() =>
                                copyToClipBoard(`I'am pretty excited about this programme.I'am for sure attending.Check it out and see if you'd be interested in joining me.
${showUrl}`)
                              }
                            >
                              Copy
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inviteScroll mt-2 InviteScrollDiv">
                      <div className="row">
                        <>
                          {invitedHosts?.length > 0 &&
                            invitedHosts?.map((host, index) => {
                              return (
                                <div
                                  className="col-lg-6 col-md-6 col-sm-12 col-12 my-2"
                                  key={index}
                                >
                                  <div className="row align-items-center border-bottom pb-2 g-0">
                                    <div className="col-sm-6">
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                          <div className="img-35 rounded-circle overflow-h me-2 bg-accent-blue d-flex align-items-center justify-content-center">
                                            {getProfileImageOrFirstAndLastChars(
                                              host?.profile_image,
                                              host?.first_name,
                                              host?.last_name
                                            )}
                                          </div>
                                        </div>
                                        <div className="w-100">
                                          <p className="mb-0">
                                            {firstNameLastInitial(
                                              host?.first_name,
                                              host?.last_name
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                      <div className="d-flex align-items-center justify-content-end">
                                        <button className="border-0 text-danger rounded small ">
                                          {/* {host?.is_attendee
                                        ? "Attending"
                                        : "Invited"} */}
                                          Invited
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </>
                        <>
                          {hostsAndAttendees?.length > 0 &&
                            hostsAndAttendees?.map((host, index) => {
                              return (
                                <div
                                  className="col-lg-6 col-md-6 col-sm-12 col-12 my-2"
                                  key={index}
                                >
                                  <div className="row align-items-center border-bottom pb-2 g-0">
                                    <div className="col-sm-6">
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                          <div className="img-35 rounded-circle overflow-h me-2 bg-accent-blue d-flex align-items-center justify-content-center">
                                            {getProfileImageOrFirstAndLastChars(
                                              host?.UserBasicDetail
                                                ?.profile_image,
                                              host?.UserBasicDetail?.first_name,
                                              host?.UserBasicDetail?.last_name
                                            )}
                                          </div>
                                        </div>
                                        <div className="w-100">
                                          <p className="mb-0">
                                            {firstNameLastInitial(
                                              host?.UserBasicDetail?.first_name,
                                              host?.UserBasicDetail?.last_name
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="d-flex align-items-center justify-content-end">
                                        <button className="bg-alert-danger bg-danger border-0 text-white rounded small attendingSize">
                                          {/* {host?.is_attendee
                                        ? "Attending"
                                        : "Invited"} */}
                                          Attending
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      </div>
                      <div className="row"></div>
                    </div>
                  </div>
                </main>
              </OutsideClickHandler>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InviteFriendsPopUp;
