import React from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./customRecurrenceModal.module.css";
import classNames from "classnames/bind";
import { useState, useEffect } from "react";
import moment from "moment";

const CustomRecurrenceModal = ({
  backdrop,
  show,
  onHide,
  setRecurrenceData,
}) => {
  const cx = classNames.bind(styles);
  const [recurValues, setRecurValues] = useState({
    repeat: 1,
    repeatType: "day",
    endsOn: 0,
    endDate: moment().utc().format(),
    occurences: 1,
  });

  const handleChange = (e) => {
    setRecurValues((recurValues) => ({
      ...recurValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDone = (e) => {
    recurValues.endDate = moment(recurValues.endDate)
      .add(1, "days")
      .utc()
      .format();
    setRecurrenceData(recurValues);
    onHide();
  };
  let props = { show };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="container">
        <div className=" d-flex align-items-center justify-content-center">
          <div className={cx("outerBox", "p-4", "position-relative")}>
            <div className={cx("landscapeMode")}>
              <div className="d-flex align-items-center justify-content-center mb-4 landscape-mb-4">
                <h4 className="text-center fw-bold">Custom Recurrence </h4>
                <div
                  className={cx("position-absolute", "closeIcon")}
                  onClick={() => {
                    onHide();
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26 1.53183L24.4682 0L13 11.4682L1.53183 0L0 1.53183L11.4682 13L0 24.4682L1.53183 26L13 14.5318L24.4682 26L26 24.4682L14.5318 13L26 1.53183Z"
                      fill="rgba(255,255,255, 1)"
                    />
                  </svg>
                  {/* <img className="img-fluid" src={CloseIcon} alt="cross" /> */}
                </div>
              </div>
              <div className="row">
                <span className="col-3 my-auto">Repeat Every</span>
                <div className=" col-3">
                  <input
                    type="number"
                    className="form-control  bg-muted "
                    name="repeat"
                    value={recurValues.repeat}
                    onChange={handleChange}
                  />
                </div>
                <select
                  className="customPadding  bg-muted col-3 "
                  name="repeatType"
                  value={recurValues.repeatType}
                  onChange={handleChange}
                >
                  <option value="day">Day</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                </select>
              </div>
              <div className="endsOn ">
                <h5 className="mt-3 fw-bold">Ends After</h5>

                <div className={cx("form-check", "mb-3")}>
                  <input
                    className={cx("form-check-input")}
                    type="radio"
                    name="endsOn"
                    value={1}
                    onChange={handleChange}
                    // defaultChecked={true}
                  />
                  <span className="ml-3">On</span>
                  {recurValues.endsOn == 1 && (
                    <>
                      <input
                        type="date"
                        className={cx("second-input")}
                        value={recurValues.endDate}
                        name="endDate"
                        onChange={handleChange}
                      />
                    </>
                  )}
                </div>
                <div className={cx("form-check", "mb-3")}>
                  <input
                    className={cx("form-check-input")}
                    type="radio"
                    name="endsOn"
                    value={2}
                    //   checked={topicType.custom}
                    onChange={handleChange}
                  />
                  <span className="ml-3 w-50">After</span>

                  {recurValues.endsOn == 2 && (
                    <>
                      <input
                        className={cx("second-input")}
                        type="number"
                        name="occurences"
                        value={recurValues.occurences}
                        onChange={handleChange}
                      />{" "}
                      <span>Occurences</span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <button
              onClick={handleDone}
              type="button"
              className={cx(
                "btn-block",
                "btn",
                "btn-xs",
                "doneButton",
                "col-xs-12",
                "col-md-5",
                "mx-auto"
              )}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomRecurrenceModal;
