/** @format */

import { Navigate } from "react-router-dom";

function ProtectedRoute({ children }) {
  const isLoggedIn = !!JSON.parse(localStorage.getItem("user"))?.username;
  return isLoggedIn ? children : <Navigate to="/" />;
}

export default ProtectedRoute;
