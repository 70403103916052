/** @format */

import React, { useCallback, useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import CloseIcon from "../../../images/cross.svg";
import MailIcon from "../../../images/emailIcon.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import auth from "./firebase";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  getAuth,
} from "firebase/auth";
import axios from "axios";
import FacebookIcon from "./images/facebook.svg";
import GoogleIcon from "./images/googlePlus.svg";
import AppleIcon from "./images/apple.svg";
import styles from "./signup.module.css";
import { userService } from "../../../_services";
import { Form, InputGroup, FormControl, Button } from "react-bootstrap";
import { useSnackbar } from "notistack";

const cx = classNames.bind(styles);

const Signup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isFormFilled, setIsFormFilled] = useState(false);
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState();
  const facebookRef = useRef(null);
  const googleRef = useRef(null);
  const [email, setEmail] = useState(location.state?.email || "");
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  useEffect(() => {
    getRedirectResult(auth)
      .then(function (result) {
        const user = result.user;

        // Apple credential
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;

        // ...
        const displayNameString = user.displayName;
        const displayNameArray = displayNameString.split(" ");
        const dataToPost = {
          country_code: "+1",
          phone_number: user.phoneNumber || "123456",
          first_name: displayNameArray[0],
          last_name: displayNameArray[1],
          email: user.email || "test@gmail.com",
          password: "admin@123",
          dob: "1994-05-01",
          gender: "1",
        };
        const displayPhotoURL = user.photoURL;
        // userService
        //   .register(dataToPost)
        //   .then((data) => {
        navigate("/register", {
          state: {
            first_name: displayNameArray[0] || "",
            last_name: displayNameArray[1] || "",
            email: user.email || "",
            photo: displayPhotoURL || "",
          },
        });
        // })
        // .catch((error) => {
        //   alert(error);
        // });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error);
      });
  }, []);

  useEffect(() => {
    if (email !== "") {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
  }, [email]);

  const handleContinue = (e) => {
    e.preventDefault();
    const regex = {
      email: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
      password: /^[#\w@_-]{8,20}$/,
    };
    if (!regex.email.test(email)) {
      return enqueueSnackbar("Enter a valid email", {
        variant: "error",
      });
    }
    setIsFormSubmitting(true);
    const dataToPost = {
      email: email,
    };
    userService.check(dataToPost).then((data) => {
      setIsFormSubmitting(false);
      if (!data.data.isRegistered) {
        navigate("/register-otp", {
          state: {
            email: email,
          },
        });
      } else {
        navigate("/login", {
          state: {
            email: email,
          },
        });
      }
    });
  };

  const handleSocialLogin = (provider) => {
    if (provider === "apple") {
      var socilaProvider = new OAuthProvider("apple.com");
    } else if (provider === "google") {
      var socilaProvider = new GoogleAuthProvider();
    } else if (provider === "facebook") {
      var socilaProvider = new FacebookAuthProvider();
    }
    // const auth = getAuth();
    signInWithRedirect(auth, socilaProvider);
    // getRedirectResult(auth)
    // .then(function(result) {
    //   console.log(result);
    //   if (result.credential) {
    //     // This gives you a Google Access Token. You can use it to access the Google API.
    //     var token = result.credential.accessToken;
    //     // setToken(token);
    //     // ...
    //   }
    //   // The signed-in user info.
    //   var user = result.user;
    //   console.log(user);
    //   // setData(user);
    // })
    // .catch(function(error) {
    //   // Handle Errors here.
    //   var errorCode = error.code;
    //   var errorMessage = error.message;
    //   // The email of the user's account used.
    //   console.log(errorCode, errorMessage);
    //   // ...
    // });

    // signInWithPopup(auth, socilaProvider)
    // .then((result) => {
    //   // The signed-in user info.
    //   const user = result.user;
    //   console.log(user);
    //   console.log(result);

    //   // Apple credential
    //   const credential = OAuthProvider.credentialFromResult(result);
    //   const accessToken = credential.accessToken;
    //   const idToken = credential.idToken;

    //   // ...
    //   const displayNameString = user.displayName;
    //   const displayNameArray = displayNameString.split(" ");
    //   const dataToPost = {
    //     country_code: "+1",
    //     phone_number: user.phoneNumber || "123456",
    //     first_name: displayNameArray[0],
    //     last_name: displayNameArray[1],
    //     email: user.email || "test@gmail.com",
    //     password: "admin@123",
    //     dob: "1994-05-01",
    //     gender: "1",
    //   };
    //   const displayPhotoURL = user.photoURL;
    //   // userService
    //   //   .register(dataToPost)
    //   //   .then((data) => {
    //   navigate("/register", {
    //     state: {
    //       first_name: displayNameArray[0] || "",
    //       last_name: displayNameArray[1] || "",
    //       email: user.email || "",
    //       photo: displayPhotoURL || ""
    //     },
    //   });
    //   // })
    //   // .catch((error) => {
    //   //   alert(error);
    //   // });
    // })
    // .catch((error) => {
    //   console.log(error);
    //   // Handle Errors here.
    //   const errorCode = error.code;
    //   const errorMessage = error.message;
    //   // The email of the user's account used.
    //   const email = error.email;
    //   // The credential that was used.
    //   const credential = OAuthProvider.credentialFromError(error);
    //   // ...
    // });
  };

  const btnStyle = isFormFilled
    ? {
        backgroundColor: "#E50914",
        color: "white",
      }
    : {
        backgroundColor: "#E3E3E3",
        color: "black",
      };

  return (
    <section className="container d-flex align-items-center justify-content-center">
      <div className={cx("outerBox", "bg-white", "p-4", "position-relative")}>
        <div
          className={cx(
            "d-flex",
            "align-items-center",
            "justify-content-center",
            "mb-4",
            "landscape-mb-4"
          )}
        >
          <h2 className="text-center fw-bold">Signup</h2>
          <div className={cx("position-absolute", "closeIcon")}>
            <img className="img-fluid" src={CloseIcon} alt="cross" />
          </div>
        </div>
        <Form onSubmit={handleContinue}>
          <div className={cx("landscapeMode")}>
            <InputGroup
              size="lg"
              className={cx(
                "mb-3",
                "landscape-my-4",
                "border",
                "border-dark",
                "rounded"
              )}
            >
              <FormControl
                placeholder="Use email"
                aria-label="use email"
                aria-describedby="use email"
                className="border-white"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <InputGroup.Text
                id="use email"
                className="fs-6 bg-white border-white"
              >
                <img src={MailIcon} />
              </InputGroup.Text>
            </InputGroup>
            <Button
              className="w-100 fw-bold socialBtn"
              size="lg"
              type="submit"
              disabled={isFormSubmitting || !isFormFilled}
              style={btnStyle}
              variant="contained"
            >
              {isFormSubmitting ? `Loading...` : `Continue`}
            </Button>
            <span
              className={cx("d-block", "text-center", "py-3", "landscape-py-3")}
            >
              OR
            </span>
          </div>
          <div className={cx("d-flex", "flex-column", "landscape-flex")}>
            <Button
              className={cx(
                "w-100",
                "fw-bold",
                "position-relative",
                "mb-3",
                "landscape-mb-3",
                "socialBtn",
                "fb-landscape"
              )}
              variant="primary"
              size="lg"
              onClick={() => {
                handleSocialLogin("facebook");
              }}
            >
              <img
                className={cx("position-absolute", "socialFb", "img-fluid")}
                src={FacebookIcon}
              />
              Log in with Facebook
            </Button>
            <Button
              className={cx(
                "w-100",
                "fw-bold",
                "position-relative",
                "mb-3",
                "landscape-mb-3",
                "btn-light-danger",
                "socialBtn",
                "google-landscape",
                "mx-lanscape"
              )}
              size="lg"
              onClick={() => {
                handleSocialLogin("google");
              }}
            >
              <img
                className={cx("position-absolute", "socialG", "img-fluid")}
                src={GoogleIcon}
              />
              Log in with Google
            </Button>
            <Button
              className={cx(
                "w-100",
                "fw-bold",
                "position-relative",
                "socialBtn",
                "apple-landscape"
              )}
              variant="outline-dark"
              size="lg"
              onClick={() => {
                handleSocialLogin("apple");
              }}
            >
              <img
                className={cx("position-absolute", "socialG", "img-fluid")}
                src={AppleIcon}
              />
              Log in with Apple
            </Button>
          </div>
          <p
            className={cx(
              "text-secondary",
              "small",
              "text-center",
              "my-4",
              "landscape-my-4"
            )}
          >
            By continuing, you agree to our{" "}
            <b className="text-dark">Terms of Service</b> and{" "}
            <br className="d-lg-block d-md-block d-sm-none d-none" />
            <b className="text-dark">Privacy Policy.</b>
          </p>
          <p
            className={cx(
              "small",
              "text-center",
              "pt-4",
              "landscape-pt-4",
              "landscape-mb-0"
            )}
          >
            Already have an account?{" "}
            <a
              className=""
              onClick={() => {
                navigate("/login", {
                  state: {
                    email: location.state?.email || "",
                  },
                });
              }}
            >
              <u className="text-danger fw-bold cursor-pointer">Log In</u>
            </a>
          </p>
        </Form>
      </div>
    </section>
  );
};
export default Signup;
