/** @format */

import styles from "../liveVideo.module.css";
import chatImg1 from "../../images/chat-3.png";
import chatCircle from "../../images/chatCircle.svg";
import chatImg2 from "../../images/chat-1.png";
import chatImg3 from "../../images/chat-2.png";
import repliesIcon from "../../images/replyComment.svg";
import smileImg from "../../images/smile.svg";
import sentIcon from "../../images/sentIcon.svg";
import React, { useState, useEffect, useRef } from "react";
import Global from "../../../../config/Global";

// Packages
import { useLocation } from "react-router-dom";
import classNames from "classnames/bind";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import _, { isNull } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import store from "../../../../store/index";
import {
  setHasChatMessage,
  setScrollChatToBottom,
  setIsXmppFetchingChatHistoryOnShowDetail,
  setXmppUserMessageReceived,
} from "../../../../store/slices/xmppConnection";
import { useDispatch, useSelector } from "react-redux";

import { firstNameLastInitial } from "../../../../_helpers";

// XMPP imports
import {
  xmppConnect,
  sendPresence,
  subscribePresence,
  // subscribeIncomingChatMessages,
  subscribeIncomingGroupMessages,
  getRecentChats,
  getChatHistory,
  sendMessage,
  APP,
  resetIsIncomingGroupMessagesHandlerAdded,
} from "../../../../Services/chatService";

import Constants from "../../../../config/constants";

const cx = classNames.bind(styles);

const override = css`
  display: block;
  border-color: red;
  z-index: 5000 !important;
`;

const useStyles = makeStyles((theme) => ({
  msgboxwidth: {
    width: "200px",
    wordBreak: "break-word",
  },
}));

const { Strophe } = window;
let show_detail_incoming_message = 0;
let lastScrollLocation = 0;
let scrollOnSend = true;

export const Chat = ({ isChatHidden }) => {
  const classes = useStyles();

  const refContainer = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const chatHistoryTimerRef = useRef(null);

  // XMPP
  const { user } = useSelector((state) => state.user);
  const xmppConnection = useSelector((state) => state.xmppConnection);
  const {
    xmppStatus,
    xmppStanza,
    XMPP_USER_RECENT_CHATS,
    recentChatPagination,
    chatMessagesLoader,
    hasChatMessage,
    scrollChatToBottom,
    isXmppFetchingChatHistoryOnShowDetail,
  } = xmppConnection;

  const [inputValue, setInputValue] = useState("");
  const [chatLoading, setChatLoading] = useState(true);
  const [scrollFrom, setScrollFrom] = useState(401);
  const [scrollTo, setScrollTo] = useState(450);
  const [firstScrollafterLoadMessages, setFirstScrollAfteLoadrMessages] =
    useState(true);
  const [initialShowChatClick, setInitialShowChatClick] = useState(false);

  const getRoomJID = () => {
    return location.state?.jid;
  };

  const debounced = (delay, fn) => {
    return function (...args) {
      if (chatHistoryTimerRef.current) {
        clearTimeout(chatHistoryTimerRef.current);
      }
      chatHistoryTimerRef.current = setTimeout(() => {
        fn(...args);
        chatHistoryTimerRef.current = null;
      }, delay);
    };
  };

  useEffect(() => {
    if (getRoomJID() && xmppStatus === Strophe.Status.CONNECTED) {
      dispatch(setXmppUserMessageReceived([]));
      getChatHistory({
        element_jid: getRoomJID() + Constants.GROUP_JID_POSTFIX,
        type: Constants.XMPP_STANZA_TYPES.GROUP,
      });
    }
  }, [getRoomJID(), xmppStatus]);

  useEffect(() => {
    if (xmppStatus === Strophe.Status.DISCONNECTED) {
      resetIsIncomingGroupMessagesHandlerAdded();
      xmppConnect(Constants.XMPPDEFAULTUSER, Constants.XMPPDEFAULTUSERPASSWORD);
    } else if (xmppStatus === Strophe.Status.CONNECTED) {
      sendPresence();
      subscribePresence();
      subscribeIncomingGroupMessages();
      setTimeout(() => {
        if (firstScrollafterLoadMessages) {
          let { current } = refContainer;
          if (current !== null) {
            // refContainer.current.scrollTop = refContainer.current.scrollHeight;
          }
          setFirstScrollAfteLoadrMessages(false);
        }
      }, 2000);
      show_detail_incoming_message = 1;
    }
    console.log("xmppStatus ", Global.xmpp_connection_status[xmppStatus], ' ststus', xmppStatus);
  }, [xmppStatus]);

  useEffect(() => {
    // console.log("chatMessagesLoader ", chatMessagesLoader);
    if (xmppStatus === Strophe.Status.CONNECTED && chatMessagesLoader === 4) {
      setChatLoading(false);
      let noMessageContainer = document.getElementById("noMessageContainer");
      if (noMessageContainer) {
        if (hasChatMessage === 0) {
          noMessageContainer.classList.remove("d-none");
          setTimeout(() => {
            let current = refContainer;
            if (current !== null) {
              refContainer.current.scrollTop =
                refContainer.current.scrollHeight;
            }
          }, 1000);
        } else {
          noMessageContainer.classList.add("d-none");
        }
      }
    }
    //  else if (
    //   xmppStatus === Strophe.Status.CONNECTED &&
    //   chatMessagesLoader < 4
    // ) {
    //   setChatLoading(true);
    // }
  }, [chatMessagesLoader, hasChatMessage]);

  useEffect(() => {
    if (scrollChatToBottom) {
      // let current = refContainer;
      // if (current !== null) {
      //   refContainer.current.scrollTop = refContainer.current.scrollHeight;
      // }
    }
  }, [scrollChatToBottom]);

  useEffect(() => {
    if (refContainer.current) {
      const { scrollHeight } = refContainer.current;
      if (isXmppFetchingChatHistoryOnShowDetail) {
        refContainer.current.scrollTop = parseInt(
          scrollHeight - lastScrollLocation
        );
      } else if (scrollOnSend) {
        refContainer.current.scrollTop = parseInt(scrollHeight - 351);
      }
    }
  }, [XMPP_USER_RECENT_CHATS]);

  useEffect(() => {
    if (!isChatHidden && !initialShowChatClick) {
      refContainer.current.scrollTop = refContainer?.current?.scrollHeight;
      setInitialShowChatClick(true);
    }
  }, [isChatHidden]);

  const _sendMessage = () => {
    // let current = refContainer;
    // if (current !== null) {
    //   refContainer.current.scrollTop = refContainer.current.scrollHeight;
    // }
    if (!inputValue) return;

    if (!_.isNull(user)) {
      let chat = {
        // element_jid: getRoomJID() + Constants.GROUP_JID_POSTFIX + '/' + APP.bare_jid,
        element_jid: getRoomJID() + Constants.GROUP_JID_POSTFIX,
        type: Constants.XMPP_STANZA_TYPES.GROUP,
        ...user,
      };
      // console.log("_sendMessage ", chat, inputValue);
      sendMessage(chat, inputValue);

      setInputValue("");
    }
  };

  const _handleKeyDown = (e) => {
    if (e.key == "Enter") {
      setInputValue(e.target.value);
      _sendMessage();
    }
    // console.log("_handleKeyDown ", e);
  };

  const detectScrollTop = (e, chat) => {
    const { scrollTop, scrollHeight } = refContainer.current;
    let diff = scrollHeight - (30 / 100) * scrollHeight;
    if (scrollTop >= diff && scrollTop <= scrollHeight) {
      scrollOnSend = true;
    } else {
      scrollOnSend = false;
    }
    if (scrollTop <= 1500) {
      lastScrollLocation = scrollHeight - scrollTop;
      debounced(100, () => {
        if (!isXmppFetchingChatHistoryOnShowDetail) {
          dispatch(setIsXmppFetchingChatHistoryOnShowDetail(true));
          getChatHistory(chat);
        }
      })();
    }
  };

  const chatStyle = !XMPP_USER_RECENT_CHATS.length
    ? {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }
    : null;

  return (
    <div
      className={cx("flex-shrink-0 chatHosts vh-100", {
        "d-none": isChatHidden,
      })}
    >
      <div
        className={cx(styles.chatBar, "position-relative", "bg-dark", "h-100")}
      >
        <div
          className={cx(styles.chatWrp, "p-3", "h-100", "chatScrollM")}
          style={chatStyle}
          ref={refContainer}
          onScroll={(e) => {
            detectScrollTop(e, {
              element_jid: getRoomJID() + Constants.GROUP_JID_POSTFIX,
              type: Constants.XMPP_STANZA_TYPES.GROUP,
              chatHistoryPaginationData: recentChatPagination,
            });
          }}
        >
          {chatLoading ? (
            <div className="d-flex align-items-center justify-content-center">
              <PuffLoader
                color={"red"}
                loading={chatLoading}
                css={override}
                size={175}
              />
            </div>
          ) : (
            XMPP_USER_RECENT_CHATS.map((chat, index) => {
              if (
                chat.element_jid ==
                getRoomJID() + Constants.GROUP_JID_POSTFIX
              ) {
                if (hasChatMessage == 0) {
                  store.dispatch(setHasChatMessage(1));
                }
              }
              return chat.element_jid ==
                getRoomJID() + Constants.GROUP_JID_POSTFIX ? (
                <div className="d-flex pt-3" key={index}>
                  <div className="flex-shrink-0 me-2">
                    <div className="img-44 rounded-circle overflow-h bg-accent-blue d-flex align-items-center justify-content-center">
                      {/* <img
                                className="img-fluid"
                                src={
                                    chat.profile_image
                                    ? chat.profile_image != ""
                                        ? chat.profile_image
                                        : chatImg1
                                    : chatImg1
                                }
                                alt="user"
                                /> */}
                      {!_.isNull(chat.profile_image) &&
                        !_.isEmpty(chat.profile_image) ? (
                        <img
                          className="img-fluid"
                          src={chat.profile_image}
                          alt="user"
                        />
                      ) : (
                        <span className="text-white">
                          {!_.isNull(chat.first_name) &&
                            !_.isNull(chat.last_name) && (
                              <>
                                <span>
                                  {chat.first_name.charAt(0)}
                                  {chat.last_name.charAt(0)}
                                </span>
                              </>
                            )}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="row chatBottom pb-2">
                      <div className="col-sm-6 col-6">
                        <div className="d-flex">
                          <p className="text-white mb-0 fw-bold small">
                            {chat.first_name && chat.last_name
                              ? firstNameLastInitial(
                                chat.first_name,
                                chat.last_name
                              )
                              : chat.element_jid}{" "}
                            {/* {chat.last_name
                                        ? chat.last_name
                                        : chat.element_jid} */}
                          </p>
                          {/* <img
                                    className="img-fluid px-2"
                                    src={chatCircle}
                                    alt="chat-circle"
                                    /> */}
                          <span className="text-secondary small"></span>
                        </div>
                        <div className="text-white small mb-0 pt-1">
                          <div className={classes.msgboxwidth}>{chat.body}</div>
                        </div>
                        <div className="d-flex mt-2">
                          <div className="d-flex align-items-center me-2"></div>
                          <div className="d-flex align-items-center"></div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-6">
                        <p className="text-secondary mb-0 small text-end">
                          {chat.message_time
                            ? moment(parseInt(chat.message_time)).fromNow()
                            : "Unknown Time"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              );
            })
          )}
          <div
            className="d-flex align-items-center justify-content-center p-3 d-none"
            id="noMessageContainer"
          >
            <div className="w-100">
              <div className="text-white mb-0 d-flex align-items-center justify-content-center flex-column">
                <h6 className="mt-2 mb-0">No Messages</h6>
              </div>
            </div>
          </div>
          {/* <div className="d-flex">
            <div className="w-100">
              <div className="d-flex pb-2">
                <div className="w-100">
                  <div className="text-white mb-0">
                    <h3>
                      <br />
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div
          className={cx(
            styles.chatBox,
            "bg-white",
            "px-2",
            "py-1",
            "chatInputM",
            "windowChatH"
          )}
        >
          <div className="d-flex align-items-center">
            {!_.isNull(user) && (
              <div className="chatIMg rounded-circle overflow-h bg-accent-blue d-flex align-items-center justify-content-center">
                {/* <img className="img-fluid" src={user.profile_image || chatImg3} alt="user" /> */}
                {!_.isNull(user?.profile_image) &&
                  !_.isEmpty(user?.profile_image) ? (
                  <img
                    className="img-fluid"
                    src={user?.profile_image}
                    alt="user"
                  />
                ) : (
                  <span className="text-white">
                    {!_.isNull(user?.first_name) && !_.isNull(user?.last_name) && (
                      <>
                        <span>
                          {user?.first_name.charAt(0)}
                          {user?.last_name.charAt(0)}
                        </span>
                      </>
                    )}
                  </span>
                )}
              </div>
            )}
            <input
              type="text"
              className="form-control border-0"
              placeholder="Type a Message"
              value={inputValue}
              onKeyDown={(e) => _handleKeyDown(e)}
              onChange={(e) => setInputValue(e.target.value)}
            />
            {/* <img src={smileImg} alt="smile img" /> */}
            <div className="bg-danger p-1 rounded ms-2" onClick={_sendMessage}>
              <img className="img-fluid" src={sentIcon} alt="sent icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
