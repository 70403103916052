/** @format */

// export function authHeader(token) {
//   let jwt =
//     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJmMjc3M2M5Ny0zZjAwLTQ2YzYtOTI1NS1mZDcxNDJhMTc0ZjQiLCJtb2JpbGUiOiIrOTE5NTE5NTE5NTE5IiwiZ2VuZXJhdGVkQXQiOiIyMDIyLTExLTAyVDExOjA4OjM3Ljg1M1oiLCJpdiI6IjRjNzY3MzIxMjYzYzcyMjYiLCJrZXkiOiI2NjRmMmYzNzYzNTYyNDUwMzAyNTVlMzY1NDQ5Mzc1YiIsImlhdCI6MTY2NzM4NzMxNywiZXhwIjoxNjY4MjUxMzE3LCJpc3MiOiJob29remFwcCJ9.UczS8jGU2CwY-Ipt4EynOMxqTe-Y2tec0chB6aymHwg";
//   if (token) jwt = token;
//   // return authorization header with jwt token
//   let user = JSON.parse(localStorage.getItem("user"));

//   if (user && user.token_type && user.access_token) {
//     return {
//       // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI4ODViODkxMi05ZDkwLTQ1MDctYjU5Yi02NzA4ZTU2YjlhMWEiLCJtb2JpbGUiOiIrOTE3NDYyOTA5NjQ2IiwiZ2VuZXJhdGVkQXQiOiIyMDIyLTA5LTIwVDA5OjM4OjM5LjIyNVoiLCJpdiI6IjRjNzY3MzIxMjYzYzcyMjYiLCJrZXkiOiI2NjRmMmYzNzYzNTYyNDUwMzAyNTVlMzY1NDQ5Mzc1YiIsImlhdCI6MTY2MzY2NjcxOSwiZXhwIjoxNjY0NTMwNzE5LCJpc3MiOiJob29remFwcCJ9.8qLqfpSpL0m56ARDhNO_472fK3WYAL2VQv7SyH3V01Y`,
//       // "Content-Type": "application/json",
//       // Accept: "application/json",
//     };
//   } else {
//     return {
//       Authorization: `Bearer ${jwt}`,
//       "Content-Type": "application/json",
//       Accept: "application/json",
//     };
//   }
// }
export function authHeader(token) {
  if (token) {
    return {
      Authorization: `Bearer ${token} `,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  } else {
    return {
      //Authorization: `Bearer ${token} `,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }
}

export function authHeaderFormData() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));

  if (user && user.token_type && user.access_token) {
    return {
      Authorization: `${user.token_type} ${user.access_token}`,
      "Content-Type": "multipart/form-data",
    };
  } else {
    return {
      "Content-Type": "multipart/form-data",
    };
  }
}
