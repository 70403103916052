import {Video} from "./Video";
import {FullScreenVideo} from "./FullScreenVideo";

export const ActivePlayerLayout = ({setBgBlurEnabled, isChatHidden, rowVideos, activeVideo, isAdmin, isHost, spotlight, handleToggleMic, debugMode}) => {
    return <div>
        <div className="d-flex h-100 justify-content-center">
            <div className="row g-0 align-items-center">
                <div className="col align-items-center d-flex">
                {rowVideos
                .map(({id, htmlElement, firstName, lastName, enabled, currentEncoding, profile_image}, index) => <Video
                    index={index}
                    totalVideos={rowVideos.length || 1}
                    profile_image={profile_image}
                    spotlight={spotlight}
                    id={id}
                    htmlElement={htmlElement}
                    firstName={firstName}
                    lastName={lastName}
                    micEnabled={enabled?.mic}
                    cameraEnabled={enabled?.camera}
                    isAdmin={isAdmin}
                    isHost={isHost}
                    handleToggleMic={handleToggleMic}
                    isChatHidden={isChatHidden}
                    debugMode={debugMode}
                    currentEncoding={currentEncoding}
                />)}
                </div>
            </div>
        </div>
        <FullScreenVideo
            activeVideo={activeVideo}
            isChatHidden={isChatHidden}
        />
    </div>
}