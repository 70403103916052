/** @format */
import * as client from "mediasoup-client";
import store from "../store";
import { setConnectionStatus } from "../store/slices/connection";
import { preCallActions } from "../store/slices/precall";
import { Signaling } from "../Services/socketService";
import { VideoFetcher } from "../Pages/Admin/liveVideo/VideoFetcher";
// packages
import moment from "moment";

// services
import { showService } from "../_services/admin";

const ENCCODINGS = [
  [
    {
      ssrc: 111110,
      scaleResolutionDownBy: 1,
      active: true,
      maxBitrate: 150000,
    },
  ],
  [
    {
      ssrc: 111110,
      scaleResolutionDownBy: 3,
      active: true,
      maxBitrate: 150000,
    },
  ],
  [
    {
      ssrc: 111110,
      scaleResolutionDownBy: 50,
      active: false,
      maxBitrate: 150000,
    },
    {
      ssrc: 111112,
      scaleResolutionDownBy: 50,
      active: false,
      maxBitrate: 350000,
    },
  ],
];

const mediaType = {
  audio: "audioType",
  video: "videoType",
  screen: "screenType",
};
const _EVENTS = {
  exitRoom: "exitRoom",
  openRoom: "openRoom",
  startVideo: "startVideo",
  stopVideo: "stopVideo",
  startAudio: "startAudio",
  stopAudio: "stopAudio",
  startScreen: "startScreen",
  stopScreen: "stopScreen",
  connected: "connected",
  addScreenshare: "addScreenshare",
  addVideo: "addVideo",
  addAudio: "addAudio",
  peerDisconnect: "peerDisconnect",
  peersConnect: "peersConnect",
  peerUpdate: "peerUpdate",
  setSpotlight: "setSpotlight",
  setActive: "setActive",
  reload: "reload",
  showDebugMessage: "showDebugMessage",
};

let producer = null;
let remoteVideoStreams = [];
let micTimeOutRef = null;
let existingLocalSpeakerVideoStreamUserId = null;
let remoteSpeakersTime = {};
let currentSpeaker = 0;
let currentTime = moment().valueOf();
let lastCalledTime = 0;

export default class RoomClient {
  _instance;

  constructor(signaling, room_id, metadata, enabled, navigate) {
    this.currentLocalDownscaleStrategy = 2;
    this.metadata = metadata;
    this.peers = [];
    this.enabled = enabled;

    this.signaling = signaling;
    this.producerTransport = null;
    this.consumerTransport = null;
    this.device = null;
    this.room_id = room_id;

    this.isVideoOnFullScreen = false;
    this.isDevicesVisible = false;

    this.consumers = new Map();
    this.producers = new Map();

    this.consumerStatIntervals = {};
    this.remoteScores = {};

    this.navigate = navigate;

    /**
     * map that contains a mediatype as key and producer_id as value
     */
    this.producerLabel = new Map();

    this._isOpen = false;
    this.eventListeners = new Map();

    Object.keys(_EVENTS).forEach(
      function (evt) {
        this.eventListeners.set(evt, []);
      }.bind(this)
    );

    this.createRoom(room_id).then(
      async function () {
        await this.join(metadata, room_id, enabled);
        this.initSockets();
        this._isOpen = true;
      }.bind(this)
    );
  }

  static initialize(signaling, room_id, metadata, enabled, navigate) {
    this._instance = new RoomClient(
      signaling,
      room_id,
      metadata,
      enabled,
      navigate
    );
    window.rc = this._instance;
  }

  static getInstance() {
    if (!this._instance) {
      console.log("Room client not initiaized yet");
      // throw new Error("Room client not initialized yet");
    }
    return this._instance;
  }

  ////////// INIT /////////

  async createRoom(room_id) {
    await this.signaling
      .request("createRoom", {
        room_id,
      })
      .catch((err) => {
        console.log("Create room error:", err);
      });
  }

  async resetExistingLocalSpeakerVideoStreamUserId() {
    existingLocalSpeakerVideoStreamUserId = null;
  }

  async join(
    metadata,
    room_id,
    enabled = { screen: false, camera: false, mic: false }
  ) {
    this.signaling
      .request("join", {
        metadata,
        room_id,
        enabled,
        currentEncoding: this._currentEncoding,
      })
      .then(async ({ error, peers, spotlight }) => {
        if (error) {
          alert(error);
          return;
        }
        const data = await this.signaling.request("getRouterRtpCapabilities");
        let device = await this.loadDevice(data);
        this.device = device;
        await this.initTransports(device);
        this.event(
          _EVENTS.connected,
          peers.filter((x) => x.id !== this.signaling.socket.id)
        );
        this.peers = peers;
        this.event(_EVENTS.setSpotlight, { id: spotlight });
        this.signaling.emit("getProducers");
      })
      .catch((err) => {
        console.log("Join error:", err);
      });
  }

  async loadDevice(routerRtpCapabilities) {
    let device;
    try {
      device = new client.Device();
    } catch (error) {
      if (error.name === "UnsupportedError") {
        console.error("Browser not supported");
        alert("Browser not supported");
      }
      console.error(error);
    }
    await device.load({
      routerRtpCapabilities,
    });
    return device;
  }

  async initTransports(device) {
    // init producerTransport
    {
      const data = await this.signaling.request("createWebRtcTransport", {
        forceTcp: false,
        rtpCapabilities: device.rtpCapabilities,
      });

      if (data.error) {
        console.error(data.error);
        return;
      }

      this.producerTransport = device.createSendTransport(data);
      this.producerTransport.on("connectionstatechange", (state) => {
        if (state !== "connected") {
          this.event(_EVENTS.showDebugMessage, {
            message: `Your producing webrtc connection is ${state}`,
          });
        }
      });

      this.producerTransport.on(
        "connect",
        async ({ dtlsParameters }, callback, errback) => {
          this.signaling
            .request("connectTransport", {
              dtlsParameters,
              transport_id: data.id,
            })
            .then(callback)
            .catch(errback);
        }
      );

      this.producerTransport.on(
        "produce",
        async ({ kind, rtpParameters, appData }, callback, errback) => {
          try {
            const { producer_id } = await this.signaling.request("produce", {
              producerTransportId: this.producerTransport.id,
              kind,
              rtpParameters,
              type: appData.type,
            });
            callback({
              id: producer_id,
            });
          } catch (err) {
            errback(err);
          }
        }
      );

      this.producerTransport.on("connectionstatechange", (state) => {
        switch (state) {
          case "connecting":
            break;

          case "connected":
            //localVideo.srcObject = stream
            break;

          case "failed":
            this.producerTransport.close();
            break;

          default:
            break;
        }
      });
    }

    // init consumerTransport
    {
      const data = await this.signaling.request("createWebRtcTransport", {
        forceTcp: false,
      });

      if (data.error) {
        console.error(data.error);
        return;
      }

      // only one needed
      this.consumerTransport = device.createRecvTransport(data);
      this.producerTransport.on("connectionstatechange", (state) => {
        if (state !== "connected") {
          this.event(_EVENTS.showDebugMessage, {
            message: `Your producing webrtc connection is ${state}`,
          });
        } else {
          this.event(_EVENTS.showDebugMessage, {
            clear: true,
          });
        }
      });
      this.consumerTransport.on(
        "connect",
        ({ dtlsParameters }, callback, errback) => {
          this.signaling
            .request("connectTransport", {
              transport_id: this.consumerTransport.id,
              dtlsParameters,
            })
            .then(callback)
            .catch(errback);
        }
      );

      this.consumerTransport.on("connectionstatechange", async (state) => {
        switch (state) {
          case "connecting":
            break;

          case "connected":
            //remoteVideo.srcObject = await stream;
            //await socket.request('resume');
            break;

          case "failed":
            this.consumerTransport.close();
            break;

          default:
            break;
        }
      });
    }
  }

  initSockets() {
    this.signaling.on("consumerClosed", ({ consumer_id }) => {
      console.log("Closing consumer:", consumer_id);
      this.removeConsumer(consumer_id);
    });

    this.signaling.on("setCurrentStats", ({ stats }) => {
      try {
        Object.keys(stats).map((peerId) => {
          const stat = stats[peerId];
          if (document.getElementById(`heights-${peerId}`)) {
            document.getElementById(
              `heights-${peerId}`
            ).innerHTML = `${stat.height1} / ${stat.height2}`;
          }
          if (document.getElementById(`currentEncoding-${peerId}`)) {
            document.getElementById(`currentEncoding-${peerId}`).innerHTML =
              stat.currentEncoding;
          }
          if (document.getElementById(`bigVideoConsumers-${peerId}`)) {
            document.getElementById(`bigVideoConsumers-${peerId}`).innerHTML =
              stat.bigVideoConsumers;
          }
          if (document.getElementById(`qlr1-${peerId}`)) {
            const qlr1 = stat.qlr1 || {};
            if (qlr1["none"]) {
              qlr1["n"] = qlr1["none"];
              delete qlr1["none"];
              qlr1["o"] = qlr1["other"];
              delete qlr1["other"];
              qlr1["b"] = qlr1["bandwidth"];
              delete qlr1["bandwidth"];
              qlr1["c"] = qlr1["cpu"];
              delete qlr1["cpu"];
            }
            document.getElementById(`qlr1-${peerId}`).innerHTML =
              JSON.stringify(qlr1);
          }
          if (document.getElementById(`qlr2-${peerId}`)) {
            const qlr2 = stat.qlr2 || {};
            if (qlr2["none"]) {
              qlr2["n"] = qlr2["none"];
              delete qlr2["none"];
              qlr2["o"] = qlr2["other"];
              delete qlr2["other"];
              qlr2["b"] = qlr2["bandwidth"];
              delete qlr2["bandwidth"];
              qlr2["c"] = qlr2["cpu"];
              delete qlr2["cpu"];
            }
            document.getElementById(`qlr2-${peerId}`).innerHTML =
              JSON.stringify(qlr2 || {});
          }
        });
      } catch (e) {
        console.error(e);
      }
    });

    this.signaling.on("setSpotlight", ({ id }) => {
      this.event(_EVENTS.setSpotlight, { id });
    });

    this.signaling.on("setActivePlayer", ({ id }) => {
      this.event(_EVENTS.setActive, { id });
    });

    this.signaling.on("peerDisconnect", ({ user_id }) => {
      this.event(_EVENTS.peerDisconnect, { user_id });
    });

    this.signaling.on(
      "peerConnect",
      ({ user_id, metadata, enabled, currentEncoding }) => {
        this.peers.push({ id: user_id, metadata, enabled });
        this.event(_EVENTS.peersConnect, [
          { id: user_id, metadata, enabled, currentEncoding },
        ]);
      }
    );

    this.signaling.on("setEnabled", ({ id, enabled }) => {
      this.event(_EVENTS.peerUpdate, { enabled, id });
    });

    this.signaling.on("setCurrentEncoding", ({ id, currentEncoding }) => {
      this.event(_EVENTS.peerUpdate, { currentEncoding, id });
    });

    this.signaling.on("local-score", ({ score }) => {
      let elem = document.getElementById(
        `score-${Signaling.getInstance().socket.id}`
      );
      if (elem) {
        elem.innerHTML = score;
      }
      this.remoteScores[Signaling.getInstance().socket.id] = score;
    });

    this.signaling.on("remote-score", ({ score, userId }) => {
      console.log({ score, userId });
      let elem = document.getElementById(`score-${userId}`);
      if (elem) {
        elem.innerHTML = score;
      }
      this.remoteScores[userId] = score;
    });

    /**
     * data: [ {
     *  producer_id:
     *  producer_socket_id:
     * }]
     */
    this.signaling.on("newProducers", async (data) => {
      console.log("New producers", data);
      for (let { producer_id, kind, type, user_id } of data) {
        await this.consume(producer_id, kind, type, user_id);
      }
    });

    this.signaling.on("broadcast", async ({ type, payload }) => {
      if (type === "session_ended") {
        if (payload.end_meeting === 1) {
          this.destroy();
          this.navigate(`/admin/show-detail/${this.room_id}`);
        }
      }
    });

    this.signaling.on("disconnect", () => {
      this.exit(true);
      this.destroy();
    });
  }

  //////// MAIN FUNCTIONS /////////////

  async produce(
    type,
    deviceId = null,
    streamReceived = null,
    showId = null,
    userId = null
  ) {
    let mediaConstraints = {};
    let params = {
      appData: {},
      stopTracks: false,
    };
    let audio = false;
    let screen = false;
    let streamType = 0;
    switch (type) {
      case mediaType.audio:
        mediaConstraints = {
          audio: {
            deviceId: deviceId,
          },
          video: false,
        };
        audio = true;
        streamType = 1;
        params.appData.type = "audio";
        this.setEnabled({
          mic: true,
        });
        break;
      case mediaType.video:
        mediaConstraints = {
          audio: false,
          video: {
            width: {
              min: 640,
              ideal: 1280,
            },
            height: {
              min: 400,
              ideal: 720,
            },
            // width: { exact: 100 },
            // height: { exact: 100 },
            deviceId: deviceId,
            /*aspectRatio: {
                            ideal: 1.7777777778
                        }*/
          },
        };
        streamType = 2;
        params.appData.type = "video";
        this.setEnabled({
          camera: true,
        });
        break;
      case mediaType.screen:
        mediaConstraints = false;
        screen = true;
        streamType = 3;
        params.appData.type = "screen";
        this.setEnabled({
          screen: true,
        });
        break;
      default:
        return;
    }
    if (!this?.device?.canProduce("video") && !audio) {
      console.error("Cannot produce video");
      return;
    }
    if (this.producerLabel.has(type)) {
      console.log("Producer already exists for this type " + type);
      return;
    }

    try {
      let stream = streamReceived;

      const track = audio
        ? stream.getAudioTracks()[0]
        : stream.getVideoTracks()[0];
      params.track = track;
      if (!audio && !screen) {
        params.encodings = ENCCODINGS[0];
        this._currentEncoding = 0;
        params.codecOptions = {
          videoGoogleStartBitrate: 1000,
        };
      }
      producer = await this.producerTransport.produce(params);

      // console.log("Producer", producer);

      this.producers.set(producer.id, producer);
      let divElem;

      if (type === mediaType.video) {
        this.currentVideoProducer = producer;
        // this._startLocalVideoAnalyser(producer, track);
      }

      producer.on("trackended", () => {
        this.closeProducer(type);
      });

      producer.on("transportclose", () => {
        console.log("Producer transport close");
        if (!audio) {
          let elem = document.getElementById(producer.id);
          elem.parentNode.removeChild(elem);
        }
        this.producers.delete(producer.id);
      });

      producer.on("close", () => {
        console.log("Closing producer");
        this.producers.delete(producer.id);
      });

      this.producerLabel.set(type, producer.id);

      switch (type) {
        case mediaType.audio:
          this.event(_EVENTS.startAudio);
          break;
        case mediaType.video:
          this.event(_EVENTS.startVideo);
          break;
        case mediaType.screen:
          this.event(_EVENTS.startScreen);
          break;
        default:
          return;
      }
      // save streams
      // showService.addStreams({
      //   user_id: userId,
      //   show_id: showId,
      //   producer_id: producer.id,
      //   type: streamType,
      // });
      console.log("producerId addstreams ", producer.id);
      return producer;
    } catch (err) {
      console.log("addstream Produce error:", err);
    }
  }

  _startRemoteVideoAnalyser(consumer, userId) {
    if (this.consumerStatIntervals[userId]) {
      window.clearInterval(this.consumerStatIntervals[userId]);
    }

    let prevFramesDropped = 0;
    let prevPacketsLost = 0;
    let prevBitrate = 0;
    let curFramesDroppedArray = [];
    let curPacketsLostArray = [];

    this.consumerStatIntervals[userId] = window.setInterval(async () => {
      const stats = await consumer.getStats();
      const statsArray = [];
      stats.forEach((stat) => {
        if (stat.type === "inbound-rtp") {
          statsArray.push(stat);
        }
      });
      const stat = statsArray[0];
      if (stat) {
        if (document.getElementById(`dimensions-${userId}`)) {
          document.getElementById(
            `dimensions-${userId}`
          ).innerHTML = `${stat.frameWidth}x${stat.frameHeight}`;
        }
        if (document.getElementById(`fps-${userId}`)) {
          document.getElementById(`fps-${userId}`).innerHTML =
            stat.framesPerSecond;
        }

        const curFramesDropped = stat.framesDropped - prevFramesDropped;
        prevFramesDropped = stat.framesDropped;
        if (document.getElementById(`framesDropped-${userId}`)) {
          document.getElementById(`framesDropped-${userId}`).innerHTML =
            curFramesDropped;
        }
        const curPacketsLost = stat.packetsLost - prevPacketsLost;
        prevPacketsLost = stat.packetsLost;
        if (document.getElementById(`packetsLost-${userId}`)) {
          document.getElementById(`packetsLost-${userId}`).innerHTML =
            curPacketsLost;
        }

        if (document.getElementById(`packetsLost-${userId}`)) {
          const curBitrate = stat.bytesReceived - prevBitrate;
          document.getElementById(`bitrate-${userId}`).innerHTML =
            (8 * curBitrate) / 1000;
          prevBitrate = stat.bytesReceived;
        }

        curFramesDroppedArray.push(curFramesDropped);
        curPacketsLostArray.push(curPacketsLost);
        curFramesDroppedArray = curFramesDroppedArray.slice(-5);
        curPacketsLostArray = curPacketsLostArray.slice(-5);

        const average = (arr) => {
          if (!arr.length) {
            return 0;
          }
          let sum = 0;
          arr.forEach((x) => (sum += x));
          return sum / arr.length;
        };

        if (
          !this.forcedAllSmallVideosTimeout &&
          curFramesDroppedArray.length >= 4 &&
          (average(curFramesDroppedArray) > 10 ||
            average(curPacketsLostArray) > 10)
        ) {
          this.event(_EVENTS.showDebugMessage, {
            message: "You seem to be having a bad internet connection",
          });
          if (document.getElementById(`localThrottle`)) {
            document.getElementById(`localThrottle`).innerHTML = "true";
          }
          this.forcedAllSmallVideosTimeout = window.setTimeout(() => {
            this.forcedAllSmallVideosTimeout = null;
            if (document.getElementById(`localThrottle`)) {
              document.getElementById(`localThrottle`).innerHTML = "false";
            }
          }, 60 * 1000);
          // this.changeSpatialLayerBulk("", null, true);
          // this.forcedAllSmallVideosTimeout = window.setTimeout(() => {
          //   this.forcedAllSmallVideosTimeout = null;
          //   this.changeSpatialLayerBulk(this.currentlyActiveConsumer, null);
          // }, 60 * 1000)
        }
      }
    }, 1000);
  }

  _applyLowerConstraints() {
    console.warn("We not start off with 360 video");
    return;
    // if (!this._lowerConstraintsApplied) {
    //     this._lowerConstraintsApplied = true;
    //     VideoFetcher.getInstance()?.videoStream?.getVideoTracks()?.[0]?.applyConstraints({
    //       height: { ideal: 360 }, width: { ideal: 640 }, frameRate: 24, aspectRatio: 1.777777778,
    //     });
    // }
  }

  _updateOutgoingStats(stats) {
    if (JSON.stringify(stats) !== JSON.stringify(this._currentStats || {})) {
      this._currentStats = stats;
      this.signaling.emit("setCurrentStats", {
        stats: stats,
      });
    }
  }

  _startLocalVideoAnalyser(producer, track) {
    if (this.localVideoAnalysisInterval) {
      window.clearInterval(this.localVideoAnalysisInterval);
    }

    let prevFramesEncoded = 0;
    let prevEncodeTime = 0;
    let encodeTimePerFrameArray = [];
    this._currentEncoding = 0;

    this.localVideoAnalysisInterval = window.setInterval(async () => {
      const stats = await producer.getStats();
      let statsArray = [];
      let finalStats = {};
      stats.forEach((stat) => {
        if (stat.type === "outbound-rtp") {
          statsArray.push(stat);
        }
      });
      statsArray.sort((a, b) => b.frameHeight - a.frameHeight);
      finalStats.height1 = statsArray[0].frameHeight || "-";
      if (document.querySelector("#height1")) {
        document.querySelector("#height1").innerHTML =
          statsArray[0].frameHeight || "-";
      }
      finalStats.height2 = statsArray[1].frameHeight || "-";
      if (document.querySelector("#height2")) {
        document.querySelector("#height2").innerHTML =
          statsArray[1].frameHeight || "-";
      }
      finalStats.qlr1 = statsArray[0].qualityLimitationDurations || {};
      if (document.querySelector("#qlr1")) {
        document.querySelector("#qlr1").innerHTML = JSON.stringify(
          statsArray[0].qualityLimitationDurations || {}
        );
      }

      finalStats.qlr2 = statsArray[1].qualityLimitationDurations || {};
      if (document.querySelector("#qlr2")) {
        document.querySelector("#qlr2").innerHTML = JSON.stringify(
          statsArray[1].qualityLimitationDurations || {}
        );
      }

      finalStats.currentEncoding = this._currentEncoding;
      try {
        finalStats.bigVideoConsumers = (this.bigVideoConsumers || "").substr(
          0,
          10
        );
      } catch (e) {}

      this._updateOutgoingStats(finalStats);

      const stat = statsArray[0].frameHeight ? statsArray[0] : statsArray[1];

      if (stat) {
        let localEncodeTime = stat.totalEncodeTime - prevEncodeTime;
        let localFramesEncoded = stat.framesEncoded - prevFramesEncoded;
        const encodeTimePerFrame = localFramesEncoded
          ? ((1000 * localEncodeTime) / localFramesEncoded).toFixed(1)
          : 0;
        if (document.querySelector("#encodeTime")) {
          document.querySelector("#encodeTime").innerHTML = encodeTimePerFrame;
        }
        prevFramesEncoded = stat.framesEncoded;
        prevEncodeTime = stat.totalEncodeTime;
        encodeTimePerFrameArray.push(encodeTimePerFrame);
        encodeTimePerFrameArray = encodeTimePerFrameArray.slice(-10);

        if (this.currentLocalDownscaleStrategy === 0) {
          if (this._currentEncoding !== 2 && encodeTimePerFrame > 15) {
            this._lastEncodingUpdateTS = Date.now();
            if (this._currentEncoding === 0) {
              this._setCurrentEncodings(producer, 1);
            } else if (this._currentEncoding === 1) {
              this._applyLowerConstraints();
              this._setCurrentEncodings(producer, 2);
            }
          } else if (
            this._lastEncodingUpdateTS &&
            Date.now() - this._lastEncodingUpdateTS > 1000 * 60 &&
            encodeTimePerFrame < 7
          ) {
            this._lastEncodingUpdateTS = Date.now();
            if (this._currentEncoding !== 0) {
              this._setCurrentEncodings(producer, this._currentEncoding - 1);
            }
          } else {
            const timeLeftToRecon =
              this._currentEncoding !== 0
                ? ((Date.now() - this._lastEncodingUpdateTS) / 1000).toFixed(1)
                : this._lastEncodingUpdateTS;
            if (document.querySelector("#reconTime")) {
              document.querySelector("#reconTime").innerHTML =
                this._currentEncoding !== 0 ? 60 - timeLeftToRecon : 0;
            }
          }
        }

        if (this.currentLocalDownscaleStrategy === 1) {
          if (
            this._currentEncoding !== 2 &&
            encodeTimePerFrameArray[encodeTimePerFrameArray.length - 1] > 20 &&
            encodeTimePerFrameArray[encodeTimePerFrameArray.length - 2] > 20
          ) {
            this._lastEncodingUpdateTS = Date.now();
            if (this._currentEncoding === 0) {
              this._setCurrentEncodings(producer, 1);
            } else if (this._currentEncoding === 1) {
              this._applyLowerConstraints();
              this._setCurrentEncodings(producer, 2);
            }
          } else if (
            this._lastEncodingUpdateTS &&
            Date.now() - this._lastEncodingUpdateTS > 1000 * 30 &&
            encodeTimePerFrame < 9
          ) {
            this._lastEncodingUpdateTS = Date.now();
            if (this._currentEncoding !== 0) {
              this._setCurrentEncodings(producer, this._currentEncoding - 1);
            }
          } else {
            const timeLeftToRecon =
              this._currentEncoding !== 0
                ? ((Date.now() - this._lastEncodingUpdateTS) / 1000).toFixed(1)
                : this._lastEncodingUpdateTS;
            if (document.querySelector("#reconTime")) {
              document.querySelector("#reconTime").innerHTML =
                this._currentEncoding !== 0 ? 30 - timeLeftToRecon : 0;
            }
          }
        }

        if (this.currentLocalDownscaleStrategy === 2) {
          if (
            this._currentEncoding !== 2 &&
            encodeTimePerFrameArray[encodeTimePerFrameArray.length - 1] > 30 &&
            encodeTimePerFrameArray[encodeTimePerFrameArray.length - 2] > 30
          ) {
            this._lastEncodingUpdateTS = Date.now();
            if (this._currentEncoding === 0) {
              this._setCurrentEncodings(producer, 1);
            } else if (this._currentEncoding === 1) {
              this._applyLowerConstraints();
              this._setCurrentEncodings(producer, 2);
            }
          } else if (
            this._lastEncodingUpdateTS &&
            Date.now() - this._lastEncodingUpdateTS > 1000 * 30 &&
            encodeTimePerFrame < 10
          ) {
            this._lastEncodingUpdateTS = Date.now();
            if (this._currentEncoding !== 0) {
              this._setCurrentEncodings(producer, this._currentEncoding - 1);
            }
          } else {
            const timeLeftToRecon =
              this._currentEncoding !== 0
                ? ((Date.now() - this._lastEncodingUpdateTS) / 1000).toFixed(1)
                : this._lastEncodingUpdateTS;
            if (document.querySelector("#reconTime")) {
              document.querySelector("#reconTime").innerHTML =
                this._currentEncoding !== 0 ? 30 - timeLeftToRecon : 0;
            }
          }
        }
      }
    }, 1000);
  }

  _setReconStage(stage) {
    if (document.querySelector("#reconStage")) {
      document.querySelector("#reconStage").innerHTML = stage;
    }
  }

  _setCurrentEncodings(producer, encodingIndex) {
    console.error(":changed parmaters " + encodingIndex);
    this._currentEncoding = encodingIndex;
    this.signaling.emit("setCurrentEncoding", {
      currentEncoding: this._currentEncoding,
    });

    try {
      const parameters = producer?.rtpSender?.getParameters();
      if (!parameters) {
        console.error("Parameters not found");
        return;
      }
      parameters.encodings.forEach((encoding, idx) => {
        let i = Number(encoding.rid?.substr(1));
        if (isNaN(i)) {
          i = idx;
        }
        parameters.encodings[idx] = Object.assign(
          {},
          encoding,
          ENCCODINGS[encodingIndex][i] || {}
        );
      });
      producer.rtpSender.setParameters(parameters);
      this._setReconStage(encodingIndex);
    } catch (e) {
      console.error("Error while updating encodings");
      console.log(e);
    }
  }

  //TODO: @hkirat -> Store last name in the SFU
  async consume(producer_id, kind, type, user_id) {
    this.getConsumeStream(producer_id, user_id, type).then(
      function ({ consumer, stream, kind }) {
        const user_name = this.peers.find(({ id }) => id === user_id)?.name;
        this.consumers.set(consumer.id, consumer);
        if (kind === "video") {
          const elem = document.createElement("video");
          elem.srcObject = stream;
          elem.id = consumer.id;
          elem.playsInline = true;
          elem.autoplay = true;
          this.event(
            type === "video" ? _EVENTS.addVideo : _EVENTS.addScreenshare,
            {
              id: user_id,
              htmlElement: elem,
              isScreenshare: true,
              consumer_id: consumer.id,
            }
          );
          if (type === "video") {
            this._startRemoteVideoAnalyser(consumer, user_id);
          }
        } else if (kind === "audio") {
          const elem = document.createElement("audio");
          elem.srcObject = stream;
          elem.id = consumer.id;
          elem.playsInline = true;
          elem.autoplay = true;
          this.event(_EVENTS.addAudio, {
            id: consumer.id,
            stream,
            firstName: user_name,
            lastName: "",
            userId: user_id,
            htmlElement: elem,
          });
        }

        consumer.on(
          "trackended",
          function () {
            this.removeConsumer(consumer.id);
          }.bind(this)
        );

        consumer.on(
          "transportclose",
          function () {
            this.removeConsumer(consumer.id);
          }.bind(this)
        );
      }.bind(this)
    );
  }

  async getConsumeStream(producerId, userId, type) {
    const { rtpCapabilities } = this.device;
    const data = await this.signaling.request("consume", {
      rtpCapabilities,
      consumerTransportId: this.consumerTransport.id, // might be
      producerId,
      userId,
      type,
    });
    const { id, kind, rtpParameters } = data;

    let codecOptions = {};
    const consumer = await this.consumerTransport.consume({
      id,
      producerId,
      kind,
      rtpParameters,
      codecOptions,
    });

    const stream = new MediaStream();
    stream.addTrack(consumer.track);

    return {
      consumer,
      stream,
      kind,
    };
  }

  setEnabled(enabled) {
    this.signaling.emit("setEnabled", {
      enabled,
    });
    this.enabled = {
      ...this.enabled,
      ...enabled,
    };
  }

  closeProducer(type) {
    if (type === mediaType.video) {
      this.setEnabled({
        camera: false,
      });
    }

    if (type === mediaType.audio) {
      this.setEnabled({
        mic: false,
      });
    }

    if (type === mediaType.screen) {
      this.setEnabled({
        screen: false,
      });
    }

    if (!this.producerLabel.has(type)) {
      console.log("There is no producer for this type " + type);
      return;
    }

    let producer_id = this.producerLabel.get(type);
    console.log("Close producer", producer_id);

    this.signaling.emit("producerClosed", {
      producer_id,
    });

    this.producers.get(producer_id)?.close();
    this.producers.delete(producer_id);
    this.producerLabel.delete(type);
  }

  // replaces the video producer track
  replaceTrack(track) {
    this.currentVideoProducer?.replaceTrack({ track });
  }

  pauseProducer(type) {
    if (!this.producerLabel.has(type)) {
      console.log("There is no producer for this type " + type);
      return;
    }

    let producer_id = this.producerLabel.get(type);
    this.producers.get(producer_id).pause();
  }

  changeSpatialLayerBulk(activeConsumers, bigVideoConsumers, bigVideoUserId) {
    this.bigVideoConsumers = bigVideoUserId;
    this.signaling.emit("changeSpatialLayerBulk", {
      activeConsumers,
      bigVideoConsumers,
    });
    if (document.getElementById(`bigVideoConsumers`)) {
      document.getElementById(`bigVideoConsumers`).innerHTML = bigVideoUserId;
    }
  }

  // changeSpatialLayerBulk(consumer, userId, force) {
  //   if (force || this.forcedAllSmallVideosTimeout) {
  //     this.signaling.emit("changeSpatialLayerBulk", {
  //       consumers: []
  //     })
  //     if (consumer) {
  //       this.currentlyActiveConsumer = consumer;
  //     }
  //     return;
  //   }
  //   if (this.currentlyActiveConsumer !== consumer) {
  //       this.signaling.emit("changeSpatialLayerBulk", {
  //         consumers: [consumer]
  //       })
  //   }
  //   this.currentlyActiveConsumer = consumer;
  // }

  spotlight(id) {
    this.event(_EVENTS.setSpotlight, { id });
    this.signaling.emit("setSpotlight", { id });
  }

  setVolume(volume) {
    this.signaling.emit("setVolume", { volume });
  }

  resumeProducer(type) {
    if (!this.producerLabel.has(type)) {
      console.log("There is no producer for this type " + type);
      return;
    }

    let producer_id = this.producerLabel.get(type);
    this.producers.get(producer_id).resume();
  }

  removeConsumer(consumer_id) {
    this.consumers.delete(consumer_id);

    let existingRemoteScreenShareStream =
      store.getState().precall.remoteScreenShareStream;
    if (
      existingRemoteScreenShareStream &&
      existingRemoteScreenShareStream.id === consumer_id
    ) {
      store.dispatch(preCallActions.setRemoteScreenShareStream(null));
    } else {
      let existingRemoteVideoStreams =
        store.getState().precall.remoteVideoStreams;
      let filteredRemoteVideoStreams = existingRemoteVideoStreams.filter(
        (val) => {
          return val.id !== consumer_id;
        }
      );
      store.dispatch(
        preCallActions.setRemoteVideoStreams(filteredRemoteVideoStreams)
      );
      remoteVideoStreams = filteredRemoteVideoStreams;
      let count = document.querySelectorAll(".vid-new").length;
      if (count >= 0 && count < 2) {
      }
      let videoDivs = document.querySelectorAll(".vid-new");
      let videoDivsCount = videoDivs.length;
      if (videoDivsCount >= 1 && videoDivsCount < 2) {
        for (let i = 0; i < videoDivsCount; i++) {
          videoDivs[i].style.cssText = "width:100%";
        }
      } else if (videoDivsCount >= 2 && videoDivsCount < 3) {
        for (let i = 0; i < videoDivsCount; i++) {
          videoDivs[i].style.cssText = "width:100%";
        }
      }
    }
  }

  exit(offline = false) {
    const clean = () => {
      this._isOpen = false;
      this.consumerTransport.close();
      this.producerTransport.close();
      this.signaling.off("disconnect");
      this.signaling.off("newProducers");
      this.signaling.off("consumerClosed");
    };

    if (!offline) {
      this.signaling
        .request("exitRoom")
        .then((e) => console.log(e))
        .catch((e) => console.warn(e))
        .finally(clean);
    } else {
      clean();
    }
    this.event(_EVENTS.reload);
  }

  ///////  HELPERS //////////

  async roomInfo() {
    let info = await this.signaling.request("getMyRoomInfo");
    return info;
  }

  static get mediaType() {
    return mediaType;
  }

  event(evt, payload) {
    if (this.eventListeners.has(evt)) {
      this.eventListeners.get(evt).forEach((callback) => callback(payload));
    }
  }

  on(evt, callback) {
    this.eventListeners.get(evt).push(callback);
  }

  //////// GETTERS ////////

  isOpen() {
    return this._isOpen;
  }

  static get EVENTS() {
    return _EVENTS;
  }

  shouldSpeak(userId) {
    const currentTime = moment().valueOf();
    try {
      const userTime = remoteSpeakersTime[userId];
      if (!userTime) {
        remoteSpeakersTime[userId] = currentTime;
        return false;
      } else {
        const speakingDuration = currentTime - userTime;
        const isSpeaker =
          speakingDuration >=
          parseInt(process.env.REACT_APP_SPEAKER_MINIMUM_SPEAKING_DURATION);
        if (isSpeaker) remoteSpeakersTime = {};
        return isSpeaker;
      }
    } catch (error) {
      console.log("error ", error);
    }
  }

  destroy() {
    this.closeProducer(mediaType.audio);
    this.closeProducer(mediaType.video);
    this.closeProducer(mediaType.screen);
    this.consumers.forEach((c) => c.close());
    if (this.localVideoAnalysisInterval) {
      window.clearInterval(this.localVideoAnalysisInterval);
    }

    Signaling.destroy();
  }

  setCurrentSpeaker(userId) {
    currentSpeaker = userId;
  }

  broadcastEvent(type, payload) {
    this.signaling.emit("broadcast", {
      type,
      payload,
    });
  }

  getCurrentSpeaker() {
    return currentSpeaker;
  }

  //////// UTILITY ////////

  copyURL() {
    let tmpInput = document.createElement("input");
    document.body.appendChild(tmpInput);
    tmpInput.value = window.location.href;
    tmpInput.select();
    document.execCommand("copy");
    document.body.removeChild(tmpInput);
    console.log("URL copied to clipboard 👍");
  }
}
