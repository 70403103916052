import textDot from "../../images/textDot.svg";
import {RemoteMobileTrack} from "../RemoteMobileTrack";
import React from "react";
import {FullScreenVideoMobile} from "./FullScreenVideoMobile";
import {useSelector} from "react-redux";
import {Video} from "./Video";
import {VideoMobile} from "./VideoMobile";

export const ActivePlayerMobileLayout = ({isChatHidden, rowVideos, activeVideo, isAdmin, isHost, spotlight, handleToggleMic}) => {
    const user = useSelector(state => state.user.user);

    return <div className="h-100 d-flex align-items-center justify-content-center flex-column ldVScroll">
        <div className="col align-items-center d-flex">
            <div>
                <div className="row videoMobileThumb">
                    <div
                        className="d-flex align-items-center h-100"
                    >
                        <FullScreenVideoMobile isChatHidden={isChatHidden} activeVideo={activeVideo} />
                    </div>
                </div>
            </div>
            <div>
                {rowVideos
                    .map(({id, htmlElement, currentEncoding, firstName, lastName, enabled, profile_image}, index) => <VideoMobile
                        index={index}
                        profile_image={profile_image}
                        currentEncoding={currentEncoding}
                        spotlight={spotlight}
                        id={id}
                        htmlElement={htmlElement}
                        firstName={firstName}
                        lastName={lastName}
                        micEnabled={enabled?.mic}
                        cameraEnabled={enabled?.camera}
                        isAdmin={isAdmin}
                        isHost={isHost}
                        handleToggleMic={handleToggleMic}
                        isChatHidden={isChatHidden}
                    />)}
            </div>
            {/*{remoteVideoStreamsArray.length*/}
            {/*    ? remoteVideoStreamsArray[idx].map((ele) => {*/}
            {/*        if (ele?.is_screen_share !== true) {*/}
            {/*            return (*/}
            {/*                <RemoteMobileTrack*/}
            {/*                    key={ele.id}*/}
            {/*                    streamObj={ele}*/}
            {/*                    handleSpotlight={sendSpotlightMessage}*/}
            {/*                    isHost={isHost}*/}
            {/*                    isAdmin={isAdmin}*/}
            {/*                />*/}
            {/*            );*/}
            {/*        }*/}
            {/*    })*/}
            {/*    : null}*/}
        </div>
    </div>
}