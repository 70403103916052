/** @format */

import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePhotoModalHandler,
  registerPayloadData,
  signupModalHandler,
  signupStepsHandler,
  editUserProfileHandler,
} from "../../../store/slices/user";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Cropper from "react-easy-crop";
import { canvasPreview } from "./CanvasPreview";
import classNames from "classnames/bind";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import CloseIcon from "../../../images/cross.svg";
import styles from "./registerAvatar.module.css";
import cameraIcon from "./images/camera.svg";
import { getCroppedImg } from "./getCroppedImage";

import Webcam from "react-webcam";
import { userService } from "../../../_services";

//helpers
import { getItemFromLocalStorage } from "../../../_helpers";
import { Modal } from "react-bootstrap";

const videoConstraints = {
  width: 180,
  height: 180,
  facingMode: "user",
};

const cx = classNames.bind(styles);

const RegisterAvatar = (props) => {
  var socialProfile = JSON.parse(localStorage.getItem("photo"));
  // console.log(socialProfile);

  // const navigate = useNavigate();
  // const location = useLocation();
  const dispatch = useDispatch();
  const { user, changePhotoModal } = useSelector((state) => state.user);
  const webcamRef = useRef(null);
  const [image, setImage] = useState("");
  const [imgSrc, setImgSrc] = useState();
  // const previewCanvasRef = useRef(null);
  // const imgRef = useRef(null);
  // const [crop, setCrop] = useState();
  // const [completedCrop, setCompletedCrop] = useState();
  // const [aspect, setAspect] = useState(1 / 1);
  const [newCrop, setNewCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState("");
  const [croppedImg, setCroppedImg] = useState(null);
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    onCropComplete(imageSrc);
  }, [webcamRef]);

  // const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  //   return centerCrop(
  //     makeAspectCrop(
  //       {
  //         unit: "%",
  //         width: 90,
  //       },
  //       aspect,
  //       mediaWidth,
  //       mediaHeight
  //     ),
  //     mediaWidth,
  //     mediaHeight
  //   );
  // };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      // setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString())
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // const onImageLoad = (e) => {
  //   if (aspect) {
  //     const { width, height } = e.currentTarget;
  //     setCrop(centerAspectCrop(width, height, aspect));
  //   }
  // };

  // const onCropCompleted = () => {
  //   canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
  // };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      setIsFormSubmitting(true);
      const croppedImage = await getCroppedImg(imgSrc, croppedArea, 0);
      // setCroppedImg(croppedImage);
      userService
        .registerAvatar({
          username: user.username,
          image: croppedImage,
        })
        .then((data) => {
          setIsFormSubmitting(false);
          if (data.success === 1) {
            dispatch(
              editUserProfileHandler({
                ...user,
                profile_image: data.data.profile_image,
              })
            );
            localStorage.setItem(
              "user",
              JSON.stringify({
                ...user,
                profile_image: data.data.profile_image,
              })
            );
            dispatch(registerPayloadData({ imgUrl: croppedImage }));
            if (changePhotoModal) {
              dispatch(signupStepsHandler(1));
              dispatch(signupModalHandler(false));
              dispatch(changePhotoModalHandler(false));
            } else {
              dispatch(signupStepsHandler(7));
            }
          }
        });
    } catch (error) {
      setIsFormSubmitting(false);
      console.error(error);
    }
  }, [croppedArea, imgSrc]);

  const [showCamera, setShowCamera] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const handleBackButton = () => {
    if (Boolean(imgSrc)) {
      setImgSrc(!Boolean(imgSrc));
    } else {
      dispatch(signupStepsHandler(5));
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="container">
        <div className=" d-flex align-items-center justify-content-center">
          <div
            className={cx(
              "outerBoxRegister",
              "bg-white",
              "p-4",
              "position-relative"
            )}
          >
            <h2 className="text-center fw-bold mt-5 landscape-mt-0">
              Create an Avatar
            </h2>

            <div
              className={cx("position-absolute", "closeIconRegister")}
              onClick={() => {
                if (changePhotoModal) {
                  dispatch(signupStepsHandler(1));
                  dispatch(signupModalHandler(false));
                  dispatch(changePhotoModalHandler(false));
                } else {
                  dispatch(signupStepsHandler(1));
                }
              }}
            >
              <img className="img-fluid" src={CloseIcon} alt="cross" />
            </div>
            {showCamera ? (
              <div
                className="webcam-container"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Webcam
                  audio={false}
                  height={"200px"}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={"100%"}
                  videoConstraints={videoConstraints}
                />
                <br />
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setShowCamera(!showCamera);
                    capture();
                  }}
                  variant="contained"
                  color="primary"
                >
                  Capture
                </Button>
                <br />
              </div>
            ) : null}

            <div className={cx("content")}>
              <input
                accept="image/*"
                id="icon-button-file"
                style={{ display: "none" }}
                type="file"
                onChange={onSelectFile}
              />

              {Boolean(imgSrc) ? (
                <div className={cx("App")}>
                  <div className={cx("crop-container")}>
                    <Cropper
                      image={imgSrc}
                      crop={newCrop}
                      zoom={zoom}
                      aspect={1}
                      cropShape="round"
                      className="containerStyle"
                      style={{
                        containerStyle: {
                          position: "relative",
                          width: "150px",
                          height: "180px",
                          margin: "auto",
                        },
                      }}
                      onCropChange={setNewCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                      showGrid={false}
                    />
                  </div>
                  <div className={cx("controls")}>
                    <input
                      type="range"
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e) => {
                        setZoom(e.target.value);
                      }}
                      className={cx("zoom-range")}
                    />
                  </div>
                </div>
              ) : (
                // <ReactCrop
                //   crop={crop}
                //   onChange={(_, percentCrop) => setCrop(percentCrop)}
                //   onComplete={(c) => setCompletedCrop(c)}
                //   aspect={aspect}
                // >
                //   <img
                //     ref={imgRef}
                //     src={imgSrc}
                //     style={{
                //       maxWidth: "220px",
                //       maxHeight: "284px",
                //       margin: "auto",
                //       cursor: "pointer",
                //     }}
                //     onLoad={onImageLoad}
                //   />
                // </ReactCrop>
                <label htmlFor="icon-button-file">
                  <Avatar
                    src=""
                    style={{
                      width: "180px",
                      height: "180px",
                      margin: "auto",
                      cursor: "pointer",
                    }}
                  />
                </label>
              )}
              {/* {Boolean(completedCrop) && ( */}
              <div className="canvas-preview">
                {/* <canvas
              ref={previewCanvasRef}
              style={{
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            /> */}
              </div>
              {/* )} */}
            </div>
            <div className="d-flex align-items-center justify-content-center flex-sm-row flex-column mb-5 landscape-mb-5">
              {changePhotoModal === false && (
                <Button
                  className={cx(
                    "mobileBtn btn-light-grey fw-bold py-2 px-4 me-sm-2 me-0 mb-sm-0 mb-2"
                  )}
                  onClick={handleBackButton}
                >
                  Back
                </Button>
              )}

              {!Boolean(imgSrc) && (
                <>
                  {/* <Button
              className={cx("removeTransformation", "nextBtn", "marginleft20")}
              variant="contained"
              onClick={() => {
                navigate("/register");
              }}
            >
              Choose Image
            </Button> */}

                  <>
                    <input
                      accept="image/*"
                      type="file"
                      id="select-image"
                      style={{ display: "none" }}
                      onChange={onSelectFile}
                    />
                    <label htmlFor="select-image">
                      <Button
                        variant="contained"
                        component="span"
                        className="mb-sm-0 mb-2 chooseImg btn-light-grey"
                        style={{
                          height: "40px",
                          width: "150px",
                          paddingBottom: "10px",
                          paddingTop: "10px",
                        }}
                      >
                        Choose Image
                      </Button>
                    </label>
                  </>
                  <Button
                    className={cx(
                      "removeTransformation",
                      "nextBtn",
                      "marginleft20"
                    )}
                    variant="contained"
                    onClick={() => setShowCamera(!showCamera)}
                    endIcon={<img className="img-fluid" src={cameraIcon} />}
                  >
                    Capture
                  </Button>
                </>
              )}
              {Boolean(imgSrc) && (
                <Button
                  className={cx(
                    "removeTransformation",
                    "nextBtn",
                    "marginleft20"
                  )}
                  variant="contained"
                  onClick={showCroppedImage}
                  disabled={isFormSubmitting}
                >
                  {isFormSubmitting ? `Loading...` : `Crop Image`}
                </Button>
              )}
            </div>
            {changePhotoModal === false && (
              <div
                className="text-end cursor-pointer skip"
                onClick={() => {
                  dispatch(signupStepsHandler(7));
                }}
              >
                Skip
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default RegisterAvatar;
