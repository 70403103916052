/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerPayloadData } from "../../../store/slices/user";
import classNames from "classnames/bind";
import Button from "@material-ui/core/Button";
import styles from "./profile.module.css";

const cx = classNames.bind(styles);

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { registerPayload } = useSelector((state) => state.user);

  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="container textcenter">
      <h2 className={cx("title")}>
        Welcome {registerPayload?.first_name || "-"}
      </h2>
      {(registerPayload?.imgUrl ||
        registerPayload?.profile_image ||
        user?.profile_image) && (
        <img
          className={cx("rounded-circle", "img-150")}
          src={
            registerPayload?.imgUrl ||
            registerPayload?.profile_image ||
            user?.profile_image ||
            ""
          }
        />
      )}
      <br />
      <Button
        className={cx("removeTransformation", "nextBtn")}
        onClick={() => {
          dispatch(registerPayloadData({}));
          // localStorage.removeItem("user");
          navigate("/login");
        }}
      >
        logout
      </Button>
    </div>
  );
};
export default Profile;
