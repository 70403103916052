/** @format */

import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./homeSetup.module.css";
import adminStyles from "../admin.module.css";
import Header from "../header/header";
import backArrow from "../images/back_arrow.svg";
import yogaSeniors from "../images/pilates_1.png";
import memoryIcon from "../images/memoryIcon.svg";
import episodeImg from "../images/episodeImg.jpg";
import plusIcon from "../images/plusIcon.svg";
import fitnessIcon from "../images/fitness.svg";
import memoryImg from "../images/memoryImg.jpg";
import fitnessImg from "../images/fitnessImg.jpg";
import closeIcon from "../images/close.svg";
import trashIcon from "../images/trash.svg";
import { commonService, showService } from "../../../_services/admin";
import { useSnackbar } from "notistack";
import _ from "lodash";
import moment from "moment";
import { authHeaderFormData, getItemFromLocalStorage } from "../../../_helpers";
import axios from "axios";
import { GlobalConfig } from "../../../config";
const cx = classNames.bind(styles);
const acx = classNames.bind(adminStyles);

const HomeSetup = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loadingHeroShow, setLoadingHeroShow] = useState(true);
  const [errorHeroShow, setErrorHeroShow] = useState(false);
  const [heroShow, setHeroShow] = useState({});

  const [loadingAllCategories, setLoadingAllCategories] = useState(true);
  const [errorAllCategories, setErrorAllCategories] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [uploadingCategory, setUploadingCategory] = useState(false);
  const [heroStrip, setHeroStrip] = useState("");

  const [values, setValues] = useState({
    id: "",
    category: "",
  });

  const [categoryInfo, setCategoryInfo] = useState({
    iconfile: [],
    imagefile: [],
  });

  const imageSrc = (adminShowImages) => {
    const hero_image = adminShowImages.find((item) => {
      return item.is_hero === 1;
    });
    if (_.isUndefined(hero_image)) {
      return "src/images/default-image.jpeg";
    }
    return hero_image?.hero_url;
  };

  const markShowAsHero = () => {
    if (window.confirm("Are you sure you want to replace this as hero show?")) {
      showService
        .markShowAsHero({
          show_id: values.show_id,
        })
        .then((data) => {
          if (data.success) {
            // setMyScheduledShows(data.data);
            // setLoadingMyScheduledShows(false);
            getHeroShow();
            enqueueSnackbar("Hero show updated", {
              variant: "success",
            });
          } else {
            // setErrorMyScheduledShows(true);
            enqueueSnackbar(data.message, {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            variant: "error",
          });
        });
    }
  };

  const changeHeroStrip = () => {
    showService
      .editHomePageStrip({
        home_strip: heroStrip,
      })
      .then((data) => {
        if (data.success) {
          getHeroShow();
          enqueueSnackbar("Hero Strip updated", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const getHeroShow = () => {
    showService
      .getHeroShow()
      .then((data) => {
        if (data.success) {
          setHeroShow(data.data);
          setLoadingHeroShow(false);
        } else {
          setErrorHeroShow(true);
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const getAllCategories = () => {
    commonService
      .getAllCategories()
      .then((data) => {
        if (data.success) {
          setAllCategories(data.data.categories);
          setLoadingAllCategories(false);
        } else {
          setErrorAllCategories(true);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleUploadIcon = async (event) => {
    console.log("icon :>> ", event.target.files);
    setCategoryInfo({
      ...categoryInfo,
      iconfile: event.target.files[0],
    });
  };

  const handleUploadImage = async (event) => {
    console.log("image :>> ", event.target.files);
    setCategoryInfo({
      ...categoryInfo,
      imagefile: event.target.files[0],
    });
  };

  const handleUploadImageButton = (filetype) => {
    if (filetype === "icon") {
      document.getElementById("icon").click();
    } else if (filetype === "image") {
      document.getElementById("image").click();
    }
  };

  const addCategory = () => {
    if (
      categoryInfo.iconfile.length !== 0 &&
      categoryInfo.imagefile.length !== 0 &&
      !_.isEmpty(values.category)
    ) {
      setUploadingCategory(true);
      const formData = new FormData();
      formData.append("title", values.category);
      formData.append("icon", categoryInfo.iconfile);
      formData.append("image", categoryInfo.imagefile);
      formData.append("display_order", "100");
      axios({
        method: "post",
        url: `${GlobalConfig.baseUrl}/admin/category/add`,
        data: formData,
        headers: authHeaderFormData(),
      })
        .then((response) => {
          if (response.data.success) {
            setUploadingCategory(false);
            console.log("data :>> ", response.data);
            getAllCategories();
            enqueueSnackbar(response.data.message, {
              variant: "success",
            });
            setCategoryInfo({
              iconfile: [],
              imagefile: [],
            });
            setValues({
              category: "",
            });
          } else {
            setUploadingCategory(false);
            enqueueSnackbar(response.data.message, {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            variant: "error",
          });
        });
    } else {
      enqueueSnackbar("Incomplete Category", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    getHeroShow();
    getAllCategories();
  }, []);

  return (
    <div>
      <Header />
      <main>
        <div className="container mt-5">
          <div className="d-flex align-items-center mb-3">
            <img className="img-fluid" src={backArrow} alt="arrow" />
            <h1 className="text-white ps-2 display-4 fw-600">Homepage Setup</h1>
          </div>
          {loadingHeroShow ? (
            <div className="text-center my-5">
              <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : errorHeroShow ? (
            <h3 className="mt-3 text-center" style={{ color: "white" }}>
              Response not found
            </h3>
          ) : (
            !_.isEmpty(heroShow) && (
              <div className="bg-dark-grey p-sm-5 p-4 rounded">
                <div className="row">
                  <div className="col-xl-9 col-sm-12 m-auto">
                    <div className="row">
                      <div className="col-xl-8 col-sm-12">
                        <h4 className="text-white fw-bold">Hero</h4>
                        <div className="row align-items-center">
                          <div className="col-sm-4 mb-sm-0 mb-4">
                            <p className="text-white mb-0">
                              Show, Series, or Episode
                            </p>
                          </div>
                          <div className="col-sm-8">
                            <div className="row">
                              <div className="col-sm-6 d-grid mb-sm-0 mb-2">
                                <input
                                  id="show_id"
                                  name="show_id"
                                  type="number"
                                  className="form-control"
                                  placeholder="ID"
                                  value={values.show_id}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-sm-6 d-grid">
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm py-2"
                                  onClick={markShowAsHero}
                                >
                                  + Replace
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4 align-items-center">
                          <div className="col-sm-4">
                            <h5 className="text-white fw-bold mb-2">
                              {heroShow.title}
                            </h5>
                            {/* <p className="text-white mb-2">
                              {heroShow.description}
                            </p> */}
                            {/* <small className="d-block text-white mb-2">
                              Episode 07
                            </small> */}
                          </div>
                          <div className="col-sm-8">
                            <div className="yogaSeniors">
                              <img
                                className="img-fluid rounded-25"
                                style={{ minWidth: "200px" }}
                                src={imageSrc(heroShow.AdminShowImages)}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="my-4">
                          <label
                            htmlFor="show_id"
                            className="form-label text-white"
                          >
                            Date Added
                          </label>
                          <input
                            type="text"
                            className="form-control disabled darkPlaceholder"
                            id="url"
                            placeholder={moment(heroShow.created_at).format(
                              "L"
                            )}
                            readOnly=""
                          />
                        </div>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-sm-4 mb-sm-0 mb-4">
                          <h5 className="text-white mb-0">Hero Strip</h5>
                        </div>
                        <div className="col-sm-8">
                          <div className="row">
                            <div className="col-sm-6 d-grid mb-sm-0 mb-2">
                              <input
                                id="show_id"
                                name="show_id"
                                type="text"
                                className="form-control"
                                placeholder="Hero Strip"
                                value={heroStrip}
                                onChange={(e) => setHeroStrip(e.target.value)}
                              />
                            </div>
                            <div className="col-sm-6 d-grid">
                              <button
                                type="button"
                                className="btn btn-danger btn-sm py-2"
                                onClick={changeHeroStrip}
                              >
                                + Replace
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {allCategories.length > 0 && (
                        <>
                          <h5 className="text-white fw-bold mt-5">Category</h5>
                          <div className="row">
                            <div className="col-sm-12">
                              {allCategories.map((item, index) => {
                                return (
                                  <div
                                    className="border-bottom border-dark p-2"
                                    key={index}
                                  >
                                    <div className="row align-items-center justify-content-between">
                                      <div className="col-xl-6 col-lg-6 col-sm-12 mb-xl-0 mb-lg-0 mb-sm-4">
                                        <div className="d-flex align-items-center">
                                          <div className="w-100">
                                            <div className="d-flex justify-content-between align-items-center">
                                              <p className="mb-0 text-white">
                                                {item.title}
                                              </p>
                                              <img
                                                className="img-fluid ms-4 categoryIcon"
                                                src={item.icon_file}
                                                alt="memory-icon"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-2"></div>
                                      <div className="col-xl-4 col-lg-4 col-sm-12">
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div className="d-flex align-items-center">
                                            <div className="memoryImg rounded-5">
                                              <img
                                                className="img-fluid"
                                                src={item.image_file}
                                                alt="memory img"
                                              />
                                            </div>
                                          </div>
                                          <img
                                            className="img-fluid closeH"
                                            src={closeIcon}
                                            alt="close icon"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div className="border-bottom border-dark p-2">
                            <div className="row align-items-center justify-content-between">
                              <div className="col-xl-6 col-lg-5 col-sm-12 mb-xl-0 mb-lg-0 mb-sm-4 mb-4">
                                <div className="d-flex align-items-center">
                                  <div className="w-100">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <input
                                        id="category"
                                        type="text"
                                        className="form-control form-control-sm "
                                        name="category"
                                        value={values.category}
                                        onChange={handleChange}
                                        placeholder="Fitness"
                                      />
                                      {categoryInfo.iconfile.length !== 0 && (
                                        <img
                                          className="img-fluid ms-4 rounded-5"
                                          style={{ maxWidth: "50px" }}
                                          src={URL.createObjectURL(
                                            categoryInfo.iconfile
                                          )}
                                          alt="memory-icon"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-4 col-sm-6 col-12 d-grid d-lg-block d-md-none d-sm-none d-none">
                                <input
                                  id="icon"
                                  type="file"
                                  className="icon"
                                  accept="image/*"
                                  name="icon"
                                  onChange={handleUploadIcon}
                                  hidden
                                />
                                <button
                                  className="btn btn-danger btn-sm py-2 fs-7"
                                  onClick={() =>
                                    handleUploadImageButton("icon")
                                  }
                                >
                                  Upload Image
                                </button>
                              </div>
                              <div className="col-xl-4 col-lg-4 col-sm-12 ps-2">
                                <div className="row justify-content-end">
                                  <div className="col-sm-6 ps-0">
                                    <input
                                      id="image"
                                      type="file"
                                      className="image"
                                      name="image"
                                      accept="image/*"
                                      onChange={handleUploadImage}
                                      hidden
                                    />
                                    {categoryInfo.imagefile.length !== 0 && (
                                      <img
                                        className="img-fluid rounded-5"
                                        style={{ maxWidth: "50px" }}
                                        src={URL.createObjectURL(
                                          categoryInfo.imagefile
                                        )}
                                        alt="memory-icon"
                                      />
                                    )}
                                  </div>
                                  <div className="col-sm-6 d-grid mb-sm-0 mb-2">
                                    <button
                                      className="btn btn-danger btn-sm py-2 fs-7"
                                      onClick={() =>
                                        handleUploadImageButton("image")
                                      }
                                    >
                                      Upload Image
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-12 d-grid mt-3">
                            <button
                              className="btn btn-secondary btn-sm  btn-sm text-white py-2 fs-7"
                              onClick={addCategory}
                              uploadingCategory
                              disabled={uploadingCategory}
                            >
                              {uploadingCategory ? `Adding..` : `Add+`}
                            </button>
                          </div>
                        </>
                      )}
                      <div className="mt-5">
                        <h5 className="text-white fw-bold mb-4">Trending</h5>
                        <div className="col-xl-10 col-sm-12">
                          <div className="row">
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-4">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Entry Field"
                              />
                              <div className="d-grid">
                                <button
                                  type="button"
                                  className="btn btn-danger mt-2 py-2 fs-7 fw-bold"
                                >
                                  + Add
                                </button>
                              </div>
                            </div>
                            <div className="col-xl-9 col-sm-12 mb-xl-0 mb-lg-4 mb-md-4 mb-sm-4 mb-4">
                              <div className="row">
                                <div className="col-sm-4">
                                  <div className="mb-3 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                                    <h5 className="text-white fw-bold mb-2">
                                      Yoga for Seniors
                                    </h5>
                                    <p className="text-white mb-2">
                                      Deep Stretch
                                    </p>
                                    <small className="text-white mb-2">
                                      Episode 07
                                    </small>
                                  </div>
                                  <div className="episodeImg rounded-10">
                                    <img src={episodeImg} alt="episode img" />
                                  </div>
                                  <h6 className="text-white mt-3 fw-normal">
                                    Date Added
                                  </h6>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm disabled darkPlaceholder"
                                    placeholder="20/03/2021"
                                    readOnly=""
                                  />
                                  <div className="mt-4">
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 26 26"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M26 1.53183L24.4682 0L13 11.4682L1.53183 0L0 1.53183L11.4682 13L0 24.4682L1.53183 26L13 14.5318L24.4682 26L26 24.4682L14.5318 13L26 1.53183Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div className="mb-3">
                                    <h5 className="text-white fw-bold mb-2  mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                                      Yoga for Seniors
                                    </h5>
                                    <p className="text-white mb-2">
                                      Deep Stretch
                                    </p>
                                    <small className="text-white mb-2">
                                      Episode 07
                                    </small>
                                  </div>
                                  <div className="episodeImg rounded-10">
                                    <img src={episodeImg} alt="episode img" />
                                  </div>
                                  <h6 className="text-white mt-3 fw-normal">
                                    Date Added
                                  </h6>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm disabled darkPlaceholder"
                                    placeholder="20/03/2021"
                                    readOnly=""
                                  />
                                  <div className="mt-4">
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 26 26"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M26 1.53183L24.4682 0L13 11.4682L1.53183 0L0 1.53183L11.4682 13L0 24.4682L1.53183 26L13 14.5318L24.4682 26L26 24.4682L14.5318 13L26 1.53183Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div className="mb-3">
                                    <h5 className="text-white fw-bold mb-2  mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                                      Yoga for Seniors
                                    </h5>
                                    <p className="text-white mb-2">
                                      Deep Stretch
                                    </p>
                                    <small className="text-white mb-2">
                                      Episode 07
                                    </small>
                                  </div>
                                  <div className="episodeImg rounded-10">
                                    <img src={episodeImg} alt="episode img" />
                                  </div>
                                  <h6 className="text-white mt-3 fw-normal">
                                    Date Added
                                  </h6>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm disabled darkPlaceholder"
                                    placeholder="20/03/2021"
                                    readOnly=""
                                  />
                                  <div className="mt-4">
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 26 26"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M26 1.53183L24.4682 0L13 11.4682L1.53183 0L0 1.53183L11.4682 13L0 24.4682L1.53183 26L13 14.5318L24.4682 26L26 24.4682L14.5318 13L26 1.53183Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-sm-row flex-column mt-5">
                        <button
                          type="button"
                          className="btn-block btn btn-danger btn-sm px-lg-5 px-md-5 px-sm-4 px-4 py-sm-2 py-1 me-sm-2 me-0 mb-sm-0 mb-2"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn-block btn btn-light btn-sm text-danger px-lg-5 px-md-5 px-sm-4 px-4 py-sm-2 py-1 me-sm-2 me-0 mb-sm-0 mb-2"
                        >
                          Preview
                        </button>
                        <button
                          type="button"
                          className="btn-block btn btn-gray-dark btn-sm text-white px-lg-5 px-md-5 px-sm-4 px-4 py-sm-2 py-1"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </main>
    </div>
  );
};
export default HomeSetup;
