/** @format */

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./scheduleShow.module.css";
import adminStyles from "../admin.module.css";
import Header from "../header/header";
import backArrow from "../images/back_arrow.svg";
import filmIcon from "../images/film.svg";
import closeIcon from "../images/close.svg";
import screenShot from "../images/screenshot.svg";
import trashIcon from "../images/trash.svg";
import hostImg from "../images/larry_tom.svg";
import downImg from "../images/download.jpeg";
import videoImg from "../images/playIcon.svg";
import downloadImg from "../images/downloadIcon.svg";
import trashImg from "../images/trashIcon.svg";
import up_arrow from "../images/up_arrow.png";
import down_arrow from "../images/down_arrow.png";
import yogaProfile from "../images/Reasonswhy.svg";
import defaultImage from "../images/default.png";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";

import { useSnackbar } from "notistack";

import { showService } from "../../../_services/admin";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import * as CONSTANTS from "../../../config/constants";

const cx = classNames.bind(styles);
const acx = classNames.bind(adminStyles);

let hour12 = [];
let hour24 = [];

const regex = {
  email: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
};

const ScheduleShow = () => {
  const navigate = useNavigate();
  const { showId } = useParams();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [values, setValues] = useState({
    show_id: "",
    dateTime: [],
    display_content_for: 1,
    to_record: 0,
  });
  const [time, setTime] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [showScheduleData, setShowScheduleData] = useState({
    title: "",
    description: "",
    dateTime: [],
    hosts: [],
    url: "",
    // image: ""
  });
  const [image, setImage] = useState("");
  const [
    showRemoveScheduleDateConfirmationModal,
    setShowRemoveScheduleDateConfirmationModal,
  ] = useState(false);
  const [clickedScheduledDateTimeId, setClickedScheduledDateTimeId] =
    useState();

  const { user } = useSelector((state) => state.user);

  const handleChange = (e) => {
    let time;
    let date;
    date = startDate.toString().slice(0, 16);
    date = date.split(" ");
    const monthName = date[1];
    const day = date[2];
    const year = date[3];
    const monthNumber = monthNumberFromString(monthName);
    const newDate = `${year}-${monthNumber}-${day}`;
    for (let i in hour12) {
      if (e.target.outerText === hour12[i]) {
        time = hour24[i];
      }
    }

    const localTimeToUTC = moment(`${newDate} ${time}`).utc().format();
    var localTimeToUTCSplit = localTimeToUTC.replace("T", " ").replace("Z", "");
    const utcDate = localTimeToUTCSplit.substring(
      0,
      localTimeToUTCSplit.indexOf(" ")
    ); // "2022-05-01"
    const utcTime = localTimeToUTCSplit.substring(
      localTimeToUTCSplit.indexOf(" ") + 1
    ); // "17:30:00"
    // const utcTimeToLocal = moment("2022-05-31T12:30:00Z")
    //   .local()
    //   .format("YYYY-MM-DD HH:mm:ss");
    // console.log("utcTimeToLocal :>> ", utcTimeToLocal);
    const clickedTimeButton = document.getElementById(e.target.outerText);
    clickedTimeButton.style.background = "red";
    clickedTimeButton.style.color = "white";

    const btns = document.getElementsByClassName("timeBtn");
    for (let i = 0; i < btns.length; i++) {
      if (btns[i].value !== e.target.outerText) {
        btns[i].style.background = "white";
        btns[i].style.color = "black";
      }
    }

    setValues({
      ...values,
      show_id: parseInt(showId),
      dateTime: [
        {
          date: utcDate,
          time: utcTime,
          utc: localTimeToUTC,
        },
      ],
    });
  };

  const handleSubmit = () => {
    showService
      .addScheduleShow(values)
      .then((data) => {
        if (data.success) {
          navigate("/admin/show-list");
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getShowSchedulesData();

    setValues({
      ...values,
      show_id: showId,
    });
  }, []);

  useEffect(() => {
    if (startDate) {
      generate_time(
        startDate.toLocaleTimeString("en-US", {
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }) ===
          new Date().toLocaleTimeString("en-US", {
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }),
        30
      );
    }
  }, [startDate]);

  useEffect(() => {
    user.role !== CONSTANTS.ADMIN_ROLE && navigate("/admin/show-list");
  });

  const monthNumberFromString = (str) => {
    return new Date(`${str} 01 2000`).toLocaleDateString(`en`, {
      month: `2-digit`,
    });
  };

  const generate_time = (isToday, step) => {
    if (isToday) {
      generate_today_time(step);
    } else {
      generate_future_days_time(step);
    }
  };

  const generate_today_time = (step) => {
    const dt = new Date(1970, 0, 1);
    hour12 = [];
    hour24 = [];
    while (dt.getDate() === 1) {
      if (
        new Date().toLocaleTimeString("en-GB") < dt.toLocaleTimeString("en-GB")
      ) {
        hour12.push(
          dt.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })
        );
        hour24.push(dt.toLocaleTimeString("en-GB"));
      }
      dt.setMinutes(dt.getMinutes() + step);
    }
    setTime(hour12);
  };

  const generate_future_days_time = (step) => {
    const dt = new Date(1970, 0, 1);
    hour12 = [];
    hour24 = [];
    while (dt.getDate() === 1) {
      hour12.push(
        dt.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })
      );
      hour24.push(dt.toLocaleTimeString("en-GB"));
      dt.setMinutes(dt.getMinutes() + step);
    }
    setTime(hour12);
  };

  const timeFormatter = (timeString) => {
    return moment(timeString).format("LT");
  };

  const dateFormatter = (showScheduledDate) => {
    const dateArray = moment(showScheduledDate).format("L").split("/");
    const day = dateArray[1];
    const year = dateArray[2];
    const monthName = moment(showScheduledDate).format("MMMM");
    console.log("date ", day, year, monthName, dateArray);
    return `${day} ${monthName} ${year}`;
  };

  const timeStyle = {
    borderRadius: 8,
    padding: 10,
  };

  const getShowSchedulesData = () => {
    showService
      .get(showId)
      .then((data) => {
        if (data.success) {
          setShowScheduleData({
            ...showScheduleData,
            title: data.data.title,
            description: data.data.description,
            hosts: data.data.AdminShowHosts,
            dateTime: data.data.AdminShowSchedules,
            url: data.data.url,
          });
          const images = data.data.AdminShowImages;
          if (images.length !== 0) {
            images.map((image) => {
              if (image.is_hero) {
                setImage(image.hero_url);
              }
            });
          }
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    user.role !== CONSTANTS.ADMIN_ROLE && navigate("/admin/show-list");
  }, []);

  const removeShowScheduledDateTime = () => {
    const payload = {
      schedule_id: clickedScheduledDateTimeId,
    };
    showService
      .removeShowScheduledDateTime(payload)
      .then((data) => {
        if (data.success) {
          getShowSchedulesData();
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => console.log("error ", error));

    handleHideModal();
  };

  const handleRemoveScheduledDateTime = (scheduledId) => {
    setShowRemoveScheduleDateConfirmationModal(true);
    setClickedScheduledDateTimeId(scheduledId);
  };

  const handleHideModal = () => {
    setShowRemoveScheduleDateConfirmationModal(false);
  };

  return (
    <div>
      <Header />
      <Modal show={showRemoveScheduleDateConfirmationModal}>
        <Modal.Header className="modalStyle">
          <h5>Do you wish to remove this scheduled show?</h5>
        </Modal.Header>
        <Modal.Footer className="modalStyle">
          <Button variant="secondary" onClick={handleHideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={removeShowScheduledDateTime}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <main>
        <div className="container mt-5">
          <div className="d-flex align-items-center mb-3">
            <img className="img-fluid" src={backArrow} alt="arrow" />
            <h1 className="text-white ps-2 display-4 fw-600">
              Schedule a Show
            </h1>
          </div>
          <div className="bg-dark-grey p-sm-5 p-4 rounded">
            <div className="col-xl-6 col-lg-8 col-md-11 col-sm-12 col-12 m-auto">
              <div className="yogaProfilePic rounded-25 mb-4">
                <img
                  src={image !== "" ? image : defaultImage}
                  style={{ borderRadius: 20 }}
                  width={300}
                  height={200}
                />
              </div>
              <h5 className="text-white fw-bold mb-4">
                {showScheduleData.title}
              </h5>
              <p className="text-white mb-4">{showScheduleData.description}</p>
              <form>
                <div className="mb-4 col-sm-4">
                  <label htmlFor="show_id" className="form-label text-white">
                    ID
                  </label>
                  <input
                    type="text"
                    className="form-control disabled darkPlaceholder"
                    id="show_id"
                    placeholder={showId}
                    readOnly
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="show_id" className="form-label text-white">
                    Link
                  </label>
                  <input
                    type="text"
                    className="form-control disabled darkPlaceholder"
                    id="show_id"
                    placeholder={showScheduleData.url}
                    readOnly
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <div>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm me-2"
                    >
                      Upcoming Shows
                    </button>
                    <button
                      type="button"
                      className="btn btn-light btn-sm text-danger"
                    >
                      Past Shows
                    </button>
                  </div>
                  <div>
                    <a className="text-white" href="#!">
                      <u>Show All Dates</u>
                    </a>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  {showScheduleData.dateTime.map((time, index) => (
                    <div
                      key={index}
                      style={{ marginRight: 10 }}
                      onClick={() => handleRemoveScheduledDateTime(time.id)}
                      className="cursor-pointer"
                    >
                      <p style={{ color: "#fff" }} key={time.id}>
                        {dateFormatter(time.utc_datetime)} <br />{" "}
                        {timeFormatter(time.utc_datetime)}
                      </p>
                    </div>
                  ))}
                </div>
                <div
                  className={cx("CalenderSec text-white mb-4")}
                  style={{ display: "flex" }}
                >
                  <div>
                    <p className="mb-2">Calender Control</p>
                    <Calendar
                      onChange={setStartDate}
                      value={startDate}
                      minDate={new Date()}
                    />
                  </div>
                  <div>
                    <ul className="mb-0">
                      <div style={{ marginBottom: 8, textAlign: "center" }}>
                        <img src={up_arrow} />
                      </div>
                      <div className="timeSlot">
                        {time.map((t) => (
                          <li
                            key={t}
                            style={{ listStyle: "none", marginBottom: 11 }}
                            name="time"
                            onClick={handleChange}
                            value={t}
                          >
                            <button
                              // onClick={e => handleButtonColor(e)}
                              id={t}
                              type="button"
                              className="time btn btn-light py-2 timeBtn"
                              value={t}
                              style={timeStyle}
                            >
                              {t}
                            </button>
                          </li>
                        ))}
                      </div>
                      <div style={{ marginTop: 8, textAlign: "center" }}>
                        <img src={down_arrow} />
                      </div>
                    </ul>
                  </div>
                </div>
                <div className="my-4 displayContent">
                  <p className="text-white mb-3">Display content for:</p>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="display_content_for"
                      id="series"
                      value={1}
                      onChange={() =>
                        setValues({ ...values, display_content_for: 1 })
                      }
                    />
                    <label
                      className="form-check-label text-white"
                      htmlFor="series"
                    >
                      Series
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="display_content_for"
                      id="episode"
                      value={2}
                      onChange={() =>
                        setValues({ ...values, display_content_for: 2 })
                      }
                    />
                    <label
                      className="form-check-label text-white"
                      htmlFor="episode"
                    >
                      Episode
                    </label>
                  </div>
                </div>
                <div className="form-check dontRecord">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={0}
                    id="flexCheckDefault"
                    name="to_record"
                    onChange={(e) =>
                      setValues({
                        ...values,
                        to_record: values.to_record === 0 ? 1 : 0,
                      })
                    }
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor="flexCheckDefault"
                  >
                    Don’t Record
                  </label>
                </div>
                <div className="invitedHost">
                  <p className="text-white mt-4 mb-2">Invited Hosts</p>
                  {/* rendering only the primary host using the below loop */}
                  {showScheduleData.hosts.map((host, index) => {
                    if (host.is_attendee === 0 && host.is_primary) {
                      return (
                        <div
                          key={index}
                          className="border-bottom border-dark pb-2 mb-2"
                        >
                          <div className="row">
                            <div className="col-sm-6 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="img-44 rounded-circle">
                                    <img
                                      className="img-fluid"
                                      src={host.profile_image || hostImg}
                                      alt="host"
                                    />
                                  </div>
                                </div>
                                <div className="w-100">
                                  <div className="d-flex align-items-center justify-content-start">
                                    <p className="mb-0 text-white mx-3">
                                      {host.first_name
                                        ? `${host.first_name} ${host.last_name}`
                                        : host.email}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                  {/* rendering only non primary hosts using the below loop */}
                  {showScheduleData.hosts.map((host, index) => {
                    if (host.is_attendee === 0 && !host.is_primary) {
                      return (
                        <div
                          key={index}
                          className="border-bottom border-dark pb-2 mb-2"
                        >
                          <div className="row">
                            <div className="col-sm-6 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="img-44 rounded-circle">
                                    <img
                                      className="img-fluid"
                                      src={host.profile_image || hostImg}
                                      alt="host"
                                    />
                                  </div>
                                </div>
                                <div className="w-100">
                                  <div className="d-flex align-items-center justify-content-start">
                                    <p className="mb-0 text-white mx-3">
                                      {host.first_name
                                        ? `${host.first_name} ${host.last_name}`
                                        : host.email}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="d-flex flex-sm-row flex-column mt-sm-5 mt-4">
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="btn-block btn btn-danger btn-sm px-lg-5 px-md-5 px-sm-4 px-4 me-sm-2 me-0 mb-sm-0 mb-2"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn-block btn btn-light btn-sm text-danger px-lg-5 px-md-5 px-sm-4 px-4 me-sm-2 me-0 mb-sm-0 mb-2"
                  >
                    Preview
                  </button>
                  <button
                    type="button"
                    className="btn-block btn btn-gray-dark btn-sm text-white px-lg-5 px-md-5 px-sm-4 px-4"
                  >
                    Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
export default ScheduleShow;
