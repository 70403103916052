import React, {createRef, useEffect, useRef, useState} from "react";
import {Signaling} from "../../../../Services/socketService";

export const FullScreenVideo = ({ activeVideo, isChatHidden }) => {
  const fullScreenVideoRef = createRef();
  const avatarRef = useRef();
  const imageRef = useRef();
  const isActive = activeVideo[0]?.currentEncoding !== 2;

  const [videoEl, setVideoEl] = useState(null);

  //set video track
  useEffect(() => {
    if (
      fullScreenVideoRef.current &&
      activeVideo[0] &&
      activeVideo[0].htmlElement
    ) {
      const element = document.createElement("video");
      element.srcObject = activeVideo[0].htmlElement.srcObject;
      element.playsInline = true;
      element.autoplay = true;

      fullScreenVideoRef.current.innerHTML = "";
      fullScreenVideoRef.current.appendChild(element);
      element.play();
      if (!activeVideo[0]?.isScreenshare && activeVideo[0]?.id === Signaling.getInstance().socket?.id) {
        element.style.cssText =
          "width:100%;height:100%;transform:scaleX(-1);-webkit-transform:scaleX(-1);";
      } else {
        element.style.cssText = "width:100%;height:100%;transform:scaleX(1);-webkit-transform:scaleX(1);"
      }
      setVideoEl(element);
    }
  }, [activeVideo[0]?.htmlElement, activeVideo[0]?.isScreenshare]);

  useEffect(() => {
    if (!videoEl) {
      return;
    }
    const handleResize = () => {
      const height = window.innerHeight * 0.85;
      const width = height * 16 / 9;
      videoEl.style.height = `${height}px`;

      if (avatarRef && avatarRef.current) {
        avatarRef.current.style.position = "absolute";
        avatarRef.current.style["top"] = (1/6) * height + "px";
        avatarRef.current.style["left"] = (window.innerWidth - width)/2 + (1/3) * 16 * height / (9) + "px";
      }

      if (imageRef && imageRef.current) {
        imageRef.current.style.width = 16 * height / (9 * 3) + "px";
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [videoEl, isChatHidden, imageRef, avatarRef]);

  // useEffect(() => {
  //
  //   if (!videoEl) {
  //     return;
  //   }
  //
  //   const setActive = () => {
  //     setIsActive(true)
  //   }
  //   const setInactive = () => {
  //     alert("setInactive")
  //     setIsActive(false)
  //   }
  //
  //   videoEl.addEventListener('playing', setActive)
  //   videoEl.addEventListener('pause', setInactive)
  //   return () => {
  //     videoEl.removeEventListener('playing', setActive)
  //     videoEl.removeEventListener('pause', setInactive)
  //   };
  //
  // }, [videoEl])

  return (
    <div className="mainVideo position-relative d-flex justify-content-center">
      <div style={{visibility: (activeVideo[0].isScreenshare || (isActive && activeVideo[0].enabled?.camera)) ? "" : "hidden"}} className={"liveVideo_container h-100"} ref={fullScreenVideoRef} />
      <div className={"rounded-circle"} style={{visibility: !(activeVideo[0].isScreenshare || (isActive && activeVideo[0].enabled?.camera)) ? "" : "hidden"}} ref={avatarRef}>
        <img
            ref={imageRef}
            className="img-fluid rounded-circle"
            src={activeVideo[0].profile_image || `https://ui-avatars.com/api/?name=${activeVideo[0]?.firstName} ${activeVideo[0]?.lastName}`}
            alt="user"
        />
      </div>
    </div>
  );
};
