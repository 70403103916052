/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  attendScheduleShowHandler,
  loginModalHandler,
  loginStepsHandler,
  registerPayloadData,
  signupModalHandler,
  signupStepsHandler,
} from "../../../store/slices/user";
import classNames from "classnames/bind";
import styles from "./registerBirthday.module.css";
import CloseIcon from "../../../images/cross.svg";
import { Form, InputGroup, FormControl, Button, Modal } from "react-bootstrap";
import { useSnackbar } from "notistack";
import { userService } from "../../../_services";
import _ from "lodash";

const cx = classNames.bind(styles);

const RegisterBirthday = (props) => {
  // const location = useLocation();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, attendScheduleShow } = useSelector((state) => state.user);

  const monthRef = useRef();
  const dayRef = useRef();
  const yearRef = useRef();
  const femaleRef = useRef();
  const maleRef = useRef();
  const customRef = useRef();
  const genderRef = useRef();

  const { enqueueSnackbar } = useSnackbar();

  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [days, setDays] = useState([]);
  const [values, setValues] = useState({
    dob_day: "-",
    dob_month: "-",
    dob_year: "-",
    gender: "",
  });
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [showGenderField, setShowGenderField] = useState(false);
  const [filled, setFilled] = useState(false);

  const handleChange = (e) => {
    let targetNames = ["dob_month", "dob_day", "dob_year"];
    if (targetNames.includes(e.target.name) && e.target.value === "-") {
      setFilled(false);
    }
    if (e.target.id === "custom") {
      setShowGenderField(true);
    } else {
      setShowGenderField(false);
    }
    if (
      monthRef.current.value !== "-" &&
      dayRef.current.value !== "-" &&
      yearRef.current.value !== "-" &&
      e.target.name === "gender"
    ) {
      setFilled(true);
    }
    if (e.target.name === "gender_optional") {
      setValues({
        ...values,
        gender: e.target.value,
      });
    } else {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      values.dob_day === "-" ||
      values.dob_month === "-" ||
      values.dob_year === "-"
    ) {
      return enqueueSnackbar("Enter valid DOB", {
        variant: "error",
      });
    }
    if (values.gender === "") {
      return enqueueSnackbar("Gender is required", {
        variant: "error",
      });
    }
    setIsFormSubmitting(true);
    var updatedValues = {
      username: user.username,
      gender: values.gender,
      dob: `${values.dob_year}-${values.dob_month}-${values.dob_day}`,
      // profile_image: location.state?.photo ? location.state.photo : "",
    };

    userService
      .registerBirthday(updatedValues)
      .then((data) => {
        dispatch(registerPayloadData(updatedValues));
        setIsFormSubmitting(false);
        // navigate("/register-location", {
        //   state: {
        //     username: data.username,
        //     email: values.email,
        //     photo: location.state?.photo,
        //   },
        // });
        dispatch(signupStepsHandler(5));
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
        setIsFormSubmitting(false);
      });
  };

  useEffect(() => {
    setYears(() => {
      let yearsList = [];
      let minOffset = 0;
      let maxOffset = 130;
      let thisYear = new Date().getFullYear();
      for (let i = minOffset; i <= maxOffset; i++) {
        let year = thisYear - i;
        yearsList.push(year);
      }
      return yearsList;
    });

    setMonths(() => {
      let monthsList = [];
      let minOffset = 1;
      let maxOffset = 12;
      for (let i = minOffset; i <= maxOffset; i++) {
        if (i <= 9) {
          monthsList.push(`0${i}`);
        } else {
          monthsList.push(i);
        }
      }
      return monthsList;
    });

    setDays(() => {
      let daysList = [];
      let minOffset = 1;
      let maxOffset = 31;
      for (let i = minOffset; i <= maxOffset; i++) {
        if (i <= 9) {
          daysList.push(`0${i}`);
        } else {
          daysList.push(i);
        }
      }
      return daysList;
    });
  }, []);

  const btnStyle = filled
    ? {
        backgroundColor: "#E50914",
        color: "white",
      }
    : {
        backgroundColor: "#E3E3E3",
        color: "black",
      };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="container">
        <div className=" d-flex align-items-center justify-content-center">
          <div
            className={cx(
              "outerBoxRegister",
              "bg-white",
              "px-5",
              "position-relative",
              "py-lg-5 py-md-4 py-sm-0 py-0"
            )}
          >
            <div
              className={cx("position-absolute", "closeIconRegister")}
              onClick={() => {
                if (!_.isEmpty(attendScheduleShow)) {
                  dispatch(signupStepsHandler(9));
                } else {
                  dispatch(signupModalHandler(false));
                  dispatch(signupStepsHandler(1));
                }
              }}
            >
              <img className="img-fluid" src={CloseIcon} alt="cross" />
            </div>
            <Form onSubmit={handleSubmit}>
              <h1 className="small fw-bold mt-5 pt-sm-0 pt-4">Birthday</h1>
              <div className="row gx-2">
                <div className="col-sm-4 mb-3 landscape-mb-3">
                  <Form.Select
                    aria-label="month"
                    size="lg"
                    className="border border-dark rounded"
                    name="dob_month"
                    value={values.dob_month}
                    onChange={handleChange}
                    ref={monthRef}
                  >
                    <option value="-">Select Month</option>
                    {months.map((value) => {
                      return (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
                <div className="col-sm-4 mb-3 landscape-mb-3">
                  <Form.Select
                    aria-label="day"
                    size="lg"
                    className="border border-dark rounded"
                    name="dob_day"
                    value={values.dob_day}
                    onChange={handleChange}
                    ref={dayRef}
                  >
                    <option value="-">Select day</option>
                    {days.map((value) => {
                      return (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
                <div className="col-sm-4 mb-3 landscape-mb-3">
                  <Form.Select
                    aria-label="year"
                    size="lg"
                    className="border border-dark rounded"
                    name="dob_year"
                    value={values.dob_year}
                    onChange={handleChange}
                    ref={yearRef}
                  >
                    <option value="-">Select year</option>
                    {years.map((value) => {
                      return (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
                <h1 className="small fw-bold pt-2">Gender</h1>
                <div className="row g-0">
                  <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                    <div className="d-flex flex-sm-row flex-column px-0">
                      <div className="form-check d-flex align-items-center mb-2 py-2 ps-sm-1 ps-0 pe-4 rounded position-relative">
                        <input
                          className="form-check-input customRadio"
                          type="radio"
                          name="gender"
                          value="Female"
                          onClick={handleChange}
                          id="female"
                          ref={femaleRef}
                        />
                        <label
                          className="form-check-label d-flex align-items-center"
                          htmlFor="female"
                        >
                          <span className="dotCheck"></span> Female
                        </label>
                      </div>
                      <div className="form-check d-flex align-items-center mb-2 mx-sm-2 mx-0 py-2 ps-sm-2 ps-0 pe-4 rounded position-relative">
                        <input
                          className="form-check-input customRadio"
                          type="radio"
                          name="gender"
                          value="Male"
                          onClick={handleChange}
                          id="male"
                          ref={maleRef}
                        />
                        <label
                          className="form-check-label d-flex align-items-center"
                          htmlFor="male"
                        >
                          <span className="dotCheck"></span> Male
                        </label>
                      </div>
                      <div className="form-check d-flex align-items-center mb-2 py-2 ps-sm-2 ps-0 pe-4 rounded position-relative">
                        <input
                          className="form-check-input customRadio"
                          type="radio"
                          name="gender"
                          value="Custom"
                          onClick={handleChange}
                          id="custom"
                          ref={customRef}
                        />
                        <label
                          className="form-check-label d-flex align-items-center"
                          htmlFor="custom"
                        >
                          <span className="dotCheck"></span> Custom
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 ps-lg-0 ps-md-0 ps-sm-1 ps-1">
                    <InputGroup size="lg">
                      <FormControl
                        placeholder="Gender (Optional)"
                        className="border border-dark rounded form-control-sm"
                        name="gender_optional"
                        value={values.gender_optional}
                        id="custom"
                        type={showGenderField ? "text" : "hidden"}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </div>
                </div>
                <div className="col-sm-4 m-auto mt-3">
                  <Button
                    className="w-100 fw-bold socialBtn"
                    variant="contained"
                    size="lg"
                    style={btnStyle}
                    type="submit"
                    disabled={!filled || isFormSubmitting}
                  >
                    {isFormSubmitting ? `Loading...` : `Next`}
                  </Button>
                </div>
                <div
                  className="text-end cursor-pointer skip"
                  onClick={() => {
                    dispatch(signupStepsHandler(5));
                  }}
                >
                  Skip
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default RegisterBirthday;
