/** @format */

import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Header from "../header/header";
import Footer from "../Footer/Footer";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./showDetail.module.css";
import logo from "../images/logo.svg";
import chatImg1 from "../images/chat-3.png";
import chatImg2 from "../images/chat-1.png";
import chatImg3 from "../images/chat-2.png";
import chatCircle from "../images/chatCircle.svg";
import repliesIcon from "../images/replyComment.svg";
import smileImg from "../images/smile.svg";
import sentIcon from "../images/sentIcon.svg";
import textDot from "../images/textDot.svg";
import yogaBanner from "../images/download.jpeg";
import emailMsg from "../images/email_msg.png";
import androidMsg from "../images/android_msg.png";
import appleMsg from "../images/apple_msg.png";
import fbMessagnger from "../images/fb_message.png";
import fbMsg from "../images/fb_msg.png";
import twitterMsg from "../images/twitter_msg.png";
import whatsappMsg from "../images/whatsapp_msg.png";

import CloseIcon from "../../Admin/images/close.svg";
import fb from "../images/fb.svg";
import insta from "../images/insta.svg";
import twitter from "../images/twitter.svg";
import Global from "../../../config/Global";
import { useParams } from "react-router-dom";
import { InlineShareButtons } from "sharethis-reactjs";
import { Button, Modal } from "react-bootstrap";
import { InlineReactionButtons } from "sharethis-reactjs";
import { showService } from "../../../_services/admin";
import VideoJS from "./VideoJS";
import {
  firstNameLastInitial,
  timeDistance,
  getFormattedDateTime,
  getProfileImageOrFirstAndLastChars,
  getTimeZoneAbbreviation,
} from "../../../_helpers";
import { getRandomString } from "../../../Utils/helper";
import { useDispatch, useSelector } from "react-redux";
import store from "../../../store/index";
import {
  loginModalHandler,
  showInviteFriendsPopUpHandler,
  inviteFriendsPopUpScheduleIdHandler,
  attendScheduleShowHandler,
} from "../../../store/slices/user";
import Constants from "../../../config/constants";
import * as CONSTANTS from "../../../config/constants";

// packages
import _, { isNull } from "lodash";
import { useSnackbar } from "notistack";
import moment from "moment";
import classNames from "classnames/bind";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { Helmet } from "react-helmet";

import {
  setHasChatMessage,
  setCurrentGroup,
  setScrollChatToBottom,
  setIsXmppFetchingChatHistoryOnShowDetail,
  setXmppUserMessageReceived,
} from "../../../store/slices/xmppConnection";

// XMPP
import {
  xmppConnect,
  sendPresence,
  subscribePresence,
  // subscribeIncomingChatMessages,
  subscribeIncomingGroupMessages,
  getRecentChats,
  getChatHistory,
  sendMessage,
  APP,
  timeSince,
  resetIsIncomingGroupMessagesHandlerAdded,
} from "../../../Services/chatService";
import constants from "../../../config/constants";
import InviteFriendsPopUp from "../InviteFriendsPopUp/InviteFriendsPopUp";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  msgboxwidth: {
    width: "200px",
    wordBreak: "break-word",
  },
}));

const { Strophe } = window;
let XMPP_USER_RECENT_CHATS_i = 0;
const cx = classNames.bind(styles);
const override = css`
  display: block;
  border-color: red;
  z-index: 5000 !important;
`;
let show_detail_incoming_message = 0;
let lastScrollLocation = 0;
let scrollOnSend = true;

const ShowDetail = () => {
  const classes = useStyles();
  const refContainer = useRef(null);
  const trailerRef = useRef();
  const videoPlayerRef = useRef();
  const textAreaRef = useRef();
  const chatHistoryTimerRef = useRef();
  const location = useLocation();
  const search = useLocation().search;
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  //params data
  const { showId } = useParams();
  const { user, showInviteFriendsPopUp, attendScheduleShow } = useSelector(
    (state) => state.user
  );
  const xmppUserRecentChats = useSelector((state) => state.xmppUserRecentChats);

  const xmppConnection = useSelector((state) => state.xmppConnection);
  const {
    xmppStatus,
    xmppStanza,
    XMPP_USER_RECENT_CHATS,
    recentChatPagination,
    chatMessagesLoader,
    hasChatMessage,
    currentGroupJID,
    scrollChatToBottom,
    isXmppFetchingChatHistoryOnShowDetail,
  } = xmppConnection;

  let scheduledMeetingFriends;
  let futureScheduleShow;
  //states
  const [loadingShowDetail, setloadingShowDetail] = useState(true);
  const [errorShowDetail, seterrorShowDetail] = useState(false);
  const [showDetail, setshowDetail] = useState();
  const [playHero, setplayHero] = useState(false);
  const [playTrailer, setplayTrailer] = useState(false);
  const [playVOD, setplayVOD] = useState(false);
  const [trailerUrl, settrailerUrl] = useState();
  const [vodUrl, setvodUrl] = useState();
  const [url, seturl] = useState();
  const [scheduleShow, setscheduleShow] = useState();
  const [timerDays, setTimerDays] = useState(0);
  const [timerHours, setTimerHours] = useState(0);
  const [timerMinutes, setTimerMinutes] = useState(0);
  const [timerSeconds, setTimerSeconds] = useState(0);
  const [currentScheduledShowAttendee, setCurrentScheduledShowAttendee] =
    useState(false);
  const [airingScheduleShows, setAiringScheduleShows] = useState([]);
  const [trailerMuted, settrailerMuted] = useState(false);
  const [scheduleShowId, setscheduleShowId] = useState();
  const [largeTitle, setLargeTitle] = useState(false);
  const [showStatusButton, setShowStatusButton] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [roomJID, setRoomJID] = useState("");
  const [chatLoading, setChatLoading] = useState(true);
  const [firstScrollafterLoadMessages, setFirstScrollAfteLoadrMessages] =
    useState(true);
  const [showWatchedTime, setShowWatchedTime] = useState({});
  const [unAttendScheduleShow, setUnAttendScheduleShow] = useState(false);
  const [clickedAiringShowScheduleId, setClickedAiringShowScheduleId] =
    useState();
  const [unAttendAiringShow, setUnAttendAiringShow] = useState(false);
  const [episodesData, setEpisodesData] = useState([]);
  const [adBanner, setAdBanner] = useState(false);

  let interval = useRef();

  const debounced = (delay, fn) => {
    return function (...args) {
      if (chatHistoryTimerRef.current) {
        clearTimeout(chatHistoryTimerRef.current);
      }
      chatHistoryTimerRef.current = setTimeout(() => {
        fn(...args);
        chatHistoryTimerRef.current = null;
      }, delay);
    };
  };

  const startTimer = () => {
    const countdownDate = new Date(`${scheduleShow.utc_datetime}`).getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        // stop our timer
        clearInterval(interval.current);
        setShowStatusButton(true);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
        setShowStatusButton(true);
      }
    }, 1000);
  };
  //functions
  const getShowDetail = async () => {
    showService
      .get(showId)
      .then((data) => {
        if (data.success) {
          setshowDetail(data.data);
          setloadingShowDetail(false);
        } else {
          seterrorShowDetail(true);
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const getShowWatchedTime = async () => {
    showService
      .getShowWatchedTime({ show_id: parseInt(showId), user_id: user.id })
      .then((data) => {
        if (data.success) {
          setShowWatchedTime(data.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const handleCurrentScheduledAttend = (scheduledShowId) => {
    if (!_.isNull(user)) {
      const alreadyAttendee = showDetail.AdminShowHosts.find((item) => {
        return (
          item.is_attendee === 1 &&
          item.email === user.email &&
          item.show_schedule_id === scheduledShowId
        );
      });
      if (!_.isUndefined(alreadyAttendee)) {
        setCurrentScheduledShowAttendee(true);
      }
    } else {
      setCurrentScheduledShowAttendee(false);
    }
  };

  const renderPlayTrailer = () => {
    if (!_.isEmpty(showDetail.trailer_url)) {
      return (
        <span className="d-flex align-items-center">
          <button
            type="button"
            className="btn text-light btn-sm px-3 me-1 mb-0 fw-bold cursor-pointer"
            style={{ background: "rgba(84,83,83,0.3)" }}
            onClick={() => {
              setplayTrailer(true);
              settrailerUrl(showDetail.trailer_url);
            }}
          >
            <span>Play Trailer</span>
          </button>
        </span>
      );
    } else {
      return <></>;
    }
  };

  const renderPlayVOD = () => {
    const vod = showDetail.AdminMedia.find((item) => {
      return item.is_vod === 1;
    });
    if (!_.isEmpty(vod)) {
      return (
        <span className="d-flex align-items-center">
          <button
            id="play-recording-btn"
            type="button"
            className="btn text-danger btn-sm bg-light px-3 me-1 mb-0 fw-bold cursor-pointer"
            onClick={() => {
              if (!_.isNull(user)) {
                setplayVOD(true);
                setvodUrl(vod.url);
              } else {
                dispatch(loginModalHandler(true));
              }
            }}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 29 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.39844 29.5C5.39844 30.324 6.3392 30.7944 6.99844 30.3L22.3318 18.8C22.8651 18.4 22.8651 17.6 22.3318 17.2L6.99844 5.7C6.3392 5.20557 5.39844 5.67595 5.39844 6.5V29.5Z"
                fill="red"
              />
            </svg>
            Play Recording
          </button>
        </span>
      );
    } else {
      return <></>;
    }
  };

  // const renderHosts = () => {
  //   const vod = showDetail.AdminShowHosts.map((item, index) => {
  //     return item.is_vod === 1;
  //   });
  //   if (!_.isEmpty(vod)) {
  //     return (
  //       <span className="d-flex align-items-center ms-3">
  //         <svg
  //           width="29"
  //           height="36"
  //           viewBox="0 0 29 36"
  //           fill="none"
  //           xmlns="http://www.w3.org/2000/svg"
  //         >
  //           <path
  //             d="M5.39844 29.5C5.39844 30.324 6.3392 30.7944 6.99844 30.3L22.3318 18.8C22.8651 18.4 22.8651 17.6 22.3318 17.2L6.99844 5.7C6.3392 5.20557 5.39844 5.67595 5.39844 6.5V29.5Z"
  //             fill="white"
  //           />
  //         </svg>
  //         <h5
  //           className="text-white mb-0 fw-bold cursor-pointer"
  //           onClick={() => {
  //             setplayHero(true);
  //             seturl(vod.url);
  //           }}
  //         >
  //           Play VOD
  //         </h5>
  //       </span>
  //     );
  //   } else {
  //     return <></>;
  //   }
  // };

  const detectScrollTop = (e, chat) => {
    const { scrollTop, scrollHeight } = refContainer.current;
    let diff = scrollHeight - (30 / 100) * scrollHeight;
    if (scrollTop >= diff && scrollTop <= scrollHeight) {
      scrollOnSend = true;
    } else {
      scrollOnSend = false;
    }
    if (scrollTop <= 1500) {
      lastScrollLocation = scrollHeight - scrollTop;
      debounced(100, () => {
        if (!isXmppFetchingChatHistoryOnShowDetail) {
          dispatch(setIsXmppFetchingChatHistoryOnShowDetail(true));
          getChatHistory(chat);
        }
      })();
    }
  };

  //usefffect's
  useEffect(async () => {
    getShowDetail();
    if (!_.isNull(user)) {
      getShowWatchedTime();
    }
    if (!_.isUndefined(location?.state?.playDirectly)) {
      (function searchPlayRecordingBtn() {
        let playRecordingButton = document.getElementById("play-recording-btn");
        if (_.isNull(playRecordingButton)) {
          setTimeout(() => {
            searchPlayRecordingBtn();
          }, 1000);
        } else {
          playRecordingButton.click();
        }
      })();
    }

    let jid;
    await showService
      .createRoom({
        show_id: showId,
        roomname: showId.toString(),
        subject: showId.toString(),
        username: "anonymous",
      })
      .then((data) => {
        if (data.success) {
          jid = data.data.mucLightRoom.luser;
          setRoomJID(jid);
          store.dispatch(setCurrentGroup(jid + Constants.GROUP_JID_POSTFIX));
        } else {
          console.log(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const payload = {
      show_id: showId,
    };
    showService
      .getShowEpisodes(payload)
      .then((data) => {
        if (data.success) {
          setEpisodesData(data.data);
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  }, []);

  useEffect(() => {
    if (!_.isUndefined(showDetail)) {
      if (
        !_.isNull(showDetail.trailer_url) &&
        !_.isEmpty(showDetail.trailer_url)
      ) {
        settrailerUrl(showDetail.trailer_url);
      }
      const vodUrl = showDetail.AdminMedia.find((item) => {
        return item.is_vod === 1;
      });
      if (!_.isUndefined(vodUrl)) {
        setvodUrl(vodUrl.url);
      }
      showDetail.title.length > 75 ? setLargeTitle(true) : setLargeTitle(false);
      scheduledShow();
    }
  }, [showDetail]);

  useEffect(() => {
    if (!_.isUndefined(scheduleShow) || !_.isEmpty(scheduleShow)) {
      setscheduleShowId(scheduleShow.id);
      getAiringRecords(scheduleShow.id);
      handleCurrentScheduledAttend(scheduleShow.id);

      startTimer();
      return () => {
        clearInterval(interval.current);
      };
    }
  }, [scheduleShow]);

  useEffect(() => {
    console.log("recentChatPagination ", recentChatPagination);
  }, [recentChatPagination]);

  useEffect(() => {
    // console.log("XMPP_USER_RECENT_CHATS useEffect", XMPP_USER_RECENT_CHATS);
    if (refContainer.current) {
      const { scrollHeight } = refContainer.current;
      if (isXmppFetchingChatHistoryOnShowDetail) {
        refContainer.current.scrollTop = parseInt(
          scrollHeight - lastScrollLocation
        );
      } else if (scrollOnSend) {
        refContainer.current.scrollTop = parseInt(scrollHeight - 351);
      }
    }
  }, [XMPP_USER_RECENT_CHATS]);

  // useEffect(() => {
  //   if (xmppStatus === Strophe.Status.CONNECTED) {
  //     if (xmppStanza.hasOwnProperty("message")) {
  //       console.log("xmppStanza ", xmppStanza);
  //     }
  //     console.log("XMPP_USER_RECENT_CHATS ", XMPP_USER_RECENT_CHATS);
  //   }
  // }, [xmppStanza, xmppStatus === Strophe.Status.CONNECTED]);

  // useEffect(() => {
  //   if (XMPP_USER_RECENT_CHATS.length) {
  //     if (firstScrollafterLoadMessages) {
  //       setTimeout(() => {
  //         let { current } = refContainer;
  //         if (current !== null) {
  //           refContainer.current.scrollTop = refContainer.current.scrollHeight;
  //         }
  //       }, 1500);
  //       setFirstScrollAfteLoadrMessages(false);
  //     }
  //   }
  // }, [XMPP_USER_RECENT_CHATS]);

  useEffect(() => {
    if (roomJID && xmppStatus === Strophe.Status.CONNECTED) {
      // dispatch(setXmppUserMessageReceived([]));
      getChatHistory({
        element_jid: roomJID + Constants.GROUP_JID_POSTFIX,
        type: Constants.XMPP_STANZA_TYPES.GROUP,
      });
    }
  }, [roomJID, xmppStatus]);

  useEffect(() => {
    if (xmppStatus === Strophe.Status.DISCONNECTED) {
      resetIsIncomingGroupMessagesHandlerAdded();
      xmppConnect(Constants.XMPPDEFAULTUSER, Constants.XMPPDEFAULTUSERPASSWORD);
    } else if (xmppStatus === Strophe.Status.CONNECTED) {
      sendPresence();
      subscribePresence();
      subscribeIncomingGroupMessages();
      setTimeout(() => {
        if (firstScrollafterLoadMessages) {
          let { current } = refContainer;
          if (current !== null) {
            // refContainer.current.scrollTop = refContainer.current.scrollHeight;
          }
          setFirstScrollAfteLoadrMessages(false);
        }
      }, 2000);
      show_detail_incoming_message = 1;
    }
    console.log("xmppStatus ", Global.xmpp_connection_status[xmppStatus]);
  }, [xmppStatus]);

  useEffect(() => {
    console.log("chatMessagesLoader ", chatMessagesLoader);
    if (xmppStatus === Strophe.Status.CONNECTED && chatMessagesLoader === 4) {
      setChatLoading(false);
      let noMessageContainer = document.getElementById("noMessageContainer");
      if (noMessageContainer) {
        if (hasChatMessage === 0) {
          noMessageContainer.classList.remove("d-none");
          setTimeout(() => {
            let current = refContainer;
            if (current !== null && refContainer.current) {
              refContainer.current.scrollTop =
                refContainer?.current?.scrollHeight;
            }
          }, 1000);
        } else {
          noMessageContainer.classList.add("d-none");
        }
      }
    }
    //  else if (
    //   xmppStatus === Strophe.Status.CONNECTED &&
    //   chatMessagesLoader < 4
    // ) {
    //   setChatLoading(true);
    // }
  }, [chatMessagesLoader, hasChatMessage]);

  useEffect(() => {
    console.log("scrollChatToBottom ", scrollChatToBottom);
    console.log("refContainer ", refContainer);
    if (scrollChatToBottom) {
      let current = refContainer;
      if (current !== null && refContainer.current) {
        refContainer.current.scrollTop = refContainer?.current?.scrollHeight;
      }
    }
  }, [scrollChatToBottom]);

  useEffect(() => {
    getShowDetail();
  }, [user, attendScheduleShow?.is_attending]);

  const handleJoinEarly = (scheduled_time) => {
    if (_.isNull(user)) {
      dispatch(loginModalHandler(true));
    } else {
      if (!currentScheduledShowAttendee) {
        UnAttendScheduleShow(scheduleShow.id, scheduled_time);
      }
      navigate("/conference/pre-join", {
        state: {
          showId,
        },
      });
    }
  };

  const scheduledShow = () => {
    const show = showDetail.AdminShowSchedules.find((item) => {
      return item.is_completed === 0;
    });
    setscheduleShow(show);
    if (
      location.state?.from === "homehero" ||
      location.state?.from === "homecontinuewatching"
    ) {
      setShowStatusButton(true);
      setplayVOD(true);
    } else if (_.isUndefined(show)) {
      setShowStatusButton(true);
      // if (
      //   !_.isNull(showDetail.trailer_url) &&
      //   !_.isEmpty(showDetail.trailer_url)
      // ) {
      //   setplayTrailer(true);
      // }
    }
  };

  const getAiringRecords = (scheduleId) => {
    // console.log('scheduleId :>> ', scheduleId);
    const shows = showDetail.AdminShowSchedules.filter((item) => {
      return item.is_completed === 0 && item.id !== scheduleId;
    });
    // console.log('show :>> ', show);
    setAiringScheduleShows(shows);
  };

  const renderHostText = () => {
    const hosts = showDetail.AdminShowHosts.filter((item) => {
      return item.is_attendee === 0;
    });
    if (hosts.length > 1) {
      return "Hosts";
    } else {
      return "Host";
    }
  };

  const renderFriendsText = () => {
    const attendees = showDetail.AdminShowHosts.filter((item) => {
      return item.is_attendee === 1 && item.show_schedule_id === scheduleShowId;
    });
    if (attendees.length === 0) {
      return "Invite Friends";
    } else {
      return "Attending";
    }
  };

  const handleScheduledShowUnAttend = (scheduleShowId) => {
    setUnAttendAiringShow(true);
    setClickedAiringShowScheduleId(scheduleShowId);
  };

  const renderAiringShowAttendButton = (
    airingShowScheduleId,
    scheduled_time,
    showId
  ) => {
    const host = showDetail?.AdminShowHosts?.find((host) => {
      return host?.show_schedule_id === airingShowScheduleId;
    });
    return (
      <button
        id="i-attend"
        type="button"
        className={`btn ${user && host?.host_id === user?.id
          ? "bg-white text-danger"
          : "btn-danger"
          }  btn-sm py-1 me-2`}
        onClick={() => {
          if (_.isNull(user)) {
            dispatch(
              attendScheduleShowHandler({
                id: airingShowScheduleId,
                show_id: showId,
                scheduled_time: getFormattedDateTime(scheduled_time),
                show_title: showDetail.title,
                is_attending: 0,
              })
            );
            dispatch(loginModalHandler(true));
          } else {
            if (host?.host_id === user?.id) {
              handleScheduledShowUnAttend(airingShowScheduleId);
            } else {
              UnAttendScheduleShow(airingShowScheduleId, scheduled_time);
            }
          }
        }}
      >
        {user && host?.host_id === user?.id ? "Attending" : "I'll Attend"}
      </button>
    );
  };

  //Handle Trailer Mute/Unmute
  const handleTrailerMute = () => {
    if (trailerRef.current.muted) {
      // if muted, show mute image
      trailerRef.current.muted = false;
      settrailerMuted(false);
    } else {
      // if not muted, show not muted image
      trailerRef.current.muted = true;
      settrailerMuted(true);
    }
  };

  const handleVODStop = () => {
    videoPlayerRef.current.saveContinueWatching(user.id, parseInt(showId));
    setplayVOD(false);
  };

  const handleVODEnd = () => {
    videoPlayerRef.current.saveContinueWatchingEnd(user.id, parseInt(showId));
    setplayVOD(false);
  };

  // Format Timer
  const formatTimer = (seconds, minutes) => {
    let secondsString;
    let minutesString;
    if (seconds < 10) {
      secondsString = "0" + seconds.toString();
      // console.log('secondsString :>> ', secondsString);
    }
    if (minutes < 10) {
      minutesString = "0" + minutes.toString();
      // console.log('minutesString :>> ', minutesString);
    }
    if (seconds >= 10) {
      secondsString = seconds.toString();
      // console.log('secondsString :>> ', secondsString);
    }
    if (minutes >= 10) {
      minutesString = minutes.toString();
      // console.log('minutesString :>> ', minutesString);
    }

    return (
      <>
        <p className="text-white h5 mb-0 me-3">Starting In</p>
        <span className="bgTransCounter p-2 rounded text-white fw-bold">
          {!!minutesString ? minutesString.charAt(0) : ""}
        </span>
        <span className="bgTransCounter p-2 rounded text-white fw-bold ms-1">
          {!!minutesString ? minutesString.charAt(1) : ""}
        </span>
        <span className="p-2 text-white fw-bold">:</span>
        <span className="bgTransCounter p-2 rounded text-white fw-bold">
          {!!secondsString ? secondsString.charAt(0) : ""}
        </span>
        <span className="bgTransCounter p-2 rounded text-white fw-bold ms-1">
          {!!secondsString ? secondsString.charAt(1) : ""}
        </span>
      </>
    );
  };

  // * mins ago
  const timeStatus = (datetime) => {
    const distance = timeDistance(datetime);

    if (distance > 0) {
      let scheduleShowStats = moment(`${datetime}`).format("LT");
      return <p className="text-white h5 mb-0 me-3">{scheduleShowStats}</p>;
      // <span className="ms-3 text-white">{scheduleShowStats}</span>;
    } else {
      const scheduleShowStats = moment(`${datetime}`).fromNow();
      return <p className="text-white h5 mb-0 me-3">{scheduleShowStats}</p>;
    }
  };

  //Video player things below
  // const videoJsOptions = {
  //   controls: true,
  //   responsive: true,
  //   fluid: true,
  //   sources: [{
  //     src: 'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4',
  //     type: 'video/mp4'
  //   }]
  // };

  const playerRef = React.useRef(null);

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    // You can handle player events here, for example:
    player.on("waiting", () => {
      player.log("player is waiting");
    });

    player.on("dispose", () => {
      player.log("player will dispose");
    });
  };

  const imageSrc = (adminShowImages) => {
    const hero_image = adminShowImages.find((item) => {
      return item.is_hero === 1;
    });
    if (_.isUndefined(hero_image)) {
      return "src/images/default-image.jpeg";
    }
    return hero_image?.hero_url;
  };

  const _handleKeyDown = (e) => {
    if (e.key == "Enter") {
      setInputValue(e.target.value);
      _sendMessage();
    }
  };

  const _sendMessage = () => {
    if (_.isNull(user)) {
      dispatch(loginModalHandler(true));
      return;
    }
    if (!inputValue) return;

    if (!_.isNull(user)) {
      let chat = {
        // element_jid: roomJID + Constants.GROUP_JID_POSTFIX + '/' + APP.bare_jid,
        element_jid: roomJID + Constants.GROUP_JID_POSTFIX,
        type: Constants.XMPP_STANZA_TYPES.GROUP,
        ...user,
      };

      let { current } = refContainer;
      // if (current !== null) {
      //   refContainer.current.scrollTop = refContainer.current.scrollHeight;
      // }
      sendMessage(chat, inputValue);
      setInputValue("");
    }
  };

  const chatStyle = !XMPP_USER_RECENT_CHATS.length
    ? {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }
    : null;

  const handleUnAttendAiringShow = () => {
    showService
      .addOrRemoveAttendee({
        show_id: showId,
        user_id: user.id,
        show_schedule_id: clickedAiringShowScheduleId,
      })
      .then((data) => {
        if (data.success) {
          if (_.isEmpty(data)) {
            setCurrentScheduledShowAttendee(true);
          } else {
            setCurrentScheduledShowAttendee(false);
          }
          getShowDetail();
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
    setUnAttendAiringShow(false);
  };

  const UnAttendScheduleShow = (scheduleId, scheduled_time) => {
    const payload = scheduled_time
      ? {
        show_id: showId,
        user_id: user.id,
        show_schedule_id: scheduleId,
        scheduled_time:
          getFormattedDateTime(scheduled_time) +
          " " +
          getTimeZoneAbbreviation(),
      }
      : {
        show_id: showId,
        user_id: user.id,
        show_schedule_id: scheduleId,
      };
    showService
      .addOrRemoveAttendee(payload)
      .then((data) => {
        if (data.success) {
          if (_.isEmpty(data)) {
            setCurrentScheduledShowAttendee(true);
          } else {
            setCurrentScheduledShowAttendee(false);
          }
          getShowDetail();
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
    setUnAttendScheduleShow(false);
  };

  //copy to clipboard
  const [copySuccess, setCopySuccess] = useState("");
  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess("Copied!");
      enqueueSnackbar("Copied!", {
        variant: "success",
      });
    } catch (err) {
      setCopySuccess("Failed to copy!");
      enqueueSnackbar("Failed to copy!", {
        variant: "error",
      });
    }
  };
  const linkTobeShared = window.location.href;
  // console.log(window.location.href);
  // console.log(copySuccess);
  const handleShowInviteFriendsPopUp = (scheduleShowId) => {
    dispatch(showInviteFriendsPopUpHandler(true));
    dispatch(inviteFriendsPopUpScheduleIdHandler(scheduleShowId));
  };

  //banner
  useEffect(() => {
    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    const Banner = urlParams.get("banner");
    // console.log("Banner", Banner);
    if (Banner) {
      setAdBanner(true);
    }
  }, []);

  const handleEditShowButton = (hosts) => {
    return hosts.some(
      (item) => user.id === item.host_id && item.is_attendee === 0
    );
  };

  const handleBannerClose = () => {
    setAdBanner(false);
  };

  function removeHttp(url) {
    return url.replace(/^https?:\/\//, "");
  }
  return (
    <div>
      <Helmet>
        <title>{showDetail?.title}</title>
      </Helmet>
      <Header />
      <Modal show={unAttendScheduleShow}>
        <Modal.Header className="modalStyle">
          <h5>Do you wish to no longer attend "{showDetail?.title}"?</h5>
        </Modal.Header>
        <Modal.Footer className="modalStyle">
          <Button
            variant="secondary"
            onClick={() => setUnAttendScheduleShow(false)}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => UnAttendScheduleShow(scheduleShow.id)}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      {loadingShowDetail ? (
        <div className="text-center my-5">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : errorShowDetail ? (
        <h1 style={{ color: "white" }}>Response not found</h1>
      ) : (
        <div>
          <main className="bg-dark-grey">
            <div className="d-flex showD_Mob">
              <div className="w-100" style={{ position: "relative" }}>
                {adBanner && (
                  <Box
                    classNae="AdBanner"
                    style={{
                      backgroundColor: "rgba(229, 9, 20,0.85)",
                      height: "65px",
                      width: "100%",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 5px",
                      position: "absolute",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    <img
                      className="bannerClose"
                      src={CloseIcon}
                      alt=""
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        cursor: "pointer",
                        width: "15px",
                        height: "15px",
                      }}
                      onClick={handleBannerClose}
                    />
                    <div className="bannerTextContainer">
                      <p className="bannerText">
                        Welcome to OurPrime, the online Community Center for
                        Experienced Adults. Checkout this{" "}
                        <b>
                          {" "}
                          {showDetail.AdminShowCategoriesWithTitle[0].title}
                        </b>{" "}
                        <span> </span>
                        Program below. <br /> For other Programs visit{" "}
                        <a
                          style={{
                            textDecoration: "none",
                            borderBottom: "1px solid white",
                            color: "white",
                          }}
                          href={process.env.REACT_APP_BASE_URL}
                          target="_blank"
                        >
                          {removeHttp(process.env.REACT_APP_BASE_URL)}
                        </a>
                      </p>
                    </div>
                  </Box>
                )}

                <div
                  className="yogaBanner windowH ratio ratio-16x9"
                  style={{
                    backgroundImage:
                      playTrailer || playVOD
                        ? `none`
                        : `url(${imageSrc(showDetail.AdminShowImages)})`,
                  }}
                >
                  <div className="d-flex align-items-center position-relative h-100">
                    <div className="container-fluid px-lg-5 px-md-5 px-ms-4 px-4">
                      <>
                        {playTrailer ? (
                          <div className="bannerVideo">
                            <video
                              width="100%"
                              height="100%"
                              autoPlay
                              onEnded={() => {
                                setplayTrailer(false);
                                settrailerMuted(false);
                              }}
                              ref={trailerRef}
                              onClick={() => {
                                setplayTrailer(false);
                                settrailerMuted(false);
                              }}
                            >
                              <source src={trailerUrl} type="video/mp4" />
                            </video>
                            <div
                              className="speakerIcon"
                              onClick={handleTrailerMute}
                            >
                              {trailerMuted ? (
                                <svg
                                  viewBox="0 0 256 256"
                                  height="25px"
                                  width="25px"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect fill="none" height="256" width="256" />
                                  <path
                                    d="M218.9,77.1a71.9,71.9,0,0,1,0,101.8"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="16"
                                  />
                                  <line
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="16"
                                    x1="80"
                                    x2="80"
                                    y1="88"
                                    y2="168"
                                  />
                                  <path
                                    d="M190.6,105.4a31.9,31.9,0,0,1,0,45.2"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="16"
                                  />
                                  <line
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="16"
                                    x1="48"
                                    x2="208"
                                    y1="40"
                                    y2="216"
                                  />
                                  <path
                                    d="M152,154.4V224L80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80l6.8-5.3"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="16"
                                  />
                                  <polyline
                                    fill="none"
                                    points="112.2 63 152 32 152 106.8"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="16"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  viewBox="0 0 256 256"
                                  height="25px"
                                  width="25px"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect fill="none" height="256" width="256" />
                                  <path
                                    d="M218.9,77.1a71.9,71.9,0,0,1,0,101.8"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="16"
                                  />
                                  <path
                                    d="M80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80l72-56V224Z"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="16"
                                  />
                                  <line
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="16"
                                    x1="80"
                                    x2="80"
                                    y1="88"
                                    y2="168"
                                  />
                                  <path
                                    d="M190.6,105.4a31.9,31.9,0,0,1,0,45.2"
                                    fill="none"
                                    stroke="#fff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="16"
                                  />
                                </svg>
                              )}
                            </div>
                          </div>
                        ) : playVOD ? (
                          <div className="bannerVideo">
                            <VideoJS
                              handleVODEnd={handleVODEnd}
                              showId={parseInt(showId)}
                              userId={user.id}
                              completedTime={showWatchedTime}
                              getShowWatchedTime={getShowWatchedTime}
                              ref={videoPlayerRef}
                              options={{
                                controls: true,
                                autoplay: true,
                                responsive: true,
                                sources: [
                                  {
                                    src: vodUrl,
                                    type: "video/mp4",
                                  },
                                ],
                              }}
                            />
                            <div className="crossIcon" onClick={handleVODStop}>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.0018 4.99997C14.8455 4.84374 14.6336 4.75598 14.4126 4.75598C14.1917 4.75598 13.9797 4.84374 13.8235 4.99997L10.0018 8.82164L6.18013 4.99997C6.02386 4.84374 5.81194 4.75598 5.59097 4.75598C5.37 4.75598 5.15807 4.84374 5.0018 4.99997C4.84558 5.15624 4.75781 5.36817 4.75781 5.58914C4.75781 5.81011 4.84558 6.02203 5.0018 6.1783L8.82347 9.99997L5.0018 13.8216C4.84558 13.9779 4.75781 14.1898 4.75781 14.4108C4.75781 14.6318 4.84558 14.8437 5.0018 15C5.15807 15.1562 5.37 15.244 5.59097 15.244C5.81194 15.244 6.02386 15.1562 6.18013 15L10.0018 11.1783L13.8235 15C13.9797 15.1562 14.1917 15.244 14.4126 15.244C14.6336 15.244 14.8455 15.1562 15.0018 15C15.158 14.8437 15.2458 14.6318 15.2458 14.4108C15.2458 14.1898 15.158 13.9779 15.0018 13.8216L11.1801 9.99997L15.0018 6.1783C15.158 6.02203 15.2458 5.81011 15.2458 5.58914C15.2458 5.36817 15.158 5.15624 15.0018 4.99997Z"
                                  fill="#FFF"
                                />
                              </svg>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>

                      <div
                        className={`col-xl-8 col-sm-12 py-lg-5 py-md-5 py-sm-4 py-4 showDPos showDetailBanner false ${(playVOD || playTrailer) && "d-none"
                          }`}
                      >
                        <span className="d-flex">
                          <img
                            src={
                              showDetail?.AdminShowCategoriesWithTitle[0]
                                .red_icon_file
                            }
                            alt="category"
                          />
                          {showDetail.AdminShowCategoriesWithTitle.map(
                            (item, index) => {
                              let uniqueIndex = `${index}_${new Date().getTime()}`;
                              return (
                                <p
                                  key={`${getRandomString(
                                    index
                                  )}${uniqueIndex}`}
                                  className="h5 text-white mb-0 ms-2 cursor-pointer"
                                >
                                  {item.title}
                                </p>
                              );
                            }
                          )}
                        </span>
                        <h1
                          className={`text-white fw-bold my-3 cursor-pointer" ${largeTitle && "h3"
                            }`}
                        >
                          {showDetail.title}
                        </h1>
                        <h4 className="my-3">
                          {/* <span className="fw-light text-danger">Episode 4</span> */}
                          {/* <span className="px-3">
                                  <img className="img-fluid" src={textDot} />
                                </span> */}
                          {/* <span className="fw-bold text-white">Deep Stretch</span> */}
                        </h4>
                        <p
                          className={`text-white lh-base cursor-pointer mb-3 mt-4 py-2 ${largeTitle && "small"
                            }`}
                        >
                          {showDetail.description}
                        </p>
                        {/* three buttons */}
                        <>
                          <div
                            className={`d-flex align-items-center ${!showStatusButton && `opacity-0`
                              }`}
                          >
                            {_.isUndefined(scheduleShow) ? (
                              <></>
                            ) : timerDays > 0 ||
                              timerHours > 0 ||
                              (timerDays === 0 &&
                                timerHours === 0 &&
                                timerMinutes >= 15) ? (
                              <button
                                type="button"
                                className={`btn ${currentScheduledShowAttendee
                                  ? "bg-white text-danger"
                                  : "btn-danger"
                                  } btn-sm px-4 me-1`}
                                onClick={() => {
                                  if (!_.isNull(user)) {
                                    if (currentScheduledShowAttendee) {
                                      setUnAttendScheduleShow(true);
                                    } else {
                                      UnAttendScheduleShow(
                                        scheduleShow.id,
                                        scheduleShow.utc_datetime
                                      );
                                    }
                                  } else {
                                    dispatch(
                                      attendScheduleShowHandler({
                                        id: scheduleShow.id,
                                        show_id: scheduleShow.show_id,
                                        scheduled_time: getFormattedDateTime(
                                          scheduleShow.utc_datetime
                                        ),
                                        show_title: showDetail.title,
                                        is_attending: 0,
                                      })
                                    );
                                    dispatch(loginModalHandler(true));
                                  }
                                }}
                              >
                                {currentScheduledShowAttendee
                                  ? "Attending"
                                  : "I'll Attend"}
                              </button>
                            ) : timerDays === 0 &&
                              timerHours === 0 &&
                              timerMinutes === 0 &&
                              timerSeconds === 0 ? (
                              <button
                                type="button"
                                className="btn btn-danger btn-sm px-4 me-1"
                                onClick={() =>
                                  handleJoinEarly(scheduleShow.utc_datetime)
                                }
                              >
                                Join Now
                                <svg
                                  className="ms-1"
                                  width="9"
                                  height="8"
                                  viewBox="0 0 9 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.75 4.0001C8.74696 3.67129 8.61447 3.35691 8.38125 3.1251L5.7 0.437605C5.5829 0.321198 5.42449 0.255859 5.25938 0.255859C5.09426 0.255859 4.93585 0.321198 4.81875 0.437605C4.76017 0.495707 4.71367 0.564832 4.68194 0.640994C4.65021 0.717156 4.63388 0.798847 4.63388 0.881354C4.63388 0.963862 4.65021 1.04555 4.68194 1.12171C4.71367 1.19788 4.76017 1.267 4.81875 1.3251L6.875 3.3751H0.625C0.45924 3.3751 0.300269 3.44095 0.183058 3.55816C0.0658481 3.67537 0 3.83434 0 4.0001C0 4.16586 0.0658481 4.32483 0.183058 4.44204C0.300269 4.55925 0.45924 4.6251 0.625 4.6251H6.875L4.81875 6.68135C4.70106 6.79821 4.63461 6.95704 4.63403 7.12289C4.63344 7.28874 4.69876 7.44803 4.81563 7.56572C4.93249 7.68341 5.09131 7.74986 5.25717 7.75044C5.42302 7.75103 5.58231 7.68571 5.7 7.56885L8.38125 4.88135C8.61599 4.64801 8.7486 4.33109 8.75 4.0001Z"
                                    fill="white"
                                  />
                                </svg>
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-danger btn-sm px-4 me-1"
                                onClick={() =>
                                  handleJoinEarly(scheduleShow.utc_datetime)
                                }
                              >
                                Join Early
                                <svg
                                  className="ms-1"
                                  width="9"
                                  height="8"
                                  viewBox="0 0 9 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.75 4.0001C8.74696 3.67129 8.61447 3.35691 8.38125 3.1251L5.7 0.437605C5.5829 0.321198 5.42449 0.255859 5.25938 0.255859C5.09426 0.255859 4.93585 0.321198 4.81875 0.437605C4.76017 0.495707 4.71367 0.564832 4.68194 0.640994C4.65021 0.717156 4.63388 0.798847 4.63388 0.881354C4.63388 0.963862 4.65021 1.04555 4.68194 1.12171C4.71367 1.19788 4.76017 1.267 4.81875 1.3251L6.875 3.3751H0.625C0.45924 3.3751 0.300269 3.44095 0.183058 3.55816C0.0658481 3.67537 0 3.83434 0 4.0001C0 4.16586 0.0658481 4.32483 0.183058 4.44204C0.300269 4.55925 0.45924 4.6251 0.625 4.6251H6.875L4.81875 6.68135C4.70106 6.79821 4.63461 6.95704 4.63403 7.12289C4.63344 7.28874 4.69876 7.44803 4.81563 7.56572C4.93249 7.68341 5.09131 7.74986 5.25717 7.75044C5.42302 7.75103 5.58231 7.68571 5.7 7.56885L8.38125 4.88135C8.61599 4.64801 8.7486 4.33109 8.75 4.0001Z"
                                    fill="white"
                                  />
                                </svg>
                              </button>
                            )}
                            {renderPlayVOD()}
                            {renderPlayTrailer()}
                          </div>
                          <>
                            {/* Timer Date Live Below 3 Buttons */}
                            {_.isUndefined(scheduleShow) ? (
                              <></>
                            ) : timerDays > 0 ||
                              timerHours > 0 ||
                              (timerDays === 0 &&
                                timerHours === 0 &&
                                timerMinutes >= 15) ? (
                              <div
                                className={`d-flex align-items-center mt-3 ${!showStatusButton && `opacity-0`
                                  }`}
                              >
                                <p className="text-white h5 mb-0 me-3">
                                  Airing on :
                                </p>
                                <p className="text-white h5 mb-0 me-3">
                                  {getFormattedDateTime(
                                    scheduleShow.utc_datetime
                                  )}
                                </p>
                              </div>
                            ) : timerDays === 0 &&
                              timerHours === 0 &&
                              timerMinutes === 0 &&
                              timerSeconds === 0 ? (
                              <div
                                className={`d-flex align-items-center mt-lg-4 mt-md-2 mt-sm-2 mt-2 pt-lg-2 pt-md-0 pt-sm-0 pt-0 ${!showStatusButton && `opacity-0`
                                  }`}
                              >
                                <svg
                                  width="10px"
                                  height="10px"
                                  viewBox="0 0 72 72"
                                  id="emoji"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g id="color">
                                    <circle
                                      cx="36"
                                      cy="36.0001"
                                      r="28"
                                      fill="#d22f27"
                                    />
                                  </g>
                                  <g id="line">
                                    <circle
                                      cx="36"
                                      cy="36.0001"
                                      r="28"
                                      fill="none"
                                      stroke="#000"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    />
                                  </g>
                                </svg>
                                <p className="text-white h5 mb-0 mx-2">
                                  Live :
                                </p>
                                {timeStatus(scheduleShow.utc_datetime)}
                              </div>
                            ) : (
                              <div
                                className={`d-flex align-items-center mt-4 ${!showStatusButton && `opacity-0`
                                  }`}
                              >
                                {formatTimer(timerSeconds, timerMinutes)}
                              </div>
                            )}
                          </>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-shrink-0 chatHosts windowHChat">
                <div
                  className={cx(
                    styles.chatBar,
                    "position-relative",
                    "bg-dark",
                    "h-100"
                  )}
                >
                  <div
                    className={cx(
                      styles.chatWrp,
                      "p-3",
                      "h-100",
                      "chatScrollM"
                    )}
                    style={chatStyle}
                    ref={refContainer}
                    onScroll={(e) => {
                      detectScrollTop(e, {
                        element_jid: roomJID + Constants.GROUP_JID_POSTFIX,
                        type: Constants.XMPP_STANZA_TYPES.GROUP,
                        chatHistoryPaginationData: recentChatPagination,
                      });
                    }}
                  >
                    {chatLoading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <PuffLoader
                          color={"red"}
                          loading={chatLoading}
                          css={override}
                          size={175}
                        />
                      </div>
                    ) : (
                      XMPP_USER_RECENT_CHATS.map((chat, index) => {
                        if (
                          chat.element_jid ==
                          roomJID + Constants.GROUP_JID_POSTFIX
                        ) {
                          if (hasChatMessage == 0) {
                            store.dispatch(setHasChatMessage(1));
                          }
                        }
                        // console.log(
                        //   "XMPP_USER_RECENT_CHATS_i ",
                        //   XMPP_USER_RECENT_CHATS_i
                        // );
                        let uniqueIndex = `${index}_${new Date().getTime()}`;
                        return chat.element_jid ==
                          roomJID + Constants.GROUP_JID_POSTFIX ? (
                          <div
                            key={`${getRandomString(index)}${uniqueIndex}`}
                            className="d-flex pt-3"
                          >
                            <div className="flex-shrink-0 me-2">
                              <div className="img-44 rounded-circle overflow-h bg-accent-blue d-flex align-items-center justify-content-center">
                                {/* <img
                                  className="img-fluid"
                                  src={
                                    chat.profile_image
                                      ? chat.profile_image != ""
                                        ? chat.profile_image
                                        : chatImg1
                                      : chatImg1
                                  }
                                  alt="user"
                                /> */}
                                {!_.isNull(chat.profile_image) &&
                                  !_.isEmpty(chat.profile_image) ? (
                                  <img
                                    className="img-fluid"
                                    src={chat.profile_image}
                                    alt="user"
                                  />
                                ) : (
                                  <span className="text-white">
                                    {!_.isNull(chat.first_name) &&
                                      !_.isNull(chat.last_name) && (
                                        <>
                                          <span>
                                            {chat.first_name.charAt(0)}
                                            {chat.last_name.charAt(0)}
                                          </span>
                                        </>
                                      )}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="w-100">
                              <div className="row chatBottom pb-2">
                                <div className="col-sm-6 col-6">
                                  <div className="d-flex">
                                    <p className="text-white mb-0 fw-bold small">
                                      {chat.first_name && chat.last_name
                                        ? firstNameLastInitial(
                                          chat.first_name,
                                          chat.last_name
                                        )
                                        : chat.element_jid}{" "}
                                      {/* {chat.last_name
                                        ? chat.last_name
                                        : chat.element_jid} */}
                                    </p>
                                    {/* <img
                                      className="img-fluid px-2"
                                      src={chatCircle}
                                      alt="chat-circle"
                                    /> */}
                                    <span className="text-secondary small"></span>
                                  </div>
                                  <div className="text-white small mb-0 pt-1">
                                    <div className={classes.msgboxwidth}>
                                      {chat.body}
                                    </div>
                                  </div>
                                  <div className="d-flex mt-2">
                                    <div className="d-flex align-items-center me-2"></div>
                                    <div className="d-flex align-items-center"></div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-6">
                                  <p className="text-secondary mb-0 small text-end">
                                    {chat.message_time
                                      ? moment(
                                        parseInt(chat.message_time)
                                      ).fromNow()
                                      : "Unknown Time"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        );
                      })
                    )}
                    {/* {store.dispatch(setScrollChatToBottom(Math.floor(Math.random() * (1000 - 10 + 1))))}  */}
                    <div
                      className="d-flex align-items-center justify-content-center p-3 d-none"
                      id="noMessageContainer"
                    >
                      <div className="w-100">
                        <div className="text-white mb-0 d-flex align-items-center justify-content-center flex-column">
                          <h6 className="mt-2 mb-0">No Messages</h6>
                        </div>
                      </div>
                    </div>
                    {/* <div className="d-flex">
                      <div className="w-100">
                        <div className="d-flex pb-2">
                          <div className="w-100">
                            <div className="text-white mb-0">
                              <h3>
                                <br />
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div
                    className={cx(
                      styles.chatBox,
                      "bg-white",
                      "px-2",
                      "py-1",
                      "chatInputM",
                      "windowChatH"
                    )}
                  >
                    <div className="d-flex align-items-center">
                      {!_.isNull(user) && (
                        <div className="chatIMg rounded-circle overflow-h bg-accent-blue d-flex align-items-center justify-content-center">
                          {/* <img className="img-fluid" src={user.profile_image || chatImg3} alt="user" /> */}
                          {!_.isNull(user?.profile_image) &&
                            !_.isEmpty(user?.profile_image) ? (
                            <img
                              className="img-fluid"
                              src={user?.profile_image}
                              alt="user"
                            />
                          ) : (
                            <span className="text-white">
                              {!_.isNull(user?.first_name) &&
                                !_.isNull(user?.last_name) && (
                                  <>
                                    <span>
                                      {user?.first_name.charAt(0)}
                                      {user?.last_name.charAt(0)}
                                    </span>
                                  </>
                                )}
                            </span>
                          )}
                        </div>
                      )}
                      <input
                        type="text"
                        className="form-control border-0"
                        minrows={1}
                        maxrows={5}
                        placeholder="Type a Message"
                        value={inputValue}
                        onKeyDown={(e) => _handleKeyDown(e)}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                      {/* <img src={smileImg} alt="smile img" /> */}
                      <div
                        className="bg-danger p-1 rounded ms-2"
                        onClick={_sendMessage}
                      >
                        <img
                          className="img-fluid"
                          src={sentIcon}
                          alt="sent icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hostsForMobile p-sm-3 p-2">
                  <h1 className="text-white fw-bold mt-lg-4 mt-md-4 mt-sm-0 mt-0 mb-lg-4 mb-md-4 mb-sm-2 mb-2">
                    {renderHostText()}
                  </h1>
                  {/* rendering only the primary host using the below loop */}
                  {showDetail?.AdminShowHosts.map((item, index) => {
                    if (
                      item.is_attendee === 0 &&
                      item.is_hidden === 0 &&
                      item.is_primary
                    ) {
                      let uniqueIndex = `${index}_${new Date().getTime()}`;
                      return (
                        <div
                          key={`${getRandomString(index)}${uniqueIndex}`}
                          className="col-sm-12"
                        >
                          <div className="d-flex align-items-center mb-3">
                            <div className="flex-shrink-0">
                              <div className="img-35 rounded-circle overflow-h me-3 bg-accent-blue d-flex align-items-center justify-content-center">
                                {!_.isNull(item.profile_image) &&
                                  !_.isEmpty(item.profile_image) ? (
                                  <img
                                    className="img-fluid"
                                    src={item.profile_image}
                                    alt="user"
                                  />
                                ) : (
                                  <span className="text-white">
                                    {!_.isNull(item.first_name) &&
                                      !_.isNull(item.last_name) && (
                                        <>
                                          <span>
                                            {item.first_name.charAt(0)}
                                            {item.last_name.charAt(0)}
                                          </span>
                                        </>
                                      )}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="w-100">
                              <p className="text-secondary small mb-0">
                                Hosted by
                              </p>
                              <p className="text-white mb-0 small">
                                {item.full_name}
                              </p>
                            </div>
                          </div>
                          <p className="text-white small">{item.about_info}</p>
                          {/* <p className="text-white small mb-0">
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem
                            Ipsum is that it has a more-or-less.
                          </p> */}
                          {/* <a className="d-block text-blue text-end moreTxt">
                            More..
                          </a> */}
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  })}
                  {/* rendering only the non primary hosts using the below loop */}
                  {showDetail?.AdminShowHosts.map((item, index) => {
                    if (
                      item.is_attendee === 0 &&
                      item.is_hidden === 0 &&
                      !item.is_primary
                    ) {
                      let uniqueIndex = `${index}_${new Date().getTime()}`;
                      return (
                        <div
                          key={`${getRandomString(index)}${uniqueIndex}`}
                          className="col-sm-12"
                        >
                          <div className="d-flex align-items-center mb-3">
                            <div className="flex-shrink-0">
                              <div className="img-35 rounded-circle overflow-h me-3 bg-accent-blue d-flex align-items-center justify-content-center">
                                {!_.isNull(item.profile_image) &&
                                  !_.isEmpty(item.profile_image) ? (
                                  <img
                                    className="img-fluid"
                                    src={item.profile_image}
                                    alt="user"
                                  />
                                ) : (
                                  <span className="text-white">
                                    {!_.isNull(item.first_name) &&
                                      !_.isNull(item.last_name) && (
                                        <>
                                          <span>
                                            {item.first_name.charAt(0)}
                                            {item.last_name.charAt(0)}
                                          </span>
                                        </>
                                      )}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="w-100">
                              <p className="text-secondary small mb-0">
                                Hosted by
                              </p>
                              <p className="text-white mb-0 small">
                                {item.full_name}
                              </p>
                            </div>
                          </div>
                          <p className="text-white small">{item.about_info}</p>
                          {/* <p className="text-white small mb-0">
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem
                            Ipsum is that it has a more-or-less.
                          </p> */}
                          {/* <a className="d-block text-blue text-end moreTxt">
                            More..
                          </a> */}
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  })}
                  {/* <div className="col-sm-12">
                    <div className="d-flex align-items-center mb-3">
                      <div className="flex-shrink-0">
                        <div className="img-35 rounded-circle overflow-h me-3 bg-accent-blue d-flex align-items-center justify-content-center">
                          <span className="text-white d-none">MS</span>
                          <img
                            className="img-fluid"
                            src={chatImg2}
                            alt="user"
                          />
                        </div>
                      </div>
                      <div className="w-100">
                        <p className="text-secondary small mb-0">Hosted by</p>
                        <p className="text-white mb-0 small">Karne Dillon</p>
                      </div>
                    </div>
                    <p className="text-white small">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here, content here', making it
                      look like readable English.
                    </p>
                    <p className="text-white small mb-0">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less.
                    </p>
                    <a className="d-block text-blue text-end moreTxt">More..</a>
                  </div> */}
                  {/* <div className="col-sm-5 ms-auto">
                      <button
                        type="button"
                        className="btn btn-danger btn-sm py-2 w-100"
                      >
                        View More
                      </button>
                    </div> */}
                </div>
              </div>
            </div>
            <div className="container-fluid px-lg-5 px-md-5 px-ms-4 px-4">
              <div className="my-4 col-sm-12">
                <div className="row">
                  <div className="col-sm-6 hostsMainWeb">
                    <div className="hostsForWeb">
                      <h1 className="text-white fw-bold my-4">
                        {renderHostText()}
                      </h1>
                      <div className="row">
                        {/* rendering only the primary host using the below loop */}
                        {showDetail?.AdminShowHosts.map((item, index) => {
                          if (
                            item.is_attendee === 0 &&
                            item.is_hidden === 0 &&
                            item.is_primary
                          ) {
                            let uniqueIndex = `${index}_${new Date().getTime()}`;
                            return (
                              <div
                                key={`${getRandomString(index)}${uniqueIndex}`}
                                className="col-sm-12"
                              >
                                <div className="d-flex align-items-center mb-3">
                                  <div className="flex-shrink-0">
                                    <div className="img-35 rounded-circle overflow-h me-3 bg-accent-blue d-flex align-items-center justify-content-center">
                                      {!_.isNull(item.profile_image) &&
                                        !_.isEmpty(item.profile_image) ? (
                                        <img
                                          className="img-fluid"
                                          src={item.profile_image}
                                          alt="user"
                                        />
                                      ) : (
                                        <span className="text-white">
                                          {!_.isNull(item.first_name) &&
                                            !_.isNull(item.last_name) && (
                                              <>
                                                <span>
                                                  {item.first_name.charAt(0)}
                                                  {item.last_name.charAt(0)}
                                                </span>
                                              </>
                                            )}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="w-100">
                                    <p className="text-secondary small mb-0">
                                      Hosted by
                                    </p>
                                    <p className="text-white mb-0 small">
                                      {item.full_name}
                                    </p>
                                  </div>
                                </div>
                                <p className="text-white small">
                                  {item.about_info}
                                </p>
                              </div>
                            );
                          } else {
                            return <></>;
                          }
                        })}
                        {/* rendering only non primary hosts using the below loop */}
                        {showDetail?.AdminShowHosts.map((item, index) => {
                          if (
                            item.is_attendee === 0 &&
                            item.is_hidden === 0 &&
                            !item.is_primary
                          ) {
                            let uniqueIndex = `${index}_${new Date().getTime()}`;
                            return (
                              <div
                                key={`${getRandomString(index)}${uniqueIndex}`}
                                className="col-sm-12"
                              >
                                <div className="d-flex align-items-center mb-3">
                                  <div className="flex-shrink-0">
                                    <div className="img-35 rounded-circle overflow-h me-3 bg-accent-blue d-flex align-items-center justify-content-center">
                                      {!_.isNull(item.profile_image) &&
                                        !_.isEmpty(item.profile_image) ? (
                                        <img
                                          className="img-fluid"
                                          src={item.profile_image}
                                          alt="user"
                                        />
                                      ) : (
                                        <span className="text-white">
                                          {!_.isNull(item.first_name) &&
                                            !_.isNull(item.last_name) && (
                                              <>
                                                <span>
                                                  {item.first_name.charAt(0)}
                                                  {item.last_name.charAt(0)}
                                                </span>
                                              </>
                                            )}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="w-100">
                                    <p className="text-secondary small mb-0">
                                      Hosted by
                                    </p>
                                    <p className="text-white mb-0 small">
                                      {item.full_name}
                                    </p>
                                  </div>
                                </div>
                                <p className="text-white small">
                                  {item.about_info}
                                </p>
                              </div>
                            );
                          } else {
                            return <></>;
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    {/* Friends Attending */}
                    {!_.isUndefined(scheduleShowId) && (
                      <>
                        <h1 className="text-white fw-bold my-4">
                          {renderFriendsText()}
                        </h1>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="w-100">
                            <div className="d-flex flex-wrap">
                              {!_.isEmpty(showDetail.AdminShowHosts) &&
                                showDetail.AdminShowHosts.map((item, index) => {
                                  if (
                                    item.is_attendee === 1 &&
                                    item.show_schedule_id === scheduleShowId
                                  ) {
                                    let uniqueIndex = `${index}_${new Date().getTime()}`;
                                    return (
                                      <div
                                        key={`${getRandomString(
                                          index
                                        )}${uniqueIndex}`}
                                        className="me-1 w-70"
                                      >
                                        <div className="img-44 rounded-circle m-auto bg-accent-blue d-flex align-items-center justify-content-center">
                                          {!_.isNull(item.profile_image) &&
                                            !_.isEmpty(item.profile_image) ? (
                                            <img
                                              className="img-fluid"
                                              src={item.profile_image}
                                              alt="user"
                                            />
                                          ) : (
                                            <span className="text-white">
                                              {!_.isNull(item.first_name) &&
                                                !_.isNull(item.last_name) && (
                                                  <>
                                                    <span>
                                                      {item.first_name.charAt(
                                                        0
                                                      )}
                                                      {item.last_name.charAt(0)}
                                                    </span>
                                                  </>
                                                )}
                                            </span>
                                          )}
                                        </div>
                                        <p className="text-white mb-0 mt-2 text-center small">
                                          {item.first_name}
                                        </p>
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              type="button"
                              className="btn btn-danger btn-sm py-1 px-3"
                              onClick={() => {
                                if (!_.isNull(user)) {
                                  handleShowInviteFriendsPopUp(scheduleShowId);
                                } else {
                                  dispatch(loginModalHandler(true));
                                }
                              }}
                            >
                              Invite
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="row mt-4">
                  {showDetail.AdminShowImages.map((item, index) => {
                    let uniqueIndex = `${index}_${new Date().getTime()}`;
                    return (
                      <div
                        key={`${getRandomString(index)}${uniqueIndex}`}
                        className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4"
                      >
                        <div
                          className="friendsYoga"
                          style={{ background: `url(${item.hero_url})` }}
                        ></div>
                      </div>
                    );
                  })}
                  {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                    <div
                      className="friendsYoga"
                      style={{ background: `url(${yoga2})` }}
                    ></div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                    <div
                      className="friendsYoga"
                      style={{ background: `url(${yoga3})` }}
                    ></div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                    <div
                      className="friendsYoga"
                      style={{ background: `url(${yoga4})` }}
                    ></div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                    <div
                      className="friendsYoga"
                      style={{ background: `url(${yoga5})` }}
                    ></div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                    <div
                      className="friendsYoga"
                      style={{ background: `url(${yoga6})` }}
                    ></div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className={`pb-5 ${!showDetail.preparation && "d-none"}`}>
              <div className="container-fluid px-lg-5 px-md-5 px-ms-4 px-4">
                <h2 className="h1 text-white fw-bold mb-2">Preparation</h2>
                <p className="text-white mb-0 textareaWhiteSpace">
                  {showDetail?.preparation}
                </p>
              </div>
            </div>
            {airingScheduleShows.length >= 1 && (
              <div className="bg-disabled py-sm-4 py-2">
                <div className="container-fluid px-lg-5 px-md-5 px-ms-4 px-4">
                  <h2 className="h1 text-white fw-bold mb-4">Also Airing On</h2>
                  <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                    <div className="row">
                      <Modal show={unAttendAiringShow}>
                        <Modal.Header className="modalStyle">
                          <h5>
                            Do you wish to no longer attend "{showDetail?.title}
                            "?
                          </h5>
                        </Modal.Header>
                        <Modal.Footer className="modalStyle">
                          <Button
                            variant="secondary"
                            onClick={() => setUnAttendAiringShow(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="danger"
                            onClick={handleUnAttendAiringShow}
                          >
                            Confirm
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      {airingScheduleShows.map((item, index) => {
                        let uniqueIndex = `${index}_${new Date().getTime()}`;
                        return (
                          <div
                            key={`${getRandomString(index)}${uniqueIndex}`}
                            className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mb-3"
                          >
                            <div className="bg-dark rounded py-3 px-3">
                              <h6 className="text-white mb-3">
                                {getFormattedDateTime(item.utc_datetime)}
                              </h6>
                              {renderAiringShowAttendButton(
                                item.id,
                                item.utc_datetime,
                                item.show_id
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {episodesData.length > 0 && (
              <div className="container-fluid px-lg-5 px-md-5 px-ms-4 px-4">
                <h1 className="text-white fw-bold my-4">Episodes</h1>
                <div className="row">
                  {episodesData.map((episode) => (
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                      <div
                        className="episodeIMg mt-2 mb-3"
                        style={{
                          background: `url(${episode?.AdminShow?.AdminShowImages[0]?.hero_url})`,
                        }}
                      >
                        <div className="position-absolute episodeContent">
                          <div className="p-2">
                            <div className="d-flex align-items-center">
                              <button
                                type="button"
                                className="btn btn-danger btn-sm py-1"
                              >
                                Play Now
                              </button>
                              <p className="text-white mb-0 ms-2">More info</p>
                            </div>
                            <h4 className="text-white fw-bold mt-2">
                              {episode?.AdminShow?.title}
                            </h4>
                            <p className="text-white mb-0">
                              Episode #{episode?.AdminShow?.id}
                            </p>
                          </div>
                        </div>
                      </div>
                      <p className="text-white small">
                        {episode?.AdminShow?.description}
                      </p>
                      <div className="d-flex align-items-center mb-4">
                        <div className="flex-shrink-0">
                          <div className="img-35 rounded-circle overflow-h me-3 bg-accent-blue d-flex align-items-center justify-content-center">
                            {getProfileImageOrFirstAndLastChars(
                              episode?.AdminShow?.AdminShowHosts[0]
                                ?.UserBasicDetail?.profile_image,
                              episode?.AdminShow?.AdminShowHosts[0]
                                ?.UserBasicDetail?.first_name,
                              episode?.AdminShow?.AdminShowHosts[0]
                                ?.UserBasicDetail?.last_name
                            )}
                          </div>
                        </div>
                        <div className="w-100">
                          <p className="text-secondary small mb-0">Hosted by</p>
                          <p className="text-white mb-0 small">
                            {
                              episode?.AdminShow?.AdminShowHosts[0]
                                ?.UserBasicDetail?.full_name
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </main>
          {user && (
            <div className="container-fluid px-lg-5 px-md-5 px-ms-4 px-4">
              {user?.role === CONSTANTS.ADMIN_ROLE ||
                showDetail.AdminShowHosts.some(
                  (item) => user.id === item.host_id && item.is_attendee === 0
                ) ? (
                <button
                  type="button"
                  className="btn btn-danger btn-sm py-1 px-3 mt-3 me-2"
                >
                  <Link
                    to={`/admin/show/${showDetail.id}`}
                    className="d-block link text-white"
                  >
                    Edit Show
                  </Link>
                </button>
              ) : (
                <></>
              )}
              {user?.role === CONSTANTS.ADMIN_ROLE && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm py-1 px-3 mt-3"
                >
                  <Link
                    to={`/admin/schedule-show/${showDetail.id}`}
                    className="d-block link text-white"
                  >
                    Schedule Show
                  </Link>
                </button>
              )}
            </div>
          )}
        </div>
      )}
      <Footer />
      <div className="popUpBg d-none">
        <div className="popUp">
          <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12 m-auto">
            <div className="position-relative">
              <div className="closeSign">
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26 1.53183L24.4682 0L13 11.4682L1.53183 0L0 1.53183L11.4682 13L0 24.4682L1.53183 26L13 14.5318L24.4682 26L26 24.4682L14.5318 13L26 1.53183Z"
                    fill="white"
                  />
                </svg>
              </div>
              <main className="bg-dark-grey p-2 popShadow">
                <div className="col-sm-12">
                  <h1 className="text-white fw-bold mt-lg-4 mt-md-4 mt-sm-0 mt-0 mb-lg-4 mb-md-4 mb-sm-2 mb-2">
                    Hosts
                  </h1>
                  <div className="col-sm-12">
                    <div className="d-flex align-items-center mb-3">
                      <div className="flex-shrink-0">
                        <div className="img-35 rounded-circle overflow-h me-3 bg-accent-blue d-flex align-items-center justify-content-center">
                          <span className="text-white d-none">MS</span>
                          <img
                            className="img-fluid"
                            src={chatImg2}
                            alt="user"
                          />
                        </div>
                      </div>
                      <div className="w-100">
                        <p className="text-secondary small mb-0">Hosted by</p>
                        <p className="text-white mb-0 small">Karne Dillon</p>
                      </div>
                    </div>
                    <p className="text-white small">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here, content here', making it
                      look like readable English.
                    </p>
                    <p className="text-white small mb-0">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less.
                    </p>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
      {/* {!_.isUndefined(showDetail) && (
        <div className={`popUpBg ${popUp === false ? "d-none" : ""}`}>
          <div className="popUp">
            <div className="popMobile">
              <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12 m-auto">
                <div className="position-relative">
                  <div
                    className="closeSign mobileClose"
                    onClick={() => {
                      setpopUp(false);
                      setplayHero(false);
                    }}
                  >
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26 1.53183L24.4682 0L13 11.4682L1.53183 0L0 1.53183L11.4682 13L0 24.4682L1.53183 26L13 14.5318L24.4682 26L26 24.4682L14.5318 13L26 1.53183Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <main className="bg-dark-grey popShadow">
                    <div className="showD_Mob">
                      {playHero ? (
                        <div
                          className="w-100"
                          onClick={() => setplayHero(false)}
                        >
                          <div className="yogaBanner">
                            <video
                              width="100%"
                              onEnded={() => setplayHero(false)}
                              autoplay
                              controls
                            >
                              <source src={url} type="video/mp4" />
                            </video>
                          </div>
                        </div>
                      ) : (
                        <div className="w-100">
                          <div className="yogaBanner">
                            <div className="d-flex align-items-center position-relative h-100">
                              <div className="container-fluid px-lg-5 px-md-5 px-ms-4 px-4">
                                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                                  <span className="d-flex">
                                    <svg
                                      width="24"
                                      height="25"
                                      viewBox="0 0 24 25"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#clip0_1264_4750)">
                                        <path
                                          d="M9.28609 13.5678C6.96909 14.0138 5.82109 16.5937 5.32309 18.2017C5.27613 18.3516 5.26513 18.5105 5.29099 18.6654C5.31685 18.8203 5.37884 18.967 5.47195 19.0935C5.56505 19.22 5.68664 19.3228 5.82687 19.3935C5.9671 19.4643 6.12202 19.501 6.27909 19.5007H9.99809C10.4152 19.5008 10.8277 19.4139 11.2094 19.2456C11.591 19.0772 11.9333 18.8312 12.2145 18.5231C12.4957 18.215 12.7095 17.8516 12.8424 17.4563C12.9752 17.0609 13.0242 16.6421 12.9861 16.2267C12.9329 15.8023 12.7927 15.3935 12.5742 15.0258C12.3558 14.6581 12.0637 14.3394 11.7164 14.0898C11.369 13.8402 10.9739 13.665 10.5557 13.5752C10.1375 13.4853 9.7053 13.4828 9.28609 13.5678Z"
                                          fill="#E50914"
                                        />
                                        <path
                                          d="M23 9.479C22.7348 9.479 22.4804 9.58436 22.2929 9.77189C22.1054 9.95943 22 10.2138 22 10.479V15.5H18C17.2044 15.5 16.4413 15.8161 15.8787 16.3787C15.3161 16.9413 15 17.7044 15 18.5V22.5H5C4.20435 22.5 3.44129 22.1839 2.87868 21.6213C2.31607 21.0587 2 20.2956 2 19.5V5.5C2 4.70435 2.31607 3.94129 2.87868 3.37868C3.44129 2.81607 4.20435 2.5 5 2.5H16.042C16.3072 2.5 16.5616 2.39464 16.7491 2.20711C16.9366 2.01957 17.042 1.76522 17.042 1.5C17.042 1.23478 16.9366 0.98043 16.7491 0.792893C16.5616 0.605357 16.3072 0.5 16.042 0.5H5C3.67441 0.501588 2.40356 1.02888 1.46622 1.96622C0.528882 2.90356 0.00158786 4.17441 0 5.5L0 19.5C0.00158786 20.8256 0.528882 22.0964 1.46622 23.0338C2.40356 23.9711 3.67441 24.4984 5 24.5H16.343C16.9998 24.5019 17.6504 24.3734 18.2572 24.1221C18.8639 23.8708 19.4149 23.5017 19.878 23.036L22.536 20.378C23.0017 19.9149 23.3708 19.3639 23.6221 18.7572C23.8734 18.1504 24.0019 17.4998 24 16.843V10.479C24 10.2138 23.8946 9.95943 23.7071 9.77189C23.5196 9.58436 23.2652 9.479 23 9.479ZM18.464 21.622C18.0607 22.0208 17.5534 22.298 17 22.422V18.5C17 18.2348 17.1054 17.9804 17.2929 17.7929C17.4804 17.6054 17.7348 17.5 18 17.5H21.925C21.801 18.0534 21.5238 18.5607 21.125 18.964L18.464 21.622Z"
                                          fill="#E50914"
                                        />
                                        <path
                                          d="M14.5642 14.6707C14.3664 14.6706 14.1731 14.612 14.0087 14.5021C13.8443 14.3922 13.7162 14.236 13.6405 14.0533C13.5648 13.8706 13.545 13.6696 13.5836 13.4757C13.6221 13.2817 13.7174 13.1035 13.8572 12.9637L21.7102 5.11069C21.7979 5.02309 21.8676 4.91905 21.9151 4.80451C21.9626 4.68997 21.9871 4.56719 21.9871 4.44319C21.9871 4.31918 21.9626 4.1964 21.9151 4.08186C21.8676 3.96732 21.7979 3.86328 21.7102 3.77569C21.6209 3.68286 21.5127 3.61028 21.393 3.56284C21.2733 3.5154 21.1448 3.4942 21.0162 3.50069C20.8876 3.50348 20.761 3.53282 20.6444 3.58685C20.5277 3.64089 20.4234 3.71845 20.3382 3.81469L12.7382 12.2217C12.5603 12.4185 12.3116 12.5366 12.0467 12.55C11.7818 12.5634 11.5225 12.471 11.3257 12.2932C11.1289 12.1154 11.0108 11.8666 10.9974 11.6017C10.984 11.3368 11.0763 11.0775 11.2542 10.8807L18.8542 2.48069C19.1207 2.18315 19.4449 1.94286 19.8071 1.7744C20.1693 1.60594 20.5619 1.51282 20.9612 1.50069C21.3611 1.49212 21.7586 1.56401 22.1302 1.71208C22.5018 1.86016 22.8398 2.08141 23.1242 2.36269C23.6754 2.91516 23.985 3.66374 23.985 4.44419C23.985 5.22463 23.6754 5.97321 23.1242 6.52569L15.2712 14.3787C15.1785 14.4717 15.0683 14.5454 14.9469 14.5955C14.8256 14.6456 14.6955 14.6712 14.5642 14.6707Z"
                                          fill="#E50914"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1264_4750">
                                          <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                            transform="translate(0 0.5)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                    <p className="h5 text-white mb-0 ms-2">
                                      Art
                                    </p>
                                  </span>
                                  <h1 className="text-white fw-bold my-3">
                                    {showDetail.title}
                                  </h1>
                                  <p className="text-white lh-base">
                                    {showDetail.description}
                                  </p>
                                  <div className="d-flex align-items-center">
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-sm px-4 py-2"
                                      onClick={handleJoinEarly}
                                    >
                                      Join Early
                                    </button>
                                    {renderPlayTrailer()}
                                    {renderPlayVOD("popup")}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-sm-12">
                        <div className="row">
                          <div className="col-sm-6">
                            <div
                              className={cx(
                                styles.chatBar,
                                "position-relative",
                                "bg-disabled",
                                "h-100",
                                "chatHeightM"
                              )}
                            >
                              <div
                                className={cx(
                                  styles.chatWrp,
                                  "p-3",
                                  "h-100",
                                  "chatScrollM"
                                )}
                              >
                                <div className="d-flex">
                                  <div className="flex-shrink-0 me-2">
                                    <div className="img-44 rounded-circle overflow-h">
                                      <img
                                        className="img-fluid"
                                        src={chatImg1}
                                        alt="user"
                                      />
                                    </div>
                                  </div>
                                  <div className="w-100">
                                    <div className="d-flex border-bottom border-secondary pb-2">
                                      <div className="w-100">
                                        <div className="d-flex">
                                          <p className="text-white mb-0 fw-bold small">
                                            Shane Atoruk
                                          </p>
                                          <img
                                            className="img-fluid px-2"
                                            src={chatCircle}
                                            alt="chat-circle"
                                          />
                                          <span className="text-secondary small">
                                            Following
                                          </span>
                                        </div>
                                        <p className="text-white small mb-0">
                                          What is this called might geet it
                                        </p>
                                        <div className="d-flex mt-2">
                                          <div className="d-flex align-items-center me-2">
                                            <svg
                                              width="18"
                                              height="18"
                                              viewBox="0 0 18 18"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10.95 5.99981H15.75C16.1478 5.99981 16.5294 6.15784 16.8107 6.43915C17.092 6.72045 17.25 7.10198 17.25 7.4998V9.07781C17.2502 9.27383 17.212 9.46799 17.1375 9.64931L14.8162 15.2856C14.7596 15.423 14.6634 15.5405 14.5399 15.6231C14.4164 15.7058 14.2711 15.7499 14.1225 15.7498H1.5C1.30109 15.7498 1.11032 15.6708 0.96967 15.5301C0.829018 15.3895 0.75 15.1987 0.75 14.9998V7.4998C0.75 7.30089 0.829018 7.11013 0.96967 6.96948C1.11032 6.82882 1.30109 6.7498 1.5 6.7498H4.1115C4.23157 6.74984 4.3499 6.72104 4.45653 6.66583C4.56315 6.61062 4.65497 6.53062 4.72425 6.43256L8.814 0.637306C8.8657 0.56404 8.94194 0.511726 9.0289 0.489848C9.11586 0.467971 9.20778 0.477978 9.288 0.518056L10.6485 1.19831C11.0314 1.38968 11.3372 1.70629 11.5153 2.09554C11.6933 2.48479 11.7329 2.92325 11.6273 3.33806L10.95 5.99981ZM5.25 7.94081V14.2498H13.62L15.75 9.07781V7.4998H10.95C10.7215 7.49977 10.4961 7.44756 10.2909 7.34715C10.0857 7.24674 9.90617 7.10078 9.76596 6.92042C9.62575 6.74006 9.52859 6.53005 9.48189 6.30642C9.43519 6.0828 9.44019 5.85146 9.4965 5.63006L10.1737 2.96906C10.1949 2.88605 10.1871 2.79829 10.1515 2.72038C10.1159 2.64246 10.0546 2.57909 9.978 2.54081L9.48225 2.29331L5.94975 7.2973C5.76225 7.5628 5.52225 7.78031 5.25 7.94081ZM3.75 8.2498H2.25V14.2498H3.75V8.2498Z"
                                                fill="white"
                                              />
                                            </svg>
                                            <p className="text-white small mb-0 ms-1">
                                              Like
                                            </p>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <svg
                                              width="18"
                                              height="18"
                                              viewBox="0 0 18 18"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M8.25 10.5H9.75C10.9803 10.4995 12.1873 10.8354 13.2406 11.4712C14.2939 12.107 15.1533 13.0186 15.726 14.1075C15.7421 13.9054 15.7501 13.7027 15.75 13.5C15.75 9.35775 12.3922 6 8.25 6V1.875L0.374998 8.25L8.25 14.625V10.5ZM9.75 9H6.75V11.481L2.75925 8.25L6.75 5.019V7.5H8.25C9.11231 7.49903 9.96466 7.68436 10.7487 8.04332C11.5328 8.40227 12.23 8.92637 12.7927 9.57975C11.8243 9.19591 10.7918 8.99919 9.75 9Z"
                                                fill="white"
                                              />
                                            </svg>
                                            <p className="text-white small mb-0 ms-1">
                                              Reply
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <p className="text-secondary mb-0 small">
                                          1 min
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex mt-3">
                                  <div className="flex-shrink-0 me-2">
                                    <div className="img-35 rounded-circle overflow-h">
                                      <img
                                        className="img-fluid"
                                        src={chatImg2}
                                        alt="user"
                                      />
                                    </div>
                                  </div>
                                  <div className="w-100">
                                    <div className="d-flex border-bottom border-secondary pb-2">
                                      <div className="w-100">
                                        <div className="d-flex">
                                          <p className="text-white mb-0 fw-bold small">
                                            Kim Jordan
                                          </p>
                                          <img
                                            className="img-fluid px-2"
                                            src={chatCircle}
                                            alt="chat-circle"
                                          />
                                          <span className="text-blue small">
                                            Follow
                                          </span>
                                        </div>
                                        <p className="text-white small mb-0">
                                          I love the episode #4
                                        </p>
                                        <div className="d-flex mt-2">
                                          <div className="d-flex align-items-center me-2">
                                            <svg
                                              width="18"
                                              height="18"
                                              viewBox="0 0 18 18"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10.95 5.99981H15.75C16.1478 5.99981 16.5294 6.15784 16.8107 6.43915C17.092 6.72045 17.25 7.10198 17.25 7.4998V9.07781C17.2502 9.27383 17.212 9.46799 17.1375 9.64931L14.8162 15.2856C14.7596 15.423 14.6634 15.5405 14.5399 15.6231C14.4164 15.7058 14.2711 15.7499 14.1225 15.7498H1.5C1.30109 15.7498 1.11032 15.6708 0.96967 15.5301C0.829018 15.3895 0.75 15.1987 0.75 14.9998V7.4998C0.75 7.30089 0.829018 7.11013 0.96967 6.96948C1.11032 6.82882 1.30109 6.7498 1.5 6.7498H4.1115C4.23157 6.74984 4.3499 6.72104 4.45653 6.66583C4.56315 6.61062 4.65497 6.53062 4.72425 6.43256L8.814 0.637306C8.8657 0.56404 8.94194 0.511726 9.0289 0.489848C9.11586 0.467971 9.20778 0.477978 9.288 0.518056L10.6485 1.19831C11.0314 1.38968 11.3372 1.70629 11.5153 2.09554C11.6933 2.48479 11.7329 2.92325 11.6273 3.33806L10.95 5.99981ZM5.25 7.94081V14.2498H13.62L15.75 9.07781V7.4998H10.95C10.7215 7.49977 10.4961 7.44756 10.2909 7.34715C10.0857 7.24674 9.90617 7.10078 9.76596 6.92042C9.62575 6.74006 9.52859 6.53005 9.48189 6.30642C9.43519 6.0828 9.44019 5.85146 9.4965 5.63006L10.1737 2.96906C10.1949 2.88605 10.1871 2.79829 10.1515 2.72038C10.1159 2.64246 10.0546 2.57909 9.978 2.54081L9.48225 2.29331L5.94975 7.2973C5.76225 7.5628 5.52225 7.78031 5.25 7.94081ZM3.75 8.2498H2.25V14.2498H3.75V8.2498Z"
                                                fill="white"
                                              />
                                            </svg>
                                            <p className="text-white small mb-0 ms-1">
                                              Like
                                            </p>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <svg
                                              width="18"
                                              height="18"
                                              viewBox="0 0 18 18"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M8.25 10.5H9.75C10.9803 10.4995 12.1873 10.8354 13.2406 11.4712C14.2939 12.107 15.1533 13.0186 15.726 14.1075C15.7421 13.9054 15.7501 13.7027 15.75 13.5C15.75 9.35775 12.3922 6 8.25 6V1.875L0.374998 8.25L8.25 14.625V10.5ZM9.75 9H6.75V11.481L2.75925 8.25L6.75 5.019V7.5H8.25C9.11231 7.49903 9.96466 7.68436 10.7487 8.04332C11.5328 8.40227 12.23 8.92637 12.7927 9.57975C11.8243 9.19591 10.7918 8.99919 9.75 9Z"
                                                fill="white"
                                              />
                                            </svg>
                                            <p className="text-white small mb-0 ms-1">
                                              Reply
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <p className="text-secondary mb-0 small">
                                          Yesterday
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex mt-3">
                                  <div className="flex-shrink-0 me-2">
                                    <div className="img-35 rounded-circle overflow-h">
                                      <img
                                        className="img-fluid"
                                        src={chatImg3}
                                        alt="user"
                                      />
                                    </div>
                                  </div>
                                  <div className="w-100">
                                    <div className="d-flex pb-2">
                                      <div className="w-100">
                                        <div className="d-flex">
                                          <p className="text-white mb-0 fw-bold small">
                                            Shane Atoruk
                                          </p>
                                          <img
                                            className="img-fluid px-2"
                                            src={chatCircle}
                                            alt="chat-circle"
                                          />
                                          <span className="text-blue small">
                                            Follow
                                          </span>
                                        </div>
                                        <p className="text-white small mb-0">
                                          Sounds Good 😀
                                        </p>
                                        <div className="d-flex mt-2">
                                          <div className="d-flex align-items-center me-2">
                                            <svg
                                              width="18"
                                              height="18"
                                              viewBox="0 0 18 18"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10.95 5.99981H15.75C16.1478 5.99981 16.5294 6.15784 16.8107 6.43915C17.092 6.72045 17.25 7.10198 17.25 7.4998V9.07781C17.2502 9.27383 17.212 9.46799 17.1375 9.64931L14.8162 15.2856C14.7596 15.423 14.6634 15.5405 14.5399 15.6231C14.4164 15.7058 14.2711 15.7499 14.1225 15.7498H1.5C1.30109 15.7498 1.11032 15.6708 0.96967 15.5301C0.829018 15.3895 0.75 15.1987 0.75 14.9998V7.4998C0.75 7.30089 0.829018 7.11013 0.96967 6.96948C1.11032 6.82882 1.30109 6.7498 1.5 6.7498H4.1115C4.23157 6.74984 4.3499 6.72104 4.45653 6.66583C4.56315 6.61062 4.65497 6.53062 4.72425 6.43256L8.814 0.637306C8.8657 0.56404 8.94194 0.511726 9.0289 0.489848C9.11586 0.467971 9.20778 0.477978 9.288 0.518056L10.6485 1.19831C11.0314 1.38968 11.3372 1.70629 11.5153 2.09554C11.6933 2.48479 11.7329 2.92325 11.6273 3.33806L10.95 5.99981ZM5.25 7.94081V14.2498H13.62L15.75 9.07781V7.4998H10.95C10.7215 7.49977 10.4961 7.44756 10.2909 7.34715C10.0857 7.24674 9.90617 7.10078 9.76596 6.92042C9.62575 6.74006 9.52859 6.53005 9.48189 6.30642C9.43519 6.0828 9.44019 5.85146 9.4965 5.63006L10.1737 2.96906C10.1949 2.88605 10.1871 2.79829 10.1515 2.72038C10.1159 2.64246 10.0546 2.57909 9.978 2.54081L9.48225 2.29331L5.94975 7.2973C5.76225 7.5628 5.52225 7.78031 5.25 7.94081ZM3.75 8.2498H2.25V14.2498H3.75V8.2498Z"
                                                fill="white"
                                              />
                                            </svg>
                                            <p className="text-white small mb-0 ms-1">
                                              Like
                                            </p>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <svg
                                              width="18"
                                              height="18"
                                              viewBox="0 0 18 18"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M8.25 10.5H9.75C10.9803 10.4995 12.1873 10.8354 13.2406 11.4712C14.2939 12.107 15.1533 13.0186 15.726 14.1075C15.7421 13.9054 15.7501 13.7027 15.75 13.5C15.75 9.35775 12.3922 6 8.25 6V1.875L0.374998 8.25L8.25 14.625V10.5ZM9.75 9H6.75V11.481L2.75925 8.25L6.75 5.019V7.5H8.25C9.11231 7.49903 9.96466 7.68436 10.7487 8.04332C11.5328 8.40227 12.23 8.92637 12.7927 9.57975C11.8243 9.19591 10.7918 8.99919 9.75 9Z"
                                                fill="white"
                                              />
                                            </svg>
                                            <p className="text-white small mb-0 ms-1">
                                              Reply
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <p className="text-secondary mb-0 small">
                                          Yesterday
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="repliedComment ps-5 mt-4">
                                  <p className="text-blue small">
                                    Replied to your comment
                                  </p>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 me-2">
                                      <div className="img-35 rounded-circle overflow-h">
                                        <img
                                          className="img-fluid"
                                          src={chatImg1}
                                          alt="user"
                                        />
                                      </div>
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex border-bottom border-secondary pb-2">
                                        <div className="w-100">
                                          <div className="d-flex">
                                            <p className="text-white mb-0 fw-bold small">
                                              Shane Atoruk
                                            </p>
                                            <img
                                              className="img-fluid px-2"
                                              src={chatCircle}
                                              alt="chat-circle"
                                            />
                                            <span className="text-secondary small">
                                              Following
                                            </span>
                                          </div>
                                          <p className="text-white small mb-0">
                                            What is this called might geet it
                                          </p>
                                          <div className="d-flex mt-2">
                                            <div className="d-flex align-items-center me-2">
                                              <svg
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M10.95 5.99981H15.75C16.1478 5.99981 16.5294 6.15784 16.8107 6.43915C17.092 6.72045 17.25 7.10198 17.25 7.4998V9.07781C17.2502 9.27383 17.212 9.46799 17.1375 9.64931L14.8162 15.2856C14.7596 15.423 14.6634 15.5405 14.5399 15.6231C14.4164 15.7058 14.2711 15.7499 14.1225 15.7498H1.5C1.30109 15.7498 1.11032 15.6708 0.96967 15.5301C0.829018 15.3895 0.75 15.1987 0.75 14.9998V7.4998C0.75 7.30089 0.829018 7.11013 0.96967 6.96948C1.11032 6.82882 1.30109 6.7498 1.5 6.7498H4.1115C4.23157 6.74984 4.3499 6.72104 4.45653 6.66583C4.56315 6.61062 4.65497 6.53062 4.72425 6.43256L8.814 0.637306C8.8657 0.56404 8.94194 0.511726 9.0289 0.489848C9.11586 0.467971 9.20778 0.477978 9.288 0.518056L10.6485 1.19831C11.0314 1.38968 11.3372 1.70629 11.5153 2.09554C11.6933 2.48479 11.7329 2.92325 11.6273 3.33806L10.95 5.99981ZM5.25 7.94081V14.2498H13.62L15.75 9.07781V7.4998H10.95C10.7215 7.49977 10.4961 7.44756 10.2909 7.34715C10.0857 7.24674 9.90617 7.10078 9.76596 6.92042C9.62575 6.74006 9.52859 6.53005 9.48189 6.30642C9.43519 6.0828 9.44019 5.85146 9.4965 5.63006L10.1737 2.96906C10.1949 2.88605 10.1871 2.79829 10.1515 2.72038C10.1159 2.64246 10.0546 2.57909 9.978 2.54081L9.48225 2.29331L5.94975 7.2973C5.76225 7.5628 5.52225 7.78031 5.25 7.94081ZM3.75 8.2498H2.25V14.2498H3.75V8.2498Z"
                                                  fill="white"
                                                />
                                              </svg>
                                              <p className="text-white small mb-0 ms-1">
                                                Like
                                              </p>
                                            </div>
                                            <div className="d-flex align-items-center">
                                              <svg
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M8.25 10.5H9.75C10.9803 10.4995 12.1873 10.8354 13.2406 11.4712C14.2939 12.107 15.1533 13.0186 15.726 14.1075C15.7421 13.9054 15.7501 13.7027 15.75 13.5C15.75 9.35775 12.3922 6 8.25 6V1.875L0.374998 8.25L8.25 14.625V10.5ZM9.75 9H6.75V11.481L2.75925 8.25L6.75 5.019V7.5H8.25C9.11231 7.49903 9.96466 7.68436 10.7487 8.04332C11.5328 8.40227 12.23 8.92637 12.7927 9.57975C11.8243 9.19591 10.7918 8.99919 9.75 9Z"
                                                  fill="white"
                                                />
                                              </svg>
                                              <p className="text-white small mb-0 ms-1">
                                                Reply
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex-shrink-0">
                                          <p className="text-secondary mb-0 small">
                                            1 min
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex mt-3">
                                    <div className="flex-shrink-0 me-2">
                                      <div className="img-35 rounded-circle overflow-h">
                                        <img
                                          className="img-fluid"
                                          src={chatImg2}
                                          alt="user"
                                        />
                                      </div>
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex border-bottom border-secondary pb-2">
                                        <div className="w-100">
                                          <div className="d-flex">
                                            <p className="text-white mb-0 fw-bold small">
                                              Kim Jordan
                                            </p>
                                            <img
                                              className="img-fluid px-2"
                                              src={chatCircle}
                                              alt="chat-circle"
                                            />
                                            <span className="text-blue small">
                                              Follow
                                            </span>
                                          </div>
                                          <p className="text-white small mb-0">
                                            I love the episode #4
                                          </p>
                                          <div className="d-flex mt-2">
                                            <div className="d-flex align-items-center me-2">
                                              <svg
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M10.95 5.99981H15.75C16.1478 5.99981 16.5294 6.15784 16.8107 6.43915C17.092 6.72045 17.25 7.10198 17.25 7.4998V9.07781C17.2502 9.27383 17.212 9.46799 17.1375 9.64931L14.8162 15.2856C14.7596 15.423 14.6634 15.5405 14.5399 15.6231C14.4164 15.7058 14.2711 15.7499 14.1225 15.7498H1.5C1.30109 15.7498 1.11032 15.6708 0.96967 15.5301C0.829018 15.3895 0.75 15.1987 0.75 14.9998V7.4998C0.75 7.30089 0.829018 7.11013 0.96967 6.96948C1.11032 6.82882 1.30109 6.7498 1.5 6.7498H4.1115C4.23157 6.74984 4.3499 6.72104 4.45653 6.66583C4.56315 6.61062 4.65497 6.53062 4.72425 6.43256L8.814 0.637306C8.8657 0.56404 8.94194 0.511726 9.0289 0.489848C9.11586 0.467971 9.20778 0.477978 9.288 0.518056L10.6485 1.19831C11.0314 1.38968 11.3372 1.70629 11.5153 2.09554C11.6933 2.48479 11.7329 2.92325 11.6273 3.33806L10.95 5.99981ZM5.25 7.94081V14.2498H13.62L15.75 9.07781V7.4998H10.95C10.7215 7.49977 10.4961 7.44756 10.2909 7.34715C10.0857 7.24674 9.90617 7.10078 9.76596 6.92042C9.62575 6.74006 9.52859 6.53005 9.48189 6.30642C9.43519 6.0828 9.44019 5.85146 9.4965 5.63006L10.1737 2.96906C10.1949 2.88605 10.1871 2.79829 10.1515 2.72038C10.1159 2.64246 10.0546 2.57909 9.978 2.54081L9.48225 2.29331L5.94975 7.2973C5.76225 7.5628 5.52225 7.78031 5.25 7.94081ZM3.75 8.2498H2.25V14.2498H3.75V8.2498Z"
                                                  fill="white"
                                                />
                                              </svg>
                                              <p className="text-white small mb-0 ms-1">
                                                Like
                                              </p>
                                            </div>
                                            <div className="d-flex align-items-center">
                                              <svg
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M8.25 10.5H9.75C10.9803 10.4995 12.1873 10.8354 13.2406 11.4712C14.2939 12.107 15.1533 13.0186 15.726 14.1075C15.7421 13.9054 15.7501 13.7027 15.75 13.5C15.75 9.35775 12.3922 6 8.25 6V1.875L0.374998 8.25L8.25 14.625V10.5ZM9.75 9H6.75V11.481L2.75925 8.25L6.75 5.019V7.5H8.25C9.11231 7.49903 9.96466 7.68436 10.7487 8.04332C11.5328 8.40227 12.23 8.92637 12.7927 9.57975C11.8243 9.19591 10.7918 8.99919 9.75 9Z"
                                                  fill="white"
                                                />
                                              </svg>
                                              <p className="text-white small mb-0 ms-1">
                                                Reply
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex-shrink-0">
                                          <p className="text-secondary mb-0 small">
                                            Yesterday
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex mt-3">
                                    <div className="flex-shrink-0 me-2">
                                      <div className="img-35 rounded-circle overflow-h">
                                        <img
                                          className="img-fluid"
                                          src={chatImg3}
                                          alt="user"
                                        />
                                      </div>
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex border-bottom border-secondary pb-2">
                                        <div className="w-100">
                                          <div className="d-flex">
                                            <p className="text-white mb-0 fw-bold small">
                                              Shane Atoruk
                                            </p>
                                            <img
                                              className="img-fluid px-2"
                                              src={chatCircle}
                                              alt="chat-circle"
                                            />
                                            <span className="text-blue small">
                                              Follow
                                            </span>
                                          </div>
                                          <p className="text-white small mb-0">
                                            Sounds Good 😀
                                          </p>
                                          <div className="d-flex mt-2">
                                            <div className="d-flex align-items-center me-2">
                                              <svg
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M10.95 5.99981H15.75C16.1478 5.99981 16.5294 6.15784 16.8107 6.43915C17.092 6.72045 17.25 7.10198 17.25 7.4998V9.07781C17.2502 9.27383 17.212 9.46799 17.1375 9.64931L14.8162 15.2856C14.7596 15.423 14.6634 15.5405 14.5399 15.6231C14.4164 15.7058 14.2711 15.7499 14.1225 15.7498H1.5C1.30109 15.7498 1.11032 15.6708 0.96967 15.5301C0.829018 15.3895 0.75 15.1987 0.75 14.9998V7.4998C0.75 7.30089 0.829018 7.11013 0.96967 6.96948C1.11032 6.82882 1.30109 6.7498 1.5 6.7498H4.1115C4.23157 6.74984 4.3499 6.72104 4.45653 6.66583C4.56315 6.61062 4.65497 6.53062 4.72425 6.43256L8.814 0.637306C8.8657 0.56404 8.94194 0.511726 9.0289 0.489848C9.11586 0.467971 9.20778 0.477978 9.288 0.518056L10.6485 1.19831C11.0314 1.38968 11.3372 1.70629 11.5153 2.09554C11.6933 2.48479 11.7329 2.92325 11.6273 3.33806L10.95 5.99981ZM5.25 7.94081V14.2498H13.62L15.75 9.07781V7.4998H10.95C10.7215 7.49977 10.4961 7.44756 10.2909 7.34715C10.0857 7.24674 9.90617 7.10078 9.76596 6.92042C9.62575 6.74006 9.52859 6.53005 9.48189 6.30642C9.43519 6.0828 9.44019 5.85146 9.4965 5.63006L10.1737 2.96906C10.1949 2.88605 10.1871 2.79829 10.1515 2.72038C10.1159 2.64246 10.0546 2.57909 9.978 2.54081L9.48225 2.29331L5.94975 7.2973C5.76225 7.5628 5.52225 7.78031 5.25 7.94081ZM3.75 8.2498H2.25V14.2498H3.75V8.2498Z"
                                                  fill="white"
                                                />
                                              </svg>
                                              <p className="text-white small mb-0 ms-1">
                                                Like
                                              </p>
                                            </div>
                                            <div className="d-flex align-items-center">
                                              <svg
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M8.25 10.5H9.75C10.9803 10.4995 12.1873 10.8354 13.2406 11.4712C14.2939 12.107 15.1533 13.0186 15.726 14.1075C15.7421 13.9054 15.7501 13.7027 15.75 13.5C15.75 9.35775 12.3922 6 8.25 6V1.875L0.374998 8.25L8.25 14.625V10.5ZM9.75 9H6.75V11.481L2.75925 8.25L6.75 5.019V7.5H8.25C9.11231 7.49903 9.96466 7.68436 10.7487 8.04332C11.5328 8.40227 12.23 8.92637 12.7927 9.57975C11.8243 9.19591 10.7918 8.99919 9.75 9Z"
                                                  fill="white"
                                                />
                                              </svg>
                                              <p className="text-white small mb-0 ms-1">
                                                Reply
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex-shrink-0">
                                          <p className="text-secondary mb-0 small">
                                            Yesterday
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center mt-4">
                                    <img src={repliesIcon} alt="replies" />
                                    <p className="text-blue small mb-0 ms-1">
                                      View 2 more replies
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={cx(
                                  styles.chatBox,
                                  "bg-white",
                                  "px-2",
                                  "py-1",
                                  "chatInputM",
                                  "windowChatH"
                                )}
                              >
                                <div className="d-flex align-items-center">
                                  <div className="img-35 rounded-circle overflow-h">
                                    <img
                                      className="img-fluid"
                                      src={chatImg3}
                                      alt="user"
                                    />
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control border-0"
                                    placeholder="Write a comment"
                                  />
                                  <img src={smileImg} alt="smile img" />
                                  <div className="bg-danger p-1 rounded ms-2">
                                    <img
                                      className="img-fluid"
                                      src={sentIcon}
                                      alt="sent icon"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 ps-lg-0">
                            <div className="pe-2">
                              <h6 className="text-white my-2">Hosts</h6>
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="d-flex align-items-center mb-3">
                                    <div className="flex-shrink-0">
                                      <div className="img-35 rounded-circle overflow-h me-3">
                                        <img
                                          className="img-fluid"
                                          src={chatImg2}
                                          alt="user"
                                        />
                                      </div>
                                    </div>
                                    <div className="w-100">
                                      <p className="text-secondary small mb-0">
                                        Hosted by
                                      </p>
                                      <p className="text-white mb-0 small">
                                        Karne Dillon
                                      </p>
                                    </div>
                                  </div>
                                  <p className="text-white small">
                                    It is a long established fact that a reader
                                    will be distracted by the readable content
                                    of a page when looking at its layout. The
                                    point of using Lorem Ipsum is that it has a
                                    more-or-less normal distribution of letters,
                                    as opposed to using 'Content here, content
                                    here', making it look like readable English.
                                  </p>
                                  <p className="text-white small">
                                    It is a long established fact that a reader
                                    will be distracted by the readable content
                                    of a page when looking at its layout. The
                                    point of using Lorem Ipsum is that it has a
                                    more-or-less.
                                  </p>
                                  <p className="text-white small">
                                    It is a long established fact that a reader
                                    will be distracted by the readable content
                                    of a page when looking at its layout. The
                                    point of using Lorem Ipsum is that it has a
                                    more-or-less.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container-fluid px-lg-5 px-md-5 px-ms-4 px-4 px-3">
                      <div className="mt-4">
                        <div className="d-flex align-items-center justify-content-between mt-2 mb-4">
                          <h6 className="text-white mb-0">Friends Attending</h6>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm py-2 px-4"
                          >
                            Invite
                          </button>
                        </div>

                        <div className="d-flex flex-wrap">
                          <div className="me-1 w-65">
                            <div className="img-44 rounded-circle m-auto">
                              <img src={chatImg1} alt="img" />
                            </div>
                            <p className="text-white mb-0 mt-2 text-center small">
                              Mike J.
                            </p>
                          </div>
                          <div className="me-1 w-65">
                            <div className="img-44 rounded-circle m-auto">
                              <img src={chatImg2} alt="img" />
                            </div>
                            <p className="text-white mb-0 mt-2 text-center small">
                              Jerry M.
                            </p>
                          </div>
                          <div className="me-1 w-65">
                            <div className="img-44 rounded-circle m-auto">
                              <img src={chatImg3} alt="img" />
                            </div>
                            <p className="text-white mb-0 mt-2 text-center small">
                              Micheal K.
                            </p>
                          </div>
                          <div className="me-1 w-65">
                            <div className="img-44 rounded-circle m-auto">
                              <img src={chatImg1} alt="img" />
                            </div>
                            <p className="text-white mb-0 mt-2 text-center small">
                              Mike J.
                            </p>
                          </div>
                          <div className="me-1 w-65">
                            <div className="img-44 rounded-circle m-auto">
                              <img src={chatImg2} alt="img" />
                            </div>
                            <p className="text-white mb-0 mt-2 text-center small">
                              Jerry M.
                            </p>
                          </div>
                          <div className="me-1 w-65">
                            <div className="img-44 rounded-circle m-auto">
                              <img src={chatImg3} alt="img" />
                            </div>
                            <p className="text-white mb-0 mt-2 text-center small">
                              Micheal K.
                            </p>
                          </div>
                          <div className="me-1 w-65">
                            <div className="img-44 rounded-circle m-auto">
                              <img src={chatImg1} alt="img" />
                            </div>
                            <p className="text-white mb-0 mt-2 text-center small">
                              Mike J.
                            </p>
                          </div>
                          <div className="me-1 w-65">
                            <div className="img-44 rounded-circle m-auto">
                              <img src={chatImg2} alt="img" />
                            </div>
                            <p className="text-white mb-0 mt-2 text-center small">
                              Jerry M.
                            </p>
                          </div>
                          <div className="me-1 w-65">
                            <div className="img-44 rounded-circle m-auto">
                              <img src={chatImg3} alt="img" />
                            </div>
                            <p className="text-white mb-0 mt-2 text-center small">
                              Micheal K.
                            </p>
                          </div>
                          <div className="w-65">
                            <div className="redCount d-flex align-items-center justify-content-center">
                              <h5 className="text-white mb-0">+4</h5>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                            <div
                              className="friendsYoga"
                              style={{ background: `url(${yoga1})` }}
                            ></div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                            <div
                              className="friendsYoga"
                              style={{ background: `url(${yoga2})` }}
                            ></div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                            <div
                              className="friendsYoga"
                              style={{ background: `url(${yoga3})` }}
                            ></div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                            <div
                              className="friendsYoga"
                              style={{ background: `url(${yoga4})` }}
                            ></div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                            <div
                              className="friendsYoga"
                              style={{ background: `url(${yoga5})` }}
                            ></div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                            <div
                              className="friendsYoga"
                              style={{ background: `url(${yoga6})` }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pb-4">
                      <div className="container-fluid px-lg-5 px-md-5 px-ms-4 px-4 px-3">
                        <h4 className="text-white fw-bold mb-4">
                          Also Airing On
                        </h4>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-xl-0 mb-lg-4 mb-md-4 mb-sm-4 mb-4">
                              <div className="bg-dark rounded py-3 px-3">
                                <h6 className="text-white mb-3">
                                  Tue 2/12 3:00 PM
                                </h6>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm py-1"
                                >
                                  I’ll Attend
                                </button>
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-xl-0 mb-lg-4 mb-md-4 mb-sm-4 mb-4">
                              <div className="bg-dark rounded py-3 px-3">
                                <h6 className="text-white mb-3">
                                  Tue 2/12 3:00 PM
                                </h6>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm py-1"
                                >
                                  I’ll Attend
                                </button>
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-xl-0 mb-lg-4 mb-md-4 mb-sm-4 mb-4">
                              <div className="bg-dark rounded py-3 px-3">
                                <h6 className="text-white mb-3">
                                  Tue 2/12 3:00 PM
                                </h6>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm py-1"
                                >
                                  I’ll Attend
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-disabled container-fluid px-lg-5 px-md-5 px-ms-4 px-4 px-3">
                      <h4 className="text-white fw-bold pt-4">Episodes</h4>
                      <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                          <div
                            className="episodeIMg mt-5 mb-3"
                            style={{
                              background: `url(${yogaBanner})`,
                            }}
                          >
                            <div className="position-absolute episodeContent">
                              <div className="p-2">
                                <div className="d-flex align-items-center">
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm py-1 fs-7"
                                  >
                                    Play Now
                                  </button>
                                  <p className="text-white mb-0 ms-2 small">
                                    More info
                                  </p>
                                </div>
                                <p className="text-white fw-bold mt-2 mb-0">
                                  20 Minute Gentle Yoga
                                </p>
                                <p className="text-white mb-0">Episode #124</p>
                              </div>
                            </div>
                          </div>
                          <p className="text-white small">
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout.
                          </p>
                          <div className="d-flex align-items-center mb-4">
                            <div className="flex-shrink-0">
                              <div className="img-35 rounded-circle overflow-h me-3">
                                <img
                                  className="img-fluid"
                                  src={chatImg2}
                                  alt="user"
                                />
                              </div>
                            </div>
                            <div className="w-100">
                              <p className="text-secondary small mb-0">
                                Hosted by
                              </p>
                              <p className="text-white mb-0 small">
                                Karne Dillon
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                          <div
                            className="episodeIMg mt-5 mb-3"
                            style={{
                              background: `url(${yogaBanner})`,
                            }}
                          >
                            <div className="position-absolute episodeContent">
                              <div className="p-2">
                                <div className="d-flex align-items-center">
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm py-1 fs-7"
                                  >
                                    Play Now
                                  </button>
                                  <p className="text-white mb-0 ms-2 small">
                                    More info
                                  </p>
                                </div>
                                <p className="text-white fw-bold mt-2 mb-0">
                                  20 Minute Gentle Yoga
                                </p>
                                <p className="text-white mb-0">Episode #124</p>
                              </div>
                            </div>
                          </div>
                          <p className="text-white small">
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout.
                          </p>
                          <div className="d-flex align-items-center mb-4">
                            <div className="flex-shrink-0">
                              <div className="img-35 rounded-circle overflow-h me-3">
                                <img
                                  className="img-fluid"
                                  src={chatImg2}
                                  alt="user"
                                />
                              </div>
                            </div>
                            <div className="w-100">
                              <p className="text-secondary small mb-0">
                                Hosted by
                              </p>
                              <p className="text-white mb-0 small">
                                Karne Dillon
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                          <div
                            className="episodeIMg mt-5 mb-3"
                            style={{
                              background: `url(${yogaBanner})`,
                            }}
                          >
                            <div className="position-absolute episodeContent">
                              <div className="p-2">
                                <div className="d-flex align-items-center">
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm py-1 fs-7"
                                  >
                                    Play Now
                                  </button>
                                  <p className="text-white mb-0 ms-2 small">
                                    More info
                                  </p>
                                </div>
                                <p className="text-white fw-bold mt-2 mb-0">
                                  20 Minute Gentle Yoga
                                </p>
                                <p className="text-white mb-0">Episode #124</p>
                              </div>
                            </div>
                          </div>
                          <p className="text-white small">
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout.
                          </p>
                          <div className="d-flex align-items-center mb-4">
                            <div className="flex-shrink-0">
                              <div className="img-35 rounded-circle overflow-h me-3">
                                <img
                                  className="img-fluid"
                                  src={chatImg2}
                                  alt="user"
                                />
                              </div>
                            </div>
                            <div className="w-100">
                              <p className="text-secondary small mb-0">
                                Hosted by
                              </p>
                              <p className="text-white mb-0 small">
                                Karne Dillon
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
      {showInviteFriendsPopUp && <InviteFriendsPopUp />}
    </div>
  );
};
export default ShowDetail;
