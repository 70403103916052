import React, { useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import encrypSvg from "./../images/encryptionImage.svg";
import { Icon } from "@iconify/react";
import { Button } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
// import {
//   abbreviatedSignupModalHandler,
//   login,
// } from "../../../store/slices/user";
import css from "./createAmeeting.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { userService } from "../../../_services";
import { useSnackbar } from "notistack";

const CreateAmeeting = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [meetingLink, setMeetingLink] = useState("");
  // const dispatch = useDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleMeetingLinkChange = (e) => {
    e.preventDefault();
    setMeetingLink(e.target.value);
  };
  const handleJoinWithLink = () => {
    navigate(`/conference/pre-join/${meetingLink}`);
  };

  const handleInstantMeeting = () => {
    let utc_datetime = moment().utc().format();
    userService
      .instantMeeting({ utc_datetime })
      .then((data) => {
        if (data.success) {
          // console.log("Meeting ID : ",  , " meeting initiated ");
          const url = data.data;
          enqueueSnackbar(data.message, {
            variant: "success",
          });
          // console.log(`url: /conference/pre-join/${url}/${token}`);
          navigate(`/conference/pre-join/${url}`);
          // dispatch(login(user));

          // console.log("utcdate :", utcdate)
        } else {
          enqueueSnackbar("This meeting url is Incorrect", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };
  return (
    <div className={`${css.joinMeetingPage}`}>
      <Header />
      <div className="mx-auto flex-row  row  mt-5 text-center text-white ">
        <div
          className={`col-sm-12 col-lg-5 m-auto mt-2 mt-lg-0 ${css.content}`}
        >
          <p className={`${css.heading_left}`}>
            <span className={`${css.spanHeading}`}>Secure Premium </span> video
            conferencing for all
          </p>
          <img src={encrypSvg} className={`${css.imgSvg}`} alt="" />
          <div className={`${css.tagline}`}>
            <span className={`${css.click}`}>Click</span>|
            <span className={`${css.connect}`}>Connect</span>|
            <span className={`${css.collaborate}`}>Collaborate</span>
          </div>
          <div className={`${css.tagline2}`}>
            <p>Feel the human connection even remotely! </p>
          </div>
          <div className={`mx-auto ${css.btnGrp}`}>
            <Button
              className={`${css.newMeetingButton} p-lg-3`}
              onClick={() => navigate(`/schedule-meeting/`)}
            >
              <Icon icon="ic:round-video-call" />
              Create an new Meeting
            </Button>
            <Button
              className={`${css.instantMeetingButton} p-lg-3`}
              onClick={handleInstantMeeting}
            >
              <Icon icon="ic:round-video-call" />
              Create an instant meeting
            </Button>

            <Button
              className={`${css.newMeetingButton} p-lg-3`}
              onClick={() => navigate(`/upcoming-meetings`)}
            >
              <Icon icon="ic:round-video-call" />
              See Your All Meeting
            </Button>
            <div>
              <input
                type="text"
                className="col-8 rounded"
                placeholder="Enter Meeting Code"
                value={meetingLink}
                onChange={handleMeetingLinkChange}
              />
              <Button
                className={`${css.instantMeetingButton} p-lg-3`}
                onClick={handleJoinWithLink}
              >
                <Icon icon="ic:round-video-call" />
                Join Meeting With Link{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CreateAmeeting;
