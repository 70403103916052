/** @format */

import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";

// packages
import classNames from "classnames/bind";
import { Form, Button, Modal } from "react-bootstrap";
import { useSnackbar } from "notistack";

// redux
import {
  signupModalHandler,
  signupStepsHandler,
} from "../../../store/slices/user";

//assets
import CloseIcon from "../../../images/fi-rr-cross.png";
import styles from "./registerOtp.module.css";

// services
import { userService } from "../../../_services";

const cx = classNames.bind(styles);

const RegisterOtp = (props) => {
  console.log(props);
  let userDetails = JSON.parse(localStorage.getItem("userDetail"));
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const timerRef = useRef(null);

  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const [isResendingOtp, setIsResendingOtp] = useState(false);

  const handleOnChange = (e) => {
    if (e.target.value !== "" && e.target.value !== null) {
      setIsVerifyingOtp(true);
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        userService
          .verifyOtp({
            email: props.email,
            otp: e.target.value,
          })
          .then((data) => {
            setIsVerifyingOtp(false);
            if (data.data.otpVerified) {
              // dispatch(
              //   registerPayloadData({
              //     username: location.state.username,
              //     otp: e.target.value,
              //   })
              // );
              // navigate("/register", {
              //   state: {
              //     username: location.state?.username,
              //     photo: location.state?.photo,
              //     email: email,
              //   },
              // });
              dispatch(signupStepsHandler(3));
            } else {
              enqueueSnackbar(data.message, {
                variant: "error",
              });
            }
          })
          .catch((error) => {
            enqueueSnackbar(error, {
              variant: "error",
            });
          });
      }, 3000);
    }
  };

  const handleResendOtp = () => {
    document.getElementById("otp").value = "";
    setIsResendingOtp(true);
    userService
      .resendOtp({
        email: props.email,
      })
      .then((data) => {
        setIsResendingOtp(false);
        if (data.success) {
          enqueueSnackbar(data.message, {
            variant: "success",
          });
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="container">
        <div className="d-flex align-items-center justify-content-center">
          <div
            className={cx(
              "outerBoxRegister",
              "bg-white",
              "px-4",
              "position-relative",
              "py-lg-5 py-md-4 py-sm-0 py-0"
            )}
          >
            <div
              className="position-absolute closeIconRegister cursor-pointer"
              onClick={() => {
                dispatch(signupModalHandler(false));
                dispatch(signupStepsHandler(1));
              }}
            >
              <img className="img-fluid" src={CloseIcon} alt="cross" />
            </div>
            <div className="col-lg-7 col-md-7 col-sm-8 col-12 m-auto text-center">
              <div className="d-flex align-items-center justify-content-center mb-4 landscape-mb-4 mt-5 landscape-mt-5">
                <h2 className="text-center fw-bold">Sign Up</h2>
              </div>
              <p className="text-secondary mb-4 landscape-mb-4">
                Your code was sent to <b className="text-blue">{props.email}</b>
              </p>
              <Form.Group className="mb-3">
                <Form.Control
                  id="otp"
                  className="border border-dark rounded"
                  type="number"
                  placeholder="Enter Code"
                  onChange={handleOnChange}
                />
              </Form.Group>
              <p className="text-secondary">
                This code will expire in 5 minutes.
              </p>
              {isVerifyingOtp && (
                <div className="d-block text-blue mt-4 landscape-mt-4 landscape-mb-4">
                  <span>Verifying...</span>
                </div>
              )}
              {isResendingOtp && (
                <div className="d-block text-blue mt-4 landscape-mt-4 landscape-mb-4">
                  <span>Resending...</span>
                </div>
              )}
              {!isVerifyingOtp && !isResendingOtp && (
                <div
                  className="d-block text-blue mt-4 landscape-mt-4 landscape-mb-4 cursor-pointer"
                  onClick={handleResendOtp}
                >
                  <span>Resend Code</span>
                </div>
              )}
              {!isVerifyingOtp && !isResendingOtp && (
                <Button
                  className="mt-4 mb-5 socialBtn btn-light-grey fw-bold py-2 px-4"
                  variant="muted"
                  size="sm"
                  onClick={() => {
                    dispatch(signupStepsHandler(1));
                  }}
                >
                  Back
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default RegisterOtp;
