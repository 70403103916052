import { useState } from "react";
import React from "react";
import styles from "./dropdownAlertTime.module.css";
import { Icon } from "@iconify/react";

const DropdownAlertTime = ({ alertTime, setAlertTime }) => {
  const [isDropdownAlertTime, setDropdownAlertTime] = useState(false);
  const options = [
    { key: 0, value: "15 mins" },
    { key: 1, value: "30 mins" },
    { key: 2, value: "1 hr" },
  ];

  return (
    <div className={styles.dropdown}>
      <div
        className={styles.dropdown_btn}
        onClick={(e) => setDropdownAlertTime(!isDropdownAlertTime)}
      >
        {alertTime.value}
        <Icon icon="akar-icons:chevron-down" color="#858585" />
      </div>
      {isDropdownAlertTime && (
        <div className={styles.dropdown_content}>
          {options.map((option) => (
            <div
              className={styles.dropdown_item}
              onClick={() => {
                // setType(option);
                setDropdownAlertTime(false);
                setAlertTime(option);
              }}
            >
              {option.value}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownAlertTime;
