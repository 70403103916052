/**
 * /*
 * These are the placeholder roles you can replace these with yours
 *
 * @format
 */

export default {
  //baseUrl: "http://127.0.0.1:8000/api",
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  // baseUrl: "http://localhost:3000/ourprime",
  minimumCharacterLength: 20,
  maximumRemoteTracks: 5,
  xmpp_connection_status: [
    "Error",
    "CONNECTING",
    "CONNFAIL",
    "AUTHENTICATING",
    "AUTHFAIL",
    " CONNECTED",
    " DISCONNECTED",
    "DISCONNECTING",
    "ATTACHED",
    " REDIRECT",
    "CONNTIMEOUT",
    "BINDREQUIRED",
    "ATTACHFAIL",
  ],
  FacebookLink: "https://www.facebook.com/OurPrimeOnlineCommunityCenter/",
  InstagramLink: "https://www.instagram.com/ourprimecommunity/",
  TwitterLink: "https://twitter.com/OurPrimeCenter",
  ShowUrl: process.env.REACT_APP_SHOW_URL,
};
