/*
 * Function to color rects based on the current audio level
 * @params vol -> Current volume
 */
export const colorPids = (vol) => {
  const allPids = [
    ...document.querySelectorAll('[class^="preJoin_pid__"]'),
  ];
  const numberOfPidsToColor = Math.round(vol / 10);
  const pidsToColor = allPids.slice(0, numberOfPidsToColor);
  for (const pid of allPids) {
    pid.style.backgroundColor = "#e6e7e8";
  }
  for (const pid of pidsToColor) {
    // console.log(pid[i]);
    pid.style.backgroundColor = "#69ce2b";
  }
};

/*
 * Takes in an audio stream as input and calls the callback
 * function if the audio level changes.
 */
export const analyseStream = (stream, callback) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(stream);
    const scriptProcessor = audioContext.createScriptProcessor(
      2048,
      1,
      1
    );

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(scriptProcessor);
    scriptProcessor.connect(audioContext.destination);
    scriptProcessor.onaudioprocess = function () {
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      const arraySum = array.reduce((a, value) => a + value, 0);
      const average = arraySum / array.length;
      if (average > 0) {
        callback(average);
      }
    };
}

export const enumerateDevices = () => {
    return new Promise((resolve, reject) => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
         let micInputDevices = [];
         let videoInputDevices = [];
         let audioOutputDevices = [];
         devices.forEach((device) => {
           if ("audioinput" === device.kind) {
             micInputDevices.push(device);
           } else if ("videoinput" === device.kind) {
             videoInputDevices.push(device);
           } else if ("audiooutput" === device.kind) {
             audioOutputDevices.push(device);
           }
         });
         resolve({
             videoInputDevices,
             micInputDevices
         })
       });
    })
 };