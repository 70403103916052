/** @format */

import { createSlice } from "@reduxjs/toolkit";
// Slice
const slice = createSlice({
  name: "connection",
  initialState: {
    status: "",
    showRecordingsUploadAxiosRequests: [],
    showImagesUploadAxiosRequests: [],
  },
  reducers: {
    setConnectionStatus: (state, action) => {
      state.status = action.payload;
    },
    setShowRecordingsUploadAxiosRequests: (state, action) => {
      state.showRecordingsUploadAxiosRequests = [
        ...state.showRecordingsUploadAxiosRequests,
        action.payload,
      ];
    },
    setShowImagesUploadAxiosRequests: (state, action) => {
      state.showImagesUploadAxiosRequests = [
        ...state.showImagesUploadAxiosRequests,
        action.payload,
      ];
    },
  },
});
export default slice.reducer;
// Actions
export const {
  setConnectionStatus,
  setShowRecordingsUploadAxiosRequests,
  setShowImagesUploadAxiosRequests,
} = slice.actions;
