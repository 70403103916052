import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import css from "./signIn.module.css";
import encrypSvg from "./../images/encryptionImage.svg";
import { Icon } from "@iconify/react";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  abbreviatedSignupModalHandler,
  login,
} from "../../../store/slices/user";
import { useDispatch } from "react-redux";

import { userService } from "../../../_services";
import { useSnackbar } from "notistack";
import ResetPassword from "./ResetPassword/ResetPassword";
const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleeEmailChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };
  const handleSubmit = () => {
    let loginData = {
      email: email,
      password: password,
    };
    userService
      .loginHookzApp(loginData)
      .then((data) => {
        if (data.success) {
          enqueueSnackbar(" Successfully Logged in", {
            variant: "success",
          });
          localStorage.setItem("user", JSON.stringify(data.data));
          dispatch(login(data.data));
          navigate(`/upcoming-meetings`);
        } else {
          enqueueSnackbar("Wrong email/password", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <Header />
      <div className="mx-auto flex-row  row  flex-sm-row-reverse mt-5 text-center text-white ">
        <div className={`col-sm-12 col-lg-5 m-auto  mt-lg-0   ${css.content}`}>
          <p className={`${css.headingMeeting}  col-6 mx-auto `}>Sign In</p>
          <div className="text-start mt-3">
            <label className={`col-6 ${css.label} mr-0 `}>Email</label>
            <input
              className={` ${css.darkInput} col-12`}
              type="email"
              value={email}
              onChange={handleeEmailChange}
            />
          </div>
          <div className="text-start mt-3">
            <label className={`col-6 ${css.label}`}>Password</label>
            <input
              className={` ${css.darkInput} col-12`}
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <Button
            className={`${css.newMeetingButton} mt-4 `}
            onClick={handleSubmit}
          >
            Sign In
          </Button>
          <p className={`${css.disclaimer}`}>
            By clicking "Sign In" you agree to our{" "}
            <a href=""> Terms of Services</a> and
            <a href=""> Privacy Policy</a>
          </p>
          <p className={`${css.disclaimer}`}>
            New User? <Link to="/signup"> Create your account Here</Link>
          </p>
          <p className={`${css.disclaimer} `}>
            Forgot Password?{" "}
            <span
              className={`${css.forgetPassword}`}
              onClick={() => setResetPassword(true)}
            >
              {" "}
              Click here{" "}
            </span>
          </p>
        </div>
        <div
          className={`col-sm-12 col-lg-5 m-auto mt-2 mt-lg-0 ${css.content}`}
        >
          <p className={`${css.heading_left}`}>
            <span className={`${css.spanHeading}`}>Secure Premium </span> video
            conferencing for all
          </p>
          <img src={encrypSvg} className={`${css.imgSvg}`} alt="" />
          <div className={`${css.tagline}`}>
            <span className={`${css.click}`}>Click</span>|
            <span className={`${css.connect}`}>Connect</span>|
            <span className={`${css.collaborate}`}>Collaborate</span>
          </div>
          <div className={`${css.tagline2}`}>
            <p>Feel the human connection even remotely! </p>
          </div>
          <div className={`mx-auto ${css.btnGrp}`}>
            <Button
              className={`${css.newMeetingButton} p-lg-3`}
              onClick={() =>
                enqueueSnackbar("Sign In First", {
                  variant: "error",
                })
              }
            >
              <Icon icon="ic:round-video-call" />
              Create an new Meeting
            </Button>
            <Button
              className={`${css.instantMeetingButton} p-lg-3`}
              onClick={() =>
                enqueueSnackbar("Sign In First", {
                  variant: "error",
                })
              }
            >
              <Icon icon="ic:round-video-call" />
              Create an instant meeting
            </Button>
          </div>
        </div>
      </div>
      <Footer />
      <ResetPassword
        backdrop="static"
        show={resetPassword}
        onHide={() => setResetPassword(false)}
      />
    </div>
  );
};

export default SignIn;
