import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import css from "./signup.module.css";
import Footer from "../Footer/Footer";
import { Button } from "react-bootstrap";
import encrypSvg from "./../images/encryptionImage.svg";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { userService } from "../../../_services";
import { useDispatch } from "react-redux";
import {
  abbreviatedSignupModalHandler,
  login,
} from "../../../store/slices/user";
import OtpModal from "./OtpModal/OtpModal";
import { isEmpty, isNull } from "lodash";
import _ from "lodash";
const SignUp = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [otpModal, setOtpModal] = useState(false);
  const dispatch = useDispatch();

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const navigate = useNavigate();

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleSubmit = () => {
    if (!firstName) {
      enqueueSnackbar("first name cannot be empty", {
        variant: "error",
      });
    } else if (!lastName) {
      enqueueSnackbar("last name cannot be empty", {
        variant: "error",
      });
    } else if (!email) {
      enqueueSnackbar("email cannot be empty", {
        variant: "error",
      });
    } else if (!password) {
      enqueueSnackbar("password name cannot be empty", {
        variant: "error",
      });
    } else {
      userService.check({ email }).then((data) => {
        console.log("first", firstName);
        if (!data.data.isRegistered) {
          setOtpModal(true);
        } else {
          enqueueSnackbar("Email already registered", {
            variant: "error",
          });
        }
      });
    }
  };

  return (
    <div>
      {" "}
      <div>
        <Header />
        <div className="mx-auto flex-row  row  flex-sm-row-reverse mt-5 text-center text-white ">
          <div
            className={`col-sm-12 col-lg-5 m-auto  mt-lg-0   ${css.content}`}
          >
            <p className={`${css.headingMeeting}  col-6 mx-auto `}>Sign up</p>
            <div className="col-12 row">
              <div className="text-start mt-1 col-6">
                <label className={`col-3 ${css.label}`}>First Name</label>
                <input
                  className={` ${css.darkInput} col-12`}
                  type="text"
                  onChange={handleFirstNameChange}
                  value={firstName}
                />
              </div>

              <div className="text-start mt-1 col-6">
                <label className={`col-3 ${css.label}`}>Last Name</label>
                <input
                  className={` ${css.darkInput} col-12`}
                  type="text"
                  value={lastName}
                  onChange={handleLastNameChange}
                />
              </div>
            </div>

            <div className="text-start mt-3">
              <label className={`col-6 ${css.label} mr-0 `}>Email</label>
              <input
                className={` ${css.darkInput} col-12`}
                type="email"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <div className="text-start mt-3">
              <label className={`col-3 ${css.label}`}>Password</label>
              <input
                className={` ${css.darkInput} col-12`}
                type="password"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            <Button
              className={`${css.signup} mt-4 `}
              onClick={handleSubmit}
              type="submit"
            >
              Sign up
            </Button>
            <p className={`${css.disclaimer}`}>
              By clicking "Sign up" you agree to our{" "}
              <a href=""> Terms of Services</a> and
              <a href=""> Privacy Policy</a>
            </p>
          </div>
          <div
            className={`col-sm-12 col-lg-5 m-auto mt-2 mt-lg-0 ${css.content}`}
          >
            <p className={`${css.heading_left}`}>
              <span className={`${css.spanHeading}`}>Secure Premium </span>{" "}
              video conferencing for all
            </p>
            <img src={encrypSvg} className={`${css.imgSvg}`} alt="" />
            <div className={`${css.tagline}`}>
              <span className={`${css.click}`}>Click</span>|
              <span className={`${css.connect}`}>Connect</span>|
              <span className={`${css.collaborate}`}>Collaborate</span>
            </div>
            <div className={`${css.tagline2}`}>
              <p>Feel the human connection even remotely! </p>
            </div>
            <div className={`mx-auto ${css.btnGrp}`}>
              <Button
                className={`${css.newMeetingButton} p-lg-3`}
                onClick={() =>
                  enqueueSnackbar("Sign In First", {
                    variant: "error",
                  })
                }
              >
                <Icon icon="ic:round-video-call" />
                Create an new Meeting
              </Button>
              <Button
                className={`${css.instantMeetingButton} p-lg-3`}
                onClick={() =>
                  enqueueSnackbar("Sign In First", {
                    variant: "error",
                  })
                }
              >
                <Icon icon="ic:round-video-call" />
                Create an instant meeting
              </Button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <OtpModal
        backdrop="static"
        show={otpModal}
        onHide={() => setOtpModal(false)}
        loginData={{
          email: email,
          first_name: firstName,
          last_name: lastName,
          password: password,
        }}
      />
    </div>
  );
};

export default SignUp;
