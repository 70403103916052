import React, { useState } from "react";
import styles from "./dropdownType.module.css";
import { Icon } from "@iconify/react";
const DropdownType = ({ type, setType }) => {
  const [isDropdownTypeActive, setDropdownTypeActive] = useState(false);
  const options = [
    { key: 0, value: "Public" },
    { key: 1, value: "Private" },
  ];
  return (
    <div className={styles.dropdown}>
      <div
        className={styles.dropdown_btn}
        onClick={(e) => setDropdownTypeActive(!isDropdownTypeActive)}
      >
        {type ? "Private " : "Public"}{" "}
        <Icon icon="akar-icons:chevron-down" color="#858585" />
      </div>
      {isDropdownTypeActive && (
        <div className={styles.dropdown_content}>
          {options.map((option) => (
            <div
              className={styles.dropdown_item}
              onClick={() => {
                // setType(option.key);
                setDropdownTypeActive(false);
                console.log(option.key);
                setType(option.key);
              }}
            >
              {option.value}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownType;
