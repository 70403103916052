/** @format */

import React from "react";
import classNames from "classnames/bind";
import styles from "./createEpisode.module.css";
import adminStyles from "../admin.module.css";
import Header from "../header/header";
import backArrow from "../images/back_arrow.svg";
import filmIcon from "../images/film.svg";
import closeIcon from "../images/close.svg";
import screenShot from "../images/screenshot.svg";
import trashIcon from "../images/trash.svg";
import hostImg from "../images/larry_tom.svg";
import downImg from "../images/download.jpeg";
import videoImg from "../images/playIcon.svg";
import downloadImg from "../images/downloadIcon.svg";
import trashImg from "../images/trashIcon.svg";

const cx = classNames.bind(styles);
const acx = classNames.bind(adminStyles);

const CreateEpisode = () => {
  return (
    <div>
      <Header />
      <main>
        <div className="container mt-5">
          <div className="d-flex align-items-center mb-3">
            <img className="img-fluid" src={backArrow} alt="arrow" />
            <h1 className="text-white ps-2 display-4 fw-600">
              Create Episode of a Series
            </h1>
          </div>
          <div className="bg-dark-grey p-sm-5 p-4 rounded">
            <div className="col-xl-6 col-lg-8 col-md-11 col-sm-12 col-12 m-auto">
              <h5 className="text-white fw-bold mb-4">Yoga with Suzie</h5>
              <form>
                <div className="mb-4 col-sm-4">
                  <label htmlFor="show_id" className="form-label text-white">
                    ID
                  </label>
                  <input
                    type="text"
                    className="form-control disabled darkPlaceholder"
                    id="show_id"
                    placeholder="#235251"
                    readOnly
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="show_id" className="form-label text-white">
                    Link
                  </label>
                  <input
                    type="text"
                    className="form-control disabled darkPlaceholder"
                    id="show_id"
                    placeholder="www.ourprime.com/s/235251"
                    readOnly
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="title" className="form-label text-white">
                    Sequence
                  </label>
                  <input type="text" className="form-control" id="title" />
                </div>
                <div className="mb-4">
                  <p className="form-label text-white mb-3">Category</p>
                  <div className="d-flex flex-wrap">
                    <div className="form-check d-flex align-items-center mb-2 py-2 px-2 rounded position-relative me-2">
                      <input
                        className="form-check-input customCheck"
                        type="checkbox"
                        name="category"
                        id="memory"
                      />
                      <label
                        className="form-check-label d-flex align-items-center fs-7"
                        htmlFor="memory"
                      >
                        Memory
                        <span className="dotCheck1"></span>
                      </label>
                    </div>
                    <div className="form-check d-flex align-items-center mb-2 py-2 px-2 rounded position-relative me-2">
                      <input
                        className="form-check-input customCheck"
                        type="checkbox"
                        name="category"
                        id="fitness"
                      />
                      <label
                        className="form-check-label d-flex align-items-center fs-7"
                        htmlFor="fitness"
                      >
                        Fitness
                        <span className="dotCheck1"></span>
                      </label>
                    </div>
                    <div className="form-check d-flex align-items-center mb-2 py-2 px-2 rounded position-relative me-2">
                      <input
                        className="form-check-input customCheck"
                        type="checkbox"
                        name="category"
                        id="games"
                      />
                      <label
                        className="form-check-label d-flex align-items-center fs-7"
                        htmlFor="games"
                      >
                        Games
                        <span className="dotCheck1"></span>
                      </label>
                    </div>
                    <div className="form-check d-flex align-items-center mb-2 py-2 px-2 rounded position-relative me-2">
                      <input
                        className="form-check-input customCheck"
                        type="checkbox"
                        name="category"
                        id="arts"
                      />
                      <label
                        className="form-check-label d-flex align-items-center fs-7"
                        htmlFor="arts"
                      >
                        Arts &amp; Crafts
                        <span className="dotCheck1"></span>
                      </label>
                    </div>
                    <div className="form-check d-flex align-items-center mb-2 py-2 px-2 rounded position-relative me-2">
                      <input
                        className="form-check-input customCheck"
                        type="checkbox"
                        name="category"
                        id="health"
                      />
                      <label
                        className="form-check-label d-flex align-items-center fs-7"
                        htmlFor="health"
                      >
                        Health
                        <span className="dotCheck1"></span>
                      </label>
                    </div>
                    <div className="form-check d-flex align-items-center mb-2 py-2 px-2 rounded position-relative me-2">
                      <input
                        className="form-check-input customCheck"
                        type="checkbox"
                        name="category"
                        id="cooking"
                      />
                      <label
                        className="form-check-label d-flex align-items-center fs-7"
                        htmlFor="cooking"
                      >
                        Cooking
                        <span className="dotCheck1"></span>
                      </label>
                    </div>
                    <div className="form-check d-flex align-items-center mb-2 py-2 px-2 rounded position-relative me-2">
                      <input
                        className="form-check-input customCheck"
                        type="checkbox"
                        name="category"
                        id="place"
                      />
                      <label
                        className="form-check-label d-flex align-items-center fs-7"
                        htmlFor="place"
                      >
                        Aging In Place
                        <span className="dotCheck1"></span>
                      </label>
                    </div>
                    <div className="form-check d-flex align-items-center mb-2 py-2 px-2 rounded position-relative me-2">
                      <input
                        className="form-check-input customCheck"
                        type="checkbox"
                        name="category"
                        id="finance"
                      />
                      <label
                        className="form-check-label d-flex align-items-center fs-7"
                        htmlFor="finance"
                      >
                        Finance
                        <span className="dotCheck1"></span>
                      </label>
                    </div>
                    <div className="form-check d-flex align-items-center mb-2 py-2 px-2 rounded position-relative me-2">
                      <input
                        className="form-check-input customCheck"
                        type="checkbox"
                        name="category"
                        id="travel"
                      />
                      <label
                        className="form-check-label d-flex align-items-center fs-7"
                        htmlFor="travel"
                      >
                        Travel
                        <span className="dotCheck1"></span>
                      </label>
                    </div>
                    <div className="form-check d-flex align-items-center mb-2 py-2 px-2 rounded position-relative me-2">
                      <input
                        className="form-check-input customCheck"
                        type="checkbox"
                        name="category"
                        id="history"
                      />
                      <label
                        className="form-check-label d-flex align-items-center fs-7"
                        htmlFor="history"
                      >
                        History
                        <span className="dotCheck1"></span>
                      </label>
                    </div>
                    <div className="form-check d-flex align-items-center mb-2 py-2 px-2 rounded position-relative me-2">
                      <input
                        className="form-check-input customCheck"
                        type="checkbox"
                        name="category"
                        id="lifestyle"
                      />
                      <label
                        className="form-check-label d-flex align-items-center fs-7"
                        htmlFor="lifestyle"
                      >
                        Lifestyle
                        <span className="dotCheck1"></span>
                      </label>
                    </div>
                    <div className="form-check d-flex align-items-center mb-2 py-2 px-2 rounded position-relative me-2">
                      <input
                        className="form-check-input customCheck"
                        type="checkbox"
                        name="category"
                        id="technology"
                      />
                      <label
                        className="form-check-label d-flex align-items-center fs-7"
                        htmlFor="technology"
                      >
                        Technology
                        <span className="dotCheck1"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
                    <div className="d-grid gap-2">
                      <button
                        type="button"
                        className="btn-block btn btn-danger btn-sm"
                      >
                        Upload Trailer
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-12 col-12 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
                    <div className="row">
                      <div className="col-lg-9 col-md-9 col-sm-8 col-8">
                        <div className="bg-disabled rounded py-2">
                          <div className="d-flex px-2 align-items-start">
                            <div className="flex-shrink-0">
                              <img src={filmIcon} alt="film" />
                            </div>
                            <div className="w-100 ps-2 lh-1 d-flex justify-content-between align-items-center">
                              <div>
                                <p className="mb-0 text-white fs-7 text-break">
                                  Trailer-1.mp4
                                </p>
                                <span className="small text-muted fs-7 text-break">
                                  16 MB
                                </span>
                              </div>
                              <a href="#!" className="ps-2">
                                <img src={closeIcon} alt="close" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-lg-start  justify-content-md-start  justify-content-sm-center  justify-content-center">
                        <a href="#!">
                          <img
                            className="img-fluid"
                            src={videoImg}
                            alt="video icon"
                          />
                        </a>
                        <a href="#!">
                          <img
                            className="img-fluid mx-2"
                            src={downloadImg}
                            alt="download icon"
                          />
                        </a>
                        <a href="#!">
                          <img
                            className="img-fluid"
                            src={trashImg}
                            alt="trash icon"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-lg-0 mb-md-0 mb-sm-3 mb-3">
                    <div className="d-grid gap-2">
                      <button
                        type="button"
                        className="btn-block btn btn-light btn-sm text-danger"
                      >
                        Upload Recording
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-12 col-12 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
                    <p className="text-white fs-7">Mark as VOD</p>
                    <div className="row mb-2">
                      <div className="col-lg-9 col-md-9 col-sm-8 col-8">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <div className="form-check">
                              <input
                                className="adminCheck form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              ></label>
                            </div>
                          </div>
                          <div className="d-flex flex-column w-100">
                            <div className="bg-disabled rounded py-2 w-100">
                              <div className="d-flex px-2 align-items-start h-100">
                                <div className="flex-shrink-0">
                                  <img src={filmIcon} alt="film" />
                                </div>
                                <div className="w-100 ps-2 lh-1 d-flex justify-content-between align-items-center">
                                  <div>
                                    <p className="mb-0 text-white fs-7 text-break">
                                      Recording-1.mp4
                                    </p>
                                    <span className="small text-muted fs-7 text-break">
                                      16 MB
                                    </span>
                                  </div>
                                  <a href="#!" className="ps-2">
                                    <img src={closeIcon} alt="close" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-lg-start  justify-content-md-start  justify-content-sm-center  justify-content-center">
                        <a href="#!">
                          <img
                            className="img-fluid"
                            src={videoImg}
                            alt="video icon"
                          />
                        </a>
                        <a href="#!">
                          <img
                            className="img-fluid mx-2"
                            src={downloadImg}
                            alt="download icon"
                          />
                        </a>
                        <a href="#!">
                          <img
                            className="img-fluid"
                            src={trashImg}
                            alt="trash icon"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-9 col-md-9 col-sm-8 col-8">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <div className="form-check">
                              <input
                                className="adminCheck form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              ></label>
                            </div>
                          </div>
                          <div className="d-flex flex-column w-100">
                            <div className="bg-disabled rounded py-2 w-100">
                              <div className="d-flex px-2 align-items-start h-100">
                                <div className="flex-shrink-0">
                                  <img src={filmIcon} alt="film" />
                                </div>
                                <div className="w-100 ps-2 lh-1 d-flex justify-content-between align-items-center">
                                  <div>
                                    <p className="mb-0 text-white fs-7 text-break">
                                      Recording-1.mp4
                                    </p>
                                    <span className="small text-muted fs-7 text-break">
                                      16 MB
                                    </span>
                                  </div>
                                  <a href="#!" className="ps-2">
                                    <img src={closeIcon} alt="close" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-lg-start  justify-content-md-start  justify-content-sm-center  justify-content-center">
                        <a href="#!">
                          <img
                            className="img-fluid"
                            src={videoImg}
                            alt="video icon"
                          />
                        </a>
                        <a href="#!">
                          <img
                            className="img-fluid mx-2"
                            src={downloadImg}
                            alt="download icon"
                          />
                        </a>
                        <a href="#!">
                          <img
                            className="img-fluid"
                            src={trashImg}
                            alt="trash icon"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-4">
                    <div className="mb-3">
                      <label
                        htmlFor="textarea"
                        className="form-label text-white"
                      >
                        Description
                      </label>
                      <textarea className="form-control" rows="3"></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5 col-md-5 col-sm-6 col-12 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                    <button
                      type="button"
                      className="btn-block btn btn-danger btn-sm w-100"
                    >
                      Upload Images
                    </button>
                  </div>
                  <div className="col-sm-7">
                    <div className="row align-items-center mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                      <div className="col-lg-5 col-md-5 col-sm-12 col-12 mb-0 px-lg-0">
                        <div className="d-flex justify-content-lg-end">
                          <div className="form-check me-4">
                            <input
                              className="adminCheck form-check-input"
                              type="checkbox"
                              value=""
                              id="hero"
                            />
                            <label
                              className="form-check-label d-flex flex-column text-white fs-7 lh-1 mt-1"
                              htmlFor="hero"
                            >
                              <span className="fs-8 text-break w-40">Hero</span>
                              <span className="text-secondary fs-9 text-break w-40">
                                16X9
                              </span>
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="adminCheck form-check-input"
                              type="checkbox"
                              value=""
                              id="thumb"
                            />
                            <label
                              className="form-check-label d-flex flex-column text-white fs-7 lh-1 mt-1"
                              htmlFor="thumb"
                            >
                              <span className="fs-8 text-break w-40">
                                Thumb
                              </span>
                              <span className="text-secondary fs-9 text-break w-40">
                                16X9
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                        <div className="bg-dark rounded-5 p-1 mb-1">
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <div className="screenShot">
                                <img src={downImg} alt="img" />
                              </div>
                            </div>
                            <div className="w-100 ms-2">
                              <div className="d-flex justify-content-between">
                                <div className="me-2">
                                  <p className="fs-10 mb-0 text-white text-break">
                                    Screenshot-3456.jpg
                                  </p>
                                  <p className="fs-10 mb-0 text-secondary text-break">
                                    786kb
                                  </p>
                                </div>
                                <div className="cursor-pointer ps-2">
                                  <img src={closeIcon} alt="trash" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label className="text-white mt-4 mb-2">Invite Hosts</label>
                  <div className="d-flex form-inline">
                    <input
                      className="form-control me-sm-2"
                      type="search"
                      placeholder="Enter email or first and last name"
                      aria-label="Search"
                    />
                    <button
                      className="btn btn-danger btn-sm my-0 px-4"
                      type="submit"
                    >
                      Invite
                    </button>
                  </div>
                </div>
                <div className="invitedHost">
                  <p className="text-white mt-4 mb-2">Invited Hosts</p>
                  <div className="border-bottom border-dark pb-2 mb-2">
                    <div className="row">
                      <div className="col-sm-6 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <div className="img-44 rounded-circle">
                              <img
                                className="img-fluid"
                                src={downImg}
                                alt="host"
                              />
                            </div>
                          </div>
                          <div className="w-100">
                            <div className="d-flex align-items-center justify-content-start">
                              <p className="mb-0 text-white mx-3">
                                Larry Tomes
                              </p>
                              <p className="mb-0 text-blue">Show Host</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="d-flex align-items-center justify-content-end h-100">
                          <button
                            type="button"
                            className="btn btn-gray-dark btn-sm text-white me-2 fs-8 py-1"
                          >
                            Primary
                          </button>
                          <button
                            type="button"
                            className="btn btn-light btn-sm text-danger me-4 fs-8 py-1"
                          >
                            Resend Invite
                          </button>
                          <a href="#!" className="ps-2">
                            <img src={closeIcon} alt="close" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-bottom border-dark pb-2 mb-2">
                    <div className="row">
                      <div className="col-sm-6 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <img
                              className="img-44 img-fluid rounded-circle"
                              src={hostImg}
                              alt="host"
                            />
                          </div>
                          <div className="w-100">
                            <div className="d-flex align-items-center justify-content-start">
                              <p className="mb-0 text-white mx-3">
                                Larry Tomes
                              </p>
                              <p className="mb-0 text-blue">Show Host</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="d-flex align-items-center justify-content-end h-100">
                          <button
                            type="button"
                            className="btn btn-danger btn-sm text-white me-2 fs-8 py-1"
                          >
                            Make Primary
                          </button>
                          <button
                            type="button"
                            className="btn btn-light btn-sm text-danger me-4 fs-8 py-1"
                          >
                            Resend Invite
                          </button>
                          <a href="#!" className="ps-2">
                            <img src={closeIcon} alt="close" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-sm-row flex-column mt-sm-5 mt-4">
                  <button
                    type="button"
                    className="btn-block btn btn-danger btn-sm px-lg-5 px-md-5 px-sm-4 px-4  me-sm-2 me-0 mb-sm-0 mb-2"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn-block btn btn-light btn-sm text-danger px-lg-5 px-md-5 px-sm-4 px-4  me-sm-2 me-0 mb-sm-0 mb-2"
                  >
                    Preview
                  </button>
                  <button
                    type="button"
                    className="btn-block btn btn-gray-dark btn-sm text-white px-lg-5 px-md-5 px-sm-4 px-4"
                  >
                    Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
export default CreateEpisode;
