import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Button } from "react-bootstrap";
import encrypSvg from "./../images/encryptionImage.svg";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { userService } from "../../../_services/user.service";
import { useNavigate, Link, useParams } from "react-router-dom";
import css from "./guestLogin.module.css";
import { useDispatch } from "react-redux";
import {
  abbreviatedSignupModalHandler,
  login,
} from "../../../store/slices/user";

const GuestLogin = () => {
  const { meetingId, email } = useParams();
  console.log(" meeting ", meetingId, " emial ", email);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [meetingLink, setMeetingLink] = useState(meetingId);
  const [password, setPassword] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [type, setType] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    userService
      .getMeetingType({ meeting_id: meetingId })
      .then((data) => {
        setType(data.data.type);
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  }, []);

  const handleFirstNameChange = (e) => {
    setfirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setlastName(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleJoin = (e) => {
    let data = {
      email: email,
      meeting_id: meetingId,
      password: password,
    };
    let guest = {
      first_name: firstName,
      last_name: lastName,
    };
    localStorage.setItem("guest", JSON.stringify(guest));
    dispatch(login(guest));
    if (type) {
      userService
        .guestLogin(data)
        .then((data) => {
          if (data.success) {
            enqueueSnackbar("Meeting Joined as guest", {
              variant: "success",
            });
            navigate(`/conference/pre-join/${meetingId}`, {
              state: "guest-join",
            });
          } else {
            enqueueSnackbar(data.message, {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            variant: "error",
          });
        });
    } else {
      navigate(`/conference/pre-join/${meetingId}`);
    }
  };
  return (
    <div>
      <Header />
      <div className="mx-auto flex-row  row  flex-sm-row-reverse mt-5  text-white ">
        <div className={`col-sm-12 col-lg-5 m-auto  mt-lg-0  ${css.content} `}>
          <p className={`${css.headingMeeting} mx-auto `}>Join a Meeting</p>
          <div className="text-start mt-3">
            <label className={`col-6 ${css.label} mr-0 `}>
              Meeting Link / Id
            </label>
            <input
              className={` ${css.darkInput} col-12`}
              type="text"
              value={meetingLink}
              readOnly
            />
          </div>
          <div className="col-12 row">
            <div className="text-start mt-3 col-6">
              <label className={`col-3 ${css.label}`}>First Name</label>
              <input
                className={` ${css.darkInput} col-12`}
                type="text"
                onChange={handleFirstNameChange}
                value={firstName}
              />
            </div>

            <div className="text-start mt-3 col-6">
              <label className={`col-3 ${css.label}`}>Last Name</label>
              <input
                className={` ${css.darkInput} col-12`}
                type="text"
                value={lastName}
                onChange={handleLastNameChange}
              />
            </div>
          </div>
          {type === 1 && (
            <div className="text-start mt-3">
              <label className={`col-6 ${css.label}`}>Password</label>
              <input
                className={` ${css.darkInput} col-12`}
                type="password"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
          )}
          <Button
            className={`${css.newMeetingButton} mt-4 `}
            onClick={handleJoin}
          >
            Join
          </Button>
        </div>
        <div
          className={`col-sm-12 col-lg-5 m-auto mt-2 mt-lg-0 ${css.content} text-center`}
        >
          <p className={`${css.heading_left}`}>
            <span className={`${css.spanHeading}`}>Secure Premium </span> video
            conferencing for all
          </p>
          <img src={encrypSvg} className={`${css.imgSvg}`} alt="" />
          <div className={`${css.tagline}`}>
            <span className={`${css.click}`}>Click</span>|
            <span className={`${css.connect}`}>Connect</span>|
            <span className={`${css.collaborate}`}>Collaborate</span>
          </div>
          <div className={`${css.tagline2}`}>
            <p>Feel the human connection even remotely! </p>
          </div>
          <div className={`mx-auto ${css.btnGrp}`}>
            <Button
              className={`${css.newMeetingButton} p-lg-3`}
              onClick={() =>
                enqueueSnackbar("Sign In First", {
                  variant: "error",
                })
              }
            >
              <Icon icon="ic:round-video-call" />
              Create an new Meeting
            </Button>
            <Button
              className={`${css.instantMeetingButton} p-lg-3`}
              onClick={() =>
                enqueueSnackbar("Sign In First", {
                  variant: "error",
                })
              }
            >
              <Icon icon="ic:round-video-call" />
              Create an instant meeting
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GuestLogin;
