import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Button } from "react-bootstrap";
import encrypSvg from "./../images/encryptionImage.svg";
import css from "./upcomingMeetings.module.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { userService } from "../../../_services/user.service";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import Pagination from "../Pagination/Pagination";

const UpcomingMeetings = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.user);
  const [meetingsArray, setMeetingsArray] = useState([]);
  const [meetingLink, setMeetingLink] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [meetingsArrayCount, setMeetingsArrayCount] = useState(0);
  const navigate = useNavigate();
  const moveToSpecificPage = (page) => setCurrentPage(page);
  const calculatePages = (count) => {
    let pages = count / 3;
    if (count % 3) {
      pages += 1;
    }
    return parseInt(pages);
  };
  const handleMeetingLinkChange = (e) => {
    e.preventDefault();
    setMeetingLink(e.target.value);
  };
  const handleJoinWithLink = () => {
    navigate(`/conference/pre-join/${meetingLink}/${user.jwt}`);
  };
  const formattedDate = (date) => {
    return moment(date).format("ddd,Do MMM YYYY ");
  };

  const formattedTime = (time) => {
    return moment(time).format("hh:mm  A");
  };
  const getAllMeetings = () => {
    userService
      .getAllMeetings(user.jwt, currentPage)
      .then((data) => {
        if (data.success) {
          setMeetingsArray(data.data.rows);
          setTotalPages(calculatePages(data.data.count));
          setMeetingsArrayCount(data.data.count);
          // console.log("Meeting array", meetingsArray);
        } else {
          enqueueSnackbar("Some Server error occured", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };
  useEffect(() => {
    getAllMeetings();
  }, []);
  useEffect(() => {
    getAllMeetings();
  }, [currentPage]);
  const handleInstantMeeting = () => {
    let utc_datetime = moment().utc().format();
    var token = user.jwt;
    userService
      .instantMeeting({ utc_datetime }, token)
      .then((data) => {
        if (data.success) {
          // console.log("Meeting ID : ",  , " meeting initiated ");
          console.log("token : ", token);
          const url = data.data;
          enqueueSnackbar(data.message, {
            variant: "success",
          });
          // console.log(`url: /conference/pre-join/${url}/${token}`);
          navigate(`/conference/pre-join/${url}`);
          // setMeetingAttendies(data.data.attendees);
          // setMeetingInfo(data.data.info);
          // let userData = data.data.loggedData;
          // localStorage.setItem("user", JSON.stringify(userData));
          // dispatch(login(userData));

          // console.log("utcdate :", utcdate)
        } else {
          enqueueSnackbar("This meeting url is Incorrect", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };
  return (
    <div>
      <Header />
      <div className="mx-auto flex-row  row  flex-sm-row-reverse mt-5  text-white ">
        <div className={`col-sm-12 col-md-5 m-auto  mt-lg-0   `}>
          <p className="h4 row">
            <p className={`${css.pageHeading} col-6`}>Upcoming Meetings</p>{" "}
            <p className="col-6">| Join a meeting</p>
          </p>
          <div className={`${css.cardList}`}>
            {meetingsArray.map((meeting) => {
              return (
                <div className={`${css.meetingCard}`}>
                  <div className={`${css.meetingGrid}`}>
                    <p className={`${css.meetingTitle}`}>
                      {meeting.AdminShowSchedule.AdminShow.title.length > 37
                        ? meeting.AdminShowSchedule.AdminShow.title.substring(
                            0,
                            37
                          ) + "..."
                        : meeting.AdminShowSchedule.AdminShow.title}
                    </p>
                    <p className={`${css.meetingInfo}`}>
                      {formattedDate(meeting.AdminShowSchedule.utc_datetime)}|
                      {formattedTime(meeting.AdminShowSchedule.utc_datetime)}
                    </p>
                    <Button
                      className={`${css.meetingJoinButton} `}
                      onClick={() => {
                        {
                          meeting.AdminShowSchedule.AdminShow.type === 0
                            ? navigate(
                                `/conference/pre-join/${meeting.AdminShowSchedule.AdminShow.url}`
                              )
                            : navigate(
                                `/private-meeting/${meeting.AdminShowSchedule.AdminShow.url}`
                              );
                        }
                      }}
                    >
                      Join
                    </Button>
                  </div>
                </div>
              );
            })}

            <div className="container-fluid mb-5">
              <Pagination
                entries={meetingsArray.length}
                totalEntries={meetingsArrayCount}
                moveToPage={moveToSpecificPage}
                currentPage={currentPage}
                pages={totalPages}
              />
            </div>
          </div>
        </div>
        <div
          className={`col-sm-12 col-md-5 m-auto mt-lg-0 ${css.content} text-center`}
        >
          <p className={`${css.heading_left}`}>
            <span className={`${css.spanHeading}`}>Secure Premium </span> video
            conferencing for all
          </p>
          <img src={encrypSvg} className={`${css.imgSvg}`} alt="" />
          <div className={`${css.tagline}`}>
            <span className={`${css.click}`}>Click</span>|
            <span className={`${css.connect}`}>Connect</span>|
            <span className={`${css.collaborate}`}>Collaborate</span>
          </div>
          <div className={`${css.tagline2}`}>
            <p>Feel the human connection even remotely! </p>
          </div>
          <div className={`mx-auto ${css.btnGrp}`}>
            <Button
              className={`${css.newMeetingButton} p-lg-3`}
              onClick={() => navigate("/schedule-meeting")}
            >
              <Icon icon="ic:round-video-call" />
              Create an new Meeting
            </Button>
            <Button
              className={`${css.instantMeetingButton} p-lg-3`}
              onClick={handleInstantMeeting}
            >
              <Icon icon="ic:round-video-call" />
              Create an instant meeting
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UpcomingMeetings;
