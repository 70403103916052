/** @format */

import React, { useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  registerPayloadData,
  signupModalHandler,
  signupStepsHandler,
} from "../../../store/slices/user";
import classNames from "classnames/bind";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "../../../images/cross.svg";
import { Form, Button, Modal } from "react-bootstrap";
import styles from "./registerAboutInfo.module.css";
import { userService } from "../../../_services";
import { useSnackbar } from "notistack";
import Global from "../../../config/Global";
import { isEmpty } from "lodash";
import _ from "lodash";
const cx = classNames.bind(styles);

const RegisterAboutInfo = (props) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { editShowRedirect, attendScheduleShow } = useSelector(
    (state) => state.user
  );
  const [values, setValues] = useState({
    username: "",
    about_info: "",
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const textAreaRef = useRef();

  const [filled, setFilled] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const btnStyle = filled
    ? {
        backgroundColor: "#E50914",
        color: "white",
      }
    : {
        backgroundColor: "#E3E3E3",
        color: "black",
      };

  const pStyle = filled ? { visibility: "hidden" } : { color: "red" };

  const handleChange = (e) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (textAreaRef.current.value.length >= Global.minimumCharacterLength) {
      setFilled(true);
    } else {
      setFilled(false);
    }

    setValues({
      ...values,
      [e.target.name]: e.target.value,
      username: user.username,
    });
  };

  const handleSubmit = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (textAreaRef.current.value === "") {
      return enqueueSnackbar("Please write something about yourself", {
        variant: "error",
      });
    } else if (
      textAreaRef.current.value.length < Global.minimumCharacterLength
    ) {
      return enqueueSnackbar("Please tell us more about yourself", {
        variant: "error",
      });
    }
    setIsFormSubmitting(true);
    userService.registerAboutInfo(values).then((data) => {
      setIsFormSubmitting(false);
      // dispatch(registerPayloadData(values));
      // navigate("/home");
      if (!isEmpty(editShowRedirect)) {
        dispatch(signupStepsHandler(8));
      } else if (!isEmpty(attendScheduleShow)) {
        dispatch(signupStepsHandler(9));
      } else {
        dispatch(signupModalHandler(false));
        dispatch(signupStepsHandler(1));
      }
    });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="container">
        <div className=" d-flex align-items-center justify-content-center">
          <div
            className={cx("outerBoxRegister", "bg-white", "position-relative")}
          >
            <h2 className="fw-bold mt-5 landscape-mt-5">
              Tell the OurPrime Community about yourself
            </h2>
            <div
              className={cx("position-absolute", "closeIconRegister")}
              onClick={() => {
                if (!_.isEmpty(attendScheduleShow)) {
                  dispatch(signupStepsHandler(9));
                } else {
                  dispatch(signupModalHandler(false));
                  dispatch(signupStepsHandler(1));
                }
              }}
            >
              <img className="img-fluid" src={CloseIcon} alt="cross" />
            </div>
            <Form>
              <textarea
                ref={textAreaRef}
                className={cx("w-100", "textarea", "p-3")}
                name="about_info"
                value={values.about_info}
                onChange={handleChange}
                maxLength={500}
              ></textarea>
              <p className="text-secondary" style={pStyle}>
                Please enter 20 or more characters
              </p>
              <div className="d-flex justify-content-center flex-sm-row flex-column">
                <Button
                  className="mt-3 btn-light-grey fw-bold py-2 px-4 me-sm-2 me-0"
                  variant="muted"
                  size="sm"
                  onClick={() => {
                    dispatch(signupStepsHandler(6));
                  }}
                >
                  Back
                </Button>
                <Button
                  className="mt-3 btn fw-bold py-2 px-4 ms-sm-2 ms-0"
                  size="sm"
                  variant="contained"
                  style={btnStyle}
                  onClick={handleSubmit}
                  disabled={!filled || isFormSubmitting}
                >
                  {isFormSubmitting ? `Loading...` : `Complete`}
                </Button>
              </div>
              <div
                className="text-end cursor-pointer skip"
                onClick={() => {
                  if (!isEmpty(editShowRedirect)) {
                    dispatch(signupStepsHandler(8));
                  } else if (!isEmpty(attendScheduleShow)) {
                    dispatch(signupStepsHandler(9));
                  } else {
                    dispatch(signupModalHandler(false));
                    dispatch(signupStepsHandler(1));
                  }
                }}
              >
                Skip
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default RegisterAboutInfo;
