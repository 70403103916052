import { Modal } from "react-bootstrap";
import classNames from "classnames/bind";
import styles from "./enterOtp.module.css";
import { Form, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { userService } from "../../../../../_services";
import { useNavigate } from "react-router-dom";

const cx = classNames.bind(styles);

function EnterOtp(props) {
    const { navigate } = useNavigate()
    const { enqueueSnackbar } = useSnackbar();

    const [values, setValues] = useState({
        otp: "",
        password: "",
    });
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [isFormFilled, setIsFormFilled] = useState(false);

    useEffect(() => {
        if (values.email === "") {
            setIsFormFilled(false);
        } else {
            setIsFormFilled(true);
        }
    }, [values]);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleContinue = (e) => {
        e.preventDefault();

        const dataToPost = {
            email: props.email,
            otp: values.otp,
            password: values.password,
        };
        userService
            .changePasswordHookz(dataToPost)
            .then((data) => {
                if (data.success) {
                    enqueueSnackbar("Password Changed", {
                        variant: "success",
                    });
                    enqueueSnackbar("Login Again", {
                        variant: "success",
                    })
                    props.onHide();
                    props.onHide2();

                } else {
                    enqueueSnackbar(data.message, {
                        variant: "error",
                    });
                }
            })
            .catch((error) => {
                enqueueSnackbar(error, {
                    variant: "error",
                });
            });
    };



    return (<Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <section className="container d-flex align-items-center justify-content-center">
            <div
                className={cx(
                    "outerBox",

                    "p-5 rounded",
                    "position-relative"
                )}
            >
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <h6>Enter Otp and New Password</h6>
                    <div onClick={props.onHide} className="mobileClose inviteCross">
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M26 1.53183L24.4682 0L13 11.4682L1.53183 0L0 1.53183L11.4682 13L0 24.4682L1.53183 26L13 14.5318L24.4682 26L26 24.4682L14.5318 13L26 1.53183Z"
                                fill="rgba(255,255,255, 1)"
                            />
                        </svg>
                    </div>
                </div>
                <Form >
                    <div className={cx("landscapeMode")}>
                        <div className="mb-2">
                            <input
                                type="number"
                                className="form-control customPadding bg-muted "
                                placeholder="Otp"
                                name="otp"
                                value={values.otp}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-2">
                            <input
                                type="password"
                                className="form-control customPadding bg-muted "
                                placeholder="New Password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                            />
                        </div>
                        <Button
                            className={`w-100  ${styles.continueButton}`}
                            type="submit"
                            onClick={handleContinue}
                        >
                            Continue
                        </Button>
                    </div>
                </Form>
            </div>
        </section>

    </Modal>);


}

export default EnterOtp;
