/** @format */

import { useRef, useMemo } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Video } from "./Video";
import { Chat } from "./Chat";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import useWindowDimensions from "../../../../_helpers/useWindowDimensions";
import { useCallback, useEffect, useState } from "react";
import { showService } from "../../../../_services/admin";
import RoomClient from "../../../../lib/RoomClientOld";
import { setConnectionStatus } from "../../../../store/slices/connection";
import { Signaling } from "../../../../Services/socketService";
import { addListener } from "@reduxjs/toolkit";
import { VideoFetcher } from "../VideoFetcher";
import { preCallActions } from "../../../../store/slices/precall";
import { useSwipeable } from "react-swipeable";
import { BottomBar } from "../GridMode/BottomBar";
import { RightBar } from "../GridMode/RightBar";
import LeaveLive from "../GridMode/LeaveLiveModal";
import { ActivePlayerLayout } from "./ActivePlayerLayout";
import classNames from "classnames/bind";
import styles from "../liveVideo.module.css";
import { DebugUI } from "./DebugUI";
import { ActivePlayerMobileLayout } from "./ActivePlayerMobileLayout";
import { BackgroundBlur } from "./backgroundBlur/BackgroundBlur";
import {
  leaveLiveMeetingHandler,
  programEndsModalHandler,
} from "../../../../store/slices/user";
import { ActivePlayerMobileLayout2 } from "./ActivePlayerMobileLayout2";
import ProgramEnds from "../GridMode/ProgramEndModal";

const cx = classNames.bind(styles);

export const LiveVideoNew = ({ setIsReloading, isAdmin, isHost, showId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { height, width } = useWindowDimensions();
  const allAudioDivRef = useRef();
  const [isChatHidden, setIsChatHidden] = useState(true);
  const [isScreenLayoutButtonClicked, setIsScreenLayoutButtonClicked] =
    useState(false);
  const [localVideoEl, setLocalVideoEl] = useState(null);
  const [bgBlurVideo, setBgBlurVideo] = useState(null);
  const [localScreenshareEl, setLocalScreenshareEl] = useState(null);
  const [spotlight, setSpotlight] = useState(null);
  const {
    localVideoInputs,
    localMicInputs,
    localMicStream,
    localVideoStream,
    localSpeakerVideoStream,
    localScreenShareStream,
    remoteVideoStreams,
    remoteScreenShareStream,
  } = useSelector((state) => state.precall);
  const [showFooter, setShowFooter] = useState(true);
  const [active, setActive] = useState("");
  const [allAudioMuted, setAllAudioMuted] = useState(false);

  const [micEnabled, setMicEnabled] = useState(localMicStream ? true : false);
  const [cameraEnabled, setCameraEnabled] = useState(
    localVideoStream ? true : false
  );
  const [screenshareEnabled, setScreenshareEnabled] = useState(
    localScreenShareStream ? true : false
  );

  const [audioStreams, setAudioStreams] = useState([]);

  const [remoteVideos, setRemoteVideos] = useState([]);
  const [remoteScreenshares, setRemoteScreenshares] = useState([]);
  const filmStripVideos = useRef([]);

  const room_id = showId;
  const user = useSelector((state) => state.user.user);
  const { leaveLiveMeetingPopUp, programEndsModal } = useSelector(
    (state) => state.user
  );

  const [screenLayout, setScreenLayout] = useState(1);
  const [debugMode, setDebugMode] = useState(false);
  const [bgBlurEnabled, setBgBlurEnabled] = useState(false);
  const [throttled, setThrottled] = useState(false);

  const getLocalVideoEl = useCallback(() => {
    if (bgBlurEnabled) {
      return bgBlurVideo;
    } else {
      return localVideoEl;
    }
  }, [localVideoEl, bgBlurVideo, bgBlurEnabled]);

  const activeVideo = useMemo(() => {
    let currentlyActiveIds = [];
    if (remoteScreenshares.length !== 0) {
      currentlyActiveIds = [remoteScreenshares[0]];
    } else if (localScreenshareEl) {
      currentlyActiveIds = [
        {
          id: Signaling.getInstance().socket.id,
          htmlElement: localScreenshareEl,
          firstName: user.first_name,
          lastName: "",
          profile_image: user.profile_image,
          enabled: {
            mic: micEnabled,
            camera: cameraEnabled,
          },
          isScreenshare: true,
        },
      ];
    } else if (spotlight && remoteVideos.find(({ id }) => id === spotlight)) {
      currentlyActiveIds = [remoteVideos.find(({ id }) => id === spotlight)];
    } else if (spotlight && Signaling.getInstance().socket.id === spotlight) {
      currentlyActiveIds = [
        {
          id: Signaling.getInstance().socket.id,
          htmlElement: getLocalVideoEl(),
          firstName: user.first_name,
          lastName: (user.last_name || "").substr(0, 1).toUpperCase(),
          profile_image: user.profile_image,
          enabled: {
            mic: micEnabled,
            camera: cameraEnabled,
          },
        },
      ];
    } else if (active && remoteVideos.find(({ id }) => id === active)) {
      currentlyActiveIds = [remoteVideos.find(({ id }) => id === active)];
    } else if (active && active === Signaling.getInstance().socket.id) {
      currentlyActiveIds = [
        {
          id: Signaling.getInstance().socket.id,
          htmlElement: getLocalVideoEl(),
          firstName: user.first_name,
          lastName: (user.last_name || "").substr(0, 1).toUpperCase(),
          enabled: {
            mic: micEnabled,
            camera: cameraEnabled,
          },
          profile_image: user.profile_image,
        },
      ];
    } else {
      currentlyActiveIds = [
        {
          id: Signaling.getInstance().socket.id,
          htmlElement: getLocalVideoEl(),
          firstName: user.first_name,
          lastName: (user.last_name || "").substr(0, 1).toUpperCase(),
          enabled: {
            mic: micEnabled,
            camera: cameraEnabled,
          },
          profile_image: user.profile_image,
        },
      ];
    }
    return currentlyActiveIds;
  }, [
    remoteVideos,
    spotlight,
    localVideoEl,
    bgBlurVideo,
    bgBlurEnabled,
    user,
    micEnabled,
    cameraEnabled,
    remoteScreenshares,
    localScreenshareEl,
    active,
  ]);

  const filmStripVisibleVideos = useMemo(() => {
    const maxVideos = isMobile || isTablet ? 3 : 9;
    if (!filmStripVideos.current) {
      return [];
    }
    // remove users that have left the session from the film strip
    filmStripVideos.current = filmStripVideos.current.filter(
      ({ id }) =>
        remoteVideos.find((remoteVideo) => remoteVideo.id === id) ||
        id === Signaling.getInstance().socket.id
    );

    if (filmStripVideos.current.find(({ id }) => id === activeVideo[0]?.id)) {
    } else {
      const foundActiveVideo = remoteVideos.find(
        ({ id }) => id === activeVideo[0]?.id
      );
      if (foundActiveVideo) {
        filmStripVideos.current.unshift(foundActiveVideo);
      }
    }
    for (let i = 0; i < remoteVideos.length; i++) {
      if (filmStripVideos.current.length >= maxVideos) {
        break;
      }
      const remoteVideo = remoteVideos[i];
      if (!filmStripVideos.current.find(({ id }) => id === remoteVideo.id)) {
        filmStripVideos.current.push(remoteVideo);
      }
    }
    if (filmStripVideos.current.length > maxVideos) {
      filmStripVideos.current = filmStripVideos.current.slice(
        0,
        -1 * (filmStripVideos.current.length - maxVideos)
      );
    }

    // Put in active video at the end if not already there
    const foundActiveVideo = remoteVideos.find(
      ({ id }) => id === activeVideo[0]?.id
    );
    if (
      foundActiveVideo &&
      !filmStripVideos.current.find(({ id }) => id === activeVideo[0]?.id)
    ) {
      filmStripVideos.current = filmStripVideos.current.slice(0, -1);
      filmStripVideos.current.push(foundActiveVideo);
    }

    filmStripVideos.current = filmStripVideos.current.map((v) => {
      const remoteVideo = remoteVideos.find((x) => x.id === v.id);
      if (remoteVideo) {
        return remoteVideo;
      }
      return v;
    });

    return [
      {
        id: Signaling.getInstance().socket.id,
        htmlElement: getLocalVideoEl(),
        firstName: user.first_name,
        lastName: (user.last_name || "").substr(0, 1).toUpperCase(),
        enabled: {
          mic: micEnabled,
          camera: cameraEnabled,
        },
        profile_image: user.profile_image,
      },
      ...filmStripVideos.current,
    ];
  }, [
    isChatHidden,
    localVideoEl,
    bgBlurVideo,
    bgBlurEnabled,
    user,
    remoteVideos,
    micEnabled,
    cameraEnabled,
    activeVideo[0]?.id,
  ]);

  useEffect(() => {
    RoomClient.getInstance()?.changeSpatialLayerBulk(
      filmStripVisibleVideos.map((x) => x.consumer_id),
      isMobile || isTablet ? [] : [activeVideo[0]?.consumer_id],
      activeVideo[0]?.firstName
    );
  }, [filmStripVisibleVideos, activeVideo[0]]);

  const processBgBlur = (bgBlurEnabled, localVideoEl) => {
    if (localVideoEl && bgBlurEnabled) {
      BackgroundBlur.getInstance().stop();
      const track = BackgroundBlur.getInstance().start(
        localVideoEl,
        bgBlurEnabled
      );
      const newLocalVideoEl = document.createElement("video");
      newLocalVideoEl.srcObject = new MediaStream([track]);
      //TODO: @hkirat Clear this video element when bg blur is stopped
      setBgBlurVideo(newLocalVideoEl);
      if (track) RoomClient.getInstance()?.replaceTrack(track);
    } else {
      if (localVideoEl?.srcObject?.getVideoTracks()[0]) {
        RoomClient.getInstance()?.replaceTrack(
          localVideoEl?.srcObject?.getVideoTracks()[0]
        );
      }
      BackgroundBlur.getInstance().stop();
    }
  };

  useEffect(() => {
    processBgBlur(bgBlurEnabled, localVideoEl);
  }, [bgBlurEnabled, localVideoEl]);

  const getAndProduceCamera = useCallback(() => {
    // if (localVideoStream) {
    //   RoomClient.getInstance().produce(
    //     RoomClient.mediaType.video,
    //     localVideoInputs[0].deviceId,
    //     localVideoStream,
    //     showId,
    //     user.id
    //   );
    // } else {
      VideoFetcher.getInstance()
        .getVideoStream()
        .then((stream) => {
          dispatch(preCallActions.setLocalVideoStream(stream));
          RoomClient.getInstance().produce(
            RoomClient.mediaType.video,
            localVideoInputs[0].deviceId,
            stream,
            showId,
            user.id
          );
        })
        .catch((err) => {
          alert("Access denied for video: " + err.message);
          console.error("Access denied for video: ", err);
        });
    // }
  }, [localVideoStream]);

  const getAndProduceScreenshare = useCallback(() => {
    if (localScreenShareStream) {
      setScreenshareEnabled(true);
      dispatch(preCallActions.setLocalScreenShareStream(null));
      RoomClient.getInstance().produce(
        RoomClient.mediaType.screen,
        null,
        localScreenShareStream,
        showId,
        user.id
      );
    } else {
      navigator.mediaDevices
        .getDisplayMedia({
          width: 720,
          height: 480,
          // width: 50,
          // height: 50,
          aspectRatio: 1.777777778,
        })
        .then((stream) => {
          dispatch(preCallActions.setLocalScreenShareStream(stream));
          setScreenshareEnabled(true);
          RoomClient.getInstance().produce(
            RoomClient.mediaType.screen,
            null,
            stream,
            showId,
            user.id
          );
          stream.getVideoTracks()[0].onended = function () {
            stream.getVideoTracks()[0].stop();
            RoomClient.getInstance().closeProducer(RoomClient.mediaType.screen);
            setScreenshareEnabled(false);
            dispatch(preCallActions.setLocalScreenShareStream(null));
          };
        })
        .catch((err) => {
          alert("Access denied for screen share: " + err.message);
          console.error("Access denied for audio/video: ", err);
        });
    }
  }, [localScreenShareStream]);

  const getAndProduceMic = useCallback(() => {
    if (localMicStream) {
      RoomClient.getInstance().produce(
        RoomClient.mediaType.audio,
        localMicInputs[0].deviceId,
        localMicStream,
        showId,
        user.id
      );
    } else {
      VideoFetcher.getInstance()
        .getAudioStream()
        .then((stream) => {
          RoomClient.getInstance().produce(
            RoomClient.mediaType.audio,
            localMicInputs[0].deviceId,
            stream,
            showId,
            user.id
          );
        })
        .catch((err) => {
          alert("Access denied for audio: " + err.message);
          console.error("Access denied for audio: ", err);
        });
    }
  }, [localMicStream]);

  const analyseLocalAudio = useCallback(() => {
    // VideoFetcher.getInstance()
    //     .getAudioStream()
    //     .then((stream) => {
    //     const audioContext = new (window.AudioContext ||
    //         window.webkitAudioContext)();
    //     const analyser = audioContext.createAnalyser();
    //     const microphone = audioContext.createMediaStreamSource(stream);
    //     // const scriptProcessor = audioContext.createScriptProcessor(
    //     //     2048,
    //     //     1,
    //     //     1
    //     // );
    //     analyser.smoothingTimeConstant = 0.5;
    //     analyser.fftSize = 256;
    //     analyser.minDecibels = -70;
    //     analyser.maxDecibels = -10;
    //
    //     microphone.connect(analyser);
    //     // analyser.connect(scriptProcessor);
    //     // scriptProcessor.connect(audioContext.destination);
    //     const array = new Uint8Array(analyser.frequencyBinCount);
    //     window.setInterval(() => {
    //         analyser.getByteFrequencyData(array);
    //         const volume = Math.max(...Array.from(array.values()));
    //         if (RoomClient.getInstance().enabled?.mic) {
    //             if (volume > 20) {
    //                 RoomClient.getInstance().setVolume(volume);
    //             }
    //         } else {
    //             RoomClient.getInstance().setVolume(0);
    //         }
    //
    //     }, 1000)
    //     // scriptProcessor.onaudioprocess = function () {
    //     // };
    // })
    // .catch((err) => {
    //     alert("Access denied for audio: " + err.message);
    //     console.error("Access denied for audio: ", err);
    // });
  }, []);

  const addListeners = useCallback(() => {
    RoomClient.getInstance().on(RoomClient.EVENTS.connected, (peers) => {
      if (cameraEnabled) {
        getAndProduceCamera();
      }
      if (micEnabled) {
        getAndProduceMic();
      }
      if (screenshareEnabled) {
        getAndProduceScreenshare();
      }
      analyseLocalAudio();
      setRemoteVideos((v) => [
        ...v,
        ...peers.map((peer) => ({
          id: peer.id,
          firstName: peer.metadata?.name,
          lastName: "",
          profile_image: peer.metadata?.profile_image,
          enabled: peer.enabled,
          userId: peer.id,
          currentEncoding: peer.currentEncoding,
          htmlElement: document.createElement("video"),
        })),
      ]);
    });
    RoomClient.getInstance().on(RoomClient.EVENTS.setSpotlight, ({ id }) => {
      setSpotlight(id);
    });

    let throttled = null;
    RoomClient.getInstance().on(
      RoomClient.EVENTS.showDebugMessage,
      ({ message, clear }) => {
        if (clear) {
          setThrottled(false);
          if (throttled) {
            window.clearTimeout(throttled);
          }
          throttled = null;
        } else {
          setThrottled(message);
          if (throttled) {
            window.clearTimeout(throttled);
          }
          throttled = window.setTimeout(() => {
            setThrottled(false);
          }, 8 * 1000);
        }
      }
    );

    RoomClient.getInstance().on(RoomClient.EVENTS.reload, () => {
      setIsReloading(true);
    });

    RoomClient.getInstance().on(RoomClient.EVENTS.setActive, ({ id }) => {
      setActive(id);
    });

    RoomClient.getInstance().on(RoomClient.EVENTS.peersConnect, (peers) => {
      setRemoteVideos((v) => [
        ...v,
        ...peers.map((peer) => ({
          id: peer.id,
          firstName: peer.metadata?.name,
          lastName: "",
          profile_image: peer.metadata?.profile_image,
          currentEncoding: peer.currentEncoding,
          enabled: peer.enabled,
          userId: peer.id,
          htmlElement: document.createElement("video"),
        })),
      ]);
    });

    RoomClient.getInstance().on(
      RoomClient.EVENTS.peerUpdate,
      ({ enabled, id, currentEncoding }) => {
        setRemoteVideos((v) =>
          v.map((remoteVideo) => {
            if (remoteVideo.id === id) {
              if (enabled && !enabled.screen) {
                setRemoteScreenshares((remoteScreenshares) =>
                  remoteScreenshares.filter(({ id }) => id !== remoteVideo.id)
                );
              }
              return {
                ...remoteVideo,
                enabled: {
                  ...remoteVideo.enabled,
                  ...(enabled || {}),
                },
                currentEncoding: currentEncoding || remoteVideo.currentEncoding,
              };
            }
            return remoteVideo;
          })
        );
      }
    );

    RoomClient.getInstance().on(
      RoomClient.EVENTS.peerDisconnect,
      ({ user_id }) => {
        setRemoteVideos((v) => {
          return v.filter(({ id }) => id !== user_id);
        });
        setRemoteScreenshares((v) => v.filter(({ id }) => id !== user_id));
      }
    );

    RoomClient.getInstance().on(
      RoomClient.EVENTS.addAudio,
      ({ htmlElement, stream }) => {
        if (allAudioDivRef.current) {
          allAudioDivRef.current.appendChild(htmlElement);
          if (RoomClient.getInstance().everyoneMuted) {
            htmlElement.muted = true;
          }
          setAudioStreams((x) => [...x, stream]);
        } else {
          alert("Audio ref not initialized");
        }
      }
    );

    RoomClient.getInstance().on(RoomClient.EVENTS.addVideo, (props) => {
      setRemoteVideos((v) =>
        v.map((remoteVideo) => {
          if (remoteVideo.id === props.id) {
            return {
              ...remoteVideo,
              htmlElement: props.htmlElement,
              consumer_id: props.consumer_id,
              mediaStream: props.htmlElement.srcObject,
            };
          }
          return remoteVideo;
        })
      );
    });

    RoomClient.getInstance().on(RoomClient.EVENTS.addScreenshare, (props) => {
      setRemoteScreenshares((v) => [...v, props]);
    });
  }, [
    allAudioDivRef,
    setRemoteVideos,
    cameraEnabled,
    micEnabled,
    screenshareEnabled,
  ]);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, []);

  useEffect(() => {
    if (!showId) {
      navigate("/admin/show-list");
    } else if (localVideoInputs.length == 0) {
      navigate("/conference/pre-join");
    } else {
      RoomClient.initialize(
        Signaling.getInstance(),
        room_id,
        {
          name:
            user.first_name +
            " " +
            (user.last_name || "").substr(0, 1).toUpperCase(),
          profile_image: user.profile_image,
        },
        {
          screen: screenshareEnabled,
          camera: cameraEnabled,
          mic: micEnabled,
        },
        navigate
      );
      addListeners();
      return () => {
        RoomClient.getInstance().destroy();
        dispatch(setConnectionStatus("disconnected"));
      };
    }
  }, []);

  useEffect(() => {
    if (localVideoStream) {
      const element = document.createElement("video");
      element.srcObject = localVideoStream;
      element.playsInline = true;
      element.autoplay = true;
      setLocalVideoEl(element);
    }
  }, [localVideoStream]);

  useEffect(() => {
    if (localScreenShareStream) {
      const element = document.createElement("video");
      element.srcObject = localScreenShareStream;
      element.playsInline = true;
      element.autoplay = true;
      setLocalScreenshareEl(element);
    } else {
      setLocalScreenshareEl(null);
    }
  }, [localScreenShareStream]);

  const handleToggleMic = () => {
    if (!micEnabled) {
      getAndProduceMic();
    } else {
      dispatch(preCallActions.setLocalMicStream(null));
      RoomClient.getInstance().closeProducer(RoomClient.mediaType.audio);
    }
    setMicEnabled((micEnabled) => !micEnabled);
  };

  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedLeft: () => setIsChatHidden(false),
    onSwipedRight: () => setIsChatHidden(true),
  });

  if (!user) {
    return <div>User is not logged in</div>;
  }

  const onKeyDown = (e) => {
    // turn debug mode on if d is pressed
    if (e.key === "d" && e.ctrlKey) {
      setDebugMode((x) => !x);
    }
  };

  return (
    <div className="landScapeModeVideo" {...handlers}>
      <LeaveLive
        showid={showId}
        backdrop="static"
        show={leaveLiveMeetingPopUp}
        onHide={() => dispatch(leaveLiveMeetingHandler(false))}
        handleEndMeetingForAllNow={() => {
          RoomClient.getInstance()?.broadcastEvent("session_ended", {
            end_meeting: 1,
          });
          navigate(`/schedule-meeting`);
        }}
      />
      <ProgramEnds
        backdrop="static"
        show={programEndsModal}
        onHide={() => dispatch(programEndsModalHandler(false))}
      />
      <div className={cx(styles.videoBg, "landscapeMainV")}>
        <div className="d-flex h-100">
          <div
            className="w-100 position-relative"
            onMouseEnter={() => setShowFooter(true)}
            onMouseLeave={() => setShowFooter(false)}
            onClick={() =>
              isMobile || isTablet ? setShowFooter(!showFooter) : false
            }
          >
            {screenLayout === 1 && !(isMobile || isTablet) && (
              <ActivePlayerLayout
                debugMode={debugMode}
                isChatHidden={isChatHidden}
                handleToggleMic={handleToggleMic}
                spotlight={spotlight}
                rowVideos={filmStripVisibleVideos}
                activeVideo={activeVideo}
                isAdmin={isAdmin}
                isHost={isHost}
              />
            )}
            {screenLayout === 1 && (isMobile || isTablet) && (
              <ActivePlayerMobileLayout
                debugMode={debugMode}
                isChatHidden={isChatHidden}
                handleToggleMic={handleToggleMic}
                spotlight={spotlight}
                rowVideos={filmStripVisibleVideos}
                activeVideo={activeVideo}
                isAdmin={isAdmin}
                isHost={isHost}
              />
            )}
            <div
              className={cx({
                "d-none": !showFooter,
              })}
            >
              <BottomBar
                setDebugMode={setDebugMode}
                localAudioStreams={[localMicStream]}
                audioStreams={audioStreams}
                allAudioMuted={allAudioMuted}
                setAllAudioMuted={setAllAudioMuted}
                bgBlurEnabled={bgBlurEnabled}
                setBgBlurEnabled={setBgBlurEnabled}
                showId={showId}
                handleToggleMic={handleToggleMic}
                setMicEnabled={setMicEnabled}
                spotlight={spotlight}
                setCameraEnabled={setCameraEnabled}
                setScreenshareEnabled={setScreenshareEnabled}
                setIsScreenLayoutButtonClicked={setIsScreenLayoutButtonClicked}
                isScreenLayoutButtonClicked={isScreenLayoutButtonClicked}
                screenLayout={screenLayout}
                setIsChatHidden={setIsChatHidden}
                isChatHidden={isChatHidden}
                isHost={isHost}
                isAdmin={isAdmin}
                micEnabled={micEnabled}
                cameraEnabled={cameraEnabled}
                screenshareEnabled={screenshareEnabled}
                getAndProduceCamera={getAndProduceCamera}
                getAndProduceMic={getAndProduceMic}
                getAndProduceScreenshare={getAndProduceScreenshare}
              />
            </div>
            {throttled && (
              <div
                style={{
                  left: 20,
                  background: "white",
                  color: "black",
                  bottom: "5vw",
                  outline: "none",
                  position: "fixed",
                  zIndex: 100,
                  fontSize: "1vw",
                  padding: 15,
                }}
              >
                {throttled}
              </div>
            )}
          </div>
          {/* <Chat isChatHidden={isChatHidden} /> */}
        </div>
      </div>
      <RightBar
        setIsScreenLayoutButtonClicked={setIsScreenLayoutButtonClicked}
        isScreenLayoutButtonClicked={isScreenLayoutButtonClicked}
        setScreenLayout={setScreenLayout}
      />
      {debugMode && <DebugUI />}
      <div ref={allAudioDivRef}></div>
    </div>
  );
};
