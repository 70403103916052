/** @format */

import moment from "moment";
import timezone from "moment-timezone";
import _ from "lodash";

export function getItemFromLocalStorage(itemName) {
  let item = JSON.parse(localStorage.getItem(itemName));

  if (typeof item !== undefined) {
    return item;
  } else {
    return undefined;
  }
}

export function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

export function timeDistance(datetime) {
  const scheduledTime = moment(`${datetime}`).utc().valueOf();
  const now = moment().utc().valueOf();
  const distance = scheduledTime - now;
  return distance;
}

export function firstNameLastInitial(first_name, last_name) {
  const lastNameInitial = last_name?.charAt(0);

  return `${first_name} ${lastNameInitial}.`;
}

export function getProfileImageOrFirstAndLastChars(
  profileImage,
  firstName,
  lastName
) {
  if (profileImage) {
    return <img className="img-fluid" src={profileImage} alt="user" />;
  } else {
    return (
      <span className="text-white">
        {firstName && lastName && (
          <>
            <span>
              {firstName?.charAt(0)}
              {lastName?.charAt(0)}
            </span>
          </>
        )}
      </span>
    );
  }
}

export const imageSrc = (adminShowImages) => {
  const hero_image = adminShowImages.find((item) => {
    return item.is_hero === 1;
  });
  if (_.isUndefined(hero_image)) {
    return "src/images/default-image.jpeg";
  }
  return hero_image?.hero_url;
};

export const getFormattedDateTime = (incomingDateTime) => {
  let day = moment(incomingDateTime).format("llll");
  day = day.substring(0, 3);
  let date = moment(incomingDateTime).format("l");
  date = date.substring(0, date.lastIndexOf("/"));
  let time = moment(incomingDateTime).format("LT");
  return `${day} ${date} ${time}`;
};

export const getTimeZoneAbbreviation = () => {
  return moment.tz(moment.tz.guess()).zoneAbbr();
};

export const getTimeZone = () => {
  return moment.tz.guess();
};

export const isLetter = (str) => {
  return str.length === 1 && str.match(/[a-z]/i );
}
