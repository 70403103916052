/** @format */

import styles from "../liveVideo.module.css";
import React, { useState } from "react";
import classNames from "classnames/bind";
import RoomClient from "../../../../lib/RoomClientOld";
import { useDispatch, useSelector } from "react-redux";
import { preCallActions } from "../../../../store/slices/precall";
import { useLocation, useNavigate } from "react-router-dom";
import { MicIcon } from "./MicIcon";
import { VideoIcon } from "./VideoIcon";
import { FullScreenIcon } from "./FullScreenIcon";
import { MicIcon3 } from "./MicIcon3";
import { VideoIcon2 } from "./VideoIcon2";
import { FullScreenIcon2 } from "./FullScreenIcon2";
import { MoreView } from "../liveVideoNew/MoreView";
import { VideoFetcher } from "../VideoFetcher";
import { leaveLiveMeetingHandler, logout } from "../../../../store/slices/user";
import constants, * as CONSTANTS from "../../../../config/constants";
import { isMobile, isTablet } from "react-device-detect";

const cx = classNames.bind(styles);

export const BottomBar = ({
  showId,
  allAudioMuted,
  spotlight,
  setAllAudioMuted,
  handleToggleMic,
  setCameraEnabled,
  setScreenshareEnabled,
  isScreenLayoutButtonClicked,
  setIsScreenLayoutButtonClicked,
  screenLayout,
  isChatHidden,
  setIsChatHidden,
  isHost,
  isAdmin,
  screenshareEnabled,
  showFooter,
  loading,
  cameraEnabled,
  micEnabled,
  getAndProduceCamera,
  getAndProduceMic,
  getAndProduceScreenshare,
  setBgBlurEnabled,
  bgBlurEnabled,
  localAudioStreams,
  audioStreams,
  setDebugMode,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const guest = localStorage.getItem("guest");

  const [isMoreClicked, setIsMoreClicked] = useState(false);
  const [isFullScreen, setIsfullScreen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    localVideoInputs,
    localMicInputs,
    localMicStream,
    localVideoStream,
    localSpeakerVideoStream,
    localScreenShareStream,
    remoteVideoStreams,
    remoteScreenShareStream,
  } = useSelector((state) => state.precall);

  const handleToggleCamera = () => {
    if (!cameraEnabled) {
      getAndProduceCamera();
    } else {
      dispatch(preCallActions.setLocalVideoStream(null));
      VideoFetcher.getInstance().stopVideo();
      RoomClient.getInstance().closeProducer(RoomClient.mediaType.video);
    }
    setCameraEnabled((x) => !x);
  };

  const handleToggleScreenShare = () => {
    if (!screenshareEnabled) {
      getAndProduceScreenshare();
    } else {
      dispatch(preCallActions.setLocalScreenShareStream(null));
      RoomClient.getInstance().closeProducer(RoomClient.mediaType.screen);
      setScreenshareEnabled(false);
    }
  };

  const exitFullScreen = () => {
    const {
      fullscreenElement,
      webkitFullscreenElement,
      mozFullScreenElement,
      mozCancelFullScreen,
      webkitExitFullscreen,
      msExitFullscreen,
    } = document;
    if (fullscreenElement || webkitFullscreenElement || mozFullScreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const handleToggleFullScreen = (isFullScreenParam) => {
    setIsfullScreen(isFullScreenParam);
    const elem = document.documentElement;
    if (isFullScreenParam) {
      setIsChatHidden(true);
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    } else {
      exitFullScreen();
      setIsChatHidden(false);
    }
  };

  const handleToggleChat = (e) => {
    setIsChatHidden(!isChatHidden);
    e.stopPropagation();
  };

  return (
    <div
      className={cx("bottomBar position-absolute py-2 w-100", {
        "d-block": !showFooter || loading,
      })}
    >
      <div className="w-100 px-1 position-relative">
        <div className="d-flex align-items-center justify-content-lg-between justify-content-md-between justify-content-sm-between justify-content-between">
          <div className="d-flex mobileJustifyCon">
            <span
              className={cx(
                "d-flex flex-column px-xl-4 px-lg-2 px-md-2 px-sm-2 px-2 icons_h_f_mobile muteU_V",
                {
                  active: !micEnabled,
                }
              )}
              onClick={handleToggleMic}
            >
              <div className="videoIcon d-flex align-items-center justify-content-center">
                <MicIcon
                  width={24}
                  height={24}
                  enabled={micEnabled}
                  viewbox={"0 0 36 36"}
                />
              </div>
              <p className="text-white mb-0 fw-bold small text-center">
                {!micEnabled ? `Muted` : `Mute`}
              </p>
            </span>
            <span
              className={cx(
                "d-flex flex-column px-xl-4 px-lg-2 px-md-2 px-sm-2 px-2 icons_h_f_mobile",
                {
                  active: !cameraEnabled,
                }
              )}
              onClick={handleToggleCamera}
            >
              <div className="videoIcon d-flex align-items-center justify-content-center">
                <VideoIcon enabled={cameraEnabled} />
              </div>
              <p className="text-white fw-bold mb-0 small">
                {!cameraEnabled ? `Show Video` : `Hide Video`}
              </p>
            </span>
            <span
              className={cx(
                "d-flex flex-column px-xl-4 px-lg-2 px-md-2 px-sm-2 px-2 icons_h_f_mobile d-none"
                // {
                //   active: isScreenLayoutButtonClicked,
                // },
                // {
                //   "d-lg-block d-md-block": isHost || isAdmin,
                // },
                // {
                //   "d-none": !isHost && !isAdmin,
                // }
              )}
              onClick={() => setIsScreenLayoutButtonClicked(true)}
            >
              <div className="videoIcon d-flex align-items-center justify-content-center">
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 37 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 4.5H17V16.5H5V4.5ZM5 19.5H17V31.5H5V19.5ZM20 4.5H32V16.5H20V4.5ZM20 19.5H32V31.5H20V19.5ZM23 7.5V13.5H29V7.5H23ZM23 22.5V28.5H29V22.5H23ZM8 7.5V13.5H14V7.5H8ZM8 22.5V28.5H14V22.5H8Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p className="text-white fw-bold mb-0 small">Layout</p>
            </span>
            {/* <span
              className={cx(
                "d-flex flex-column px-xl-4 px-lg-2 px-md-2 px-sm-2 px-2 icons_h_f_mobile",
                {
                  active: screenshareEnabled,
                },
                {
                  "d-lg-block d-md-block": isHost || isAdmin,
                },
                {
                  "d-none": !isHost && !isAdmin,
                }
              )}
              onClick={handleToggleScreenShare}
            >
              <div className="videoIcon d-flex align-items-center justify-content-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 7.5V24H30V7.5H6ZM3 6.0105C3 5.1765 3.6825 4.5 4.488 4.5H31.512C32.334 4.5 33 5.1735 33 6.0105V27H3V6.0105ZM1.5 28.5H34.5V31.5H1.5V28.5Z"
                    fill="white"
                  />
                  <path
                    d="M17.4375 15.75L13.5 15.75L18.75 10.5L24 15.75L20.0625 15.75L20.0625 21L17.4375 21L17.4375 15.75Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p className="text-white fw-bold mb-0 small">
                {screenshareEnabled ? `Stop Sharing` : `Share Screen`}
              </p>
            </span> */}
            <div
              className="d-lg-none d-md-block d-sm-block d-block align-self-center"
              onClick={() => {
                // localStorage.removeItem("liveMeetingShowId");
                // navigate(`/admin/show-detail/${showId}`);
                dispatch(leaveLiveMeetingHandler(true));
              }}
            >
              <button
                type="button"
                className="btn btn-primary btn-sm px-4 py-1 fw-bold"
              >
                Leave
              </button>
            </div>
            <span
              className={cx(
                "d-flex flex-column px-xl-4 px-lg-2 px-md-2 px-sm-2 px-2 icons_h_f_mobile",
                {
                  active: isFullScreen,
                }
              )}
              onClick={() => handleToggleFullScreen(!isFullScreen)}
            >
              <div className="videoIcon d-flex align-items-center justify-content-center">
                <FullScreenIcon enabled={isFullScreen} />
              </div>
              <p className="text-white fw-bold mb-0 small">
                {isFullScreen ? `Exit Full Screen` : `Full Screen`}
              </p>
            </span>
            {(isMobile || isTablet) && (
              <MoreView
                setDebugMode={setDebugMode}
                localAudioStreams={localAudioStreams}
                audioStreams={audioStreams}
                allAudioMuted={allAudioMuted}
                setAllAudioMuted={setAllAudioMuted}
                bgBlurEnabled={bgBlurEnabled}
                setBgBlurEnabled={setBgBlurEnabled}
                spotlight={spotlight}
                isMoreClicked={isMoreClicked}
                isHost={isHost}
                isAdmin={isAdmin}
                handleToggleMic={handleToggleMic}
                handleToggleCamera={handleToggleCamera}
                cameraEnabled={cameraEnabled}
                micEnabled={micEnabled}
                handleToggleScreenShare={handleToggleScreenShare}
                isFullScreen={isFullScreen}
                setIsMoreClicked={setIsMoreClicked}
                handleToggleFullScreen={handleToggleFullScreen}
              />
            )}
            {/* <div
              className={cx(
                "d-lg-none d-md-block d-sm-block d-block align-self-center d-none"
                // {
                //   "d-lg-block d-md-block": isHost || isAdmin,
                // },
                // {
                //   "d-none": !isHost && !isAdmin,
                // }
              )}
            >
              <label className="toggleSwitch ms-lg-4 ms-md-4 ms-sm-0 ms-0">
                <input
                  type="checkbox"
                  id="chat-checkbox"
                  checked={!isChatHidden}
                  onChange={handleToggleChat}
                />
                <span>
                  <span>{isChatHidden ? `Show Chat` : `Hide Chat`}</span>
                  <span></span>
                </span>
                <a></a>
              </label>
            </div> */}
          </div>
          <div
            className={cx(
              styles.rightButton,
              "d-flex",
              "align-items-center",
              "px-1",
              "d-lg-block d-md-none d-sm-none d-none"
            )}
          >
            {" "}
            {user.role === CONSTANTS.ADMIN_ROLE || user.is_host === 1 ? (
              <button
                type="button"
                className="btn btn-primary btn-sm px-4 py-1 fw-bold"
                onClick={() => {
                  dispatch(leaveLiveMeetingHandler(true));
                }}
              >
                Leave
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary btn-sm px-4 py-1 fw-bold"
                onClick={() => {
                  localStorage.removeItem("liveMeetingShowId");
                  navigate(`/upcoming-meetings`);
                  if (guest) {
                    localStorage.removeItem("guest");
                    window.location.reload();
                  }
                }}
              >
                Leave
              </button>
            )}
            {/* <label className="toggleSwitch ms-lg-4 ms-md-4 ms-sm-0 ms-0 d-none">
              <input
                type="checkbox"
                id="chat-checkbox"
                onChange={handleToggleChat}
              />
              <span>
                <span>{isChatHidden ? `Show Chat` : `Hide Chat`}</span>
                <span></span>
              </span>
              <a></a>
            </label> */}
          </div>
        </div>
      </div>
    </div>
  );
};
