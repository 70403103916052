/** @format */

// import config from 'config';
import { GlobalConfig } from "../config";
import { authHeader } from "../_helpers";

export const userService = {
  check,
  getAllCountries,
  getStatesOfCountry,
  getCitiesOfStates,
  login,
  logout,
  register,
  registerBirthday,
  verifyOtp,
  resendOtp,
  registerLocation,
  registerAvatar,
  registerAboutInfo,
  changePassword,
  geoCode,
  socialLogin,
  getInviteDataViaScheduleId,
  inviteMemberOurPrime,
  getHomePageStrip,
  getEmailPreferences,
  editEmailPreferences,
  // HookzApp
  getLoggedUserDetails,
  fetchContacts,
  scheduleMeeting,
  meetingInfo,
  instantMeeting,
  registerNewUser,
  loginHookzApp,
  getLoginInfo,
  getAllMeetings,
  resetPasswordSendOTP,
  changePasswordHookz,
  joinPrivateMeeting,
  getMeetingType,
  guestLogin,
  // HooksApp
};
function guestLogin(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/hookzconnect/user/guest-login`,
    requestOptions
  ).then(handleResponse);
}
function getMeetingType(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/hookzconnect/user/is-meeting-private-or-public`,
    requestOptions
  ).then(handleResponse);
}
function joinPrivateMeeting(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/hookzconnect/user/private-password-check`,
    requestOptions
  ).then(handleResponse);
}
function changePasswordHookz(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/hookzconnect/user/change-password`,
    requestOptions
  ).then(handleResponse);
}
function resetPasswordSendOTP(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/hookzconnect/user/send-password-reset-otp`,
    requestOptions
  ).then(handleResponse);
}

function getLoggedUserDetails(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(data),
    // body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/getLoggedUserDetails`,
    requestOptions
  ).then(handleResponse);
}
function fetchContacts(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(data),
    // body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/syncContactsOnAppOpenViaCS`,
    requestOptions
  ).then(handleResponse);
}

function scheduleMeeting(data, token) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(token),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/hookzconnect/user/schedule-meeting`,
    requestOptions
  ).then(handleResponse);
}

function meetingInfo(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/hookzconnect/user/get-meeting-info`,
    requestOptions
  ).then(handleResponse);
}

function instantMeeting(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/hookzconnect/user/create-instant-meeting`,
    requestOptions
  ).then(handleResponse);
}

function registerNewUser(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/hookzconnect/user/register`,
    requestOptions
  ).then(handleResponse);
}

function loginHookzApp(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/hookzconnect/user/login`,
    requestOptions
  ).then(handleResponse);
}
function getLoginInfo(token) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(token),
    body: JSON.stringify(),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/hookzconnect/user/get-login-info`,
    requestOptions
  ).then(handleResponse);
}
function getAllMeetings(token, page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(token),
    body: JSON.stringify(),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/hookzconnect/user/scheduled-meetings?page=${page}`,
    requestOptions
  ).then(handleResponse);
}
function verifyOtp(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/hookzconnect/user/verify-otp`,
    requestOptions
  ).then(handleResponse);
}
function check(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/hookzconnect/user/check`,
    requestOptions
  ).then(handleResponse);
}

function getAllCountries() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/get-all-countries`,
    requestOptions
  ).then(handleResponse);
}

function getStatesOfCountry(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/get-states-of-country`,
    requestOptions
  ).then(handleResponse);
}

function getCitiesOfStates(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/get-cities-of-state`,
    requestOptions
  ).then(handleResponse);
}

function login(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${GlobalConfig.baseUrl}/user/login`, requestOptions).then(
    handleResponse
  );
  // .then((user) => {
  //   // store user details and jwt token in local storage to keep user logged in between page refreshes
  //   localStorage.setItem("user", JSON.stringify(user.data));
  //   return user.data;
  // });
}
function socialLogin(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${GlobalConfig.baseUrl}/user/social-login`,
    requestOptions
  ).then(handleResponse);
  // .then((user) => {
  //   // store user details and jwt token in local storage to keep user logged in between page refreshes
  //   localStorage.setItem("user", JSON.stringify(user.data));
  //   return user.data;
  // });
}

function logout() {
  // remove user from local storage to log user out
  //    localStorage.removeItem("user");
  //  localStorage.removeItem("photo");
  let keysToRemove = ["user", "photo", "userDetail"];

  for (const key of keysToRemove) {
    localStorage.removeItem(key);
  }
}

function register(user) {
  console.log(user);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(user),
  };
  return fetch(`${GlobalConfig.baseUrl}/user/register`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      if (user.success === 1) {
        console.log(user);
        localStorage.setItem("user", JSON.stringify(user.data));
        localStorage.removeItem("photo");
        return user.data;
      } else {
        throw "Something went wrong";
      }
    });
}

function registerBirthday(user) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(user),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/register-birthday-gender`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      if (user.success === 1) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        return user.data;
      } else {
        throw "Something went wrong";
      }
    });
}

function resendOtp(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${GlobalConfig.baseUrl}/user/resend-otp`, requestOptions).then(
    handleResponse
  );
}

function registerLocation(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/register-location`,
    requestOptions
  ).then(handleResponse);
}

function registerAvatar(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/register-avatar`,
    requestOptions
  ).then(handleResponse);
}

function registerAboutInfo(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/register-about-info`,
    requestOptions
  ).then(handleResponse);
}

function changePassword(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/change-password`,
    requestOptions
  ).then(handleResponse);
}

function geoCode(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/get-city-state-via-zipcode`,
    requestOptions
  ).then(handleResponse);
}

function getInviteDataViaScheduleId(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/show/get-invite-data-via-schedule_id`,
    requestOptions
  ).then(handleResponse);
}

function inviteMemberOurPrime(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/show/invite-member-ourprime`,
    requestOptions
  ).then(handleResponse);
}

function getHomePageStrip() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/get-homepage-strip`,
    requestOptions
  ).then(handleResponse);
}

function getEmailPreferences(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/show/get-email-preferences`,
    requestOptions
  ).then(handleResponse);
}

function editEmailPreferences(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/show/edit-email-preferences`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // location.reload(true);
      }

      // const error = (data && data.message) || response.statusText;
      const error = data || response.statusText;
      if (error.hasOwnProperty("message")) {
        return Promise.reject(error.message);
      } else {
        let errorArray = Object.values(error.errors);
        return Promise.reject(errorArray[0]); // show first error
      }

      // errorArray.map((val) => {
      //   return Promise.reject(val);
      // });
    }
    return data;
  });
}
