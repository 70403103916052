import React from "react";
import PageNumbers from "./PageNumbers/PageNumbers";
import PaginationPreviousArrow from "./PaginationArrows/PaginationPreviousArrow/PaginationPreviousArrow";
import PaginationNextArrow from "./PaginationArrows/PaginationNextArrow/PaginationNextArrow";

const Pagination = ({
  entries,
  totalEntries,
  moveToPage,
  currentPage,
  pages,
}) => {
  return (
    <div
      className={`d-flex align-items-center position-relative ${
        !totalEntries && "d-none"
      }`}
    >
      <p className="mb-0 small position-absolute">
        Showing entries {entries} of {totalEntries}
      </p>
      <nav
        className="d-flex align-items-center justify-content-center w-100"
        aria-label="Page navigation example"
      >
        <PaginationPreviousArrow
          moveToPreviousPage={moveToPage}
          page={currentPage}
        />
        <PageNumbers
          pages={pages}
          currentPage={currentPage}
          moveToPage={moveToPage}
        />
        <PaginationNextArrow
          moveToNextPage={moveToPage}
          page={currentPage}
          lastPage={pages}
        />
      </nav>
    </div>
  );
};

export default Pagination;
