import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import Header from "../header/header";
import Footer from "./Footer";
import { Navigate, useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) =>
  createStyles({
    a: {
      borderBottom: "1px solid blue",
    },
    MainHead: {
      position: "sticky",
      top: 0,
      zIndex: "10",
      backgroundColor: "#000",
    },
    MainList: {
      marginLeft: "50px",
      marginRight: "50px",
    },
    Container: {
      //   display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      //   border: "2px solid white",
      height: "100%",
      color: "white",
      fontFamily: "Netflix Sans",
    },
    SubContainer: {
      margin: "0px 50px",
    },
    Header: {
      color: "white",
      textDecoration: "underline",
      textAlign: "center",
      marginTop: "50px",
      marginBottom: "10px",
    },
    HeaderBox: {
      marginBottom: "50px",
    },
    subHeading: {
      color: "white",
      justifyContent: "flex-start",
      fontWeight: "200",
      fontFamily: "Netflix Sans",
    },

    website: {
      margin: "0px 5px",
      textDecoration: "underline",
    },
    listHeading: {
      fontWeight: "bold",
      textDecoration: "underline",
      fontStyle: "italic",
    },
  })
);
const TermsConditions = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <>
      <Box className={classes.MainHead}>
        <Header />
      </Box>
      <Box className={classes.Container}>
        <Box className={classes.SubContainer}>
          <Box className={classes.HeaderBox}>
            <Typography variant="h3" className={classes.Header}>
              TERMS OF SERVICE <br />
            </Typography>
            <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
              Last Updated: 15 July 2022
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.subHeading}>
              <span style={{ fontSize: "30px" }}>W</span>elcome to OurPrime!
              OurPrime Inc. (“OurPrime”, “we”, “us” or “our”) builds
              technologies and services that enable mature adults to learn,
              laugh, play, meet, and socialize with each other. These Terms of
              Service (“Terms”) govern your use of the OurPrime platform, which
              you may access through{" "}
              <a
                href="https://www.ourprime.com/home"
                className={classes.website}
                target="_blank"
              >
                www.ourprime.com
              </a>
              , or through a mobile application (collectively, we refer to these
              as our “Services”), which is provided by OurPrime Inc. (“
              (“OurPrime”, “we”, “us” or “our”).
              <br />
              <br />
              <span
                style={{
                  fontStyle: "italic",
                  fontWeight: "bold",
                }}
              >
                BY REGISTERING, ACCESSING OR USING OUR SERVICES, YOU ARE
                AGREEING TO VOLUNTARILY ABIDE BY THESE TERMS, AND THIS AGREEMENT
                TO OUR TERMS CONSTITUTES A LEGALLY BINDING CONTRACT BETWEEN YOU
                AND OURPRIME. IF AT ANY TIME YOU DO NOT AGREE TO THE TERMS,
                AND/OR OUR{" "}
                <a
                  onClick={() => navigate("/PrivacyPolicy")}
                  target="_blank"
                  style={{
                    color: "blue",
                    borderBottom: "1px solid blue",
                    cursor: "pointer",
                  }}
                >
                  PRIVACY NOTICE
                </a>{" "}
                , YOU MAY NOT ACCESS OR USE OUR SERVICES.
              </span>
              <br />
              <br />
              You may terminate your account with OurPrime at any time you can
              do so by closing your profile account and no longer accessing or
              using our Services. OurPrime may revise and update these Terms at
              any time. Please review our Terms periodically for changes. Your
              continued use of the Services after the updated Terms have been
              posted here will mean you accept the revised Terms.
            </Typography>
            <br />
            <br />
          </Box>
        </Box>

        <Box className={classes.MainList}>
          <ol>
            <li>
              <Typography className={classes.listHeading}>
                WHO CAN USE OUR SERVICES
              </Typography>
              <Typography>
                {" "}
                You must be at least 18 years old to register, access or use our
                Services. By creating a profile and using the Service, you also
                represent and warrant that you are not:
              </Typography>
              <br />
              <ul>
                <li>A convicted sex offender.</li>
                <li>
                  Prohibited from using our Services under applicable law.
                </li>
                <li>
                  Previous subjected to a disablement of our Services by
                  OurPrime for violating our Terms.
                </li>
              </ul>
              <br />
            </li>
            <br />
            <br />
            <li>
              <Typography className={classes.listHeading}>
                COMMUNITY GUIDELINES AND USE RESTRICTIONS
              </Typography>

              <Typography>
                We want to keep our Services safe and engaging for all users. As
                such, you agree not to engage in any of the following while
                using our Services:
              </Typography>
              <br />
              <ul>
                <li>
                  <span
                    style={{ textDecoration: "underline", fontWeight: "600" }}
                  >
                    Misrepresent Your Identity
                  </span>
                  <ul>
                    <li>
                      Knowingly provide or submit false or misleading
                      information.
                    </li>
                    <li>
                      Impersonate anyone or falsely state or otherwise
                      misrepresent your affiliation, connection or association
                      with a person or entity.
                    </li>
                    <li>
                      Use another user’s account, share an account with another
                      user, or maintain more than one account.
                    </li>
                    <li>
                      Create another account if we have already terminated your
                      account, unless you have our permission.
                    </li>
                    <li>
                      Imply or state that you are affiliated with or endorsed by
                      OurPrime without our express consent.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <span
                    style={{ textDecoration: "underline", fontWeight: "600" }}
                  >
                    {" "}
                    Technically Interfere with or Monitor OurPrime and its
                    Services
                  </span>

                  <ul>
                    <li>
                      Upload, post, email or otherwise transmit any material
                      that contains software viruses, Trojan horses, worms, time
                      bombs, spiders, cancelbots or any other computer code,
                      files or programs designed to interrupt, destroy or limit
                      the functionality of any computer software or hardware or
                      telecommunications equipment.
                    </li>
                    <li>
                      Use bots or other automated methods to access the
                      Services, add or download contacts, send or redirect
                      messages.
                    </li>
                    <li>
                      Monitor the Services’ availability, performance or
                      functionality for any competitive purpose.
                    </li>
                    <li>
                      Attempt to interfere with or disrupt our servers or
                      networks.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <span
                    style={{ textDecoration: "underline", fontWeight: "600" }}
                  >
                    {" "}
                    Violate OurPrime’s Rights
                  </span>

                  <ul>
                    <li>
                      Rent, lease, loan, trade, sell/re-sell or otherwise
                      monetize the Services
                    </li>
                    <li>
                      Violate the intellectual property rights of OurPrime or
                      third parties, including, without limitation, copying or
                      distributing content, technology and/or materials.
                    </li>
                    <li>Use the Service in order to damage OurPrime.</li>
                    <li>
                      Engage in “framing,” “mirroring,” or otherwise simulating
                      the appearance or function of the Services.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <span
                    style={{ textDecoration: "underline", fontWeight: "600" }}
                  >
                    Violate the Rights of Others
                  </span>

                  <ul>
                    <li>
                      Upload, post, email or otherwise transmit any content that
                      you do not have a right to transmit under any law or under
                      contractual or fiduciary relationships (such as inside
                      information, proprietary and confidential information
                      learned or disclosed as part of employment relationships
                      or under nondisclosure agreements);
                    </li>
                    <li>
                      Post anything that violates or infringes anyone’s rights,
                      including rights of publicity, privacy, copyright,
                      trademark or other intellectual property or contract
                      right.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <span
                    style={{ textDecoration: "underline", fontWeight: "600" }}
                  >
                    Violate the Law
                  </span>

                  <ul>
                    <li>
                      ntentionally or unintentionally violate any applicable
                      local, state, national or international law or any
                      regulations having the force of law, including applicable
                      international, federal, state, provincial or other local
                      laws, rules or regulations relating to personal
                      information, data privacy, information security,
                      personally identifiable information, identity theft, data
                      breach notification, trans-border data flow or data
                      protection.
                    </li>
                    <li>
                      Use the Services for any purpose that is illegal or
                      prohibited by these Terms.
                    </li>
                    <li>
                      Post content that is unlawful, harmful, threatening,
                      abusive, harassing, tortious, defamatory, libelous,
                      slanderous, invasive of another’s privacy, hateful, or
                      racially, ethnically or otherwise objectionable.
                    </li>
                    <li>
                      Stalk or otherwise harass another user of our Services or
                      any of our employees.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <span
                    style={{ textDecoration: "underline", fontWeight: "600" }}
                  >
                    {" "}
                    Post Sexually Explicit Content
                  </span>

                  <ul>
                    <li>
                      Post content that promoting or advocating for commercial
                      sexual services, human trafficking or other non-consensual
                      sexual acts
                    </li>
                    <li>
                      Post nudity or pornographic or sexually explicit content.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <span
                    style={{ textDecoration: "underline", fontWeight: "600" }}
                  >
                    {" "}
                    Post Violent Content
                  </span>

                  <ul>
                    <li>
                      Post violent, graphic, or gory content, or any actions or
                      content that advocate for or threaten violence of any
                      sort, including threatening or promoting terrorism.
                    </li>
                    <li>
                      Post content that advocates for or idolizes suicide or
                      self-harm.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <span
                    style={{ textDecoration: "underline", fontWeight: "600" }}
                  >
                    Post Other Prohibited Content
                  </span>

                  <ul>
                    <li>
                      Post content that promotes, advocates for, or condones
                      racism, bigotry, hatred, or violence against individuals
                      or groups based on factors like (but not limited to) race,
                      ethnicity, religious affiliation, disability, gender, age,
                      national origin, sexual orientation, or gender identity.
                    </li>
                    <li>
                      Post anything that is hate speech, threatening or incites
                      violence.
                    </li>
                    <li>
                      Post photos of unaccompanied minors. If you want to post a
                      photograph of you and your children, you must be in the
                      photograph and be the children’s legal guardian with
                      authority to authorize the posting of their image to our
                      Services.
                    </li>
                    <li>
                      Post content that is otherwise, and in OurPrime’s sole
                      discretion, vulgar, obscene, or objectionable.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <span
                    style={{ textDecoration: "underline", fontWeight: "600" }}
                  >
                    {" "}
                    Defraud Other Users
                  </span>

                  <ul>
                    <li>
                      Attempt to get other users’ private information for
                      fraudulent or illegal activity, including sharing
                      financial account information for the purpose of
                      transferring money between users.
                    </li>
                    <li>Spam, solicit money from or defraud any members.</li>
                  </ul>
                </li>
                <br />
                <li>
                  <span
                    style={{ textDecoration: "underline", fontWeight: "600" }}
                  >
                    {" "}
                    Use our Services for Advertising
                  </span>

                  <ul>
                    <li>
                      Use OurPrime to drive people to external websites through
                      hyperlinks or otherwise.
                    </li>
                    <li>
                      Use your profile to promote your own events or businesses,
                      non-profit, political campaign, contest, or to conduct
                      research.
                    </li>
                    <li>
                      Upload, post, email or otherwise transmit any unsolicited
                      or unauthorized advertising, promotional materials, "junk
                      mail," "spam," "chain letters," "pyramid schemes," or any
                      other form of solicitation or content for any purposes not
                      authorized by these Terms.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <span
                    style={{ textDecoration: "underline", fontWeight: "600" }}
                  >
                    {" "}
                    Bully or Harass Others
                  </span>

                  <ul>
                    <li>
                      Bully, abuse, stalk, intimidate, assault, harass,
                      mistreat, defame, or threaten, or encourage others to
                      engage in such against another person. This includes
                      sending any unsolicited sexual content to your matches. We
                      take reports of stalking, threats, bullying, or
                      intimidation, very seriously.
                    </li>
                    <li>
                      Use the Services for any harmful or nefarious purpose.
                    </li>
                  </ul>
                  <br />
                  <Typography>
                    If we believe you are misusing our Services or violate these
                    Terms in any way, we may, in our sole discretion and without
                    limiting other remedies, limit, suspend, or terminate your
                    use and access to our Services.
                  </Typography>
                </li>
              </ul>
            </li>
            <br />
            <br />
            <li>
              <Typography className={classes.listHeading}>PAYMENT</Typography>

              <Typography>
                OurPrime does not collect credit card or bank account
                information from its users. Some features of our Services
                require you to pay a fee. You agree to pay these fees and any
                other charges incurred in connection with your username and
                password (including any applicable taxes) at the rates in effect
                when the charges were incurred. We process all charges
                automatically through a third-party payment processor. All fees
                and charges are nonrefundable. We may change the fees and
                charges then in effect, or add new fees or charges, by giving
                you notice in advance. All fees and charges are nonrefundable,
                except in instances where we are unable or unwilling to provide
                the requested services.
              </Typography>
              <br />
            </li>
            <br />
            <br />
            <li>
              <Typography className={classes.listHeading}>
                OURPRIME’S INTELLECTUAL PROPERTY RIGHTS
              </Typography>

              <Typography>
                The contents of our Services are protected by copyright,
                trademark and other intellectual property laws and, except as
                otherwise expressly set out in these Terms, may not be used,
                copied, reproduced, sold, published, distributed, displayed,
                retransmitted or modified, without the express prior permission
                of OurPrime.
                <br />
                <br />
                “OurPrime™” and other graphics, logos, designs, page headers,
                button icons, scripts, and service names available on the
                Services are the trademarks of OurPrime. These trademarks may
                not be used, including as part of trademarks and/or as part of
                domain names, keyword advertisements, or email addresses, in any
                manner that is likely to cause confusion.
              </Typography>
            </li>
            <br />
            <br />
            <li>
              <Typography className={classes.listHeading}>
                {" "}
                YOUR CONTENT
              </Typography>

              <Typography>
                You may be able to upload content to the Service (“User
                Generated Content”). You alone are responsible for ensuring User
                Generated Content you submit to the Services complies with these
                Terms. For example, the User Generated Content you submit may
                not include third-party intellectual property unless you have
                that party’s permission or are otherwise legally entitled to do
                so. If any of User Generated Content you submit violates these
                Terms s, we reserve the right to remove or take down some or all
                of such User Generated Content in our discretion.
                <br />
                <br />
                We require you to grant certain rights in the User Generated
                Content you submit to OurPrime. By submitting User Generated
                Content to the Services, you grant to OurPrime a perpetual,
                worldwide, non-exclusive, royalty-free, sublicensable and
                transferable license to use that User Generated Content
                (including to reproduce, distribute, prepare derivative works,
                display and perform it) in connection with the Services and
                OurPrime’s business, including for promoting or redistributing
                part or all of the Services. You grant to OurPrime the right to
                monetize the User Generated Content you submit on the Service
                without entitling you to any payments.
                <br />
                <br />
                You hereby release and waive all claims against OurPrime with
                respect to any intellectual property or other proprietary
                rights, rights of privacy and publicity, rights of attribution,
                or any other liability under the applicable laws.
              </Typography>
            </li>
            <br />
            <br />
            <li>
              <Typography className={classes.listHeading}>
                VIDEO AND AUDIO RECORDINGS RELEASE
              </Typography>

              <Typography>
                Certain activities on our Services, including, for example,
                classes, may be recorded. By using our Services you consent and
                grant to the OurPrime the following rights:
              </Typography>
              <br />
              <ul>
                <li>
                  To take, obtain or make photographs, audio, video and/or
                  digital recordings that may contain your voice, image, and/or
                  likeness during your participation in activies conducted
                  through the Services (the “Recordings”); and
                </li>
                <li>
                  To use, reproduce, display, modify, distribute, publish,
                  create derivative works from, and otherwise use, in whole or
                  in part, the Recordings for use in any and all media now or
                  hereafter known, provided such use is consistent with the
                  purpose of and marketing and promoting the Services.
                </li>
              </ul>
              <br />
              <Typography>
                In granting this consent, you further understand and agree that:
                (a) the Recordings may or may not contain attributions
                specifically identifying you; (b) OurPrime shall have the right
                to modify, edit, reproduce, display, or otherwise disseminate
                the Recordings, in whole or in part; (c) OurPrime may crop, edit
                and otherwise alter the Recordings and combine them with other
                text, images, graphic elements, and/or other materials; (d) with
                respect to the Recordings and any derivative works thereof,
                OurPrime solely has the right to copyright and to exploit such
                copyrighted material embodied in or represented by the
                Recordings; (e) you have no right of approval and no right to
                inspect or preview the Recordings and any derivative works
                thereof, or any use to which they may be put; and (f) you have
                no claim, now or in the future, to any compensation, attribution
                or other similar right relating to or arising out of the
                Recordings or their use as described in this Agreement.
                <br />
                <br />
                To the fullest extent permitted by applicable law, you hereby
                irrevocably waive, discharge, and release OurPrime and its
                officers, directors, employees, agents, representatives,
                successors and assigns, and those acting under their authority
                from any and all legal and equitable claims I might have in
                connection with the use, display, dissemination or exploitation
                of any Recordings, including, but not limited to, any claims for
                defamation, invasion of privacy, right of publicity, rights in
                my name, image, voice or likeness, and/or violation of any moral
                or artist rights.
              </Typography>
            </li>
            <br />
            <br />

            <li>
              <Typography className={classes.listHeading}>
                COPYRIGHT INFRINGEMENT CLAIMS
              </Typography>

              <Typography>
                Although OurPrime reserves the right to review and remove any
                User Generated Content posted to its Services that violates
                these Terms, such content is the sole responsibility of the user
                who posts it, and OurPrime cannot guarantee that all User
                Generated Content will comply with its Terms.
                <br />
                <br />
                If you believe that your work has been copied and posted on the
                Services in a way that constitutes copyright infringement,
                please provide our copyright agent with the following
                information:
              </Typography>
              <br />
              <ul>
                <li>
                  An electronic or physical signature of the person authorized
                  to act on behalf of the owner of the copyright interest.
                </li>
                <li>
                  A description specifying the location on our Services of the
                  material that you claim is infringing.
                </li>
                <li>
                  A description of the copyrighted work that you claim has been
                  infringed.
                </li>
                <li>Your contact information</li>
                <li>
                  A statement by you, of your good faith belief that the
                  material is infringing and its use is not authorized
                </li>
                <li>
                  A statement by you, made under the penalty of perjury, that
                  the information you provided is accurate and that you are the
                  copyright owner or are authorized to act on the copyright
                  owner’s behalf.
                </li>
              </ul>
              <br />
              <Typography>
                Notice of claims of copyright infringement should be sent to:{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    (window.location = "mailto:privacy@ourprime.com")
                  }
                >
                  privacy@ourprime.com.
                </span>
              </Typography>
              <br />
              <Typography style={{ textDecoration: "underline" }}>
                OurPrime will terminate the accounts of repeat infringers.
              </Typography>
            </li>
            <br />
            <br />
            <li>
              <Typography className={classes.listHeading}>
                {" "}
                SERVICE INTERRUPTIONS
              </Typography>
              <Typography>
                You acknowledge and accept that OurPrime does not guarantee
                continuous, uninterrupted, or secure access to our Services and
                operation or use of our Services may be interfered with or
                adversely affected by numerous factors or circumstances outside
                of our control. OurPrime shall not be liable should for any
                modifications, interruptions, outages, downtimes or disruptions
                to our Services. OurPrime reserves the right to modify or
                discontinue our Services with or without notice.
              </Typography>
            </li>
            <br />
            <br />
            <li>
              <Typography className={classes.listHeading}>
                {" "}
                HEALTH AND SAFETY WARNING
              </Typography>
              <Typography>
                CERTAIN PORTIONS OF OUR SERVICES, INCLUDING USER GENERATED
                CONTENT, MAY OFFER HEALTH AND FITNESS INFORMATION. SUCH
                INFORMATION IS DESIGNED FOR EDUCATIONAL AND ENTERTAINMENT
                PURPOSES ONLY. YOU SHOULD CONSULT YOUR HEALTHCARE PROVIDER
                BEFORE BEGINNING A NEW FITNESS PROGRAM. INFORMATION AVAILABLE
                THROUGH OUR SERVICES IS NOT A SUBSTITUTE FOR, NOR DOES IT
                REPLACE, PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT.
                IF YOU HAVE ANY CONCERNS OR QUESTIONS ABOUT YOUR HEALTH, YOU
                SHOULD ALWAYS CONSULT WITH A HEALTHCARE PROVIDER. DO NOT
                DISREGARD, AVOID OR DELAY OBTAINING MEDICAL OR HEALTH RELATED
                ADVICE FROM YOUR HEALTHCARE PROVIDER BECAUSE OF INFORMATION YOU
                ACCESSED THROUGH OUR SERVICES. THE USE OF INFORMATION PROVIDED
                THROUGH OUR SERVICES IS SOLELY AT YOUR OWN RISK AND IS NOT
                MEDICAL OR HEALTHCARE ADVICE.
                <br />
                <br />
                NOTHING AVAILABLE THROUGH OUR SERVICES IS INTENDED TO BE, AND
                MUST NOT BE TAKEN TO BE, THE PRACTICE OF MEDICAL OR COUNSELING
                CARE INCLUDING, WITHOUT LIMITATION, PSYCHIATRY, PSYCHOLOGY,
                PSYCHOTHERAPY, OR PROVIDING HEALTH CARE TREATMENT, INSTRUCTIONS,
                DIAGNOSIS, PROGNOSIS OR ADVICE. TO THE FULL EXTENT PERMITTED BY
                LAW, OURPRIME MAKES NO WARRANTY OF ANY KIND, IMPLIED OR EXPRESS,
                AS TO ITS ACCURACY, COMPLETENESS OR APPROPRIATENESS FOR ANY
                PURPOSE.
              </Typography>
            </li>
            <br />
            <br />
            <li>
              <Typography className={classes.listHeading}>
                {" "}
                DISCLAIMER OF GENERAL WARRANTIES
              </Typography>
              <Typography>
                YOU USE THE SERVICES AT YOUR OWN RISK. WE EXPRESSLY DISCLAIM ALL
                REPRESENTATIONS AND WARRANTIES ABOUT THE ACCURACY, COMPLETENESS,
                TIMELINESS OR EFFICACY THE SERVICES AND ASSUME NO LIABILITY OR
                RESPONSIBILITY TO YOU FOR ANY ERRORS, MISTAKES, OR INACCURACIES
                IN THE SERVICES PROVIDED BY US. YOU AGREE THAT YOUR ACCESS TO,
                AND USE OF, THE SERVICES IS ON AN “AS-IS”, “AS AVAILABLE” BASIS
                AND WE SPECIFICALLY DISCLAIM ANY REPRESENTATIONS OR WARRANTIES,
                EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY
                REPRESENTATIONS OR WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE AND NON-INFRINGEMENT. WE DO NOT WARRANT OR
                MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE
                USE OF THE SERVICESOR ANY CONTENT AVAILABLE THROUGH THE
                SERVICES. IF ANY APPLICABLE AUTHORITY HOLDS ANY PORTION OF THIS
                SECTION TO BE UNENFORCEABLE, THEN OUR LIABILITY AND
                RESPONSIBILITY WILL BE LIMITED TO THE EXTENT PERMITTED BY
                APPLICABLE LAW.
              </Typography>
            </li>
            <br />
            <br />
            <li>
              <Typography className={classes.listHeading}>
                {" "}
                LIMITATION OF LIABILITY
              </Typography>
              <Typography>
                YOU ACKNOWLEDGE AND AGREE THAT WE SHALL NOT BE LIABLE TO YOU FOR
                ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
                EXEMPLARY OR PUNITIVE DAMAGES, OR ANY OTHER DAMAGES WHATSOEVER,
                INCLUDING BUT NOT LIMITED TO, DAMAGES FOR PHYSICAL INJURY, LOSS
                OF LIFE, LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
                INTANGIBLE LOSSES (EVEN IF OURPRIME HAS BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF, OR RESULTING FROM,
                (A) THE USE OR THE INABILITY TO USE THE SERVICES; (B) THE USE OF
                ANY CONTENT OR OTHER MATERIAL ON OR THROUGH THE SERVICES OR ANY
                WEBSITES LINKED TO THE SERVICES, INCLUDING USER GENERATED
                CONTENT; (C) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND
                SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES
                PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS
                ENTERED INTO THROUGH OR FROM THE SERVICES; (D) UNAUTHORIZED
                ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (E)
                STATEMENTS, SERVICES OR CONDUCT OF ANY THIRD-PARTY ON OR THROUGH
                THE SERVICES; OR (F) ANY OTHER MATTER RELATING TO THE SERVICES.
                IN NO EVENT SHALL OURPRIME’S TOTAL LIABILITY TO YOU OR ANY
                INDIVIDUAL OR ENTITY FOR WHOM YOU ARE RESPONSIBLE FOR ANY AND
                ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT,
                TORT– INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE – OR OTHERWISE)
                EXCEED THE AMOUNT PAID BY YOU TO OURPRIME IN THE IMMEDIATELY
                PRECEDING TWELVE (12) MONTH PERIOD. IF ANY PORTION OF THIS
                LIMITATION OF LIABILITY IS FOUND TO BE INVALID, OURPRIME’S
                LIABILITY SHALL BE LIMITED TO THE FULL EXTENT PERMITTED BY
                APPLICABLE LAW.{" "}
              </Typography>
            </li>
            <br />
            <br />
            <li>
              {" "}
              <Typography className={classes.listHeading}>
                {" "}
                LIMITATION ON LEGAL ACTION
              </Typography>
              <Typography>
                YOU AND OURPRIME AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF
                OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR
                AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF
                ACTION IS PERMANENTLY BARRED.
              </Typography>
            </li>
            <br />
            <br />
            <li>
              {" "}
              <Typography className={classes.listHeading}>
                {" "}
                YOUR INDEMNITY TO OURPRIME
              </Typography>
              <Typography>
                You agree, to the extent permitted under applicable law, to
                indemnify, defend and hold harmless OurPrime, together with its
                officers, directors, agents, and employees from and against any
                and all liabilities, penalties, claims, causes of action,
                demands, damages, losses, costs, and expenses, including
                attorney’s fees, due to, arising out of, or relating in any way
                to your use and access to or use of the Services or your
                violation of these Terms. This indemnification section shall
                survive your termination of or cessation of use of the Services.
              </Typography>
            </li>
            <br />
            <br />
            <li>
              {" "}
              <Typography className={classes.listHeading}>
                {" "}
                GOVERNING LAW & VENUE
              </Typography>
              <Typography>
                All claims arising out of or relating to these Terms or the
                Services will be governed by Massachusetts law, except
                Massachusetts’ conflict of laws rules, and will be litigated
                exclusively in the federal or state courts of Suffolk County,
                Massachusetts, USA. You and OurPrime consent to personal
                jurisdiction in those courts.
              </Typography>
            </li>
            <br />
            <br />
            <li>
              {" "}
              <Typography className={classes.listHeading}>
                {" "}
                MISCELLANEOUS
              </Typography>
              <Typography>
                If any provision of these Terms is deemed unlawful, void or
                unenforceable for any reason, the other provisions (and any
                partially-enforceable provision) shall not be affected and shall
                remain valid and enforceable to the fullest extent under law.
                You agree that these Terms and any other agreements referenced
                herein may be assigned by OurPrime, in our sole discretion, to a
                third party in the event of a merger or acquisition. These Terms
                shall apply in addition to, and shall not be superseded by, any
                other written agreement between us in relation to your access
                and use of the Services.
              </Typography>
            </li>
          </ol>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default TermsConditions;
