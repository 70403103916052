/** @format */

import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./passwordForm.module.css";
import { Form, InputGroup, FormControl, Button } from "react-bootstrap";
import Global from "../../../config/Global";
import { useSnackbar } from "notistack";
import EyeIcon from "../../../images/eye_password.svg";
import EyeLine from "../../../images/EyeLine.svg";
import { userService } from "../../../_services";
import _ from "lodash";
import {
  loginModalHandler,
  resetPasswordModalHandler,
} from "../../../store/slices/user";
import { useDispatch } from "react-redux";

const cx = classNames.bind(styles);

const ResetPassword = ({ email }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  //Refs
  const passwordRef = useRef();
  const otpRef = useRef();
  //States
  const [filled, setFilled] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [Newpassword, setNewPassword] = useState("");
  const [otp, setOtp] = useState();
  const baseUrl = Global.baseUrl;
  //Functions

  const handleTogglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);

  const handleSubmit = (e) => {
    e.preventDefault();
    userService
      .changePassword({
        email: email,
        password: password,
        otp: parseInt(otp),
      })
      .then((data) => {
        console.log(data);
        if (data.success === 0 && !_.isEmpty(data.message)) {
          return enqueueSnackbar(data.message, {
            variant: "error",
          });
        } else if (data.success === 1) {
          enqueueSnackbar(data.message, {
            variant: "success",
          });
          dispatch(resetPasswordModalHandler(false));
          dispatch(loginModalHandler(true));
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const changeButtonColor = () => {
    if (otpRef.current.value !== "" && passwordRef.current.value !== "") {
      setFilled(true);
    } else {
      setFilled(false);
    }
  };

  const btnStyle = filled
    ? {
        backgroundColor: "#E50914",
        color: "white",
      }
    : {
        backgroundColor: "#E3E3E3",
        color: "black",
      };

  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup
        size="lg"
        className="border border-dark rounded mb-3 landscape-mb-3"
      >
        <FormControl
          id="otp"
          placeholder="Enter 6 digit code we emailed you"
          aria-label="enter 6 digit code"
          aria-describedby="enter 6 digit code"
          className="border-white"
          type="number"
          name="otp"
          ref={otpRef}
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          onKeyUp={changeButtonColor}
          autoComplete="off"
          required
        />
        <InputGroup.Text
          id="enter 6 digit code"
          className="fs-6 bg-white border-white"
        ></InputGroup.Text>
      </InputGroup>

      <InputGroup
        size="lg"
        className="border border-dark rounded mb-3 landscape-mb-3"
      >
        <FormControl
          id="pass"
          placeholder="New Password"
          aria-label="Password"
          aria-describedby="Password"
          className="border-white"
          name="password"
          type={isPasswordVisible ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          ref={passwordRef}
          onKeyUp={changeButtonColor}
          autocomplete="new-password"
          required
        />
        <InputGroup.Text
          id="Password"
          className="fs-6 bg-white border-white"
          onClick={handleTogglePasswordVisibility}
        >
          <img src={isPasswordVisible ? EyeLine : EyeIcon} />
        </InputGroup.Text>
      </InputGroup>
      <Button
        className="w-100 btn btn-danger btn-sm fw-bold"
        variant="contained"
        size="lg"
        type="submit"
      >
        Reset Password
      </Button>
    </Form>
  );
};
export default ResetPassword;
