/** @format */

import React, { useEffect, useState, useCallback, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import user, {
  registerPayloadData,
  signupModalHandler,
  signupStepsHandler,
} from "../../../store/slices/user";
import classNames from "classnames/bind";
import styles from "./registerLocation.module.css";
import CloseIcon from "../../../images/cross.svg";
import { Form, Button, InputGroup, Modal } from "react-bootstrap";
// import countryStateCityData from "./countries+states+cities.json";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015

import States from "./us_states.json";
import Cities from "./us_cities_states.json";
import Zipcodes from "./us_cities.json";

import { userService } from "../../../_services";
import Global from "../../../config/Global";

import { useSnackbar } from "notistack";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../../../App.css";
import _ from "lodash";
const cx = classNames.bind(styles);

const RegisterLocation = (props) => {
  // const location = useLocation();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, attendScheduleShow } = useSelector((state) => state.user);
  const countryRef = useRef();

  const zipcodeRef = useRef();

  const { registerPayload } = useSelector((state) => state.user);

  const username = JSON.parse(localStorage.getItem("user"))?.username;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [values, setValues] = useState({
    username: "",
    country: "USA",
    city: "",
    state: "",
    zipcode: "",
  });

  // const [isZipValid, setIsZipValid] = useState(false);

  const [filled, setFilled] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const changeButtonColor = () => {
    if (
      countryRef.current.value !== "" &&
      // stateRef.current.value !== "" &&
      // cityRef.current.value !== "" &&
      zipcodeRef.current.value !== ""
    ) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  };

  useEffect(() => {
    // userService
    //   .getAllCountries()
    //   .then((data) => {
    //     setCountries(data.data.countries);
    //   })
    //   .catch((error) => {
    //     alert(error);
    //   });
    setStates(States);
  }, []);

  // const handleCountryChange = (e) => {
  //   changeButtonColor();
  //   const id = e.target.value;
  //   userService
  //     .getStatesOfCountry({
  //       country_id: id,
  //     })
  //     .then((data) => {
  //       setStates(data.data.states);
  //       setValues({
  //         ...values,
  //         [e.target.name]: e.target.value,
  //         state: "-",
  //       });
  //     })
  //     .catch((error) => {
  //       alert(error);
  //     });
  // };

  const handleStateChange = (e) => {
    changeButtonColor();
    const tempArray = [];
    const state = e.target.value;
    setValues({
      ...values,
      [e.target.name]: state,
      city: "-",
    });
    Cities.map((data) => {
      if (data.state === state) {
        tempArray.push(data.city);
      }
    });
    setCities(tempArray);

    // userService
    //   .getCitiesOfStates({
    //     state_id: id,
    //   })
    //   .then((data) => {
    //     setCities(data.data.cities);
    //     setValues({
    //       ...values,
    //       [e.target.name]: e.target.value,
    //       city: "-",
    //     });
    //   })
    //   .catch((error) => {
    //     alert(error);
    //   });
  };

  // const verifyZip = async () => {
  //   let validZip = false;
  //   Zipcodes.map((data) => {
  //     let stringZipcode = data.zip_code.toString();
  //     if (stringZipcode === values.zipcode) {
  //       if (data.city === values.city) {
  //         validZip = true;
  //       }
  //     }
  //   });
  //   return validZip;
  // };

  const handleChange = (event) => {
    changeButtonColor();
    if (event.target.name === "zipcode") {
      if (event.target.value.length >= 5) {
        setValues({
          ...values,
          [event.target.name]: event.target.value,
        });
        let zipcode = event.target.value;
        userService
          .geoCode({
            zipcode,
          })
          .then((data) => {
            if (data.data.status === "OK") {
              const addresses = data.data.results[0].address_components;
              let country = addresses.find((item) => {
                return item.types[0] === "country";
              });
              country = country.long_name;
              let state = addresses.find((item) => {
                return item.types[0] === "administrative_area_level_1";
              });
              state = state.long_name;
              let city = addresses.find((item) => {
                return item.types[0] === "locality";
              });
              if (_.isUndefined(city)) {
                city = addresses.find((item) => {
                  return item.types[0] === "administrative_area_level_2";
                });
              }
              city = city.long_name;
              setValues({
                ...values,
                state,
                city,
                zipcode,
              });
            } else {
              setValues({
                ...values,
                state: "",
                city: "",
                zipcode,
              });
            }
          });
      } else {
        setValues({
          ...values,
          state: "",
          city: "",
          [event.target.name]: event.target.value,
        });
      }
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubmit = async () => {
    if (values.zipcode === "" || values.city === "") {
      return enqueueSnackbar("All fields are required", {
        variant: "error",
      });
    }
    setIsFormSubmitting(true);

    // const zipValid = await verifyZip();
    // if (!zipValid) {
    //   return enqueueSnackbar("Invalid Zipcode", {
    //     variant: "error",
    //   });
    // }

    const updatedValues = {
      ...values,
      username: user.username,
    };

    userService
      .registerLocation(updatedValues)
      .then((data) => {
        setIsFormSubmitting(false);
        if (!data.success) {
          return enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
        dispatch(registerPayloadData(updatedValues));
        // if (registerPayload?.profile_image) {
        //   navigate("/register-about-info");
        // } else {
        //   navigate("/register-avatar");
        // }
        dispatch(signupStepsHandler(6));
      })
      .catch((error) => {
        setIsFormSubmitting(false);
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const btnStyle = filled
    ? {
        backgroundColor: "#E50914",
        color: "white",
      }
    : {
        backgroundColor: "#E3E3E3",
        color: "black",
      };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="container">
        <div className=" d-flex align-items-center justify-content-center">
          <div
            className={cx(
              "outerBoxRegister",
              "bg-white",
              "p-5",
              "position-relative"
            )}
          >
            <h2 className="text-center fw-bold">Location</h2>
            <div
              className={cx("position-absolute", "closeIconRegister")}
              onClick={() => {
                if (!_.isEmpty(attendScheduleShow)) {
                  dispatch(signupStepsHandler(9));
                } else {
                  dispatch(signupModalHandler(false));
                  dispatch(signupStepsHandler(1));
                }
              }}
            >
              <img className="img-fluid" src={CloseIcon} alt="cross" />
            </div>
            <Form>
              <div className="row">
                <div className="col-sm-10 m-auto">
                  <h1 className="small fw-bold mt-5 landscape-mt-5 pt-sm-0 pt-4 landscape-pt-4">
                    Location
                  </h1>
                  <div className="row gx-3">
                    <div className="col-sm-6">
                      <Form.Control
                        size="lg"
                        className="border border-dark rounded mb-3"
                        defaultValue="USA"
                        name="country"
                        disabled
                        ref={countryRef}
                      />
                    </div>
                    <div className="col-sm-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          className="border border-dark rounded"
                          type="text"
                          placeholder="Zipcode"
                          name="zipcode"
                          value={values.zipcode}
                          onChange={handleChange}
                          ref={zipcodeRef}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-sm-12">
                      {!_.isEmpty(values.state) && !_.isEmpty(values.city) && (
                        <>
                          {values.city}, {values.state}
                        </>
                      )}
                    </div>
                    {/* <div className="col-sm-6"> */}
                    {/* <Form.Select
                      size="lg"
                      className="border border-dark rounded mb-3"
                      defaultValue={values.state}
                      name="state"
                      onChange={handleStateChange}
                      ref={stateRef}
                    >
                      <option value="-">Select state</option>
                      {states.map((option) => (
                        <option key={option.abbreviation} value={option.name}>
                          {option.name}
                        </option>
                      ))}
                    </Form.Select> */}
                    {/* <Form.Control
                      size="lg"
                      placeholder="State"
                      className="border border-dark rounded mb-3"
                      name="state"
                      value={values.state}
                      onChange={() => {}}
                      ref={stateRef}
                    /> */}
                    {/* </div> */}
                    {/* <div className="col-sm-6">
                    <Form.Control
                      size="lg"
                      placeholder="City"
                      className="border border-dark rounded mb-3"
                      name="city"
                      value={values.city}
                      onChange={() => {}}
                      ref={cityRef}
                    />
                  </div> */}
                    <div className="d-flex justify-content-center">
                      <Button
                        className="mt-3 mb-5 landscape-mb-0 socialBtn btn-light-grey fw-bold py-2 px-4 me-2"
                        variant="muted"
                        size="sm"
                        onClick={() => {
                          dispatch(signupStepsHandler(4));
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        className="mt-3 mb-5 landscape-mb-0  socialBtn btn fw-bold py-2 px-4 ms-2"
                        size="sm"
                        variant="contained"
                        style={btnStyle}
                        onClick={handleSubmit}
                        disabled={!filled || isFormSubmitting}
                      >
                        {isFormSubmitting ? `Loading...` : `Next`}
                      </Button>
                    </div>
                    <div
                      className="text-end cursor-pointer skip"
                      onClick={() => {
                        dispatch(signupStepsHandler(6));
                      }}
                    >
                      Skip
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default RegisterLocation;
