/** @format */

import React, { useEffect, useCallback, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames/bind";
import _ from "lodash";

import styles from "./createShow.module.css";
import adminStyles from "../admin.module.css";
import Header from "../header/header";
import backArrow from "../images/back_arrow.svg";
import filmIcon from "../images/film.svg";
import closeIcon from "../images/close.svg";
import hostImg from "../images/larry_tom.svg";
import playIcon from "../images/playIcon.svg";
import downloadIcon from "../images/downloadIcon.svg";
import { useSnackbar } from "notistack";
// import "@pathofdev/react-tag-input/build/index.css";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import "./createShowExtra.module.css";
import OutsideClickHandler from "react-outside-click-handler";

import "../../../App.css";
// services
import { showService, commonService } from "../../../_services/admin";
import { userService } from "../../../_services";

import constants, * as CONSTANTS from "../../../config/constants";
import { getProfileImageOrFirstAndLastChars } from "../../../_helpers";

const cx = classNames.bind(styles);
const acx = classNames.bind(adminStyles);

const regex = {
  email: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
};

const CreateShow = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const timerRef = useRef(null);
  const tagRef = useRef(null);
  const tagInputTimerRef = useRef(null);
  const newReactTagsRef = useRef(null);
  const isMounted = useRef(false);

  const { showRecordingsUploadAxiosRequests, showImagesUploadAxiosRequests } =
    useSelector((state) => state.connection);

  const { user } = useSelector((state) => state.user);

  let tagInputChange;
  let cachedSearchedHostsArray = [];

  const [values, setValues] = useState({
    show_id: "",
    title: "",
    url: "",
    category_ids: null,
    trailer_url: "",
    vod_video_ids: [],
    description: "",
    preparation: "",
    hero_image_id: null,
    thumb_image_id: null,
    primary_host_id: null,
  });

  // upload trailer
  const [uploadedTrailer, setUploadedTrailer] = useState({});
  const [isUploadingTrailer, setisUploadingTrailer] = useState(false);
  // upload recordings
  const [uploadedRecordings, setUploadedRecordings] = useState([]);
  const [isUploadingRecordings, setIsUploadingRecordings] = useState(false);
  // upload images
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isUploadingImages, setIsUploadingImages] = useState(false);
  const [searchedHosts, setSearchedHosts] = useState([]);
  const [items, setItems] = useState([]);
  const [cachedSearchedHosts, setCachedSearchedHosts] = useState([]);
  const [invitedHosts, setInvitedHosts] = useState([]);
  const [existingHostIds, setExistingHostIds] = useState([]);
  const [saveOnBlur, setSaveOnBlur] = useState(true);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [invalidTags, setInvalidTags] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [newSuggestions, setNewSuggestions] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [existingCategories, setExistingCategories] = useState([]);
  // const [clickedUploadRecordingInEdit, setClickedUploadRecordingInEdit] =
  //   useState(false);
  // const [clickedUploadImagesInEdit, setClickedUploadImagesInEdit] =
  //   useState(false);
  const [clickedInviteHostsInEdit, setClickedInviteHostsInEdit] =
    useState(false);
  const [saveIncomplete, setSaveIncomplete] = useState(false);
  const [saveIncompleteError, setSaveIncompleteError] = useState(false);
  const [showPreparationField, setShowPreparationField] = useState(false);
  const [showHasEpisodes, setShowHasEpisodes] = useState(false);
  const [hasEpisodesShowId, setHasEpisodesShowId] = useState("");
  const [episodesData, setEpisodesData] = useState([]);
  const [getShows, setGetShows] = useState([]);
  const [masterShow, setMasterShow] = useState();

  const debounced = (delay, fn) => {
    return function (...args) {
      if (tagInputTimerRef.current) {
        clearTimeout(tagInputTimerRef.current);
      }
      tagInputTimerRef.current = setTimeout(() => {
        fn(...args);
        tagInputTimerRef.current = null;
      }, delay);
    };
  };

  const addTagInputEventListner = (existingHostIds) => {
    const tagInput = document?.getElementById("create-show-tag-input");
    const tagInputHandler = (e) => {
      if (e.target.value !== "") {
        if (cachedSearchedHostsArray.length === 0) {
          showService
            .searchHosts({
              existingHostIds,
              search: e.target.value.toLowerCase(),
            })
            .then((data) => {
              if (data.data?.hosts?.length > 0) {
                cachedSearchedHostsArray = data.data.hosts;
                setCachedSearchedHosts(cachedSearchedHostsArray);
                setItems(cachedSearchedHostsArray);
                // setSearchedHosts(data.data.hosts);
              } else {
                setCachedSearchedHosts([]);
                // setSearchedHosts([]);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setCachedSearchedHosts(() => {
            let searchedHosts = cachedSearchedHostsArray.filter((val) => {
              if (val.full_name.match(new RegExp(e.target.value, "gi"))) {
                return val;
              }
            });
            return searchedHosts;
          });
        }
      } else {
        cachedSearchedHostsArray = [];
        setCachedSearchedHosts([]);
        // setSearchedHosts([]);
      }
    };
    // tagInputChange = tagInput.addEventListener(
    //   "keyup",
    //   debounced(1000, tagInputHandler)
    // );
  };

  useEffect(() => {
    if (!params.id) {
      user.role !== CONSTANTS.ADMIN_ROLE && navigate("/admin/show-list");
    }
    commonService
      .getAllCategories()
      .then((data) => {
        setCategories(data.data.categories);
      })
      .catch((error) => {
        console.log(error);
      });
    if (!!params.id) {
      showService
        .get(params.id)
        .then((data) => {
          if (!data.success) {
            return navigate("/admin/create-show");
          }
          if (user.role !== CONSTANTS.ADMIN_ROLE) {
            const isHost = data.data?.AdminShowHosts?.find((host) => {
              return host.host_id === user.id && !host.is_attendee;
            });
            !isHost && navigate("/admin/show-list");
          }
          setValues((values) => {
            addTagInputEventListner(existingHostIds);
            return {
              ...values,
              show_id: params.id,
              title: data.data.title,
              url: data.data.url,
              category_ids:
                data.data.AdminShowCategories.length > 0
                  ? data.data.AdminShowCategories
                  : null,
              description: data.data.description,
              preparation: data.data.preparation,
            };
          });
          if (data.data.preparation.length) {
            setShowPreparationField(true);
          }
          setExistingCategories(data.data.AdminShowCategories);
          // set trailer data
          setUploadedTrailer(() => {
            let trailerMedia = data.data.AdminMedia.find((val) => {
              return val.type === 1;
            });
            return {
              id: trailerMedia?.id,
              title: trailerMedia?.title,
              size: trailerMedia?.size,
              url: trailerMedia?.url,
            };
          });
          setValues((values) => {
            let trailerMedia = data.data.AdminMedia.find((val) => {
              return val.type === 1;
            });
            return {
              ...values,
              trailer_url: trailerMedia?.url,
            };
          });
          // set uploaded recordings data
          setUploadedRecordings(() => {
            let recordingMedias = data.data.AdminMedia.filter((val) => {
              return val.type === 2;
            });
            return recordingMedias;
          });
          // set uploaded images data
          setUploadedImages(data.data.AdminShowImages);
          // set existing hosts
          setInvitedHosts(data.data.AdminShowHosts);
        })
        .catch((error) => {
          console.log(error);
        });

      getEpisodes();
      // getMasterShow();
    } else {
      showService
        .add({
          title: "-",
        })
        .then((data) => {
          setSaveOnBlur(false);
          setValues((values) => {
            addTagInputEventListner(existingHostIds);
            return {
              ...values,
              show_id: data.data.id,
              url: `${window.location.host}/s/${data.data.id}`,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    showService
      .searchHosts({
        existingHostIds,
        search: "",
      })
      .then((data) => {
        if (data.data?.hosts?.length > 0) {
          setNewSuggestions((newSuggestions) => {
            let array = data.data.hosts.map((host) => {
              return {
                id: host.email,
                name: `${host.first_name} ${host.last_name}`,
                profile_image: host.profile_image,
              };
            });
            return [...newSuggestions, ...array];
          });
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      document.removeEventListener("change", tagInputChange);
    };
  }, []);

  useEffect(() => {
    // if (categories?.length > 0) {
    //   if (!params.id) {
    //     // let firstCategoryCheckbox = document.querySelectorAll(
    //     //   'input[id^="category_"]'
    //     // );
    //     // if (firstCategoryCheckbox.length > 0) {
    //     //   firstCategoryCheckbox[0].checked = true;
    //     //   setValues((values) => {
    //     //     return {
    //     //       ...values,
    //     //       category_ids: [firstCategoryCheckbox[0].getAttribute("data-id")],
    //     //     };
    //     //   });
    //     // }
    //   }
    // }
    if (categories?.length > 0 && existingCategories?.length > 0) {
      for (var i = 0; i < existingCategories.length; i++) {
        document.getElementById(
          `category_${existingCategories[i]}`
        ).checked = true;
      }
    }
  }, [existingCategories, categories]);

  useEffect(() => {
    if (uploadedRecordings.length > 0) {
      if (!!params.id) {
        let vodRecording = uploadedRecordings.find((val) => {
          return val?.is_vod === 1;
        });
        if (!!vodRecording) {
          document.getElementById(`vod_${vodRecording?.id}`).checked = true;
        }
        setValues((values) => {
          return {
            ...values,
            vod_video_ids: vodRecording?.id ? [vodRecording.id] : [],
          };
        });
      }
      // uploadedRecordings.map((ele) => {
      //   if (ele.isPlayed) {
      //     document.getElementById(`recording-video-container_${ele.id}`).play();
      //   }
      // });
    }
  }, [uploadedRecordings]);

  useEffect(() => {
    if (uploadedImages.length > 0) {
      if (uploadedImages.length == 1) {
        let firstHeroCheckbox = document.querySelectorAll('input[id^="hero_"]');
        if (firstHeroCheckbox.length > 0) {
          firstHeroCheckbox[0].checked = true;
        }
        let firstThumbCheckbox = document.querySelectorAll(
          'input[id^="thumb_"]'
        );
        if (firstThumbCheckbox.length > 0) {
          firstThumbCheckbox[0].checked = true;
        }
        setValues((values) => {
          return {
            ...values,
            hero_image_id: uploadedImages[0].id,
            thumb_image_id: uploadedImages[0].id,
          };
        });
      } else {
        if (!!params.id) {
          let heroImage = uploadedImages.find((val) => {
            return val?.is_hero === 1;
          });
          let thumbImage = uploadedImages.find((val) => {
            return val?.is_thumb === 1;
          });
          if (!!heroImage) {
            document.getElementById(`hero_${heroImage?.id}`).checked = true;
          }
          if (!!thumbImage) {
            document.getElementById(`thumb_${thumbImage?.id}`).checked = true;
          }
          setValues((values) => {
            return {
              ...values,
              hero_image_id: heroImage?.id || null,
              thumb_image_id: thumbImage?.id || null,
            };
          });
        }
      }
    }
  }, [uploadedImages]);

  useEffect(() => {
    if (invitedHosts.length > 0 && !clickedInviteHostsInEdit) {
      let primaryHost = invitedHosts?.find((val) => {
        return val?.is_primary === 1;
      });
      if (!!primaryHost) {
        document.getElementById(
          `make-primary-btn_${primaryHost?.host_id}`
        ).checked = true;
      }
      setValues((values) => {
        return {
          ...values,
          primary_host_id: primaryHost?.host_id || null,
        };
      });
    }
    setExistingHostIds(() => {
      let invitedHostIds = invitedHosts.map((val) => {
        return val.id;
      });
      return invitedHostIds;
    });
  }, [invitedHosts]);

  useEffect(() => {
    if (existingHostIds.length > 0) {
      document.removeEventListener("change", tagInputChange);
      addTagInputEventListner(existingHostIds);
    }
  }, [existingHostIds]);

  useEffect(() => {
    if (cachedSearchedHosts.length > 0) {
      setSearchedHosts(cachedSearchedHosts);
    } else {
      setSearchedHosts([]);
    }
  }, [cachedSearchedHosts]);

  const handleTitleChange = (e) => {
    setValues((values) => {
      return {
        ...values,
        title: e.target.value,
      };
    });
    if (saveOnBlur) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        showService
          .add({
            title: e.target.value,
          })
          .then((data) => {
            setSaveOnBlur(false);
            setValues((values) => {
              return {
                ...values,
                title: e.target.value,
                show_id: data.data.id,
                url: `${window.location.host}/s/${data.data.id}`,
              };
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }, 3000);
    }
  };

  const handleCategoryChange = (e) => {
    let id = parseInt(e.target.getAttribute("data-id"));
    let resultArray = [];
    if (e.target.checked) {
      //if checked (true), then add this id into checkedList
      if (!_.isNull(values.category_ids)) {
        resultArray = values.category_ids.filter(
          (CheckedId) => CheckedId !== id
        );
      }
      resultArray.push(id);
    } else {
      //if not checked (false), then remove this id from checkedList
      if (!_.isNull(values.category_ids)) {
        resultArray = values.category_ids.filter(
          (CheckedId) => CheckedId !== id
        );
      }
    }
    setValues((values) => {
      return {
        ...values,
        category_ids: resultArray.length > 0 ? resultArray : null,
      };
    });
  };

  const handleUploadTrailerButton = () => {
    if (!Boolean(values.show_id)) {
      return enqueueSnackbar("Show title is required", {
        variant: "error",
      });
    }
    document.getElementById("trailer-video-file").click();
  };

  const handleUploadTrailer = (e) => {
    if (e.target.files.length > 0) {
      let fileSizeInMbs = parseInt(e.target.files[0].size / 1000000);
      if (fileSizeInMbs > 512) {
        return enqueueSnackbar("Max upload file size is 500 MB", {
          variant: "error",
        });
      }
      setUploadedTrailer({
        title: e.target.files[0].name,
        size: parseInt(e.target.files[0].size / 1000000),
      });
      setisUploadingTrailer(true);
      const formData = new FormData();
      formData.append("show_id", values.show_id);
      formData.append("title", e.target.files[0].name);
      formData.append("size", parseInt(e.target.files[0].size / 1000000));
      formData.append("video_file", e.target.files[0]);
      formData.append("type", 1);
      showService
        .uploadVideo(formData)
        .then((data) => {
          setValues((values) => {
            return {
              ...values,
              trailer_url: data.data.url,
            };
          });
          setUploadedTrailer({
            id: data.data.id,
            title: e.target.files[0].name,
            size: parseInt(e.target.files[0].size / 1000000),
            url: data.data.url,
          });
          var trailerVideoFileInput =
            document.getElementById("trailer-video-file");
          trailerVideoFileInput.value = "";
          setisUploadingTrailer(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleDeleteTrailer = (id) => {
    if (!id) {
      showService.cancelUploadTrailer();
      setUploadedTrailer({});
      setValues((values) => {
        return {
          ...values,
          trailer_url: "",
        };
      });
      setisUploadingTrailer(false);
      var trailerVideoFileInput = document.getElementById("trailer-video-file");
      trailerVideoFileInput.value = "";
    } else {
      showService
        .deleteVideo({
          id: id,
        })
        .then((data) => {
          if (data.success) {
            setUploadedTrailer({});
            setValues((values) => {
              return {
                ...values,
                trailer_url: "",
              };
            });
          } else {
            return enqueueSnackbar(data.message, {
              variant: "error",
            });
          }
          setisUploadingTrailer(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handlePlayStopTrailer = (isPlayed) => {
    setUploadedTrailer((uploadedTrailer) => {
      return { ...uploadedTrailer, isPlayed: isPlayed };
    });
    if (isPlayed) {
      setTimeout(() => {
        document.getElementById("trailer-video-container").play();
      }, 200);
    }
  };

  const handleUploadRecordingButton = () => {
    if (!Boolean(values.show_id)) {
      return enqueueSnackbar("Show title is required", {
        variant: "error",
      });
    }
    document.getElementById("recording-video-file").click();
    // setClickedUploadRecordingInEdit(true);
  };

  const handleUploadRecording = (e) => {
    const uploadedFiles = e.target.files;
    let uniqueIndex = new Date().getTime();
    // setIsUploadingRecordings(true);
    let uploadedRecordingAfterSizeCheckConfirmed = [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      let fileSizeInMbs = parseInt(uploadedFiles[i].size / 1000000);
      if (fileSizeInMbs > 1024) {
        return enqueueSnackbar("Max upload file size is 1 GB", {
          variant: "error",
        });
      }
      uploadedRecordingAfterSizeCheckConfirmed.push({
        id: `${uniqueIndex}-${i}`,
        title: uploadedFiles[i].name,
        size: parseInt(uploadedFiles[i].size / 1000000),
        is_uploading: true,
      });
    }
    setUploadedRecordings((uploadedRecordings) => {
      return [
        ...uploadedRecordings,
        ...uploadedRecordingAfterSizeCheckConfirmed,
      ];
    });
    setIsUploadingRecordings(true);
    let uploadingArrayForUploadedFiles = [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      let promise = new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("show_id", values.show_id);
        formData.append("title", uploadedFiles[i].name);
        formData.append("size", parseInt(uploadedFiles[i].size / 1000000));
        formData.append("video_file", uploadedFiles[i]);
        formData.append("type", 2);
        showService
          .uploadVideo(formData, dispatch, `${uniqueIndex}-${i}`)
          .then((data) => {
            setUploadedRecordings((uploadedRecordings) => {
              let updatedUploadedRecordings = uploadedRecordings.map((val) => {
                if (val.id === `${uniqueIndex}-${i}`) {
                  return {
                    ...val,
                    ...data.data,
                    is_uploading: false,
                  };
                } else {
                  return val;
                }
              });
              return updatedUploadedRecordings;
            });
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
      uploadingArrayForUploadedFiles.push(promise);
    }
    Promise.all(uploadingArrayForUploadedFiles)
      .then(() => {
        var recordingVideoFileInput = document.getElementById(
          "recording-video-file"
        );
        recordingVideoFileInput.value = "";
        setIsUploadingRecordings(false);
      })
      .catch((err) => {
        setIsUploadingRecordings(false);
        console.log(err);
      });
    // console.log(uploadedRecordingAfterSizeCheckConfirmed);
    // let uniqueIndex = new Date().getTime();
    // if (e.target.files.length > 0) {
    //   let fileSizeInMbs = parseInt(e.target.files[0].size / 1000000);
    //   if (fileSizeInMbs > 1024) {
    //     return enqueueSnackbar("Max upload file size is 1 GB", {
    //       variant: "error",
    //     });
    //   }
    //   setUploadedRecordings((uploadedRecordings) => {
    //     return [
    //       ...uploadedRecordings,
    //       {
    //         id: uniqueIndex,
    //         title: e.target.files[0].name,
    //         size: parseInt(e.target.files[0].size / 1000000),
    //       },
    //     ];
    //   });
    //   setIsUploadingRecordings(true);
    //   const formData = new FormData();
    //   formData.append("show_id", values.show_id);
    //   formData.append("title", e.target.files[0].name);
    //   formData.append("size", parseInt(e.target.files[0].size / 1000000));
    //   formData.append("video_file", e.target.files[0]);
    //   formData.append("type", 2);
    //   showService
    //     .uploadVideo(formData)
    //     .then((data) => {
    //       setUploadedRecordings((uploadedRecordings) => {
    //         let newUploadedRecordings = uploadedRecordings;
    //         newUploadedRecordings.splice(-1);
    //         return [
    //           ...newUploadedRecordings,
    //           {
    //             ...data.data,
    //             title: e.target.files[0].name,
    //             size: parseInt(e.target.files[0].size / 1000000),
    //           },
    //         ];
    //       });
    //       var recordingVideoFileInput = document.getElementById(
    //         "recording-video-file"
    //       );
    //       recordingVideoFileInput.value = "";
    //       setIsUploadingRecordings(false);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
  };

  const handleDeleteRecording = (id) => {
    showService
      .deleteVideo({
        id: id,
      })
      .then((data) => {
        if (!data.success) {
          // return enqueueSnackbar(data.message, {
          //   variant: "error",
          // });
        }
        // showService.cancelUploadRecording();
        // get particular recording axios request
        let particularAxiosRequest = showRecordingsUploadAxiosRequests.find(
          (val) => {
            return val.id === id;
          }
        );
        if (!!particularAxiosRequest) {
          particularAxiosRequest.callingFunction();
        }
        setUploadedRecordings((uploadedRecordings) => {
          return uploadedRecordings.filter((val) => {
            return val.id !== id;
          });
        });
        var recordingVideoFileInput = document.getElementById(
          "recording-video-file"
        );
        recordingVideoFileInput.value = "";
        setIsUploadingRecordings(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePlayStopRecording = (id, isPlayed) => {
    setUploadedRecordings((UploadedRecordings) => {
      let updatedUploadedRecordings = UploadedRecordings.map((val) => {
        if (val.id === id) {
          return {
            ...val,
            isPlayed: isPlayed,
          };
        } else {
          return val;
        }
      });
      return updatedUploadedRecordings;
    });
    if (isPlayed) {
      setTimeout(() => {
        document.getElementById(`recording-video-container_${id}`).play();
      }, 500);
    }
  };

  const handleMarkVod = (e) => {
    let id = parseInt(e.target.getAttribute("data-id"));
    var selectedVodCheckbox = document.getElementById(`vod_${id}`);

    let isChecked = selectedVodCheckbox.checked;
    if (isChecked) {
      var vodCheckboxes = document.querySelectorAll('input[id^="vod_"]');
      for (var i = 0; i < vodCheckboxes.length; i++) {
        vodCheckboxes[i].checked = false;
      }
      selectedVodCheckbox.checked = isChecked;
    }
    setValues((values) => {
      if (!isChecked) {
        return {
          ...values,
          vod_video_ids: [],
        };
      } else {
        return {
          ...values,
          vod_video_ids: [id],
        };
      }
    });
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleUploadImageButton = () => {
    if (!Boolean(values.show_id)) {
      return enqueueSnackbar("Show title is required", {
        variant: "error",
      });
    }
    document.getElementById("image-file").click();
    // setClickedUploadImagesInEdit(true);
  };

  const handleUploadImage = async (e) => {
    const uploadedFiles = e.target.files;
    let uniqueIndex = new Date().getTime();
    let uploadedImagesAfterSizeCheckConfirmed = [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      // let fileSizeInMbs = parseInt(uploadedFiles[i].size / 1000000);
      // if (fileSizeInMbs > 1024) {
      //   return enqueueSnackbar("Max upload file size is 5 MB", {
      //     variant: "error",
      //   });
      // }
      uploadedImagesAfterSizeCheckConfirmed.push({
        id: `${uniqueIndex}-${i}`,
        title: uploadedFiles[i].name,
        size: parseInt(uploadedFiles[i].size / 1000000),
        is_uploading: true,
      });
    }
    setUploadedImages((uploadedImages) => {
      return [...uploadedImages, ...uploadedImagesAfterSizeCheckConfirmed];
    });
    setIsUploadingImages(true);

    let uploadingArrayForUploadedFiles = [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      let promise = new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("show_id", values.show_id);
        formData.append("title", uploadedFiles[i].name);
        formData.append("size", parseInt(uploadedFiles[i].size / 1000000));
        formData.append("image_file", uploadedFiles[i]);
        showService
          .uploadImage(formData, dispatch, `${uniqueIndex}-${i}`)
          .then((data) => {
            setUploadedImages((uploadedImages) => {
              let updatedUploadedImages = uploadedImages.map((val) => {
                if (val.id === `${uniqueIndex}-${i}`) {
                  return {
                    ...val,
                    ...data.data,
                    is_uploading: false,
                  };
                } else {
                  return val;
                }
              });
              return updatedUploadedImages;
            });
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
      uploadingArrayForUploadedFiles.push(promise);
    }
    Promise.all(uploadingArrayForUploadedFiles)
      .then(() => {
        var imageFileInput = document.getElementById("image-file");
        imageFileInput.value = "";
        setIsUploadingImages(false);
      })
      .catch((err) => {
        setIsUploadingRecordings(false);
        console.log(err);
      });
  };

  const handleDeleteImage = (id) => {
    showService
      .deleteImage({
        id: id,
      })
      .then((data) => {
        if (!data.success) {
          // return enqueueSnackbar(data.message, {
          //   variant: "error",
          // });
        }
        if (isUploadingImages) {
          // get particular recording axios request
          let particularAxiosRequest = showImagesUploadAxiosRequests.find(
            (val) => {
              return val.id === id;
            }
          );
          particularAxiosRequest.callingFunction();
        }
        setUploadedImages((uploadedImages) => {
          return uploadedImages.filter((val) => {
            return val.id !== id;
          });
        });
        var imageFileInput = document.getElementById("image-file");
        imageFileInput.value = "";
        setIsUploadingImages(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleHeroImageChange = (e) => {
    let id = parseInt(e.target.getAttribute("data-id"));
    var selectedHeroImgCheckbox = document.getElementById(`hero_${id}`);
    // let isChecked = selectedHeroImgCheckbox.checked;
    // if (isChecked) {
    var heroImgCheckboxes = document.querySelectorAll('input[id^="hero_"]');
    for (var i = 0; i < heroImgCheckboxes.length; i++) {
      heroImgCheckboxes[i].checked = false;
    }
    // selectedHeroImgCheckbox.checked = isChecked;
    selectedHeroImgCheckbox.checked = true;
    // }
    setValues((values) => {
      let updatedValues = values;
      // if (!isChecked) {
      //   delete updatedValues.hero_image_id;
      // } else {
      updatedValues = {
        ...updatedValues,
        hero_image_id: id,
      };
      // }
      return {
        ...updatedValues,
      };
    });
  };

  const handleThumbImageChange = (e) => {
    let id = parseInt(e.target.getAttribute("data-id"));
    var selectedThumbImgCheckbox = document.getElementById(`thumb_${id}`);
    // let isChecked = selectedThumbImgCheckbox.checked;
    // if (isChecked) {
    var thumbImgCheckboxes = document.querySelectorAll('input[id^="thumb_"]');
    for (var i = 0; i < thumbImgCheckboxes.length; i++) {
      thumbImgCheckboxes[i].checked = false;
    }
    // selectedThumbImgCheckbox.checked = isChecked;
    selectedThumbImgCheckbox.checked = true;
    // }
    setValues((values) => {
      let updatedValues = values;
      // if (!isChecked) {
      //   delete updatedValues.thumb_image_id;
      // } else {
      updatedValues = {
        ...updatedValues,
        thumb_image_id: id,
      };
      // }
      return {
        ...updatedValues,
      };
    });
  };

  const handleInviteHost = (e) => {
    e.preventDefault();
    if (!Boolean(values.show_id)) {
      return enqueueSnackbar("Show title is required", {
        variant: "error",
      });
    }
    setClickedInviteHostsInEdit(true);
    if (tags.length) {
      showService
        .inviteHost({
          show_id: values.show_id,
          host_emails: tags,
        })
        .then((data) => {
          if (data.data.length > 0) {
            setInvitedHosts((invitedHosts) => {
              let uniqueArray = invitedHosts.filter(
                (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
              );
              let newArray = [...uniqueArray, ...data.data];
              uniqueArray = newArray.filter(
                (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
              );
              return uniqueArray;
            });
            setTags([]);
            setInvalidTags([]);
            setNewTags([]);
            // setTimeout(() => {
            //   document.getElementsByClassName("react-tag-input__input")[0].value =
            //     "";
            // }, 200);
            if (data.data.length && !invitedHosts.length) {
              setValues((values) => {
                return {
                  ...values,
                  primary_host_id: data.data[0].id,
                };
              });
            }
          } else {
            return enqueueSnackbar(data.message, {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      addEmailToHosts(e);
    }
  };

  useEffect(() => {
    if (invitedHosts.length) {
      console.log("invitedHosts ", invitedHosts);
    }
  }, [invitedHosts]);

  const handleAddHost = (userData) => {
    if (!Boolean(values.show_id)) {
      return enqueueSnackbar("Show title is required", {
        variant: "error",
      });
    }
    setClickedInviteHostsInEdit(true);
    showService
      .inviteHost({
        show_id: values.show_id,
        host_emails: [userData.email || userData.id],
      })
      .then((data) => {
        if (data.data.length > 0) {
          if (data.data.length && !invitedHosts.length) {
            setValues((values) => {
              return {
                ...values,
                primary_host_id: data.data[0].id,
              };
            });
          }
          setInvitedHosts((invitedHosts) => {
            let uniqueArray = invitedHosts.filter(
              (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
            );
            let newArray = [...uniqueArray, ...data.data];
            uniqueArray = newArray.filter(
              (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
            );
            return uniqueArray;
          });
          setCachedSearchedHosts([]);
          // setSearchedHosts([]);
          setTags([]);
          console.log(data.data[0].id);
          document.getElementById("input-tag").value = "";
        } else {
          return enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMakePrimaryHost = (id) => {
    console.log("invitedHosts hostId ", id);
    var selectedMakePrimaryBtn = document.getElementById(
      `make-primary-btn_${id}`
    );
    var makePrimaryBtns = document.querySelectorAll(
      'button[id^="make-primary-btn_"]'
    );
    for (var i = 0; i < makePrimaryBtns.length; i++) {
      selectedMakePrimaryBtn.classList.remove("btn-dark-gray");
      makePrimaryBtns[i].classList.add("btn-danger");
    }
    selectedMakePrimaryBtn.classList.remove("btn-danger");
    selectedMakePrimaryBtn.classList.add("btn-dark-gray");
    setValues((values) => {
      return {
        ...values,
        primary_host_id: id,
      };
    });
  };

  const handleHideShowHost = (hostId, is_hidden) => {
    showService
      .hideUnhideHost({
        show_id: values.show_id,
        host_id: hostId,
        is_hidden: !is_hidden ? 1 : 0,
      })
      .then((data) => {
        if (data.success) {
          setInvitedHosts((invitedHosts) => {
            return invitedHosts.map((val) => {
              return {
                ...val,
                is_hidden:
                  val.id === hostId ? (!is_hidden ? 1 : 0) : val.is_hidden,
              };
            });
          });
        } else {
          return enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSendEmailHost = (showHostId) => {
    showService
      .sendEmailToHost({
        show_url: values.url,
        host_id: showHostId,
      })
      .then((data) => {
        if (data.success) {
          setInvitedHosts((invitedHosts) => {
            return invitedHosts.map((val) => {
              return {
                ...val,
                is_invited: val.id === showHostId ? 1 : 0,
              };
            });
          });
        } else {
          return enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteHost = (showHostId) => {
    showService
      .deleteHost({
        show_id: values.show_id,
        host_id: showHostId,
      })
      .then((data) => {
        if (data.success) {
          setInvitedHosts((invitedHosts) => {
            let filteredHosts = invitedHosts.filter((val) => {
              return val.id !== showHostId;
            });

            if (filteredHosts.length === 1) {
              setValues((values) => {
                return {
                  ...values,
                  primary_host_id: filteredHosts[0].id,
                };
              });
            }
            return filteredHosts;
          });
        } else {
          return enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    showService
      .addComplete(values)
      .then((data) => {
        if (data.success) {
          addShowEpisode();
          user.role === CONSTANTS.ADMIN_ROLE
            ? navigate(`/admin/schedule-show/${values.show_id}`)
            : navigate("/admin/show-list");
        } else {
          if (
            (values.category_ids === null ||
              values.hero_image_id === null ||
              values.thumb_image_id === null ||
              values.description === "") &&
            !_.isEmpty(values.title) &&
            !_.isNull(values.primary_host_id)
          ) {
            setSaveIncomplete(true);
            setSaveIncompleteError(data.message);
          } else {
            enqueueSnackbar(data.message, {
              variant: "error",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveIncompleteShow = () => {
    showService
      .addCompleteOptional(values)
      .then((data) => {
        if (data.success) {
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1500);
          //
          addShowEpisode();
          navigate(`/admin/show-list`);
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addEmailToHosts = () => {
    const inputData = document.getElementById("input-tag").value;
    if (inputData.length) {
      if (!regex.email.test(inputData)) {
        setInvalidTags([...new Set([...invalidTags, inputData])]);
        tagRef.current.value = "";
      } else {
        handleAddHost({ email: inputData });
      }
    }
  };

  const onChange = (e) => {
    const userInput = e.target.value;
    // Filter our suggestions that don't contain the user's input
    const unLinked = newSuggestions.filter(
      (suggestion) =>
        suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) === 0
    );

    setFilteredSuggestions(unLinked);

    if (!userInput) {
      setFilteredSuggestions([]);
    }
  };

  const handleTags = (e) => {
    if (e.key === "Enter") {
      addEmailToHosts();
      e.target.value = "";
    } else if (
      (e.key === "," || e.key === " " || e.key === ";") &&
      e.target.value !== ""
    ) {
      const newTag = e.target.value;
      const newTagArray = newTag.split(/,| |;/);
      newTagArray.pop();
      const tag = newTagArray[0];
      if (regex.email.test(tag)) {
        setTags([...new Set([...tags, tag])]);
      } else {
        setInvalidTags([...new Set([...invalidTags, tag])]);
      }
      e.target.value = "";
    }
  };

  const removeTags = (index) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };

  const removeInvalidTags = (index) => {
    setInvalidTags([
      ...invalidTags.filter((tag) => invalidTags.indexOf(tag) !== index),
    ]);
  };

  const handlePaste = (e) => {
    let tempTagArr = [];
    let tempInvalidTagArr = [];

    setTimeout(() => {
      let pastedTags = e.target.value.split(/,| |;|;     /);
      pastedTags.map((tag) => {
        if (tag.length) {
          if (regex.email.test(tag)) {
            tempTagArr.push(...tags, tag);
          } else {
            tempInvalidTagArr.push(...invalidTags, tag);
          }
        }
      });

      if (tempTagArr.length) {
        setTags([...new Set([...tempTagArr])]);
      }
      if (tempInvalidTagArr.length) {
        setInvalidTags([...new Set([...tempInvalidTagArr])]);
      }
      e.target.value = "";
    }, 100);
  };

  const togglePreparationFieldVisibility = () =>
    setShowPreparationField(!showPreparationField);

  const toggleHasEpisodeVisibility = () => setShowHasEpisodes(!showHasEpisodes);

  const addShowEpisode = () => {
    let showIds = [];
    getShows.forEach((data) => {
      showIds.push(data.id);
    });
    console.log(showIds);

    const payload = {
      show_id: values.show_id,
      episode_ids: showIds,
      sequence_order: 0,
    };
    showService
      .addShowEpisode(payload)
      .then((data) => {
        if (data.success) {
          console.log("data :>> ", data);
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const handleAdd = (data) => {
    const newDatas = [...getShows];
    newDatas.push(data);
    setGetShows(newDatas);
  };

  const getShowViaShowId = () => {
    const payload = {
      episode_id: hasEpisodesShowId,
    };
    showService
      .getShowViaShowId(payload)
      .then((data) => {
        if (data.success) {
          handleAdd(data?.data);
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const getEpisodes = () => {
    const payload = {
      show_id: params.id,
    };
    showService
      .getShowEpisodes(payload)
      .then((data) => {
        if (data.success) {
          setEpisodesData(data.data);
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const getMasterShow = () => {
    const payload = {
      show_id: params.id,
    };
    showService
      .getMasterShow(payload)
      .then((data) => {
        if (data.success) {
          setMasterShow(data.data);
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    if (isMounted.current === true) {
      function myFunction() {}
      const elements = document.querySelectorAll(".hosts");
      if (elements.length > 0) {
        elements[0].addEventListener("click", myFunction);
      }
      return () => {
        window.removeEventListener("click", myFunction);
      };
    } else {
      isMounted.current = true;
    }
  }, [newSuggestions]);

  return (
    <div>
      <Header />
      <main>
        <div className="container mt-5">
          <div className="d-flex align-items-center mb-3">
            <img className="img-fluid" src={backArrow} alt="arrow" />
            <h1 className="text-white ps-2 display-4 fw-600">
              {!!params.id ? `Edit Show` : `Create Show`}
            </h1>
          </div>
          <div className="bg-dark-grey p-sm-5 p-4 rounded">
            <div className="col-xl-6 col-lg-8 col-md-11 col-sm-12 col-12 m-auto">
              <form>
                <div className="mb-4">
                  <label htmlFor="title" className="form-label text-white">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4 col-sm-4">
                  <label htmlFor="show_id" className="form-label text-white">
                    ID
                  </label>
                  <input
                    type="text"
                    className="form-control disabled darkPlaceholder"
                    id="show_id"
                    name="show_id"
                    placeholder={values.show_id}
                    readOnly
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="show_id" className="form-label text-white">
                    Link
                  </label>
                  <input
                    type="text"
                    className="form-control disabled darkPlaceholder"
                    id="url"
                    placeholder={values.url}
                    readOnly
                  />
                </div>
                <div className="mb-4">
                  <p className="form-label text-white mb-3">Category</p>
                  <div className="d-flex flex-wrap">
                    {categories.map((element) => {
                      return (
                        <div
                          key={element?.id}
                          className="form-check d-flex align-items-center mb-2  p-2 rounded position-relative me-2"
                        >
                          <input
                            className="form-check-input customCheck"
                            type="checkbox"
                            id={`category_${element.id}`}
                            data-id={element.id}
                            onChange={handleCategoryChange}
                          />
                          <label
                            className="form-check-label d-flex align-items-center fs-7"
                            htmlFor={`category_${element.id}`}
                          >
                            {element.title}
                            <span className="dotCheck1"></span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="row">
                  <input
                    id="trailer-video-file"
                    type="file"
                    accept="video/*"
                    onChange={handleUploadTrailer}
                    hidden
                  />
                  <div className="col-sm-6 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
                    <div className="d-grid gap-2">
                      <button
                        type="button"
                        className="btn-block btn btn-danger btn-sm  "
                        onClick={handleUploadTrailerButton}
                        disabled={isUploadingTrailer}
                      >
                        Upload Trailer
                      </button>
                    </div>
                  </div>
                  {Boolean(uploadedTrailer?.title) && (
                    <div className="col-sm-6">
                      <div className="d-flex align-items-center">
                        {!Boolean(uploadedTrailer?.url) && (
                          <div className="flex-shrink-0">
                            <div
                              className="spinner-border customSpinner text-danger me-1"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="w-100">
                          <div className="bg-disabled rounded  ">
                            <div className="d-flex p-2 align-items-start">
                              <div className="flex-shrink-0">
                                <img src={filmIcon} alt="film" />
                              </div>
                              <div className="w-100 ps-2 lh-1 d-flex justify-content-between align-items-center">
                                <div>
                                  <p className="mb-0 text-white fs-7 text-break">
                                    {uploadedTrailer?.title}
                                  </p>
                                  <span className="small text-muted fs-7 text-break">
                                    {uploadedTrailer?.size} MB
                                  </span>
                                </div>
                                <a
                                  className="cursor-pointer ps-2"
                                  onClick={() =>
                                    handleDeleteTrailer(uploadedTrailer?.id)
                                  }
                                >
                                  <img src={closeIcon} alt="close" />
                                </a>
                                {Boolean(uploadedTrailer?.url) && (
                                  <>
                                    <a
                                      className="cursor-pointer ps-2"
                                      onClick={() =>
                                        handlePlayStopTrailer(
                                          !uploadedTrailer?.isPlayed
                                        )
                                      }
                                    >
                                      <img src={playIcon} alt="Play" />
                                    </a>
                                    <a
                                      href={uploadedTrailer?.url}
                                      className="cursor-pointer ps-2"
                                      target="_blank"
                                    >
                                      <img src={downloadIcon} alt="Download" />
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {Boolean(uploadedTrailer?.url) &&
                        uploadedTrailer?.isPlayed && (
                          <video
                            id="trailer-video-container"
                            width="288"
                            controls
                          >
                            <source src={uploadedTrailer?.url} />
                          </video>
                        )}
                    </div>
                  )}
                </div>
                <div style={{ position: "relative" }}>
                  <div className="row mt-5">
                    <input
                      id="recording-video-file"
                      type="file"
                      accept="video/*"
                      onChange={handleUploadRecording}
                      multiple
                      hidden
                    />
                    <div className="col-sm-6 mb-lg-0 mb-md-0 mb-sm-3 mb-3">
                      <div className="d-grid gap-2">
                        <button
                          type="button"
                          className="btn-block btn btn-light btn-sm text-danger "
                          onClick={handleUploadRecordingButton}
                          disabled={isUploadingRecordings}
                        >
                          {isUploadingRecordings
                            ? `Uploading...`
                            : `Upload Recordings`}
                        </button>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-white fs-7 text-break">Mark as VOD</p>

                      {uploadedRecordings.map((ele) => {
                        return (
                          <div key={ele?.id}>
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                {ele?.is_uploading ? (
                                  <div
                                    className="spinner-border customSpinner text-danger me-1"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                ) : (
                                  <div className="form-check">
                                    <input
                                      className="adminCheck form-check-input"
                                      type="checkbox"
                                      id={`vod_${ele?.id}`}
                                      data-id={ele?.id}
                                      onChange={handleMarkVod}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`vod_${ele?.id}`}
                                    ></label>
                                  </div>
                                )}
                              </div>
                              <div className="d-flex flex-column w-100">
                                <div className="bg-disabled rounded  w-100 mb-2">
                                  <div className="d-flex p-2 align-items-start h-100">
                                    <div className="flex-shrink-0">
                                      <img src={filmIcon} alt="film" />
                                    </div>
                                    <div className="w-100 ps-2 lh-1 d-flex justify-content-between align-items-center">
                                      <div>
                                        <p className="mb-0 text-white fs-7 text-break">
                                          {ele?.title}
                                        </p>
                                        <span className="small text-muted fs-7 text-break">
                                          {ele?.size} MB
                                        </span>
                                      </div>
                                      {/* {!ele?.is_uploading && ( */}
                                      <a
                                        className="cursor-pointer ps-2"
                                        onClick={() =>
                                          handleDeleteRecording(ele?.id)
                                        }
                                      >
                                        <img src={closeIcon} alt="close" />
                                      </a>
                                      {/* )} */}
                                      {Boolean(ele?.url) && (
                                        <>
                                          <a
                                            className="cursor-pointer ps-2"
                                            onClick={() =>
                                              handlePlayStopRecording(
                                                ele?.id,
                                                !ele?.isPlayed
                                              )
                                            }
                                          >
                                            <img src={playIcon} alt="Play" />
                                          </a>
                                          <a
                                            href={ele?.url}
                                            className="cursor-pointer ps-2"
                                            target="_blank"
                                          >
                                            <img
                                              src={downloadIcon}
                                              alt="Download"
                                            />
                                          </a>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {Boolean(ele?.url) && ele?.isPlayed && (
                              <video
                                id={`recording-video-container_${ele?.id}`}
                                width="288"
                                controls
                              >
                                <source src={ele?.url} />
                              </video>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 mt-4">
                      <div className="mb-3">
                        <label
                          htmlFor="textarea"
                          className="form-label text-white"
                        >
                          Description
                        </label>
                        <textarea
                          className="form-control"
                          rows="3"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 mt-4">
                      <div className="mb-3">
                        <div className="form-check">
                          <input
                            className="adminCheck form-check-input"
                            type="checkbox"
                            onChange={togglePreparationFieldVisibility}
                            checked={showPreparationField && true}
                          />
                          <span className="text-white">Preparation</span>
                        </div>
                        <textarea
                          className={`form-control ${
                            !showPreparationField && "d-none"
                          }`}
                          rows="3"
                          name="preparation"
                          value={values.preparation}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  {!masterShow ? (
                    <div className="form-check my-2">
                      <input
                        className="adminCheck form-check-input"
                        type="checkbox"
                        onChange={toggleHasEpisodeVisibility}
                        checked={showHasEpisodes && true}
                      />
                      <span className="text-white">Has Episodes</span>
                    </div>
                  ) : (
                    <div
                      key={masterShow?.id}
                      className="border-bottom border-dark pb-2 mb-2"
                    >
                      <h6 className="text-white">Master Show</h6>
                      <div className="d-flex mt-2">
                        <img
                          style={{ width: "100px" }}
                          src={
                            masterShow?.AdminShow?.AdminShowImages[0]?.hero_url
                          }
                          alt="user"
                        />
                        <span className="mb-0 text-white mx-3">
                          {masterShow?.AdminShow?.title}
                        </span>
                      </div>
                      <div className="row mt-2">
                        <div className="col-sm-6 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <div className="img-44 rounded-circle overflow-h bg-accent-blue d-flex align-items-center justify-content-center">
                                {getProfileImageOrFirstAndLastChars(
                                  masterShow?.AdminShow?.AdminShowHosts[0]
                                    ?.UserBasicDetail?.profile_image,
                                  masterShow?.AdminShow?.AdminShowHosts[0]
                                    ?.UserBasicDetail?.first_name,
                                  masterShow?.AdminShow?.AdminShowHosts[0]
                                    ?.UserBasicDetail?.last_name
                                )}
                              </div>
                            </div>
                            <div className="w-100">
                              <div className="d-flex align-items-center justify-content-start">
                                <p className="mb-0 text-white mx-3">
                                  {masterShow?.AdminShow?.AdminShowHosts[0]
                                    ?.UserBasicDetail.first_name
                                    ? `${masterShow?.AdminShow?.AdminShowHosts[0]?.UserBasicDetail.first_name} ${masterShow?.AdminShow?.AdminShowHosts[0]?.UserBasicDetail.last_name}`
                                    : masterShow?.AdminShow?.AdminShowHosts[0]
                                        ?.UserBasicDetail.email}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="d-flex align-items-center justify-content-end h-100">
                            <a
                              className="cursor-pointer ps-2"
                              onClick={() => handleDeleteHost(masterShow.id)}
                            >
                              <img src={closeIcon} alt="close" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className={`row align-items-center mb-3 ${
                      !showHasEpisodes && "d-none"
                    }`}
                  >
                    <div className="col-sm-8">
                      <div className="row">
                        <div className="col-sm-6 d-grid mb-sm-0 mb-2">
                          <input
                            id="show_id"
                            name="show_id"
                            type="number"
                            className="form-control"
                            placeholder="Show ID"
                            value={hasEpisodesShowId}
                            onChange={(e) =>
                              setHasEpisodesShowId(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-sm-6 d-grid">
                          <button
                            type="button"
                            className="btn btn-danger btn-sm py-2"
                            onClick={getShowViaShowId}
                          >
                            Add Episode
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <>
                    {getShows.length > 0 &&
                      getShows?.map((episode) => {
                        return (
                          <div
                            key={episode?.id}
                            className="border-bottom border-dark pb-2 mb-2"
                          >
                            <div className="d-flex mt-2">
                              <img
                                style={{ width: "100px" }}
                                src={episode?.AdminShowImages[0]?.hero_url}
                                alt="user"
                              />
                              <span className="mb-0 text-white mx-3">
                                {episode?.title}
                              </span>
                            </div>
                            <div className="row mt-2">
                              <div className="col-sm-6 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0">
                                    <div className="img-44 rounded-circle overflow-h bg-accent-blue d-flex align-items-center justify-content-center">
                                      {getProfileImageOrFirstAndLastChars(
                                        episode?.AdminShowHosts[0]
                                          ?.UserBasicDetail?.profile_image,
                                        episode?.AdminShowHosts[0]
                                          ?.UserBasicDetail?.first_name,
                                        episode?.AdminShowHosts[0]
                                          ?.UserBasicDetail?.last_name
                                      )}
                                    </div>
                                  </div>
                                  <div className="w-100">
                                    <div className="d-flex align-items-center justify-content-start">
                                      <p className="mb-0 text-white mx-3">
                                        {episode?.AdminShowHosts[0]
                                          ?.UserBasicDetail.first_name
                                          ? `${episode?.AdminShowHosts[0]?.UserBasicDetail.first_name} ${episode?.AdminShowHosts[0]?.UserBasicDetail.last_name}`
                                          : episode?.AdminShowHosts[0]
                                              ?.UserBasicDetail.email}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="d-flex align-items-center justify-content-end h-100">
                                  <a
                                    href
                                    className="cursor-pointer ps-2"
                                    onClick={() => handleDeleteHost(episode.id)}
                                  >
                                    <img src={closeIcon} alt="close" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </>
                  <>
                    {episodesData?.map((episode) => {
                      return (
                        <div
                          key={episode?.id}
                          className="border-bottom border-dark pb-2 mb-2"
                        >
                          <div className="d-flex mt-2">
                            <img
                              style={{ width: "100px" }}
                              src={
                                episode?.AdminShow?.AdminShowImages[0]?.hero_url
                              }
                              alt="user"
                            />
                            <span className="mb-0 text-white mx-3">
                              {episode?.AdminShow?.title}
                            </span>
                          </div>
                          <div className="row mt-2">
                            <div className="col-sm-6 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="img-44 rounded-circle overflow-h bg-accent-blue d-flex align-items-center justify-content-center">
                                    {getProfileImageOrFirstAndLastChars(
                                      episode?.AdminShow?.AdminShowHosts[0]
                                        ?.UserBasicDetail?.profile_image,
                                      episode?.AdminShow?.AdminShowHosts[0]
                                        ?.UserBasicDetail?.first_name,
                                      episode?.AdminShow?.AdminShowHosts[0]
                                        ?.UserBasicDetail?.last_name
                                    )}
                                  </div>
                                </div>
                                <div className="w-100">
                                  <div className="d-flex align-items-center justify-content-start">
                                    <p className="mb-0 text-white mx-3">
                                      {episode?.AdminShow?.AdminShowHosts[0]
                                        ?.UserBasicDetail.first_name
                                        ? `${episode?.AdminShow?.AdminShowHosts[0]?.UserBasicDetail.first_name} ${episode?.AdminShow?.AdminShowHosts[0]?.UserBasicDetail.last_name}`
                                        : episode?.AdminShow?.AdminShowHosts[0]
                                            ?.UserBasicDetail.email}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="d-flex align-items-center justify-content-end h-100">
                                <a
                                  className="cursor-pointer ps-2"
                                  onClick={() => handleDeleteHost(episode.id)}
                                >
                                  <img src={closeIcon} alt="close" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                  <div className="row">
                    <input
                      id="image-file"
                      type="file"
                      accept="image/*"
                      onChange={handleUploadImage}
                      hidden
                      multiple
                    />
                    <div
                      className="col-lg-5 col-md-5 col-sm-6 col-12 mb-lg-0 mb-md-0 mb-sm-4 mb-4"
                      onClick={handleUploadImageButton}
                    >
                      <button
                        type="button"
                        className="btn-block btn btn-danger btn-sm  w-100"
                        disabled={isUploadingImages}
                      >
                        {isUploadingImages ? `Uploading...` : `Upload Images`}
                      </button>
                    </div>
                    <div className="col-sm-7">
                      {uploadedImages.map((ele, index) => {
                        let uniqueIndex = `${index}_${new Date().getTime()}`;
                        return (
                          <div
                            key={ele?.id || uniqueIndex}
                            className="row align-items-center mb-lg-0 mb-md-0 mb-sm-4 mb-4"
                          >
                            <div className="col-lg-5 col-md-5 col-sm-12 col-12 mb-0 px-lg-0">
                              {ele.is_uploading ? (
                                <div
                                  className="spinner-border customSpinner text-danger me-1"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                <div className="d-flex justify-content-lg-end">
                                  <div className="form-check me-2">
                                    <input
                                      className="adminCheck form-check-input"
                                      type="checkbox"
                                      id={`hero_${ele?.id || uniqueIndex}`}
                                      data-id={ele?.id || uniqueIndex}
                                      onChange={handleHeroImageChange}
                                    />
                                    <label
                                      className="form-check-label d-flex flex-column text-white fs-7 lh-1 mt-1"
                                      id={`hero_${ele?.id || uniqueIndex}`}
                                    >
                                      <span className="fs-8 text-break w-40">
                                        Hero
                                      </span>
                                      <span className="text-secondary fs-9 text-break w-40">
                                        16X9
                                      </span>
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="adminCheck form-check-input"
                                      type="checkbox"
                                      id={`thumb_${ele?.id || uniqueIndex}`}
                                      data-id={ele?.id || uniqueIndex}
                                      onChange={handleThumbImageChange}
                                    />
                                    <label
                                      className="form-check-label d-flex flex-column text-white fs-7 lh-1 mt-1"
                                      id={`thumb_${ele?.id || uniqueIndex}`}
                                    >
                                      <span className="fs-8 text-break w-40">
                                        Thumb
                                      </span>
                                      <span className="text-secondary fs-9 text-break w-40">
                                        4X3
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                              <div
                                key={ele?.id || uniqueIndex}
                                className="bg-dark rounded-5 p-1 mb-1"
                              >
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    <div className="screenShot">
                                      <img
                                        className="img-fluid"
                                        src={ele.hero_url || ""}
                                      />
                                    </div>
                                  </div>
                                  <div className="w-100 ms-2">
                                    <div className="d-flex justify-content-between">
                                      <div className="me-2">
                                        <p className="fs-10 mb-0 text-white text-break">
                                          {ele.title}
                                        </p>
                                        <p className="fs-10 mb-0 text-secondary text-break">
                                          {ele.size}
                                        </p>
                                      </div>
                                      <a
                                        className="cursor-pointer ps-2"
                                        onClick={() =>
                                          handleDeleteImage(ele?.id)
                                        }
                                      >
                                        <img src={closeIcon} alt="close" />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setFilteredSuggestions([]);
                      // setSearchedHosts([]);
                    }}
                  >
                    <div
                      className={cx(
                        "col-lg-10 col-md-10 col-sm-12 col-12 mb-2 simplebar-scrollbar"
                      )}
                      style={{ position: "absolute", bottom: -30 }}
                    >
                      <SimpleBar
                        className={cx("simplebar")}
                        style={
                          searchedHosts.length > 0
                            ? { marginBottom: "-20px" }
                            : null
                        }
                      >
                        <div
                          data-simplebar
                          className={cx({
                            simpleBarInner: searchedHosts.length > 0,
                          })}
                        >
                          {filteredSuggestions.map((ele) => {
                            return (
                              <div
                                key={ele.id}
                                className={cx(
                                  "hoverColor border-dark bg-dark p-2"
                                )}
                                onClick={() => {
                                  handleAddHost(ele);
                                  setFilteredSuggestions([]);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <div className="row">
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-6 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <div className="img-44 rounded-circle">
                                          <img
                                            className="img-44 img-fluid rounded-circle"
                                            src={ele.profile_image || hostImg}
                                            alt="host"
                                          />
                                        </div>
                                      </div>
                                      <div className="w-100">
                                        <div className="d-flex align-items-center justify-content-start">
                                          <p className="mb-0 text-white mx-3">
                                            {/* {`${ele.first_name} ${ele.last_name}`} */}
                                            {ele.name}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </SimpleBar>
                    </div>
                  </OutsideClickHandler>
                </div>
                <div className="row mt-4" style={{ width: "500px" }}>
                  <div
                    // id="create-show-tag-input"
                    className="d-flex form-inline flex-sm-row flex-column col-sm-12"
                  >
                    <div className="col-sm-10">
                      <div className={cx("tags-input")}>
                        <ul id={cx("tags")}>
                          {invalidTags.map((invalidTag, index) => (
                            <li key={index} className={cx("invalid-tag")}>
                              <span className={cx("tag-title")}>
                                {invalidTag}
                              </span>
                              <span
                                className={cx("tag-close-icon")}
                                onClick={() => removeInvalidTags(index)}
                              >
                                x
                              </span>
                            </li>
                          ))}
                        </ul>
                        <ul id={cx("tags")}>
                          {tags.map((tag, index) => (
                            <li key={index} className={cx("tag")}>
                              <span className={cx("tag-title")}>{tag}</span>
                              <span
                                className={cx("tag-close-icon")}
                                onClick={() => removeTags(index)}
                              >
                                x
                              </span>
                            </li>
                          ))}
                        </ul>
                        <input
                          ref={tagRef}
                          id="input-tag"
                          // value={input}
                          type="search"
                          onPaste={(e) => handlePaste(e)}
                          onKeyUp={(e) => handleTags(e)}
                          onChange={onChange}
                          // onKeyDown={onKeyDown}
                          placeholder="Enter email or first and last name"
                          autoCorrect="off"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="col-sm-2 d-grid">
                      <button
                        type="button"
                        className="btn btn-danger btn-sm my-0 px-4 mt-sm-0 mt-2 ms-sm-2 ms-0"
                        onClick={handleInviteHost}
                      >
                        Invite
                      </button>
                    </div>
                  </div>
                </div>
                <div className="invitedHost">
                  {invitedHosts.length > 0 && (
                    <p className="text-white mt-4 mb-2">Invited Host</p>
                  )}
                  {/* rendering only the primary host using the below loop */}
                  {invitedHosts.map((ele) => {
                    if (ele?.is_primary) {
                      return (
                        <div
                          key={ele.id}
                          className="border-bottom border-dark pb-2 mb-2"
                        >
                          <div className="row">
                            <div className="col-sm-6 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="img-44 rounded-circle">
                                    <img
                                      className="img-44 img-fluid rounded-circle"
                                      src={ele.profile_image || hostImg}
                                      alt="host"
                                    />
                                  </div>
                                </div>
                                <div className="w-100">
                                  <div className="d-flex align-items-center justify-content-start">
                                    <p className="mb-0 text-white mx-3">
                                      {ele.first_name
                                        ? `${ele.first_name} ${ele.last_name}`
                                        : ele.email}
                                    </p>
                                    <p
                                      className="mb-0 text-blue cursor-pointer"
                                      onClick={() =>
                                        handleHideShowHost(
                                          ele.id,
                                          ele.is_hidden
                                        )
                                      }
                                    >
                                      {ele.is_hidden
                                        ? `Show Host`
                                        : `Hide Host`}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="d-flex align-items-center justify-content-end h-100">
                                <button
                                  type="button"
                                  id={`make-primary-btn_${
                                    ele?.host_id || ele?.id
                                  }`}
                                  className={`btn ${
                                    Boolean(values.primary_host_id) &&
                                    values.primary_host_id ===
                                      (ele?.host_id || ele?.id)
                                      ? "btn-gray-dark"
                                      : "btn-danger"
                                  } btn-sm text-white me-2 fs-8 py-1`}
                                  onClick={() =>
                                    handleMakePrimaryHost(
                                      ele?.host_id || ele?.id
                                    )
                                  }
                                >
                                  {Boolean(values.primary_host_id) &&
                                  values.primary_host_id ===
                                    (ele?.host_id || ele?.id)
                                    ? "Primary"
                                    : "Make Primary"}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-light btn-sm text-danger me-2 fs-8 py-1"
                                  onClick={() => handleSendEmailHost(ele?.id)}
                                  disabled={ele?.is_invited}
                                >
                                  {ele?.is_invited
                                    ? `Invited`
                                    : `Invite Pending`}
                                </button>
                                <a
                                  className="cursor-pointer ps-2"
                                  onClick={() => handleDeleteHost(ele.id)}
                                >
                                  <img src={closeIcon} alt="close" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    // }
                  })}
                  {/* rendering only non primary hosts using the below loop */}
                  {invitedHosts
                    .filter((element) => parseInt(element?.is_attendee) !== 1)
                    .map((ele) => {
                      if (!ele?.is_primary) {
                        return (
                          <div
                            key={ele.id}
                            className="border-bottom border-dark pb-2 mb-2"
                          >
                            <div className="row">
                              <div className="col-sm-6 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0">
                                    <div className="img-44 rounded-circle">
                                      <img
                                        className="img-44 img-fluid rounded-circle"
                                        src={ele.profile_image || hostImg}
                                        alt="host"
                                      />
                                    </div>
                                  </div>
                                  <div className="w-100">
                                    <div className="d-flex align-items-center justify-content-start">
                                      <p className="mb-0 text-white mx-3">
                                        {ele.first_name
                                          ? `${ele.first_name} ${ele.last_name}`
                                          : ele.email}
                                      </p>
                                      <p
                                        className="mb-0 text-blue cursor-pointer"
                                        onClick={() =>
                                          handleHideShowHost(
                                            ele.id,
                                            ele.is_hidden
                                          )
                                        }
                                      >
                                        {ele.is_hidden
                                          ? `Show Host`
                                          : `Hide Host`}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="d-flex align-items-center justify-content-end h-100">
                                  <button
                                    type="button"
                                    id={`make-primary-btn_${
                                      ele?.host_id || ele?.id
                                    }`}
                                    className={`btn ${
                                      Boolean(values.primary_host_id) &&
                                      values.primary_host_id ===
                                        (ele?.host_id || ele?.id)
                                        ? "btn-gray-dark"
                                        : "btn-danger"
                                    } btn-sm text-white me-2 fs-8 py-1`}
                                    onClick={() =>
                                      handleMakePrimaryHost(
                                        ele?.host_id || ele?.id
                                      )
                                    }
                                  >
                                    {Boolean(values.primary_host_id) &&
                                    values.primary_host_id ===
                                      (ele?.host_id || ele?.id)
                                      ? "Primary"
                                      : "Make Primary"}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-light btn-sm text-danger me-2 fs-8 py-1"
                                    onClick={() => handleSendEmailHost(ele?.id)}
                                    disabled={ele?.is_invited}
                                  >
                                    {ele?.is_invited
                                      ? `Invited`
                                      : `Invite Pending`}
                                  </button>
                                  <a
                                    className="cursor-pointer ps-2"
                                    onClick={() => handleDeleteHost(ele.id)}
                                  >
                                    <img src={closeIcon} alt="close" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      // }
                    })}
                </div>
                <div className="d-flex flex-sm-row flex-column mt-4">
                  {!!params.id ? (
                    <>
                      <button
                        type="button"
                        className="btn-block btn btn-gray-dark btn-sm text-white px-lg-5 px-md-5 px-sm-4 px-4  me-sm-2 me-0"
                        onClick={() => {
                          navigate("/admin/show-list");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn-block btn btn-light btn-sm text-danger px-lg-5 px-md-5 px-sm-4 px-4  me-sm-2 me-0 mb-sm-0 mb-2"
                        onClick={() => {
                          navigate("/admin/show-list");
                        }}
                      >
                        Preview
                      </button>
                      <button
                        type="button"
                        className="btn-block btn text-light btn-sm btn-danger px-lg-5 px-md-5 px-sm-4 px-4  me-sm-2 me-0 mb-sm-0 mb-2"
                        onClick={handleSubmit}
                      >
                        Save
                      </button>
                      {/* <button
                        type="button"
                        className="btn-block btn btn-danger btn-sm text-white px-lg-5 px-md-5 px-sm-4 px-4 "
                        onClick={() => {
                          navigate("/admin/schedule-show");
                        }}
                      >
                        Schedule
                      </button> */}
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn-block btn btn-danger btn-sm px-lg-5 px-md-5 px-sm-4 px-4  me-sm-2 me-0 mb-sm-0 mb-2"
                        onClick={handleSubmit}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn-block btn btn-light btn-sm text-danger px-lg-5 px-md-5 px-sm-4 px-4  me-sm-2 me-0 mb-sm-0 mb-2"
                        onClick={() => {
                          navigate("/admin/show-list");
                        }}
                      >
                        Preview
                      </button>
                      <button
                        type="button"
                        className="btn-block btn btn-gray-dark btn-sm text-white px-lg-5 px-md-5 px-sm-4 px-4 "
                        onClick={() => {
                          navigate("/admin/show-list");
                        }}
                      >
                        Discard
                      </button>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className={`popUpBg popUpCentred ${
            saveIncomplete === true ? "" : "d-none"
          }`}
        >
          <div className="popUp">
            <div className="col-sm-6 m-auto">
              <div className="position-relative">
                <main className="bg-dark-grey p-4 popShadow">
                  <div className="col-sm-12">
                    <h1 className="text-white fw-bold mt-lg-4 mt-md-4 mt-sm-0 mt-0 mb-lg-4 mb-md-4 mb-sm-2 mb-2">
                      This show configuration is not complete
                    </h1>
                    <h6 className="form-label text-white mb-3">
                      NOTE: {saveIncompleteError}
                    </h6>
                    <p className="text-white mb-3">
                      Do you still want to save?
                    </p>
                    <div className="col-sm-12">
                      <div className="d-flex align-items-center mb-3">
                        <div>
                          <button
                            type="button"
                            className="btn btn-secondary me-3"
                            data-dismiss="modal"
                            onClick={() => setSaveIncomplete(false)}
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={saveIncompleteShow}
                          >
                            Save Incomplete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
export default CreateShow;
