import { Modal } from "react-bootstrap";
import classNames from "classnames/bind";
import styles from "./signuppopup.module.css";
import { Form, InputGroup, FormControl, Button } from "react-bootstrap";
import MailIcon from "../../../../images/emailIcon.svg";
import CloseIcon from "../../../../images/cross.svg";
import AppleIcon from "../../Signup/images/apple.svg";
import FacebookIcon from "../../Signup/images/facebook.svg";
import GoogleIcon from "../../Signup/images/googlePlus.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { userService } from "../../../../_services";
import { useSnackbar } from "notistack";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import auth from "../../Signup/firebase";
import {
  loginModalHandler,
  signupModalHandler,
  signupStepsHandler,
  login,
  userDetailsHandler,
  socialEmailModalHandler,
} from "../../../../store/slices/user";
import { useDispatch, useSelector } from "react-redux";
import RegisterOtp from "../../RegisterOtp/RegisterOtp";
import Register from "../../Register/Register";
import RegisterBirthday from "../../RegisterBirthday/RegisterBirthday";
import RegisterLocation from "../../RegisterLocation/RegisterLocation";
import RegisterAvatar from "../../RegisterAvatar/RegisterAvatar";
import RegisterAboutInfo from "../../RegisterAboutInfo/RegisterAboutInfo";
import EditShow from "../../EditShow/EditShow";
import RegisterForShow from "../../RegisterForShow/RegisterForShow";
import SocialEmail from "../SocialEmail/SocialEmail";

function SignUpPopUp(props) {
  const navigate = useNavigate();
  const cx = classNames.bind(styles);
  const [email, setEmail] = useState("");
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  // const [googleData, setIsFormSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [IsLoggedIn, setIsLoggedIn] = useState(false);
  const handleContinue = (e) => {
    e.preventDefault();
    const regex = {
      email: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
      password: /^[#\w@_-]{8,20}$/,
    };
    if (!regex.email.test(email)) {
      return enqueueSnackbar("Enter a valid email", {
        variant: "error",
      });
    }

    let userDetails = JSON.parse(localStorage.getItem("userDetail"));
    localStorage.setItem(
      "userDetail",
      JSON.stringify({
        ...userDetails,
        email: email,
      })
    );
    setIsFormSubmitting(true);
    const dataToPost = {
      email: email,
    };
    userService.check(dataToPost).then((data) => {
      setIsFormSubmitting(false);
      if (!data.data.isRegistered) {
        console.log("data.data.isRegistered", data.data.isRegistered);
        dispatch(signupStepsHandler(2));
      } else {
        dispatch(signupModalHandler(false));
        dispatch(loginModalHandler(true));
      }
    });
  };

  const { signupModal, signupSteps, userDetails, socialEmailModal } =
    useSelector((state) => state.user);

  useEffect(() => {
    if (email !== "") {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
  }, [email]);

  const btnStyle = isFormFilled
    ? {
        backgroundColor: "#E50914",
        color: "white",
      }
    : {
        backgroundColor: "#E3E3E3",
        color: "black",
      };

  //temp modal
  // const handleSocialEmail = () => {

  //   dispatch(signupModalHandler(false));
  // };
  //temp modal
  const handleSocialLogin = async (provider) => {
    //sign in facebook
    // const provider =
    if (provider === "apple") {
      var socialProvider = new OAuthProvider();
    } else if (provider === "google") {
      var socialProvider = new GoogleAuthProvider();
    } else if (provider === "facebook") {
      var socialProvider = new FacebookAuthProvider();
    }
    // const auth = getAuth();
    console.log(socialProvider);

    await signInWithPopup(auth, socialProvider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        console.log(token);
        console.log(result.user);
        const user = result.user;
        const displayNameString = user.displayName;
        const displayNameArray = displayNameString.split(" ");
        const displayPhotoURL = user.photoURL || "";
        let first_name = displayNameArray[0];
        let last_name = displayNameArray[1] || "last_name";
        let userDetailCheck = {
          first_name: first_name,
          last_name: last_name,
        };
        localStorage.setItem("photo", JSON.stringify(displayPhotoURL));
        const checkUser = {
          email: user.email,
        };
        localStorage.setItem(
          "userDetail",
          JSON.stringify({ ...userDetailCheck, email: user.email })
        );

        if (!user.email) {
          console.log("in if ", first_name, last_name);
          let userDetail = {
            first_name: first_name,
            last_name: last_name,
          };
          localStorage.setItem("userDetail", JSON.stringify(userDetail));
        } else {
          console.log("in else ", first_name, last_name);
          userService.check(checkUser).then((datas) => {
            if (!datas.data.isRegistered) {
              console.log("datas.data.isRegistered", datas.data.isRegistered);

              const dataToPost = {
                first_name: first_name,
                last_name: last_name,
                email: user.email,
                password: "admin@123",
              };

              userService
                .register(dataToPost)
                .then((data) => {
                  localStorage.setItem(
                    "photo",
                    JSON.stringify(displayPhotoURL)
                  );
                  console.log(displayPhotoURL);
                  dispatch(signupStepsHandler(4));
                  dispatch(login(data));

                  console.log(data);
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              userService
                .socialLogin({
                  email: user.email,
                  social_token: token,
                })
                .then((data) => {
                  if (data.success) {
                    console.log(data.data);
                    localStorage.setItem("user", JSON.stringify(data.data));

                    setIsLoggedIn(true);
                    dispatch(signupModalHandler(false));
                    dispatch(login(data.data));
                  } else {
                    enqueueSnackbar(data.message, {
                      variant: "error",
                    });
                  }
                  // // dispatch(signupStepsHandler(4));
                  // dispatch(signupModalHandler(false));
                  // dispatch(login(data));
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const HandleFacebookLogin = () => {
    var socialProvider = new FacebookAuthProvider();
    signInWithRedirect(auth, socialProvider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // console.log(token);
        // console.log(result.user);
        const user = result.user;
        const displayNameString = user.displayName;
        const displayNameArray = displayNameString.split(" ");
        const displayPhotoURL = user.photoURL || "";
        let first_name = displayNameArray[0];
        let last_name = displayNameArray[1] || "last_name";
        let userDetailCheck = {
          first_name: first_name,
          last_name: last_name,
        };
        localStorage.setItem("photo", JSON.stringify(displayPhotoURL));
        const checkUser = {
          email: user.email,
        };
        localStorage.setItem(
          "userDetail",
          JSON.stringify({ ...userDetailCheck, email: user.email })
        );

        if (!user.email) {
          // console.log("in if ", first_name, last_name);
          let userDetail = {
            first_name: first_name,
            last_name: last_name,
          };
          localStorage.setItem("userDetail", JSON.stringify(userDetail));
        } else {
          userService.check(checkUser).then((datas) => {
            if (!datas.data.isRegistered) {
              // console.log("datas.data.isRegistered", datas.data.isRegistered);

              const dataToPost = {
                first_name: first_name,
                last_name: last_name,
                email: user.email,
                password: "admin@123",
              };

              userService
                .register(dataToPost)
                .then((data) => {
                  localStorage.setItem(
                    "photo",
                    JSON.stringify(displayPhotoURL)
                  );
                  console.log(displayPhotoURL);
                  dispatch(signupStepsHandler(4));
                  dispatch(login(data));

                  console.log(data);
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              userService
                .socialLogin({
                  email: user.email,
                  social_token: token,
                })
                .then((data) => {
                  if (data.success) {
                    console.log(data.data);
                    localStorage.setItem("user", JSON.stringify(data.data));
                    setIsLoggedIn(true);
                    dispatch(signupModalHandler(false));
                    dispatch(login(data.data));
                  } else {
                    enqueueSnackbar(data.message, {
                      variant: "error",
                    });
                  }
                  // // dispatch(signupStepsHandler(4));
                  // dispatch(signupModalHandler(false));
                  // dispatch(login(data));
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      {signupSteps === 1 ? (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <section className="container d-flex align-items-center justify-content-center">
            <div
              className={cx("outerBox", "bg-white", "p-4", "position-relative")}
            >
              <div
                className={cx(
                  "d-flex",
                  "align-items-center",
                  "justify-content-center",
                  "mb-4",
                  "landscape-mb-4"
                )}
              >
                <h2 className="text-center fw-bold">Signup</h2>
                <div
                  onClick={props.onHide}
                  className={cx("position-absolute", "closeIcon")}
                >
                  <img className="img-fluid" src={CloseIcon} alt="close" />
                </div>
              </div>
              <Form onSubmit={handleContinue}>
                <div className={cx("landscapeMode")}>
                  <InputGroup
                    size="lg"
                    className={cx(
                      "mb-3",
                      "landscape-my-4",
                      "border",
                      "border-dark",
                      "rounded"
                    )}
                  >
                    <FormControl
                      placeholder="Use email"
                      aria-label="use email"
                      aria-describedby="use email"
                      className="border-white"
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <InputGroup.Text
                      id="use email"
                      className="fs-6 bg-white border-white"
                    >
                      <img src={MailIcon} alt="mail" />
                    </InputGroup.Text>
                  </InputGroup>
                  <Button
                    className="w-100 fw-bold socialBtn"
                    size="lg"
                    type="submit"
                    disabled={isFormSubmitting || !isFormFilled}
                    style={btnStyle}
                    variant="contained"
                  >
                    {isFormSubmitting ? `Loading...` : `Continue`}
                  </Button>

                  {/* <span
                    className={cx(
                      "d-block",
                      "text-center",
                      "py-3",
                      "landscape-py-3"
                    )}
                  >
                    OR
                  </span> */}
                </div>
                {/* <div className={cx("d-flex", "flex-column", "landscape-flex")}>
                  <Button
                    className={cx(
                      "w-100",
                      "fw-bold",
                      "position-relative",
                      "mb-3",
                      "landscape-mb-3",
                      "socialBtn",
                      "fb-landscape"
                    )}
                    variant="primary"
                    size="lg"
                    // onClick={() => {
                    //   handleSocialLogin("facebook");
                    // }}
                    // onClick={() => {
                    //   dispatch(socialEmailModalHandler(true));
                    //   dispatch(signupModalHandler(false));
                    // }}
                    onClick={HandleFacebookLogin}
                  >
                    <img
                      className={cx(
                        "position-absolute",
                        "socialFb",
                        "img-fluid"
                      )}
                      src={FacebookIcon}
                      alt="FacebookIcon"
                    />
                    Log in with Facebook
                  </Button>
                  <Button
                    className={cx(
                      "w-100",
                      "fw-bold",
                      "position-relative",
                      "mb-3",
                      "landscape-mb-3",
                      "btn-light-danger",
                      "socialBtn",
                      "google-landscape",
                      "mx-lanscape"
                    )}
                    size="lg"
                    onClick={() => {
                      handleSocialLogin("google");
                    }}
                    // onClick={handleSignIn}
                  >
                    <img
                      className={cx(
                        "position-absolute",
                        "socialG",
                        "img-fluid"
                      )}
                      src={GoogleIcon}
                      alt="GoogleIcon"
                    />
                    Log in with Google
                  </Button>
                  
                </div> */}
                <p
                  className={cx(
                    "text-secondary",
                    "small",
                    "text-center",
                    "my-4",
                    "landscape-my-4"
                  )}
                >
                  By continuing, you agree to our{" "}
                  <b className="text-dark">Terms of Service</b> and{" "}
                  <br className="d-lg-block d-md-block d-sm-none d-none" />
                  <b className="text-dark">Privacy Policy.</b>
                </p>
                <p
                  className={cx(
                    "small",
                    "text-center",
                    "pt-4",
                    "landscape-pt-4",
                    "landscape-mb-0"
                  )}
                >
                  Already have an account?{" "}
                  <span
                    className=""
                    onClick={() => {
                      dispatch(loginModalHandler(true));
                      dispatch(signupModalHandler(false));
                      //   navigate("/login", {
                      //     state: {
                      //       email: location.state?.email || "",
                      //     },
                      //   });
                    }}
                  >
                    <u className="text-danger fw-bold cursor-pointer">Log In</u>
                  </span>
                </p>
              </Form>
            </div>
          </section>
        </Modal>
      ) : signupSteps === 2 ? (
        <RegisterOtp
          backdrop="static"
          email={email}
          show={signupModal}
          onHide={() => dispatch(signupModalHandler(false))}
        />
      ) : signupSteps === 3 ? (
        <Register
          backdrop="static"
          email={email}
          show={signupModal}
          onHide={() => dispatch(signupModalHandler(false))}
        />
      ) : signupSteps === 4 ? (
        <RegisterBirthday
          backdrop="static"
          email={email}
          show={signupModal}
          onHide={() => dispatch(signupModalHandler(false))}
        />
      ) : signupSteps === 5 ? (
        <RegisterLocation
          backdrop="static"
          email={email}
          show={signupModal}
          onHide={() => dispatch(signupModalHandler(false))}
        />
      ) : signupSteps === 6 ? (
        <RegisterAvatar
          backdrop="static"
          email={email}
          show={signupModal}
          onHide={() => dispatch(signupModalHandler(false))}
        />
      ) : signupSteps === 7 ? (
        <RegisterAboutInfo
          backdrop="static"
          email={email}
          show={signupModal}
          onHide={() => dispatch(signupModalHandler(false))}
        />
      ) : signupSteps === 8 ? (
        <EditShow
          backdrop="static"
          email={email}
          show={signupModal}
          onHide={() => dispatch(signupModalHandler(false))}
        />
      ) : signupSteps === 9 ? (
        <RegisterForShow
          backdrop="static"
          email={email}
          show={signupModal}
          onHide={() => dispatch(signupModalHandler(false))}
        />
      ) : (
        <SocialEmail
          backdrop="static"
          email={email}
          show={SocialEmail}
          onHide={() => dispatch(signupModalHandler(false))}
        />
      )}
    </>
  );
}

export default SignUpPopUp;
