/** @format */

import { Navigate } from "react-router-dom";

function LoggedInRoute({ children }) {
  const isLoggedIn = !!JSON.parse(localStorage.getItem("user"))?.username;
  return isLoggedIn ? <Navigate to="/upcoming-meetings" /> : children;
}

export default LoggedInRoute;
