import { APP } from "../Services/chatService";
import { isNull, isUndefined } from "lodash";
import $ from 'jquery';
import sizzle from "sizzle";
import { generateUserJid, getNickNameFromJid } from "./helper";
const { Strophe } = window;
export const parseMessage = (stanza) => {
  let to_jid = stanza.getAttribute("to");
  //const to_resource = Strophe.getResourceFromJid(to_jid);

  const original_stanza = stanza;
  let from_jid = stanza.getAttribute("from") || APP.bare_jid;
  if (isCarbon(stanza)) {
    if (from_jid === APP.bare_jid) {
      const selector = `[xmlns="urn:xmpp:carbons:2"] > forwarded[xmlns="urn:xmpp:forward:0"] > message`;
      stanza = sizzle(selector, stanza).pop();
      to_jid = stanza.getAttribute("to");
      from_jid = stanza.getAttribute("from");
    }
  }

  const is_archived = isArchived(stanza);
  if (is_archived) {
    if (from_jid === APP.bare_jid) {
      const selector = `[xmlns="urn:xmpp:mam:2"] > forwarded[xmlns="urn:xmpp:forward:0"] > message`;
      stanza = sizzle(selector, stanza).pop();
      to_jid = stanza.getAttribute("to");
      from_jid = stanza.getAttribute("from");
    }
  }

  const is_inboxed = isInboxed(stanza);
  if (is_inboxed) {
    if (from_jid === APP.bare_jid) {
      const selector = `[xmlns="erlang-solutions.com:xmpp:inbox:0"] > forwarded[xmlns="urn:xmpp:forward:0"] > message`;
      stanza = sizzle(selector, stanza).pop();
      to_jid = stanza.getAttribute("to");
      from_jid = stanza.getAttribute("from");
    }
  }

  const from_bare_jid = Strophe.getBareJidFromJid(from_jid);
  const is_me = from_bare_jid === APP.bare_jid;
  /* if (is_me && to_jid === null) {
      return new StanzaParseError(
          `Don't know how to handle message stanza without 'to' attribute. ${stanza.outerHTML}`,
          stanza
      );
  } */

  const is_headline = isHeadline(stanza);
  const is_server_message = isServerMessage(stanza);
  let contact, element_jid;
  if (!is_headline && !is_server_message) {
    element_jid = is_me ? Strophe.getBareJidFromJid(to_jid) : from_bare_jid;
    /* contact = await api.contacts.get(element_jid);
    if (contact === undefined && !api.settings.get('allow_non_roster_messaging')) {
        log.error(stanza);
        return new StanzaParseError(
            `Blocking messaging with a JID not in our roster because allow_non_roster_messaging is false.`,
            stanza
        );
    } */
  }

  const delay = sizzle(`delay[xmlns="urn:xmpp:delay"]`, original_stanza).pop();
  const marker = getChatMarker(stanza);
  const now = new Date().toISOString();
  let attrs = Object.assign(
    {
      element_jid,
      is_archived,
      is_headline,
      is_server_message,
      body: stanza.querySelector("body")?.textContent?.trim(),
      chat_state: getChatState(stanza),
      from: Strophe.getBareJidFromJid(stanza.getAttribute("from")),
      is_carbon: isCarbon(original_stanza),
      is_inboxed: is_inboxed,
      is_delayed: !!delay,
      is_chat_state: (isUndefined(getChatState(stanza))) ? false : true,
      is_markable: !!sizzle(`markable[xmlns="urn:xmpp:chat-markers:0"]`, stanza)
        .length,
      is_marker: !!marker,
      marker_id: marker && marker.getAttribute("id"),
      msgid: stanza.getAttribute("id") || original_stanza.getAttribute("id"),
      receipt_id: getReceiptId(stanza),
      received: new Date().toISOString(),
      sender: is_me ? "me" : "them",
      subject: stanza.querySelector("subject")?.textContent,
      time: delay ? delay.getAttribute("stamp") : now,
      to: stanza.getAttribute("to"),
      type: stanza.getAttribute("type"),
      message_type: getMessageType(stanza),
      // company_code: getCompanyCode(stanza),
      thumbUrl: getThumbUrl(stanza)
    },
    getStanzaIDs(stanza, original_stanza)
    /* getMediaAttrs(stanza), */
    //getRetractionAttributes(stanza, original_stanza),
    //getEncryptionAttributes(stanza, _converse)
  );

  if (attrs.is_archived) {
    const from = original_stanza.getAttribute("from");
    if (from && from !== APP.bare_jid) {
      //return new StanzaParseError(`Invalid Stanza: Forged MAM message from ${from}`, stanza);
    }
  }
  attrs = Object.assign(
    {
      message: attrs.body || attrs.error, // TODO: Remove and use body and error attributes instead
      is_valid_receipt_request: isValidReceiptRequest(stanza, attrs),
    },
    attrs
  );

  if (attrs.is_inboxed) {
    const xmlns = "erlang-solutions.com:xmpp:inbox:0";
    let temp = sizzle(`message > result[xmlns="${xmlns}"]`, original_stanza).pop();
    attrs = Object.assign(
      {
        unread_count: temp.getAttribute('unread')
      },
      attrs
    );
  }

  if (attrs.is_marker) {
    attrs = Object.assign(
      {
        marker_type: marker.nodeName
      },
      attrs
    );
  }

  // We prefer to use one of the XEP-0359 unique and stable stanza IDs
  // as the Model id, to avoid duplicates.
  attrs["id"] = attrs["origin_id"] || attrs[`stanza_id ${attrs.from}`]; //|| u.getUniqueId();

  /**
   * *Hook* which allows plugins to add additional parsing
   * @event _converse#parseMessage
   */
  //attrs = await api.hook('parseMessage', stanza, attrs);

  // We call this after the hook, to allow plugins to decrypt encrypted
  // messages, since we need to parse the message text to determine whether
  // there are media urls.
  //return Object.assign(attrs, getMediaURLsMetadata(attrs.is_encrypted ? attrs.plaintext : attrs.body));
  return attrs;
};

export const isCarbon = (stanza) => {
  const xmlns = "urn:xmpp:carbons:2";
  return (
    sizzle(`message > received[xmlns="${xmlns}"]`, stanza).length > 0 ||
    sizzle(`message > sent[xmlns="${xmlns}"]`, stanza).length > 0
  );
};

export const isArchived = (original_stanza) => {
  let is_arc = !!sizzle(
    `[xmlns="urn:xmpp:mam:2"] > forwarded[xmlns="urn:xmpp:forward:0"] > message`,
    original_stanza
  ).pop();
  return is_arc;
};

export const isHeadline = (stanza) => {
  return stanza.getAttribute("type") === "headline";
};

export const isInboxed = (stanza) => {
  const xmlns = "erlang-solutions.com:xmpp:inbox:0";
  return sizzle(`message > result[xmlns="${xmlns}"]`, stanza).length > 0;
};
export const isServerMessage = (stanza) => {
  if (sizzle(`mentions[xmlns="urn:xmpp:mmn:0"]`, stanza).pop()) {
    return false;
  }
  const from_jid = stanza.getAttribute("from");
  if (
    stanza.getAttribute("type") !== "error" &&
    from_jid &&
    !from_jid.includes("@")
  ) {
    // Some servers (e.g. Prosody) don't set the stanza
    // type to "headline" when sending server messages.
    // For now we check if an @ signal is included, and if not,
    // we assume it's a headline stanza.
    return true;
  }
  return false;
};

export const getStanzaIDs = (stanza, original_stanza) => {
  const attrs = {};
  // Store generic stanza ids
  const sids = sizzle(`stanza-id[xmlns="urn:xmpp:sid:0"]`, stanza);
  const sid_attrs = sids.reduce((acc, s) => {
    acc[`stanza_id ${s.getAttribute("by")}`] = s.getAttribute("id");
    return acc;
  }, {});
  Object.assign(attrs, sid_attrs);

  // Store the archive id
  const result = sizzle(
    `message > result[xmlns="urn:xmpp:mam:2"]`,
    original_stanza
  ).pop();
  if (result) {
    const by_jid = original_stanza.getAttribute("from") || APP.bare_jid;
    attrs[`stanza_id ${by_jid}`] = result.getAttribute("id");
  }

  // Store the origin id
  const origin_id = sizzle(`origin-id[xmlns="urn:xmpp:sid:0"]`, stanza).pop();
  if (origin_id) {
    attrs["origin_id"] = origin_id.getAttribute("id");
  }
  return attrs;
};

export const getChatMarker = (stanza) => {
  // If we receive more than one marker (which shouldn't happen), we take
  // the highest level of acknowledgement.
  return sizzle(
    `
      acknowledged[xmlns="urn:xmpp:chat-markers:0"],
      displayed[xmlns="urn:xmpp:chat-markers:0"],
      received[xmlns="urn:xmpp:chat-markers:0"]`,
    stanza
  ).pop();
};

export const getChatState = (stanza) => {
  return sizzle(
    `
      composing[xmlns="http://jabber.org/protocol/chatstates"],
      paused[xmlns="http://jabber.org/protocol/chatstates"],
      inactive[xmlns="http://jabber.org/protocol/chatstates"],
      active[xmlns="http://jabber.org/protocol/chatstates"],
      gone[xmlns="http://jabber.org/protocol/chatstates"]`,
    stanza
  ).pop()?.nodeName;
};

export const getReceiptId = (stanza) => {
  const receipt = sizzle(`received[xmlns="urn:xmpp:receipts"]`, stanza).pop();
  return receipt?.getAttribute("id");
};

export const isValidReceiptRequest = (stanza, attrs) => {
  return (
    attrs.sender !== "me" &&
    !attrs.is_carbon &&
    !attrs.is_archived &&
    sizzle(`request[xmlns="urn:xmpp:receipts"]`, stanza).length
  );
};

export const getMessageType = (stanza) => {
  // console.log("getMessageType stanza : ",stanza)
  const data = sizzle(
    `message_type[xmlns="jabber:message:type"]`,
    stanza
  ).pop();
  // console.log("getMessageType stanza data : ",data)
  // console.log("getMessageType stanza data textContent: ",data?.textContent)
  // if (isUndefined(data)) {
  //   data = sizzle(
  //   `message_type[xmlns="jabber:message:type"]`,
  //   stanza
  // ).pop();
  // }
  if (!isUndefined(data)) {
    return data?.textContent;
  }
  return undefined;
};

// export const getCompanyCode = (stanza) => {
//   const data = sizzle(
//     `company_code[xmlns="jabber:message:companycode"]`,
//     stanza
//   ).pop();
//   if (!isUndefined(data)) {
//     return data.textContent;
//   }
// };

export const getThumbUrl = (stanza) => {
  let url = "";
  const data = sizzle(`thumb[xmlns="jabber:thumb"]`, stanza).pop();
  if (!isUndefined(data)) {
    url = data.getAttribute('thumbUrl');
  }
  return url;
}


export function parseMUCMessage(stanza) {
  try {
    const selector = `[xmlns="urn:xmpp:mam:2"] > forwarded[xmlns="urn:xmpp:forward:0"] > message`;
    const original_stanza = stanza;
    stanza = sizzle(selector, stanza).pop() || stanza;

    const is_inboxed = isInboxed(stanza);
    if (is_inboxed) {
      const selector = `[xmlns="erlang-solutions.com:xmpp:inbox:0"] > forwarded[xmlns="urn:xmpp:forward:0"] > message`;
      stanza = sizzle(selector, stanza).pop();
    }

    /* if (sizzle(`message > forwarded[xmlns="urn:xmpp:forward:0"]`, stanza).length) {
        return new StanzaParseError(
            `Invalid Stanza: Forged MAM groupchat message from ${stanza.getAttribute('from')}`,
            stanza
        );
    } */
    const delay = sizzle(`delay[xmlns="urn:xmpp:delay"]`, original_stanza).pop();
    const spotlight = sizzle(`spotlight[xmlns="urn:xmpp:receipts"]`, original_stanza).pop();
    const user_detail = sizzle(`user_detail[xmlns="urn:xmpp:receipts"]`, original_stanza).pop();
    let message_time = sizzle(`message_time`, original_stanza).pop();
    // console.log('message_time ', message_time)
    if (message_time) {
      message_time = original_stanza.querySelector("message_time")?.textContent?.trim()
      // message_time = timeSince(new Date(time_stamp_msg))
      // console.log('message_time converted ', message_time)
    }
    // console.log('user_detail sizzle', user_detail)
    let user_detail_data = []
    if (user_detail) {
      // console.log('user_detail sizzle document', user_detail.getAttributeNames())
      user_detail.getAttributeNames().forEach(function (val, index) {
        user_detail_data[val] = user_detail.getAttribute(val)
      });

    }
    // console.log('user_detail sizzle user_detail_data : ', user_detail_data)
    const from = stanza.getAttribute('from');
    const from_muc = Strophe.getBareJidFromJid(from);
    let nick = Strophe.getResourceFromJid(from);
    if (!isNull(nick)) {
      nick = getNickNameFromJid(Strophe.unescapeNode(nick));
    }
    const marker = getChatMarker(stanza);
    const now = new Date().toISOString();
    // console.log('spotlight sizzle', spotlight)
    let spotlightValue = 0;
    if (spotlight) {
      spotlightValue = 1
    }
    let user_details_data = [];
    if (user_detail) {
      user_details_data = user_detail
    }
    let attrs = Object.assign(
      {
        ...user_detail_data,
        message_time: message_time,
        from,
        from_muc,
        nick,
        'element_jid': from_muc,
        'is_forwarded': !!stanza.querySelector('forwarded'),
        //'activities': getMEPActivities(stanza),
        'body': stanza.querySelector('body')?.textContent?.trim(),
        'chat_state': getChatState(stanza),
        'is_archived': isArchived(original_stanza),
        'is_carbon': isCarbon(original_stanza),
        'is_delayed': !!delay,
        'is_headline': isHeadline(stanza),
        'is_markable': !!sizzle(`markable[xmlns="urn:xmpp:chat-markers:0"]`, stanza).length,
        'is_marker': !!marker,
        //'is_unstyled': !!sizzle(`unstyled[xmlns="${Strophe.NS.STYLING}"]`, stanza).length,
        'marker_id': marker && marker.getAttribute('id'),
        'msgid': stanza.getAttribute('id') || original_stanza.getAttribute('id'),
        //'occupant_id': getOccupantID(stanza, chatbox),
        'receipt_id': getReceiptId(stanza),
        'received': new Date().toISOString(),
        //'references': getReferences(stanza),
        'subject': stanza.querySelector('subject')?.textContent,
        'thread': stanza.querySelector('thread')?.textContent,
        'time': delay ? delay.getAttribute("stamp") : now,
        'to': stanza.getAttribute('to'),
        'type': stanza.getAttribute('type'),
        'message_type': getMessageType(stanza),
        // 'company_code': getCompanyCode(stanza),
        'thumbUrl': getThumbUrl(stanza),
        'spotlight': spotlightValue
      },

      getStanzaIDs(stanza, original_stanza),

    );
    const from_real_jid = attrs.is_archived && getJIDFromMUCUserData(stanza, attrs) ||
      generateUserJid(attrs.nick)

    attrs = Object.assign({
      from_real_jid,
      'is_valid_receipt_request': isValidReceiptRequest(stanza, attrs),
      'message': attrs.body || attrs.error, // TODO: Remove and use body and error attributes instead
      'sender': attrs.nick === getNickNameFromJid(APP.bare_jid) ? 'me' : 'them',
    }, attrs);

    attrs['id'] = attrs['origin_id'] || attrs[`stanza_id ${attrs.from_muc || attrs.from}`]

    return attrs
  } catch (e) {
    console.log(e);
  }

}

function getJIDFromMUCUserData(stanza) {
  const item = sizzle(`x[xmlns="${Strophe.NS.MUC_USER}"] item`, stanza).pop();
  return item?.getAttribute('jid');
}