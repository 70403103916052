import { io } from "socket.io-client";

export class Signaling {
    static instance;

    constructor() {
        this.socket = io(process.env.REACT_APP_MEDIASOUP_SOCKET_URL);
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new Signaling();
        }
        return this.instance
    }

    emit(type, data = {}) {
        this.socket.emit(type, data);
    }

    on(event, callback) {
        this.socket.on(event, callback);
    }

    off(event, callback) {
        this.socket.off(event, callback);
    }

    request(type, data = {}) {
      return new Promise((resolve, reject) => {
        this.socket.emit(type, data, (data) => {
          if (data.error) {
            reject(data.error)
          } else {
            resolve(data)
          }
        })
      })
    }

    static destroy() {
        this.getInstance().socket.destroy();
        this.instance = null;
    }

}