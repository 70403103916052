import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import css from "./addViaContact.module.css";
import classNames from "classnames/bind";
import CloseIcon from "./../../images/close.svg";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { isLetter } from "../../../../_helpers";
import { ClipLoader } from "react-spinners";
import { userService } from "../../../../_services";
import { validateImage } from "image-validator";
import _ from "lodash";

const AddViaContact = ({
  show,
  setAddViaContactsModal,
  contactsList,
  setContactsList,
  token,
}) => {
  const cx = classNames.bind(css);
  const { enqueueSnackbar } = useSnackbar();
  const listInnerRef = useRef();
  const totalFetchedContactsList = useRef([]);

  const [fetchcontactsList, setFetchContactsList] = useState([]);
  const [selectedNumberArray, setSelectedNumberArray] = useState([
    ...contactsList,
  ]);
  const [searchInput, setSearchInput] = useState("");
  const [currentLen, setCurrentLen] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [newContacts, setnewContacts] = useState([]);
  const [contacts, setcontacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [maxItemsToShow, setMaxItemsToShow] = useState(10);

  let props = {
    show,
  };

  useEffect(() => {
    setLoading(true);
    if (token)
      userService
        .fetchContacts(token)
        .then((data) => {
          if (data) {
            let checkImageValidationUrlPromises = data.data.map((ele) => {
              return new Promise(async (resolve, reject) => {
                let isContactPicValid = false;
                try {
                  isContactPicValid = await imgUrlValidation(ele.pic);
                  if (isContactPicValid) {
                    resolve({ ...ele, isContactPicValid });
                  } else {
                    resolve({ ...ele, isContactPicValid });
                  }
                } catch (error) {
                  resolve({ ...ele, isContactPicValid });
                }
              });
            });
            Promise.all(checkImageValidationUrlPromises).then((result) => {
              totalFetchedContactsList.current = result;
              setFetchContactsList(result);
              setLoading(false);
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            variant: "error",
          });
        })
        .finally();
  }, [show]);

  useEffect(() => {
    let allCheckBoxes = document.querySelectorAll('input[id^="contact_"]');
    for (var i = 0; i < allCheckBoxes.length; i++) {
      allCheckBoxes[i].checked = false;
    }
    for (var i = 0; i < selectedNumberArray.length; i++) {
      let ele = document.getElementById(`contact_${selectedNumberArray[i].id}`);
      if (ele) {
        ele.checked = true;
      }
    }
  }, [fetchcontactsList]);

  useEffect(
    () => console.log("maxItemsToShow", maxItemsToShow),
    [maxItemsToShow]
  );

  const handleSearchInput = (e) => {
    let lowerCaseValue = e.target.value.toLowerCase();
    if (!_.isEmpty(lowerCaseValue)) {
      let filteredFetchedContactsList = totalFetchedContactsList.current.filter(
        (ele) => {
          return ele.full_name.toLowerCase().includes(lowerCaseValue);
        }
      );
      setFetchContactsList(filteredFetchedContactsList);
    } else {
      setFetchContactsList(totalFetchedContactsList.current);
    }
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      let selectedContact = totalFetchedContactsList.current.find((ele) => {
        return ele.friend_id === e.target.name;
      });
      if (!_.isUndefined(selectedContact)) {
        setSelectedNumberArray((existingSelectedNumberArray) => {
          return [
            ...existingSelectedNumberArray,
            {
              id: selectedContact.friend_id,
              mobile: selectedContact.mobile,
              name: selectedContact.full_name,
              pic: selectedContact.pic,
            },
          ];
        });
      }
    } else {
      let arr = selectedNumberArray.filter((ele) => ele.id !== e.target.name);
      setSelectedNumberArray(arr);
    }
  };

  const handleClick = () => {
    if (selectedNumberArray.length > 0) {
      setContactsList([...selectedNumberArray]);
      setAddViaContactsModal(false);
    } else {
      enqueueSnackbar(
        " Click Add without adding any contact, try Clicking close icon ",
        {
          variant: "error",
        }
      );
      setContactsList([...selectedNumberArray]);
      setAddViaContactsModal(false);
    }
  };

  const imgUrlValidation = async (url) => {
    const isValidImage = await validateImage(url);
    return isValidImage;
  };
  const filteredContacts = fetchcontactsList.filter((c) => {
    if (searchInput === "") {
      return c;
    } else {
      return c.full_name.toLowerCase().includes(searchInput);
    }
  });

  const onScroll = () => {
    if (!listInnerRef?.current) return;
    const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
    if (scrollTop + clientHeight === scrollHeight) {
      // TO SOMETHING HERE
      console.log("Reached bottom");
      setMaxItemsToShow((current) => current + 10);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="container">
        <div className=" d-flex align-items-center justify-content-center">
          <div
            className={cx(
              "outerBox",
              "bg-white",
              "p-4",
              "position-relative",
              "rounded"
            )}
          >
            <div className={cx("landscapeMode")}>
              <div className="d-flex ml-2 mb-4 landscape-mb-4">
                <h4 className="">Add via mobile </h4>
                <div
                  className={cx(
                    "position-absolute",
                    "closeIcon",
                    "cursor-pointer"
                  )}
                >
                  <img
                    className="img-fluid"
                    src={CloseIcon}
                    onClick={() => setAddViaContactsModal(false)}
                  />
                </div>
              </div>
              <i className="fa fa-user icon"></i>
              <input
                type="text"
                className={`${css.modalInput} col-12`}
                placeholder="Search"
                onChange={handleSearchInput}
              />

              <div
                className={`col-12 mt-3 ${css.numberList}`}
                onScroll={() => onScroll()}
                ref={listInnerRef}
              >
                <p className="text-center">
                  {loading && (
                    <ClipLoader
                      color="#509EF1"
                      cssOverride={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        "z-index": 10,
                      }}
                      loading
                    />
                  )}
                </p>
                {fetchcontactsList.map((contact, index) => {
                  return (
                    <div className={`row ${css.contactList}`} key={index}>
                      {contact.pic.length > 0 ? (
                        <>
                          {contact.isContactPicValid ? (
                            <img
                              src={contact.pic}
                              className={`col-3   text-right p-0 ${css.contactImage}`}
                              width="50"
                              height="50"
                              alt="bad link"
                            />
                          ) : (
                            <Icon
                              icon="bi:person-circle"
                              color="#5ad2d9"
                              className="col-2  text-start p-0"
                              width="50"
                              height="50"
                            />
                          )}
                        </>
                      ) : isLetter(contact.full_name[0]) ? (
                        <Icon
                          icon={`emojione:letter-${contact.full_name[0].toLowerCase()}`}
                          className="col-2  text-start p-0"
                          width="50"
                          height="50"
                        />
                      ) : (
                        <Icon
                          icon="bi:person-circle"
                          color="#5ad2d9"
                          className="col-2  text-start p-0"
                          width="50"
                          height="50"
                        />
                      )}
                      <label className=" text-white col-6 text-left m-0 p-0">
                        {contact.full_name}
                        <br />
                        <span style={{ color: "#898989" }}>
                          {contact.mobile}
                        </span>
                      </label>
                      <input
                        className={`col-3 ${css.modalCheck} text-left`}
                        type="checkbox"
                        id={`contact_${contact.friend_id}`}
                        name={contact.friend_id}
                        onChange={handleChange}
                      />
                    </div>
                  );
                })}
              </div>
              <button
                type="button"
                className={`btn text-white bg-primary me-2 px-5 py-2 col-12 rounded ${css.contactInvite}`}
                onClick={handleClick}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddViaContact;
