/** @format */

import React, { useRef, useState } from "react";
import classNames from "classnames/bind";
import MailIcon from "../../../images/emailIcon.svg";
import CloseIcon from "../../../images/cross.svg";
import styles from "./edit.module.css";
import { Form, InputGroup, FormControl, Button, Modal } from "react-bootstrap";
import Global from "../../../config/Global";
import { useSnackbar } from "notistack";
import logo from "../../../images/logo.svg";
import PasswordForm from "../PasswordForm/PasswordForm";
import {
  editShowNavigationHandler,
  editShowRedirectHandler,
  loginModalHandler,
  loginStepsHandler,
  resetPasswordModalHandler,
  signupModalHandler,
  signupStepsHandler,
} from "../../../store/slices/user";
import { useDispatch, useSelector } from "react-redux";

const cx = classNames.bind(styles);

const EditShow = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { editShowRedirect } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  //Refs
  const handleSubmit = () => {
    dispatch(editShowNavigationHandler(true));
    dispatch(signupModalHandler(false));
    dispatch(signupStepsHandler(1));
    dispatch(loginStepsHandler(1));
    dispatch(loginModalHandler(false));
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="container">
        <div className=" d-flex align-items-center justify-content-center text-center">
          <div
            className={cx("outerBox", "bg-white", "p-4", "position-relative")}
          >
            <div className={cx("landscapeMode")}>
              <div className="d-flex align-items-center justify-content-center mb-2 landscape-mb-4">
                <h4>Setup your Program</h4>
                <div
                  className={cx("position-absolute", "closeIconRegister")}
                  onClick={() => {
                    dispatch(signupModalHandler(false));
                    dispatch(signupStepsHandler(1));
                    dispatch(loginModalHandler(false));
                    dispatch(loginStepsHandler(1));
                  }}
                >
                  <img className="img-fluid" src={CloseIcon} alt="cross" />
                </div>
              </div>
              {/* <h4 className="mb-4">Setup your Program</h4> */}
              <p>{editShowRedirect.title}</p>
              <Button
                className="w-100 btn btn-danger btn-sm fw-bold"
                variant="contained"
                size="lg"
                type="submit"
                onClick={handleSubmit}
              >
                Setup Now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default EditShow;
