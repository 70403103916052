/** @format */

import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  attendScheduleShowHandler,
  login,
  registerPayloadData,
  signupModalHandler,
  signupStepsHandler,
} from "../../../store/slices/user";
import PhoneBlackIcon from "../../Admin/images/phoneblack.svg";
import classNames from "classnames/bind";
import styles from "./register.module.css";
import UserIcon from "../../../images/userIcon.svg";
import CloseIcon from "../../../images/cross.svg";
import emailBIcon from "../../../images/emailBlackIcon.svg";
import eyeBIcon from "../../../images/eyeBlackIcon.svg";
import HidePassword from "../../../images/HidePassword.svg";
import { Form, InputGroup, FormControl, Button, Modal } from "react-bootstrap";
import { useSnackbar } from "notistack";
import _ from "lodash";

import { userService } from "../../../_services";
import { showService } from "../../../_services/admin";
import {
  getFormattedDateTime,
  getTimeZone,
  getTimeZoneAbbreviation,
} from "../../../_helpers";

const cx = classNames.bind(styles);

const Register = (props) => {
  let userDetails = JSON.parse(localStorage.getItem("userDetail"));
  // const location = useLocation();
  const { attendScheduleShow } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();

  const changeButtonColor = () => {
    if (
      firstNameRef.current.value !== "" &&
      lastNameRef.current.value !== "" &&
      emailRef.current.value !== "" &&
      passwordRef.current.value !== ""
    ) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  };

  const [filled, setFilled] = useState(false);

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: props.email,
    password: "",
    country_code: null,
    phone_number: null,
    timezone: getTimeZone(),
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const [submittingBasicInfo, setSubmittingBasicInfo] = useState(false);

  const togglePasswordVisibility = () => setPasswordShown(!passwordShown);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    console.log("values :>> ", values);
  };

  useEffect(() => {
    setValues({
      ...values,
      first_name: userDetails?.first_name,
      last_name: userDetails?.last_name,
      email: userDetails?.email,
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmittingBasicInfo(true);
    userService
      .register(values)
      .then((data) => {
        setSubmittingBasicInfo(false);
        dispatch(login(data));
        let user = JSON.parse(localStorage.getItem("user"));
        dispatch(signupStepsHandler(4));
        dispatch(registerPayloadData(values));
        if (!_.isEmpty(attendScheduleShow)) {
          showService
            .addOrRemoveAttendee({
              show_id: attendScheduleShow.show_id,
              user_id: user.id,
              show_schedule_id: attendScheduleShow.id,
              scheduled_time:
                attendScheduleShow.scheduled_time +
                " " +
                getTimeZoneAbbreviation(),
            })
            .then((data) => {
              dispatch(
                attendScheduleShowHandler({
                  ...attendScheduleShow,
                  is_attendee: 1,
                })
              );
            });
        }
      })
      .catch((error) => {
        setSubmittingBasicInfo(false);
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const btnStyle = filled
    ? {
        backgroundColor: "#E50914",
        color: "white",
      }
    : {
        backgroundColor: "#E3E3E3",
        color: "black",
      };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="container">
          <div className="d-flex align-items-center justify-content-center">
            <div
              className={cx(
                "outerBoxRegister",
                "bg-white",
                "px-5",
                "position-relative",
                "py-lg-5 py-md-4 py-sm-0 py-0"
              )}
            >
              <div
                onClick={() => {
                  dispatch(signupStepsHandler(1));
                  props.onHide();
                }}
                className="position-absolute closeIconRegister"
              >
                <img className="img-fluid" src={CloseIcon} alt="cross" />
              </div>
              <div className={"registerLandscape"}>
                <div className="d-flex align-items-center justify-content-center mb-4 landscape-mb-4 mt-5 landscape-mt-5">
                  <h2 className="text-center fw-bold">Sign Up</h2>
                </div>
                <Form onSubmit={handleSubmit}>
                  <h1 className="small fw-bold mb-3 landscape-mb-3">
                    Basic Info
                  </h1>
                  <div className="row gx-3">
                    <div className="col-sm-6">
                      <InputGroup
                        size="lg"
                        className="mb-3 border border-dark rounded"
                      >
                        <FormControl
                          placeholder="First Name"
                          aria-label="First Name"
                          aria-describedby="First Name"
                          className="border-white"
                          type="text"
                          name="first_name"
                          ref={firstNameRef}
                          value={values.first_name}
                          onChange={handleChange}
                          onKeyUp={changeButtonColor}
                        />
                        <InputGroup.Text
                          id="First Name"
                          className="fs-6 bg-white border-white"
                        >
                          <img
                            className="img-fluid"
                            src={UserIcon}
                            alt="user"
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="col-sm-6">
                      <InputGroup
                        size="lg"
                        className="mb-3 border border-dark rounded"
                      >
                        <FormControl
                          placeholder="Last Name"
                          aria-label="Last Name"
                          ref={lastNameRef}
                          aria-describedby="Last Name"
                          className="border-white"
                          type="text"
                          name="last_name"
                          value={values.last_name}
                          onChange={handleChange}
                          onKeyUp={changeButtonColor}
                        />
                        <InputGroup.Text
                          id="Last Name"
                          className="fs-6 bg-white border-white"
                        >
                          <img
                            className="img-fluid"
                            src={UserIcon}
                            alt="user"
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="col-sm-6">
                      <InputGroup
                        size="lg"
                        className="mb-3 border border-dark rounded"
                      >
                        <FormControl
                          placeholder="Email"
                          aria-label="Email"
                          ref={emailRef}
                          aria-describedby="Email"
                          className="border-white"
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onKeyUp={changeButtonColor}
                        />
                        <InputGroup.Text
                          id="Email"
                          className="fs-6 bg-white border-white"
                        >
                          <img
                            className="img-fluid"
                            src={emailBIcon}
                            alt="mail"
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="col-sm-6">
                      <InputGroup
                        size="lg"
                        className="mb-3 border border-dark rounded"
                      >
                        <FormControl
                          id="passwordField"
                          placeholder="Password"
                          aria-label="Password"
                          ref={passwordRef}
                          aria-describedby="Password"
                          className="border-white"
                          type={passwordShown ? "text" : "password"}
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onKeyUp={changeButtonColor}
                        />
                        <InputGroup.Text
                          id="togglePassword"
                          className="fs-6 bg-white border-white"
                          onClick={togglePasswordVisibility}
                        >
                          <img
                            src={passwordShown ? eyeBIcon : HidePassword}
                            alt="hidden"
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="col-sm-2">
                      <InputGroup
                        size="lg"
                        className="mb-3 border border-dark rounded"
                      >
                        <FormControl
                          id="country_code"
                          placeholder="+1"
                          aria-label="countrycode"
                          // ref={countryCodeRef}
                          aria-describedby="countrycode"
                          className="border-white"
                          type="text"
                          name="country_code"
                          value={values.country_code}
                          onChange={handleChange}
                          onKeyUp={changeButtonColor}
                        />
                      </InputGroup>
                    </div>
                    <div className="col-sm-4">
                      <InputGroup
                        size="lg"
                        className="mb-3 border border-dark rounded"
                      >
                        <FormControl
                          id="phone_number"
                          placeholder="Phone Number"
                          aria-label="phonenumber"
                          // ref={phoneNumberRef}
                          aria-describedby="phonenumber"
                          className="border-white"
                          type="text"
                          name="phone_number"
                          value={values.phone_number}
                          onChange={handleChange}
                          onKeyUp={changeButtonColor}
                        />
                        <InputGroup.Text
                          id="Email"
                          className="fs-6 bg-white border-white"
                        >
                          <img
                            className="img-fluid"
                            src={PhoneBlackIcon}
                            width="19px"
                            alt="phone"
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                  </div>
                  <div className="col-sm-3 m-auto mb-5 landscape-mb-5">
                    <Button
                      className="mt-4 fw-bold socialBtn w-100"
                      variant="muted"
                      size="lg"
                      type="submit"
                      style={btnStyle}
                      disabled={!filled || submittingBasicInfo}
                    >
                      {!submittingBasicInfo ? "Next" : "Loading..."}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default Register;
