import React from "react";
import css from "./pageNumber.module.css";
const PageNumber = ({ page, currentPage, moveToPage }) => {
  let active = `${css.activeButton}`;
  return (
    <li className="page-item" key={page}>
      <a
        className={`page-link   fs-12 cursor-pointer ${
          currentPage === page && active
        } ${css.pageButton}`}
        onClick={() => moveToPage(page)}
      >
        {page}
      </a>
    </li>
  );
};

export default PageNumber;
