/** @format */

import { createSlice } from "@reduxjs/toolkit";
// Slice
const slice = createSlice({
  name: "precall",
  initialState: {
    mediaDevices: [],
    localVideoInputs: [],
    localMicInputs: [],
    speakerOutputs: [],
    localVideoStream: null,
    localSpeakerVideoStream: null,
    localMicStream: null,
    localScreenShareStream: null,
    localDisplayName: "",
    cameraDeviceId: "-",
    isPrecall: false,
    roomId: "",
    roomPassword: "",
    remoteVideoStreams: [],
    remoteScreenShareStream: null,
    showHosts: null,
  },
  reducers: {
    setMediaDevices: (state, action) => {
      state.mediaDevices = action.payload;
    },
    setLocalVideoInputs: (state, action) => {
      state.localVideoInputs = action.payload;
    },

    setLocalMicInputs: (state, action) => {
      state.localMicInputs = action.payload;
    },

    setSpeakerOutputs: (state, action) => {
      state.speakerOutputs = action.payload;
    },

    setLocalVideoStream: (state, action) => {
      state.localVideoStream = action.payload;
    },

    setLocalSpeakerVideoStream: (state, action) => {
      state.localSpeakerVideoStream = action.payload;
    },

    setLocalMicStream: (state, action) => {
      state.localMicStream = action.payload;
    },

    setLocalScreenShareStream: (state, action) => {
      state.localScreenShareStream = action.payload;
    },

    setLocalDisplayName: (state, action) => {
      state.localDisplayName = action.payload;
    },

    setCameraId: (state, action) => {
      state.cameraDeviceId = action.payload;
    },

    setIsPrecall: (state, action) => {
      state.isPrecall = action.payload;
    },

    setPrecallDefault: (state, action) => {
      state = action.payload;
    },

    setRemoteVideoStreams: (state, action) => {
      state.remoteVideoStreams = action.payload;
    },

    setRemoteScreenShareStream: (state, action) => {
      state.remoteScreenShareStream = action.payload;
    },

    setShowHosts: (state, action) => {
      state.showHosts = action.payload;
    },
  },
});
export default slice.reducer;
// Actions
const {
  setMediaDevices,
  setLocalVideoInputs,
  setLocalMicInputs,
  setSpeakerOutputs,
  setLocalVideoStream,
  setLocalSpeakerVideoStream,
  setLocalMicStream,
  setLocalScreenShareStream,
  setLocalDisplayName,
  setCameraId,
  setIsPrecall,
  setPrecallDefault,
  setRemoteVideoStreams,
  setRemoteScreenShareStream,
  setShowHosts,
} = slice.actions;

export const preCallActions = {
  setMediaDevices,
  setLocalVideoInputs,
  setLocalMicInputs,
  setSpeakerOutputs,
  setLocalVideoStream,
  setLocalSpeakerVideoStream,
  setLocalMicStream,
  setLocalScreenShareStream,
  setLocalDisplayName,
  setCameraId,
  setIsPrecall,
  setPrecallDefault,
  setRemoteVideoStreams,
  setRemoteScreenShareStream,
  setShowHosts,
};
