/** @format */

import classNames from "classnames/bind";
import CloseIcon from "../../../images/cross.svg";
import styles from "./registerforshow.module.css";
import { Modal } from "react-bootstrap";

import { useSnackbar } from "notistack";
import {
  attendScheduleShowHandler,
  loginModalHandler,
  loginStepsHandler,
  signupModalHandler,
  signupStepsHandler,
} from "../../../store/slices/user";
import { useDispatch, useSelector } from "react-redux";

const cx = classNames.bind(styles);

const RegisterForShow = (props) => {
  const { editShowRedirect, attendScheduleShow } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  //Refs

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="container">
        <div className=" d-flex align-items-center justify-content-center text-center">
          <div
            className={cx("outerBox", "bg-white", "p-5", "position-relative")}
          >
            <div className={cx("landscapeMode")}>
              <div className="d-flex align-items-center justify-content-center mb-2 landscape-mb-4">
                <h4 className="text-danger">Congratulations</h4>
                <div
                  className={cx("position-absolute", "closeIconRegister")}
                  onClick={() => {
                    dispatch(signupModalHandler(false));
                    dispatch(signupStepsHandler(1));
                    dispatch(loginModalHandler(false));
                    dispatch(loginStepsHandler(1));
                    dispatch(attendScheduleShowHandler({}));
                  }}
                >
                  <img
                    className="img-fluid"
                    width="20px"
                    src={CloseIcon}
                    alt="cross"
                  />
                </div>
              </div>
              {/* <h4 className="mb-4">Setup your Program</h4> */}
              <p>
                You are registered for <b>{attendScheduleShow.show_title}</b> on{" "}
                <b>{attendScheduleShow.scheduled_time}</b>.{" "}
              </p>
              <p>
                A confirmation was sent to your email with instructions on
                connecting live via video conference to your host and
                classmates. Enjoy!
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default RegisterForShow;
