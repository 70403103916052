import { Modal } from "react-bootstrap";
import classNames from "classnames/bind";
import styles from "./resetPassword.module.css";
import { Form, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { userService } from "../../../../_services";
import EnterOtp from "./EnterOtp/EnterOtp";

const cx = classNames.bind(styles);

function ResetPassword(props) {
    const { enqueueSnackbar } = useSnackbar();

    const [values, setValues] = useState({
        email: "",

    });
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [isFormFilled, setIsFormFilled] = useState(false);
    const [enterOtp, setEnterOtp] = useState(false)
    useEffect(() => {
        if (values.email === "") {
            setIsFormFilled(false);
        } else {
            setIsFormFilled(true);
        }
    }, [values]);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleContinue = (e) => {
        e.preventDefault();
        //setIsFormSubmitting(true);
        setEnterOtp(true)
        const regex = {
            email: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
        };
        if (!regex.email.test(values.email)) {
            setIsFormSubmitting(false);
            return enqueueSnackbar("Enter a valid email", {
                variant: "error",
            });
        }
        const dataToPost = {
            email: values.email,

        };
        userService
            .resetPasswordSendOTP(values)
            .then((data) => {
                setIsFormSubmitting(false);
                if (data.success) {
                    setEnterOtp(true);
                    enqueueSnackbar("Email Succesfully Sent ", {
                        variant: "success",
                    });
                } else {
                    enqueueSnackbar(data.message, {
                        variant: "error",
                    });
                }
            })
            .catch((error) => {
                enqueueSnackbar(error, {
                    variant: "error",
                });
            });
    };

    const btnStyle =
        isFormFilled && !isFormSubmitting
            ? {
                backgroundColor: "#E50914",
                color: "white",
            }
            : {
                backgroundColor: "#E3E3E3",
                color: "black",
            };

    return (
        <><Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <section className="container d-flex align-items-center justify-content-center">
                <div
                    className={cx(
                        "outerBox",

                        "p-5 rounded",
                        "position-relative"
                    )}
                >
                    <div className="d-flex align-items-center justify-content-between mb-4">
                        <h6>Reset Password</h6>
                        <div onClick={props.onHide} className="mobileClose inviteCross">
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 26 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M26 1.53183L24.4682 0L13 11.4682L1.53183 0L0 1.53183L11.4682 13L0 24.4682L1.53183 26L13 14.5318L24.4682 26L26 24.4682L14.5318 13L26 1.53183Z"
                                    fill="rgba(255,255,255, 1)"
                                />
                            </svg>
                        </div>
                    </div>
                    <Form onSubmit={handleContinue}>
                        <div className={cx("landscapeMode")}>
                            <div className="mb-2">
                                <input
                                    type="text"
                                    className="form-control customPadding bg-muted "
                                    placeholder="Enter Your Email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                />
                            </div>

                            <Button
                                className={`w-100  ${styles.continueButton}`}
                                type="submit"
                                disabled={isFormSubmitting || !isFormFilled}
                                style={btnStyle}
                                variant="contained"
                            >
                                {isFormSubmitting ? `Loading...` : `Continue`}
                            </Button>
                        </div>
                    </Form>
                </div>
            </section>

        </Modal>
            <EnterOtp
                backdrop="static"
                show={enterOtp}
                onHide={() => setEnterOtp(false)}
                onHide2={() => props.onHide(false)}
                email={values.email} />
        </>);


}

export default ResetPassword;
