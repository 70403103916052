/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./createSeries.module.css";
import adminStyles from "../admin.module.css";
import Header from "../header/header";
import backArrow from "../images/back_arrow.svg";
import filmIcon from "../images/film.svg";
import closeIcon from "../images/close.svg";
import screenShot from "../images/screenshot.svg";
import trashIcon from "../images/trash.svg";
import hostImg from "../images/larry_tom.svg";

import downImg from "../images/download.jpeg";
import { useSnackbar } from "notistack";
import ReactTagInput from "@pathofdev/react-tag-input";

import { seriesService, commonService } from "../../../_services/admin";

const cx = classNames.bind(styles);
const acx = classNames.bind(adminStyles);

const CreateSeries = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const timerRef = useRef(null);
  const tagInputTimerRef = useRef(null);

  const [values, setValues] = useState({
    show_id: "",
    title: "",
    url: "",
    category_ids: [],
    trailer_url: "",
    vod_video_ids: [],
    description: "",
    hero_image_id: null,
    thumb_image_id: null,
    primary_host_id: null,
  });
  // trailer
  const [trailerUpload, setTrailerUpload] = useState({
    id: null,
    title: "",
    size: "",
  });

  // upload images
  const [uploadedImages, setUploadedImages] = useState([]);
  const [searchedHosts, setSearchedHosts] = useState([]);
  const [invitedHosts, setInvitedHosts] = useState([]);
  const [saveOnBlur, setSaveOnBlur] = useState(true);
  const [programTopic, setProgramTopic] = useState([]);
  const [tags, setTags] = React.useState([]);

  useEffect(() => {
    let tagInputChange;
    const addTagInputEventListner = (show_id) => {
      const tagInput = document.getElementById("create-show-tag-input");
      const tagInputHandler = (event) => {
        if (event.target.value !== "") {
          seriesService
            .searchHosts({
              show_id: show_id,
              search: event.target.value,
            })
            .then((data) => {
              if (data.data?.hosts?.length > 0) {
                setSearchedHosts(data.data.hosts);
              } else {
                setSearchedHosts([]);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setSearchedHosts([]);
        }
      };
      tagInputChange = tagInput.addEventListener(
        "keyup",
        debounced(1000, tagInputHandler)
      );
    };
    commonService
      .getAllCategories()
      .then((data) => {
        setProgramTopic(data.data.categories);
      })
      .catch((error) => {
        console.log(error);
      });
    seriesService
      .add({
        title: "-",
      })
      .then((data) => {
        setSaveOnBlur(false);
        setValues((values) => {
          addTagInputEventListner(data.data.id);
          return {
            ...values,
            show_id: data.data.id,
            url: `${window.location.host}/s/${data.data.id}`,
          };
        });
      })
      .catch((error) => {
        console.log(error);
      });
    const debounced = (delay, fn) => {
      return function (...args) {
        if (tagInputTimerRef.current) {
          clearTimeout(tagInputTimerRef.current);
        }
        tagInputTimerRef.current = setTimeout(() => {
          fn(...args);
          tagInputTimerRef.current = null;
        }, delay);
      };
    };

    return () => {
      document.removeEventListener("change", tagInputChange);
    };
  }, []);

  const handleMakePrimaryHost = (id) => {
    var selectedMakePrimaryBtn = document.getElementById(
      `make-primary-btn_${id}`
    );
    var makePrimaryBtns = document.querySelectorAll(
      'button[id^="make-primary-btn_"]'
    );
    for (var i = 0; i < makePrimaryBtns.length; i++) {
      selectedMakePrimaryBtn.classList.remove("btn-dark-gray");
      makePrimaryBtns[i].classList.add("btn-danger");
    }
    selectedMakePrimaryBtn.classList.remove("btn-danger");
    selectedMakePrimaryBtn.classList.add("btn-dark-gray");
    setValues((values) => {
      return {
        ...values,
        primary_host_id: id,
      };
    });
  };

  const handleDeleteHost = (hostId) => {
    seriesService
      .deleteHost({
        show_id: values.show_id,
        host_id: hostId,
      })
      .then((data) => {
        if (data.success) {
          setInvitedHosts((invitedHosts) => {
            return invitedHosts.filter((val) => {
              return val.id !== hostId;
            });
          });
        } else {
          return enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    seriesService
      .addComplete(values)
      .then((data) => {
        if (data.success) {
          enqueueSnackbar("Success", {
            variant: "info",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUploadImageButton = () => {
    if (!Boolean(values.show_id)) {
      return enqueueSnackbar("Show title is required", {
        variant: "error",
      });
    }
    document.getElementById("image-file").click();
  };

  const handleUploadImage = (e) => {
    if (typeof e.target.files[0] !== undefined) {
      setUploadedImages((uploadedImages) => {
        return [
          ...uploadedImages,
          {
            title: e.target.files[0].name,
            size: parseInt(e.target.files[0].size / 1000000),
          },
        ];
      });
      const formData = new FormData();
      formData.append("show_id", values.show_id);
      // formData.append("title", e.target.files[0].name);
      // formData.append("size", parseInt(e.target.files[0].size / 1000000));
      formData.append("image_file", e.target.files[0]);
      seriesService
        .uploadImage(formData)
        .then((data) => {
          setUploadedImages((uploadedImages) => {
            let newUploadedImages = uploadedImages;
            newUploadedImages.splice(-1);
            return [
              ...newUploadedImages,
              {
                ...data.data,
                title: e.target.files[0].name,
                size: parseInt(e.target.files[0].size / 1000000),
              },
            ];
          });
          var imageFileInput = document.getElementById("image-file");
          imageFileInput.value = "";
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleDeleteImage = (id) => {
    setUploadedImages((uploadedImages) => {
      return uploadedImages.filter((val) => {
        return val.id !== id;
      });
    });
  };

  const handleHeroImageChange = (e) => {
    let id = parseInt(e.target.getAttribute("data-id"));
    var selectedHeroImgCheckbox = document.getElementById(`hero_${id}`);
    let isChecked = selectedHeroImgCheckbox.checked;
    if (isChecked) {
      var heroImgCheckboxes = document.querySelectorAll('input[id^="hero_"]');
      for (var i = 0; i < heroImgCheckboxes.length; i++) {
        heroImgCheckboxes[i].checked = false;
      }
      selectedHeroImgCheckbox.checked = isChecked;
    }
    setValues((values) => {
      let updatedValues = values;
      if (!isChecked) {
        delete updatedValues.hero_image_id;
      } else {
        updatedValues = {
          ...updatedValues,
          hero_image_id: id,
        };
      }
      return {
        ...updatedValues,
      };
    });
  };

  const handleThumbImageChange = (e) => {
    let id = parseInt(e.target.getAttribute("data-id"));
    var selectedThumbImgCheckbox = document.getElementById(`thumb_${id}`);
    let isChecked = selectedThumbImgCheckbox.checked;
    if (isChecked) {
      var thumbImgCheckboxes = document.querySelectorAll('input[id^="thumb_"]');
      for (var i = 0; i < thumbImgCheckboxes.length; i++) {
        thumbImgCheckboxes[i].checked = false;
      }
      selectedThumbImgCheckbox.checked = isChecked;
    }
    setValues((values) => {
      let updatedValues = values;
      if (!isChecked) {
        delete updatedValues.thumb_image_id;
      } else {
        updatedValues = {
          ...updatedValues,
          thumb_image_id: id,
        };
      }
      return {
        ...updatedValues,
      };
    });
  };

  const handleProgramTopicChange = (e) => {
    let id = parseInt(e.target.getAttribute("data-id"));
    let resultArray = [];
    if (e.target.checked) {
      //if checked (true), then add this id into checkedList
      resultArray = values.category_ids.filter((CheckedId) => CheckedId !== id);
      resultArray.push(id);
    } else {
      //if not checked (false), then remove this id from checkedList
      resultArray = values.category_ids.filter((CheckedId) => CheckedId !== id);
    }
    setValues((values) => {
      return {
        ...values,
        category_ids: resultArray,
      };
    });
  };

  const handleUploadTrailerButton = () => {
    if (!Boolean(values.show_id)) {
      return enqueueSnackbar("Show title is required", {
        variant: "error",
      });
    }
    document.getElementById("trailer-video-file").click();
  };

  const handleUploadTrailer = (e) => {
    if (e.target.files.length > 0) {
      let fileSizeInMbs = parseInt(e.target.files[0].size / 1000000);
      if (fileSizeInMbs > 512) {
        return enqueueSnackbar("Max upload file size is 500 MB", {
          variant: "error",
        });
      }
      setTrailerUpload({
        title: e.target.files[0].name,
        size: parseInt(e.target.files[0].size / 1000000),
      });
      const formData = new FormData();
      formData.append("show_id", values.show_id);
      // formData.append("title", e.target.files[0].name);
      // formData.append("size", parseInt(e.target.files[0].size / 1000000));
      formData.append("video_file", e.target.files[0]);
      formData.append("type", 1);
      seriesService
        .uploadVideo(formData)
        .then((data) => {
          setValues((values) => {
            return {
              ...values,
              trailer_url: data.data.url,
            };
          });
          setTrailerUpload({
            id: data.data.id,
            title: e.target.files[0].name,
            size: parseInt(e.target.files[0].size / 1000000),
          });
          var trailerVideoFileInput =
            document.getElementById("trailer-video-file");
          trailerVideoFileInput.value = "";
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleDeleteTrailer = (id) => {
    setTrailerUpload({
      id: null,
      title: "",
      size: "",
    });
    setValues((values) => {
      return {
        ...values,
        trailer_url: "",
      };
    });
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectTag = (newTags) => {
    if (newTags.length > 0) {
      const newTag = newTags.splice(-1);
      const regex = {
        email: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
      };
      if (!regex.email.test(newTag[0])) {
        return enqueueSnackbar("Enter a valid email", {
          variant: "error",
        });
      } else {
        setTags([...new Set([...newTags, newTag[0]])]);
      }
      // check if user exists in the DB
      // userService
      //   .check({
      //     email: newTag,
      //   })
      //   .then((data) => {
      //     console.log(data);
      //     if (data.data.isRegistered) {
      // setTags(receivedNewTags);
      // } else {
      //     return enqueueSnackbar("No host found", {
      //       variant: "error",
      //     });
      //   }
      // });
    } else {
      setTags(newTags);
    }
  };

  const handleInviteHost = (e) => {
    e.preventDefault();
    if (!Boolean(values.show_id)) {
      return enqueueSnackbar("Show title is required", {
        variant: "error",
      });
    }
    seriesService
      .inviteHost({
        show_id: values.show_id,
        host_emails: tags,
      })
      .then((data) => {
        if (data.data.length > 0) {
          setInvitedHosts((invitedHosts) => {
            return [...invitedHosts, ...data.data];
          });
          setTags([]);
          setTimeout(() => {
            document.getElementsByClassName("react-tag-input__input")[0].value =
              "";
          }, 200);
          if (data.data.length === 1 && invitedHosts.length == 0) {
            setValues((values) => {
              return {
                ...values,
                primary_host_id: data.data[0].id,
              };
            });
          }
        } else {
          return enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Header />
      <main>
        <div className="container mt-5">
          <div className="d-flex align-items-center mb-3">
            <img className="img-fluid" src={backArrow} alt="arrow" />
            <h1 className="text-white ps-2 display-4 fw-600">Create Series</h1>
          </div>
          <div className="bg-dark-grey p-sm-5 p-4 rounded">
            <div className="col-xl-6 col-lg-8 col-md-11 col-sm-12 col-12 m-auto">
              <form>
                <div className="mb-4">
                  <label htmlFor="title" className="form-label text-white">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    value={values.title}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-4 col-sm-4">
                  <label htmlFor="show_id" className="form-label text-white">
                    ID
                  </label>
                  <input
                    type="text"
                    className="form-control disabled darkPlaceholder"
                    id="show_id"
                    placeholder={values.show_id}
                    readOnly
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="show_id" className="form-label text-white">
                    Link
                  </label>
                  <input
                    type="text"
                    className="form-control disabled darkPlaceholder"
                    id="show_id"
                    placeholder={values.url}
                    readOnly
                  />
                </div>
                <div className="mb-4">
                  <p className="form-label text-white mb-3">Program Topic</p>
                  <div className="d-flex flex-wrap">
                    {programTopic.map((element) => {
                      return (
                        <div
                          key={element.id}
                          className="form-check d-flex align-items-center mb-2 py-2 px-2 rounded position-relative me-2"
                        >
                          <input
                            className="form-check-input customCheck"
                            type="checkbox"
                            id={`category_${element.id}`}
                            data-id={element.id}
                            onChange={handleProgramTopicChange}
                          />
                          <label
                            className="form-check-label d-flex align-items-center fs-7"
                            htmlFor={`category_${element.id}`}
                          >
                            {element.title}
                            <span className="dotCheck1"></span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="row">
                  <input
                    id="trailer-video-file"
                    type="file"
                    accept="video/*"
                    onChange={handleUploadTrailer}
                    hidden
                  />
                  <div className="col-sm-6 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
                    <div className="d-grid gap-2">
                      <button
                        type="button"
                        className="btn-block btn btn-danger btn-sm"
                        onClick={handleUploadTrailerButton}
                      >
                        Upload Trailer
                      </button>
                    </div>
                  </div>
                  {Boolean(trailerUpload.title) && (
                    <div className="col-sm-6">
                      <div className="d-flex align-items-center">
                        {!Boolean(values.trailer_url) && (
                          <div className="flex-shrink-0">
                            <div
                              className="spinner-border customSpinner text-danger me-1"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="w-100">
                          <div className="bg-disabled rounded  py-2">
                            <div className="d-flex px-2 align-items-start">
                              <div className="flex-shrink-0">
                                <img src={filmIcon} alt="film" />
                              </div>
                              <div className="w-100 ps-2 lh-1 d-flex justify-content-between align-items-center">
                                <div>
                                  <p className="mb-0 text-white fs-7 text-break">
                                    {trailerUpload.title}
                                  </p>
                                  <span className="small text-muted fs-7 text-break">
                                    {trailerUpload.size} MB
                                  </span>
                                </div>
                                <a
                                  className="cursor-pointer ps-2"
                                  onClick={() =>
                                    handleDeleteTrailer(trailerUpload?.id)
                                  }
                                >
                                  <img src={closeIcon} alt="close" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-4">
                    <div className="mb-3">
                      <label
                        htmlFor="textarea"
                        className="form-label text-white"
                      >
                        Description
                      </label>
                      <textarea
                        className="form-control"
                        rows="3"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <input
                    id="image-file"
                    type="file"
                    accept="image/*"
                    onChange={handleUploadImage}
                    hidden
                  />
                  <div
                    className="col-lg-5 col-md-5 col-sm-6 col-12 mb-lg-0 mb-md-0 mb-sm-4 mb-4"
                    onClick={handleUploadImageButton}
                  >
                    <button
                      type="button"
                      className="btn-block btn btn-danger btn-sm w-100"
                    >
                      Upload Images
                    </button>
                  </div>
                  <div className="col-sm-7">
                    {uploadedImages.map((ele, index) => {
                      let uniqueIndex = `${index}_${new Date().getTime()}`;
                      return (
                        <div
                          key={ele?.id || uniqueIndex}
                          className="row align-items-center mb-lg-0 mb-md-0 mb-sm-4 mb-4"
                        >
                          <div className="col-lg-5 col-md-5 col-sm-12 col-12 mb-0 px-lg-0">
                            <div className="d-flex justify-content-lg-end">
                              <div className="form-check me-2">
                                <input
                                  className="adminCheck form-check-input"
                                  type="checkbox"
                                  id={`hero_${ele?.id || uniqueIndex}`}
                                  data-id={ele?.id || uniqueIndex}
                                  onChange={handleHeroImageChange}
                                />
                                <label
                                  className="form-check-label d-flex flex-column text-white fs-7 lh-1 mt-1"
                                  id={`hero_${ele?.id || uniqueIndex}`}
                                >
                                  <span className="fs-8 text-break w-40">
                                    Hero
                                  </span>
                                  <span className="text-secondary fs-9 text-break w-40">
                                    16X9
                                  </span>
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="adminCheck form-check-input"
                                  type="checkbox"
                                  id={`thumb_${ele?.id || uniqueIndex}`}
                                  data-id={ele?.id || uniqueIndex}
                                  onChange={handleThumbImageChange}
                                />
                                <label
                                  className="form-check-label d-flex flex-column text-white fs-7 lh-1 mt-1"
                                  id={`thumb_${ele?.id || uniqueIndex}`}
                                >
                                  <span className="fs-8 text-break w-40">
                                    Thumb
                                  </span>
                                  <span className="text-secondary fs-9 text-break w-40">
                                    4X3
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                            <div
                              key={ele?.id || uniqueIndex}
                              className="bg-dark rounded-5 p-1 mb-1"
                            >
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <div className="screenShot">
                                    <img
                                      className="img-fluid"
                                      src={ele.hero_url || ""}
                                    />
                                  </div>
                                </div>
                                <div className="w-100 ms-2">
                                  <div className="d-flex justify-content-between">
                                    <div className="me-2">
                                      <p className="fs-10 mb-0 text-white text-break">
                                        {ele.title}
                                      </p>
                                      <p className="fs-10 mb-0 text-secondary text-break">
                                        {ele.size}
                                      </p>
                                    </div>
                                    <a
                                      className="cursor-pointer ps-2"
                                      onClick={() => handleDeleteImage(ele?.id)}
                                    >
                                      <img src={closeIcon} alt="close" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="row mt-4">
                  <div
                    id="create-show-tag-input"
                    className="d-flex form-inline flex-sm-row flex-column col-sm-12"
                  >
                    <div className="col-sm-10 d-grid">
                      <ReactTagInput tags={tags} onChange={handleSelectTag} />
                    </div>
                    <div className="col-sm-2 d-grid">
                      <button
                        className="btn btn-danger btn-sm my-0 px-4 mt-sm-0 mt-2"
                        onClick={handleInviteHost}
                        disabled={tags.length > 0 ? false : true}
                      >
                        Invite
                      </button>
                    </div>
                  </div>
                </div>
                <div className="invitedHost">
                  <p className="text-white mt-4 mb-2">Invited Hosts</p>
                  {invitedHosts.map((ele) => {
                    return (
                      <div
                        key={ele.id}
                        className="border-bottom border-dark pb-2 mb-2"
                      >
                        <div className="row">
                          <div className="col-sm-6 mb-lg-0 mb-md-0 mb-sm-2 mb-2">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <div className="img-44 rounded-circle">
                                  <img
                                    className="img-44 img-fluid rounded-circle"
                                    src={ele.profile_image || hostImg}
                                    alt="host"
                                  />
                                </div>
                              </div>
                              <div className="w-100">
                                <div className="d-flex align-items-center justify-content-start">
                                  <p className="mb-0 text-white mx-3">
                                    {`${ele.first_name} ${ele.last_name}`}
                                  </p>
                                  <p className="mb-0 text-blue">Show Host</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="d-flex align-items-center justify-content-end h-100">
                              <button
                                type="button"
                                id={`make-primary-btn_${ele.id}`}
                                className={`btn ${
                                  Boolean(values.primary_host_id) &&
                                  values.primary_host_id === ele.id
                                    ? "btn-gray-dark"
                                    : "btn-danger"
                                } btn-sm text-white me-2 fs-8`}
                                onClick={() => handleMakePrimaryHost(ele.id)}
                              >
                                {Boolean(values.primary_host_id) &&
                                values.primary_host_id === ele.id
                                  ? "Primary"
                                  : "Make Primary"}
                              </button>
                              {/* <button
                                type="button"
                                className="btn btn-light btn-sm text-danger me-4 fs-8"
                              >
                                Resend Invite
                              </button> */}
                              <a
                                className="ps-2"
                                onClick={() => handleDeleteHost(ele.id)}
                              >
                                <img src={closeIcon} alt="close" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex flex-sm-row flex-column mt-sm-5 mt-4">
                  <button
                    type="button"
                    className="btn-block btn btn-danger btn-sm px-lg-5 px-md-5 px-sm-4 px-4 me-sm-2 me-0 mb-sm-0 mb-2"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn-block btn btn-light btn-sm text-danger px-lg-5 px-md-5 px-sm-4 px-4 me-sm-2 me-0 mb-sm-0 mb-2"
                  >
                    Preview
                  </button>
                  <button
                    type="button"
                    className="btn-block btn btn-gray-dark btn-sm text-white px-lg-5 px-md-5 px-sm-4 px-4"
                  >
                    Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
export default CreateSeries;
