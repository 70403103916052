
import React, {createRef, useEffect, useRef, useState} from "react";
import {Signaling} from "../../../../Services/socketService";

export const FullScreenVideoMobile = ({ activeVideo, isChatHidden }) => {
    const fullScreenVideoRef = createRef();
    const avatarRef = useRef();
    const imageRef = useRef();
    const [videoEl, setVideoEl] = useState(null);
    const isActive = activeVideo[0]?.currentEncoding !== 2;

    //set video track
    useEffect(() => {
        if (fullScreenVideoRef.current && activeVideo[0] && activeVideo[0].htmlElement) {
            const element = document.createElement("video");
            element.srcObject = new MediaStream((activeVideo[0].htmlElement?.srcObject?.getTracks()?.map(x => x.clone())) || []);
            element.playsInline = true;
            element.autoplay = true;
            element.controls = false;

            fullScreenVideoRef.current.innerHTML = "";
            fullScreenVideoRef.current.appendChild(element);
            element.play();
            if (!activeVideo[0]?.isScreenshare && activeVideo[0]?.id === Signaling.getInstance().socket?.id) {
                element.style.cssText =
                    "width:100%;height:100%;transform:scaleX(-1);-webkit-transform:scaleX(-1);";
            } else {
                element.style.cssText =
                    "width:100%;height:100%;transform:scaleX(1);-webkit-transform:scaleX(1);"
            }
            setVideoEl(element);
        }
    }, [activeVideo[0]?.htmlElement, activeVideo[0]?.isScreenshare]);


    useEffect(() => {
        if (!videoEl) {
            return;
        }

        const handleResize = () => {
            const height = window.innerHeight / 4
            const width = height * 16 / 9;
            const bigVideoWidth = window.innerWidth - width;
            const bigVideoHeight = bigVideoWidth * 9 / 16;
            videoEl.style.height = `${
                bigVideoHeight
            }px`;
            videoEl.style.width = `${
                bigVideoWidth
            }px`;

            if (avatarRef && avatarRef.current) {
                avatarRef.current.style.position = "absolute";
                avatarRef.current.style["top"] = (1/6) * bigVideoHeight + "px";
                avatarRef.current.style["left"] = (bigVideoWidth)/2 - bigVideoWidth/6 + "px";
            }

            if (imageRef && imageRef.current) {
                imageRef.current.style.width = 16 * bigVideoHeight / (9 * 3) + "px";
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [isChatHidden, videoEl])

    return  <div className="mainVideo position-relative d-flex justify-content-center">
        <div style={{visibility: (activeVideo[0].isScreenshare || (isActive && activeVideo[0].enabled?.camera)) ? "" : "hidden"}} className={"liveVideo_container h-100"} ref={fullScreenVideoRef} />
        <div style={{visibility: !(activeVideo[0].isScreenshare || (isActive && activeVideo[0].enabled?.camera)) ? "" : "hidden"}} ref={avatarRef}>
            <img
                ref={imageRef}
                className="img-fluid rounded-circle"
                src={activeVideo[0].profile_image || `https://ui-avatars.com/api/?name=${activeVideo[0]?.firstName} ${activeVideo[0]?.lastName}`}
                alt="user"
            />
        </div>
    </div>
}