/** @format */

import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import user from "./slices/user";
import connection from "./slices/connection";
import precall from "./slices/precall";
import xmppConnection from "./slices/xmppConnection";

const reducer = combineReducers({
  user,
  connection,
  precall,
  xmppConnection
});
const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;
