import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Button } from "react-bootstrap";
import encrypSvg from "./../images/encryptionImage.svg";
import css from "./privateMeetingJoin.module.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { userService } from "../../../_services/user.service";
import { useNavigate, Link, useParams } from "react-router-dom";
import moment from "moment";

const PrivateMeetingJoin = () => {
    const { meetingId } = useParams();
    const [meetingLink, setMeetingLink] = useState(meetingId);
    const [password, setPassword] = useState('')
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();

    const handleInstantMeeting = () => {
        let utc_datetime = moment().utc().format();
        var token = user.jwt;
        userService
            .instantMeeting({ utc_datetime }, token)
            .then((data) => {
                if (data.success) {
                    // console.log("Meeting ID : ",  , " meeting initiated ");
                    console.log("token : ", token);
                    const url = data.data;
                    enqueueSnackbar(data.message, {
                        variant: "success",
                    });
                    // console.log(`url: /conference/pre-join/${url}/${token}`);
                    navigate(`/conference/pre-join/${url}`);
                    // setMeetingAttendies(data.data.attendees);
                    // setMeetingInfo(data.data.info);
                    // let userData = data.data.loggedData;
                    // localStorage.setItem("user", JSON.stringify(userData));
                    // dispatch(login(userData));

                    // console.log("utcdate :", utcdate)
                } else {
                    enqueueSnackbar("This meeting url is Incorrect", {
                        variant: "error",
                    });
                }
            })
            .catch((error) => {
                enqueueSnackbar(error, {
                    variant: "error",
                });
            });
    };
    const handleMeetingLinkChange = e => {
        setMeetingLink(e.target.value)
    }
    const handlePassword = (e) => {
        setPassword(e.target.value);
    }
    const handlePrivateMeeting = (e) => {
        let data = {
            username : user.username,
            password : password,
            meeting_id : meetingId,

        }
        userService
        .joinPrivateMeeting(data)
        .then((data) => {
          if (data.success) {
            enqueueSnackbar(data.message, {
              variant: "success",
            });
            navigate(`/conference/pre-join/${meetingId}`, { state:"private-meeting"})
           } else {
            enqueueSnackbar(data.message, {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            variant: "error",
          });
        });
    };
        
         
    return (
        <>
            <Header />
            <div className="mx-auto flex-row  row  flex-sm-row-reverse mt-5  text-white ">
                <div className={`col-sm-12 col-lg-5 m-auto  mt-lg-0  ${css.content} `}>
                    <p className={`${css.headingMeeting} mx-auto `}>Join Private Meeting</p>
                    <div className="text-start mt-3">
                        <label className={`col-6 ${css.label} mr-0 `}>Meeting Link / Id</label>
                        <input
                            className={` ${css.darkInput} col-12`}
                            type="text"
                            value={meetingLink}
                            onChange={handleMeetingLinkChange}
                            readOnly
                        />
                    </div>
                    <div className="text-start mt-3">
                        <label className={`col-6 ${css.label}`}>Password</label>
                        <input
                            className={` ${css.darkInput} col-12`}
                            type="password"
                            value={password}
                            onChange={handlePassword}
                        />
                    </div>
                    <Button
                        className={`${css.newMeetingButton} mt-4 `}
                        onClick={handlePrivateMeeting}
                    >
                        Join
                    </Button>

                </div>
                <div
                    className={`col-sm-12 col-lg-5 m-auto mt-2 mt-lg-0 ${css.content} text-center`}
                >
                    <p className={`${css.heading_left}`}>
                        <span className={`${css.spanHeading}`}>Secure Premium </span> video
                        conferencing for all
                    </p>
                    <img src={encrypSvg} className={`${css.imgSvg}`} alt="" />
                    <div className={`${css.tagline}`}>
                        <span className={`${css.click}`}>Click</span>|
                        <span className={`${css.connect}`}>Connect</span>|
                        <span className={`${css.collaborate}`}>Collaborate</span>
                    </div>
                    <div className={`${css.tagline2}`}>
                        <p>Feel the human connection even remotely! </p>
                    </div>
                    <div className={`mx-auto ${css.btnGrp}`}>
                        <Button
                            className={`${css.newMeetingButton} p-lg-3`}
                            onClick={() => navigate("/schedule-meeting")}
                        >
                            <Icon icon="ic:round-video-call" />
                            Create an new Meeting
                        </Button>
                        <Button
                            className={`${css.instantMeetingButton} p-lg-3`}
                            onClick={handleInstantMeeting}
                        >
                            <Icon icon="ic:round-video-call" />
                            Create an instant meeting
                        </Button>

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PrivateMeetingJoin