
export class VideoFetcher {
    static instance;
    videoStream;
    audioStream;

    constructor() {

    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new VideoFetcher();
        }
        return this.instance
    }

    getAudioStream() {
        return new Promise((resolve, reject) => {
            if (!this.audioStream) {
                navigator.mediaDevices
                    .getUserMedia({
                        audio: true,
                        video: false,
                    })
                    .then(stream => {
                        this.audioStream = stream
                        resolve(stream);
                    })
                    .catch(e => reject(e));
            } else {
                // resolve(this.audioStream.clone());
                resolve(this.audioStream);
            }
        })
    }

    getVideoStream() {
        return new Promise((resolve, reject) => {
            if (!this.videoStream) {
                navigator.mediaDevices
                    .getUserMedia({
                        audio: false,
                        video: { height: { ideal: 180 }, width: { ideal: 300 }, frameRate: 24 },
                    })
                    .then(stream => {
                        this.videoStream = stream
                        resolve(stream);
                    })
                    .catch(e => reject(e));
            } else {
                // resolve(this.videoStream.clone());
                resolve(this.videoStream);
            }
        })
    }

    stopVideo() {
        this.videoStream?.getVideoTracks()?.[0]?.stop();
        this.videoStream?.stop?.();
        this.videoStream = null;
    }

    stopAudio() {
        this.audioStream?.getAudioTracks()?.[0]?.stop();
        this.audioStream?.stop?.();
        this.audioStream = null;
    }

    destroy() {
        this.stopAudio()
        this.stopVideo()
    }

}