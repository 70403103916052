import { useState } from "react";
import RoomClient from "../../../../lib/RoomClientOld";

export const DebugUI = () => {
    const [encodeStrategy, setEncodeStrategy] = useState(RoomClient.getInstance().currentLocalDownscaleStrategy || 0);
    return <div style={{position: "fixed", top: 0, right: 0, width: 300, background: "white"}}>
        Room ID: {window.rc?.room_id} <br/>
        User ID: {RoomClient.getInstance().signaling?.socket?.id?.substr(0, 7)} <br/>
        <div>Encode time: <span id={"encodeTime"}></span></div>
        <div>Height 1: <span id={"height1"}></span></div>
        <div>Height 2: <span id={"height2"}></span></div>
        <div>QLR: <span id={"qlr1"}></span></div>
        <div>QLR: <span id={"qlr2"}></span></div>
        <div>Recon Time: <span id={"reconTime"}></span></div>
        <div>Recon Stage: <span id={"reconStage"}>0</span></div>
        <div>Big: <span id={"bigVideoConsumers"}>{RoomClient.getInstance().bigVideoConsumers}</span></div>
        <div>Local thorttle: <span id={"localThrottle"}>{RoomClient.getInstance().forcedAllSmallVideosTimeout ? "true" : "false"}</span></div>
        <div style={{background: encodeStrategy == 0 ? "red" : ""}} onClick={() => {
            RoomClient.getInstance().currentLocalDownscaleStrategy = 0;
            setEncodeStrategy(0);
        }}>Encode strategy 1</div>
        <div style={{background: encodeStrategy == 1 ? "red" : ""}} onClick={() => {
            RoomClient.getInstance().currentLocalDownscaleStrategy = 1;
            setEncodeStrategy(1);
        }}>Encode strategy 2</div>
        <div style={{background: encodeStrategy == 2 ? "red" : ""}} onClick={() => {
            RoomClient.getInstance().currentLocalDownscaleStrategy = 2;
            setEncodeStrategy(2);
        }}>Encode strategy 3</div>

        <div onClick={() => {
            RoomClient.getInstance()?.signaling?.socket.disconnect();
        }}>Simulate Disconnect</div>
    </div>
}