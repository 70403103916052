import React from "react";
import classNames from "classnames/bind";
import styles from "../liveVideo.module.css";
const cx = classNames.bind(styles);

export const RightBar = ({setIsScreenLayoutButtonClicked, isScreenLayoutButtonClicked, setScreenLayout, }) => {
    return <div
        className={cx("rightSlider", {
            "d-none": !isScreenLayoutButtonClicked,
        })}
    >
        <div className="rightSWhite ms-auto overflowY-auto">
            <div className="bg-white p-4 min-vh-100">
                <div className="position-relative">
                    <div
                        className="d-flex justify-content-end position-absolute right-0"
                        onClick={() => setIsScreenLayoutButtonClicked(false)}
                    >
                        <svg
                            className="cursor-pointer"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M23.7068 0.292786C23.5193 0.105315 23.2649 0 22.9998 0C22.7346 0 22.4803 0.105315 22.2928 0.292786L11.9998 10.5858L1.70679 0.292786C1.51926 0.105315 1.26495 0 0.999786 0C0.734622 0 0.480314 0.105315 0.292786 0.292786C0.105315 0.480314 0 0.734622 0 0.999786C0 1.26495 0.105315 1.51926 0.292786 1.70679L10.5858 11.9998L0.292786 22.2928C0.105315 22.4803 0 22.7346 0 22.9998C0 23.2649 0.105315 23.5193 0.292786 23.7068C0.480314 23.8943 0.734622 23.9996 0.999786 23.9996C1.26495 23.9996 1.51926 23.8943 1.70679 23.7068L11.9998 13.4138L22.2928 23.7068C22.4803 23.8943 22.7346 23.9996 22.9998 23.9996C23.2649 23.9996 23.5193 23.8943 23.7068 23.7068C23.8943 23.5193 23.9996 23.2649 23.9996 22.9998C23.9996 22.7346 23.8943 22.4803 23.7068 22.2928L13.4138 11.9998L23.7068 1.70679C23.8943 1.51926 23.9996 1.26495 23.9996 0.999786C23.9996 0.734622 23.8943 0.480314 23.7068 0.292786Z"
                                fill="#374957"
                            />
                        </svg>
                    </div>
                    <div className="scrollable">
                        <div className="form-check speaker">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                onChange={() => setScreenLayout(1)}
                            />
                            <label
                                className="form-check-label w-100"
                                htmlFor="flexRadioDefault1"
                            >
                                <h5 className="mb-3 ms-1">Speaker</h5>
                                <div className="col-sm-9 col-9 mt-2">
                                    <div className="row g-1">
                                        <div className="col-sm-4 col-4">
                                            <div className="bg-extra-l ms-1 d-flex align-items-center justify-content-center px-3 py-2">
                                                <svg
                                                    width="36"
                                                    height="37"
                                                    viewBox="0 0 36 37"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g opacity="0.2" clipPath="url(#clip0_2261_5622)">
                                                        <path
                                                            d="M18.1818 18.512C23.0715 18.512 27.0354 14.5481 27.0354 9.65835C27.0354 4.76861 23.0715 0.804688 18.1818 0.804688C13.292 0.804688 9.32812 4.76861 9.32812 9.65835C9.32812 14.5481 13.292 18.512 18.1818 18.512Z"
                                                            fill="#515151"
                                                        />
                                                        <path
                                                            d="M18.1809 21.4619C10.8496 21.4701 4.90855 27.4112 4.90039 34.7424C4.90039 35.5574 5.56103 36.218 6.37598 36.218H29.9857C30.8007 36.218 31.4613 35.5574 31.4613 34.7424C31.4532 27.4112 25.5121 21.47 18.1809 21.4619Z"
                                                            fill="#515151"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2261_5622">
                                                            <rect
                                                                width="35.4146"
                                                                height="35.4146"
                                                                fill="white"
                                                                transform="translate(0.472656 0.804688)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-4">
                                            <div className="bg-extra-l ms-1 d-flex align-items-center justify-content-center px-3 py-2">
                                                <svg
                                                    width="36"
                                                    height="37"
                                                    viewBox="0 0 36 37"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g opacity="0.2" clipPath="url(#clip0_2261_5622)">
                                                        <path
                                                            d="M18.1818 18.512C23.0715 18.512 27.0354 14.5481 27.0354 9.65835C27.0354 4.76861 23.0715 0.804688 18.1818 0.804688C13.292 0.804688 9.32812 4.76861 9.32812 9.65835C9.32812 14.5481 13.292 18.512 18.1818 18.512Z"
                                                            fill="#515151"
                                                        />
                                                        <path
                                                            d="M18.1809 21.4619C10.8496 21.4701 4.90855 27.4112 4.90039 34.7424C4.90039 35.5574 5.56103 36.218 6.37598 36.218H29.9857C30.8007 36.218 31.4613 35.5574 31.4613 34.7424C31.4532 27.4112 25.5121 21.47 18.1809 21.4619Z"
                                                            fill="#515151"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2261_5622">
                                                            <rect
                                                                width="35.4146"
                                                                height="35.4146"
                                                                fill="white"
                                                                transform="translate(0.472656 0.804688)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-4">
                                            <div className="bg-extra-l ms-1 d-flex align-items-center justify-content-center px-3 py-2">
                                                <svg
                                                    width="36"
                                                    height="37"
                                                    viewBox="0 0 36 37"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g opacity="0.2" clipPath="url(#clip0_2261_5622)">
                                                        <path
                                                            d="M18.1818 18.512C23.0715 18.512 27.0354 14.5481 27.0354 9.65835C27.0354 4.76861 23.0715 0.804688 18.1818 0.804688C13.292 0.804688 9.32812 4.76861 9.32812 9.65835C9.32812 14.5481 13.292 18.512 18.1818 18.512Z"
                                                            fill="#515151"
                                                        />
                                                        <path
                                                            d="M18.1809 21.4619C10.8496 21.4701 4.90855 27.4112 4.90039 34.7424C4.90039 35.5574 5.56103 36.218 6.37598 36.218H29.9857C30.8007 36.218 31.4613 35.5574 31.4613 34.7424C31.4532 27.4112 25.5121 21.47 18.1809 21.4619Z"
                                                            fill="#515151"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2261_5622">
                                                            <rect
                                                                width="35.4146"
                                                                height="35.4146"
                                                                fill="white"
                                                                transform="translate(0.472656 0.804688)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="bg-extra-l ms-1 d-flex align-items-center justify-content-center p-3">
                                                <svg
                                                    width="100"
                                                    height="99"
                                                    viewBox="0 0 36 37"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g opacity="0.2" clipPath="url(#clip0_2261_5622)">
                                                        <path
                                                            d="M18.1818 18.512C23.0715 18.512 27.0354 14.5481 27.0354 9.65835C27.0354 4.76861 23.0715 0.804688 18.1818 0.804688C13.292 0.804688 9.32812 4.76861 9.32812 9.65835C9.32812 14.5481 13.292 18.512 18.1818 18.512Z"
                                                            fill="#515151"
                                                        />
                                                        <path
                                                            d="M18.1809 21.4619C10.8496 21.4701 4.90855 27.4112 4.90039 34.7424C4.90039 35.5574 5.56103 36.218 6.37598 36.218H29.9857C30.8007 36.218 31.4613 35.5574 31.4613 34.7424C31.4532 27.4112 25.5121 21.47 18.1809 21.4619Z"
                                                            fill="#515151"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2261_5622">
                                                            <rect
                                                                width="35.4146"
                                                                height="35.4146"
                                                                fill="white"
                                                                transform="translate(0.472656 0.804688)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="form-check speaker my-4">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                                onChange={() => setScreenLayout(2)}
                            />
                            <label
                                className="form-check-label w-100"
                                htmlFor="flexRadioDefault2"
                            >
                                <h5 className="mb-3 ms-1">
                                    Maximize Speaker or ScreenShare
                                </h5>
                                <div className="col-sm-9 col-9 mt-2">
                                    <div className="row g-1">
                                        <div className="col-sm-12">
                                            <div className="bg-extra-l ms-1 d-flex align-items-center justify-content-center p-3">
                                                <svg
                                                    width="100"
                                                    height="99"
                                                    viewBox="0 0 36 37"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g opacity="0.2" clipPath="url(#clip0_2261_5622)">
                                                        <path
                                                            d="M18.1818 18.512C23.0715 18.512 27.0354 14.5481 27.0354 9.65835C27.0354 4.76861 23.0715 0.804688 18.1818 0.804688C13.292 0.804688 9.32812 4.76861 9.32812 9.65835C9.32812 14.5481 13.292 18.512 18.1818 18.512Z"
                                                            fill="#515151"
                                                        />
                                                        <path
                                                            d="M18.1809 21.4619C10.8496 21.4701 4.90855 27.4112 4.90039 34.7424C4.90039 35.5574 5.56103 36.218 6.37598 36.218H29.9857C30.8007 36.218 31.4613 35.5574 31.4613 34.7424C31.4532 27.4112 25.5121 21.47 18.1809 21.4619Z"
                                                            fill="#515151"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2261_5622">
                                                            <rect
                                                                width="35.4146"
                                                                height="35.4146"
                                                                fill="white"
                                                                transform="translate(0.472656 0.804688)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="form-check speaker">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault3"
                                onChange={() => setScreenLayout(3)}
                            />
                            <label
                                className="form-check-label w-100"
                                htmlFor="flexRadioDefault3"
                            >
                                <h5 className="mb-3 ms-1">Participant grid</h5>
                                <div className="col-sm-9 col-9 mt-2">
                                    <div className="row g-1">
                                        <div className="col-sm-4 col-4">
                                            <div className="bg-extra-l ms-1 d-flex align-items-center justify-content-center px-3 py-2">
                                                <svg
                                                    width="36"
                                                    height="37"
                                                    viewBox="0 0 36 37"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g opacity="0.2" clipPath="url(#clip0_2261_5622)">
                                                        <path
                                                            d="M18.1818 18.512C23.0715 18.512 27.0354 14.5481 27.0354 9.65835C27.0354 4.76861 23.0715 0.804688 18.1818 0.804688C13.292 0.804688 9.32812 4.76861 9.32812 9.65835C9.32812 14.5481 13.292 18.512 18.1818 18.512Z"
                                                            fill="#515151"
                                                        />
                                                        <path
                                                            d="M18.1809 21.4619C10.8496 21.4701 4.90855 27.4112 4.90039 34.7424C4.90039 35.5574 5.56103 36.218 6.37598 36.218H29.9857C30.8007 36.218 31.4613 35.5574 31.4613 34.7424C31.4532 27.4112 25.5121 21.47 18.1809 21.4619Z"
                                                            fill="#515151"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2261_5622">
                                                            <rect
                                                                width="35.4146"
                                                                height="35.4146"
                                                                fill="white"
                                                                transform="translate(0.472656 0.804688)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-4">
                                            <div className="bg-extra-l ms-1 d-flex align-items-center justify-content-center px-3 py-2">
                                                <svg
                                                    width="36"
                                                    height="37"
                                                    viewBox="0 0 36 37"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g opacity="0.2" clipPath="url(#clip0_2261_5622)">
                                                        <path
                                                            d="M18.1818 18.512C23.0715 18.512 27.0354 14.5481 27.0354 9.65835C27.0354 4.76861 23.0715 0.804688 18.1818 0.804688C13.292 0.804688 9.32812 4.76861 9.32812 9.65835C9.32812 14.5481 13.292 18.512 18.1818 18.512Z"
                                                            fill="#515151"
                                                        />
                                                        <path
                                                            d="M18.1809 21.4619C10.8496 21.4701 4.90855 27.4112 4.90039 34.7424C4.90039 35.5574 5.56103 36.218 6.37598 36.218H29.9857C30.8007 36.218 31.4613 35.5574 31.4613 34.7424C31.4532 27.4112 25.5121 21.47 18.1809 21.4619Z"
                                                            fill="#515151"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2261_5622">
                                                            <rect
                                                                width="35.4146"
                                                                height="35.4146"
                                                                fill="white"
                                                                transform="translate(0.472656 0.804688)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-4">
                                            <div className="bg-extra-l ms-1 d-flex align-items-center justify-content-center px-3 py-2">
                                                <svg
                                                    width="36"
                                                    height="37"
                                                    viewBox="0 0 36 37"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g opacity="0.2" clipPath="url(#clip0_2261_5622)">
                                                        <path
                                                            d="M18.1818 18.512C23.0715 18.512 27.0354 14.5481 27.0354 9.65835C27.0354 4.76861 23.0715 0.804688 18.1818 0.804688C13.292 0.804688 9.32812 4.76861 9.32812 9.65835C9.32812 14.5481 13.292 18.512 18.1818 18.512Z"
                                                            fill="#515151"
                                                        />
                                                        <path
                                                            d="M18.1809 21.4619C10.8496 21.4701 4.90855 27.4112 4.90039 34.7424C4.90039 35.5574 5.56103 36.218 6.37598 36.218H29.9857C30.8007 36.218 31.4613 35.5574 31.4613 34.7424C31.4532 27.4112 25.5121 21.47 18.1809 21.4619Z"
                                                            fill="#515151"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2261_5622">
                                                            <rect
                                                                width="35.4146"
                                                                height="35.4146"
                                                                fill="white"
                                                                transform="translate(0.472656 0.804688)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-4">
                                            <div className="bg-extra-l ms-1 d-flex align-items-center justify-content-center px-3 py-2">
                                                <svg
                                                    width="36"
                                                    height="37"
                                                    viewBox="0 0 36 37"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g opacity="0.2" clipPath="url(#clip0_2261_5622)">
                                                        <path
                                                            d="M18.1818 18.512C23.0715 18.512 27.0354 14.5481 27.0354 9.65835C27.0354 4.76861 23.0715 0.804688 18.1818 0.804688C13.292 0.804688 9.32812 4.76861 9.32812 9.65835C9.32812 14.5481 13.292 18.512 18.1818 18.512Z"
                                                            fill="#515151"
                                                        />
                                                        <path
                                                            d="M18.1809 21.4619C10.8496 21.4701 4.90855 27.4112 4.90039 34.7424C4.90039 35.5574 5.56103 36.218 6.37598 36.218H29.9857C30.8007 36.218 31.4613 35.5574 31.4613 34.7424C31.4532 27.4112 25.5121 21.47 18.1809 21.4619Z"
                                                            fill="#515151"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2261_5622">
                                                            <rect
                                                                width="35.4146"
                                                                height="35.4146"
                                                                fill="white"
                                                                transform="translate(0.472656 0.804688)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-4">
                                            <div className="bg-extra-l ms-1 d-flex align-items-center justify-content-center px-3 py-2">
                                                <svg
                                                    width="36"
                                                    height="37"
                                                    viewBox="0 0 36 37"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g opacity="0.2" clipPath="url(#clip0_2261_5622)">
                                                        <path
                                                            d="M18.1818 18.512C23.0715 18.512 27.0354 14.5481 27.0354 9.65835C27.0354 4.76861 23.0715 0.804688 18.1818 0.804688C13.292 0.804688 9.32812 4.76861 9.32812 9.65835C9.32812 14.5481 13.292 18.512 18.1818 18.512Z"
                                                            fill="#515151"
                                                        />
                                                        <path
                                                            d="M18.1809 21.4619C10.8496 21.4701 4.90855 27.4112 4.90039 34.7424C4.90039 35.5574 5.56103 36.218 6.37598 36.218H29.9857C30.8007 36.218 31.4613 35.5574 31.4613 34.7424C31.4532 27.4112 25.5121 21.47 18.1809 21.4619Z"
                                                            fill="#515151"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2261_5622">
                                                            <rect
                                                                width="35.4146"
                                                                height="35.4146"
                                                                fill="white"
                                                                transform="translate(0.472656 0.804688)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-4">
                                            <div className="bg-extra-l ms-1 d-flex align-items-center justify-content-center px-3 py-2">
                                                <svg
                                                    width="36"
                                                    height="37"
                                                    viewBox="0 0 36 37"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g opacity="0.2" clipPath="url(#clip0_2261_5622)">
                                                        <path
                                                            d="M18.1818 18.512C23.0715 18.512 27.0354 14.5481 27.0354 9.65835C27.0354 4.76861 23.0715 0.804688 18.1818 0.804688C13.292 0.804688 9.32812 4.76861 9.32812 9.65835C9.32812 14.5481 13.292 18.512 18.1818 18.512Z"
                                                            fill="#515151"
                                                        />
                                                        <path
                                                            d="M18.1809 21.4619C10.8496 21.4701 4.90855 27.4112 4.90039 34.7424C4.90039 35.5574 5.56103 36.218 6.37598 36.218H29.9857C30.8007 36.218 31.4613 35.5574 31.4613 34.7424C31.4532 27.4112 25.5121 21.47 18.1809 21.4619Z"
                                                            fill="#515151"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2261_5622">
                                                            <rect
                                                                width="35.4146"
                                                                height="35.4146"
                                                                fill="white"
                                                                transform="translate(0.472656 0.804688)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-4">
                                            <div className="bg-extra-l ms-1 d-flex align-items-center justify-content-center px-3 py-2">
                                                <svg
                                                    width="36"
                                                    height="37"
                                                    viewBox="0 0 36 37"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g opacity="0.2" clipPath="url(#clip0_2261_5622)">
                                                        <path
                                                            d="M18.1818 18.512C23.0715 18.512 27.0354 14.5481 27.0354 9.65835C27.0354 4.76861 23.0715 0.804688 18.1818 0.804688C13.292 0.804688 9.32812 4.76861 9.32812 9.65835C9.32812 14.5481 13.292 18.512 18.1818 18.512Z"
                                                            fill="#515151"
                                                        />
                                                        <path
                                                            d="M18.1809 21.4619C10.8496 21.4701 4.90855 27.4112 4.90039 34.7424C4.90039 35.5574 5.56103 36.218 6.37598 36.218H29.9857C30.8007 36.218 31.4613 35.5574 31.4613 34.7424C31.4532 27.4112 25.5121 21.47 18.1809 21.4619Z"
                                                            fill="#515151"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2261_5622">
                                                            <rect
                                                                width="35.4146"
                                                                height="35.4146"
                                                                fill="white"
                                                                transform="translate(0.472656 0.804688)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-4">
                                            <div className="bg-extra-l ms-1 d-flex align-items-center justify-content-center px-3 py-2">
                                                <svg
                                                    width="36"
                                                    height="37"
                                                    viewBox="0 0 36 37"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g opacity="0.2" clipPath="url(#clip0_2261_5622)">
                                                        <path
                                                            d="M18.1818 18.512C23.0715 18.512 27.0354 14.5481 27.0354 9.65835C27.0354 4.76861 23.0715 0.804688 18.1818 0.804688C13.292 0.804688 9.32812 4.76861 9.32812 9.65835C9.32812 14.5481 13.292 18.512 18.1818 18.512Z"
                                                            fill="#515151"
                                                        />
                                                        <path
                                                            d="M18.1809 21.4619C10.8496 21.4701 4.90855 27.4112 4.90039 34.7424C4.90039 35.5574 5.56103 36.218 6.37598 36.218H29.9857C30.8007 36.218 31.4613 35.5574 31.4613 34.7424C31.4532 27.4112 25.5121 21.47 18.1809 21.4619Z"
                                                            fill="#515151"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2261_5622">
                                                            <rect
                                                                width="35.4146"
                                                                height="35.4146"
                                                                fill="white"
                                                                transform="translate(0.472656 0.804688)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-4">
                                            <div className="bg-extra-l ms-1 d-flex align-items-center justify-content-center px-3 py-2">
                                                <svg
                                                    width="36"
                                                    height="37"
                                                    viewBox="0 0 36 37"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g opacity="0.2" clipPath="url(#clip0_2261_5622)">
                                                        <path
                                                            d="M18.1818 18.512C23.0715 18.512 27.0354 14.5481 27.0354 9.65835C27.0354 4.76861 23.0715 0.804688 18.1818 0.804688C13.292 0.804688 9.32812 4.76861 9.32812 9.65835C9.32812 14.5481 13.292 18.512 18.1818 18.512Z"
                                                            fill="#515151"
                                                        />
                                                        <path
                                                            d="M18.1809 21.4619C10.8496 21.4701 4.90855 27.4112 4.90039 34.7424C4.90039 35.5574 5.56103 36.218 6.37598 36.218H29.9857C30.8007 36.218 31.4613 35.5574 31.4613 34.7424C31.4532 27.4112 25.5121 21.47 18.1809 21.4619Z"
                                                            fill="#515151"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2261_5622">
                                                            <rect
                                                                width="35.4146"
                                                                height="35.4146"
                                                                fill="white"
                                                                transform="translate(0.472656 0.804688)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}