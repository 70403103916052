/** @format */

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./scheduleMeeting.module.css";
import adminStyles from "../admin.module.css";
import Header from "../Header/Header";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import AddViaContact from "./AddViaContactsModal/AddViaContact";
import AddViaEmail from "./AddViaEmailModal/AddViaEmail";
import { userService } from "../../../_services";
import DropdownLinkWithCalender from "./DropdownLinkWithCalender/DropdownLinkWithCalender";
// import Chip from "@mui/material/Chip";
// import Autocomplete from "@mui/material/Autocomplete";
import { MdDeleteOutline } from "react-icons/md";

// import TextField from "@mui/material/TextField";

import { useSnackbar } from "notistack";

import { showService } from "../../../_services/admin";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import * as CONSTANTS from "../../../config/constants";
import { Today } from "@material-ui/icons";
import DropdownType from "./DropdownType/DropdownType";
import DropdownAlertTime from "./DropdownAlertTime/DropdownAlertTime";
import DropdownRecurrences from "./DropdownRecurrences/DropdownRecurrences";
import { Icon } from "@iconify/react";
import _ from "lodash";
import CustomRecurrenceModal from "./CustomRecurrenceModal/CustomRecurrenceModal";
import { ClipLoader } from "react-spinners";
import {
  abbreviatedSignupModalHandler,
  login,
} from "../../../store/slices/user";
const cx = classNames.bind(styles);
const acx = classNames.bind(adminStyles);

let hour12 = [];
let hour24 = [];

const regex = {
  email: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
};

const ScheduleMeeting = () => {
  const navigate = useNavigate();
  let { token } = useParams();
  let tokenFromParam = token;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [emailsList, setEmailsList] = useState([]);
  const [emailField, setEmailField] = useState("");
  const [type, setType] = useState(0);
  const [alertTime, setAlertTime] = useState({ key: 0, value: "15 Mins" });
  const [recurrence, setRecurrence] = useState({
    key: 0,
    value: "Does not Repeat",
  });
  const [userDetails, setUserDetails] = useState({});
  const [linkWithCal, setLinkWithCal] = useState("Choose");
  const [addViaContactsModal, setAddViaContactsModal] = useState(false);
  const [addViaEmailModal, setAddViaEmailModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [contactsList, setContactsList] = useState([]);
  const [customRecModal, setCustomRecModal] = useState(false);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    if (token)
      userService
        .getLoggedUserDetails(token)
        .then((data) => {
          if (data) {
            // enqueueSnackbar(`Hi ${data.data.details.first_name}`, {
            //   variant: "info",
            // });
            // console.log(data);
            setUserDetails(data.data.details);
            dispatch(login(data.data.details));
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            variant: "error",
          });
        });
  }, []);

  const [values, setValues] = useState({
    title: "",
    alert: "15 mins",
    time: "",
    date: "",
    contacts: [],
    linkWcalender: "",
  });

  const handleSubmit = () => {
    values.emails = emailsList;
    values.contacts = contactsList;
    values.type = type;
    values.linkWcalender = linkWithCal;
    values.alert = alertTime.key;
    values.recurrences = recurrence.key;
    // setValues((prev) => ({ ...prev, contact: contacts }));
    const localTimeToUTC = moment(`${startDate} ${values.time}`).utc().format();
    //
    if (!values.title || values.title === "") {
      enqueueSnackbar("Title Cannot be empty", {
        variant: "error",
      });
    } else if (!startDate) {
      enqueueSnackbar("Date cannot be empty", {
        variant: "error",
      });
    } else if (!values.time) {
      enqueueSnackbar("Time cannot be empty", {
        variant: "error",
      });
    } else if (contactsList === 0 && emailsList == 0) {
      enqueueSnackbar("Cannot create scheduled meeting with 0 attendees", {
        variant: "error",
      });
    } else {
      //'
      delete values.date;
      delete values.time;
      values.utc_datetime = localTimeToUTC;
      if (!token) {
        token = user.jwt;
      }
      userService
        .scheduleMeeting(values, token)
        .then((data) => {
          if (data) {
            enqueueSnackbar(data.message, {
              variant: "info",
            });

            if (_.isUndefined(tokenFromParam))
              navigate(`/conference/pre-join/${data.data}`);
            else
              navigate(`/conference/pre-join/${data.data}/${tokenFromParam}`);
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            variant: "error",
          });
        });
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleEmailchange = (e) => {
    setEmailField(e.target.value);
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      if (!regex.email.test(emailField)) {
        return enqueueSnackbar("Invalid Email", { variant: "error" });
      }
      setEmailsList([...emailsList].concat(emailField));
      setEmailField("");
    }
  };

  const removeContacts = (e, mob) => {
    setContactsList(contactsList.filter((tag) => tag.mobile !== mob));

    enqueueSnackbar("Contact Removed Successfully", {
      variant: "info",
    });
  };
  const removeEmails = (index) => {
    setEmailsList([
      ...emailsList.filter((tag) => emailsList.indexOf(tag) !== index),
    ]);
  };
  const handleAddViaEmailClick = (e) => {
    e.preventDefault();
    setAddViaEmailModal(true);
  };

  const handleAddViaContactClick = (e) => {
    e.preventDefault();
    setAddViaContactsModal(true);
  };
  const handleStartDateChange = (e) => {
    let date = new Date(e.target.value);
    var full_date =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    setStartDate(full_date);
  };

  return (
    <div>
      <Header />

      <main>
        <div className="container my-3">
          <div className={` pl-2  pr-2  ${styles.box}`}>
            <div className="col-xl-10 col-lg-8 col-md-11 col-sm-12 col-12 m-auto">
              <h3
                className=" ps-2  text-center  mt-5 d-none d-sm-block"
                style={{ color: "#509EF1" }}
              >
                Schedule a call
              </h3>

              <form>
                <div className="row">
                  <div className=" col-xs-12 col-md-6 px-xl-5  py-lg-1">
                    <label htmlFor="show_id" className={`form-label  `}>
                      Title
                      <span style={{ marginLeft: "2px", color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control  ${styles.input_dark}`}
                      name="title"
                      onChange={handleChange}
                      value={values.title}
                    />
                  </div>

                  <div className=" col-xs-12 col-md-6 px-xl-5  py-lg-1">
                    <div className="">
                      <label htmlFor="type" className="form-label col-12">
                        Type
                        <span style={{ marginLeft: "2px", color: "red" }}>
                          *
                        </span>
                      </label>

                      <DropdownType type={type} setType={setType} />
                    </div>
                    <div className="text-white mt-1"></div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={cx(" mb-2 col-xs-12 col-md-6 px-xl-5  py-lg-1")}
                    style={{ display: "flex" }}
                  >
                    <div className="col-8  col-md-6 ">
                      <label htmlFor="time" className="mb-2 col-12">
                        Date{" "}
                        <span style={{ marginLeft: "2px", color: "red" }}>
                          *
                        </span>
                      </label>
                      <input
                        type="date"
                        className={`form-control col-xs-5 ${styles.input_dark}`}
                        name="date"
                        onChange={handleStartDateChange}
                        placeholder="DD-MM-YYYY"
                        min={moment().format("YYYY-MM-DD")}
                      />
                    </div>

                    <div className="col-md-5 offset-1 col-3">
                      <label htmlFor="time" className="mb-2 col-12">
                        Time{" "}
                        <span style={{ marginLeft: "2px", color: "red" }}>
                          *
                        </span>
                      </label>

                      <input
                        type="time"
                        className={`form-control col-xs-6 ${styles.input_dark}`}
                        name="time"
                        onChange={handleChange}
                        min={moment().toDate().getTime()}
                      />
                    </div>
                  </div>
                  <div className="mb-2 col-xs-12 col-md-6 px-xl-5  py-lg-1">
                    <div className="">
                      <label htmlFor="type" className="form-label  col-12">
                        Alert
                        <span style={{ marginLeft: "2px", color: "red" }}>
                          *
                        </span>
                      </label>
                      <DropdownAlertTime
                        alertTime={alertTime}
                        setAlertTime={setAlertTime}
                      />{" "}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="mb-2 col-xs-12 col-md-6 px-xl-5  py-lg-1">
                    <div className="">
                      <label htmlFor="type" className="form-label col-12">
                        <div className="row">
                          <div className="col-5">Recurrences</div>
                        </div>
                      </label>
                      <DropdownRecurrences
                        recurrence={recurrence}
                        setRecurrence={setRecurrence}
                        setCustomRecModal={setCustomRecModal}
                      />
                    </div>
                  </div>
                  <div className="mb-2 col-xs-12 col-md-6 px-xl-5">
                    <label htmlFor="type" className="form-label col-12">
                      <div className="row">
                        <div className="col-12">Add Participants</div>
                      </div>
                    </label>
                    {tokenFromParam ? (
                      <>
                        <button
                          type="button"
                          className={`col-12 ${styles.addViaButton}`}
                          onClick={handleAddViaContactClick}
                        >
                          + Add Via Contact
                        </button>
                        {addViaContactsModal && (
                          <AddViaContact
                            show={addViaContactsModal}
                            setAddViaContactsModal={setAddViaContactsModal}
                            contactsList={contactsList}
                            setContactsList={setContactsList}
                            token={token}
                          />
                        )}
                        <ul id={cx("tags")}>
                          {contactsList.map((contact, index) => (
                            <li key={index} className={cx("tag")}>
                              {contact.pic.length > 0 ? (
                                <>
                                  <img
                                    src={contact.pic}
                                    className={`col-2   text-right p-0  ${styles.selectedContactProfile}`}
                                    width="50"
                                    height="50"
                                    alt="bad link"
                                  />
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <Icon
                                    icon={`emojione:letter-${contact.name[0].toLowerCase()}`}
                                    className="mr-2 col-2 text-right p-0"
                                    width="30"
                                    height="30"
                                  />
                                </>
                              )}

                              <span className={cx("tag-title")}>
                                {contact.name}
                              </span>
                              <span
                                className={cx("tag-close-icon ")}
                                onClick={(e) =>
                                  removeContacts(e, contact.mobile)
                                }
                              >
                                <Icon
                                  icon="ant-design:delete-outlined"
                                  width="30"
                                  height="30"
                                />{" "}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <>
                        {" "}
                        <label
                          htmlFor="show_id"
                          className="form-label text-white"
                        ></label>
                        <button
                          type="button"
                          className={`col-12 ${styles.addViaButton}`}
                          onClick={handleAddViaEmailClick}
                        >
                          + Add Via Email
                        </button>
                        <AddViaEmail
                          show={addViaEmailModal}
                          setAddViaEmailModal={setAddViaEmailModal}
                          handleEnterKey={handleEnterKey}
                          handleEmailchange={handleEmailchange}
                          emailField={emailField}
                          styles={styles}
                          emailsList={emailsList}
                        />
                        <ul id={cx("tags")}>
                          {emailsList.map((email, index) => (
                            <li key={index} className={cx("tag")}>
                              <Icon
                                icon="pajamas:profile"
                                width="30"
                                height="30"
                              />
                              <span className={cx("tag-title")}>{email}</span>
                              <span
                                className={cx("tag-close-icon")}
                                onClick={() => removeEmails(index)}
                              >
                                <Icon
                                  icon="ant-design:delete-outlined"
                                  width="30"
                                  height="30"
                                />
                              </span>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </div>

                <div className="text-center row">
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className={`btn-block btn  btn-xs  ${styles.sendInvitation} col-xs-12 col-md-5 mx-auto `}
                  >
                    Send Invitation
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <CustomRecurrenceModal
          backdrop="static"
          show={customRecModal}
          onHide={() => setCustomRecModal(false)}
          setRecurrenceData={(recurrenceData) =>
            setValues((v) => {
              return {
                ...v,
                ...recurrenceData,
              };
            })
          }
        />
      </main>
    </div>
  );
};
export default ScheduleMeeting;
