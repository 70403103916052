import React from "react";
import PageNumber from "./PageNumber/PageNumber";

const PageNumbers = ({ pages, currentPage, moveToPage }) => {
  return (
    <ul className="pagination mb-0">
      {Array.from(Array(pages + 1), (e, page) => {
        if (page) {
          return (
            <PageNumber
              key={page}
              page={page}
              currentPage={currentPage}
              moveToPage={moveToPage}
            />
          );
        }
      })}
    </ul>
  );
};

export default PageNumbers;
