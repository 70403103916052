import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import classNames from "classnames/bind";
import MailIcon from "../../../../images/emailIcon.svg";
import CloseIcon from "../../../../images/cross.svg";
import EyeIcon from "../../../../images/eye_password.svg";
import EyeLine from "../../../../images/EyeLine.svg";
import styles from "./loginpopup.module.css";
import { Form, InputGroup, FormControl, Button } from "react-bootstrap";
import { useSnackbar } from "notistack";
import { userService } from "../../../../_services";
import _ from "lodash";
import EditShow from "../../EditShow/EditShow";
import {
  attendScheduleShowHandler,
  login,
  loginModalHandler,
  loginStepsHandler,
  resetPasswordModalHandler,
  signupModalHandler,
} from "../../../../store/slices/user";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { showService } from "../../../../_services/admin";
import {
  getFormattedDateTime,
  getTimeZone,
  getTimeZoneAbbreviation,
} from "../../../../_helpers";

function MyVerticallyCenteredModal(props) {
  const cx = classNames.bind(styles);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isFormfilled, setIsFormfilled] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { editShowRedirect, loginSteps, loginModal, attendScheduleShow } =
    useSelector((state) => state.user);

  useEffect(() => {
    if (values.email !== "" && values.password !== "") {
      setIsFormfilled(true);
    } else {
      setIsFormfilled(false);
    }
  }, [values]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    userService
      .login({
        email: values.email,
        password: values.password,
        timezone: getTimeZone(),
      })
      .then((data) => {
        if (data.success) {
          localStorage.setItem("user", JSON.stringify(data.data));
          let user = JSON.parse(localStorage.getItem("user"));
          dispatch(login(data.data));
          if (!_.isEmpty(editShowRedirect)) {
            dispatch(loginStepsHandler(2));
          } else if (!_.isEmpty(attendScheduleShow)) {
            showService
              .addOrRemoveAttendee({
                show_id: attendScheduleShow.show_id,
                user_id: user.id,
                show_schedule_id: attendScheduleShow.id,
                scheduled_time:
                  attendScheduleShow.scheduled_time +
                  " " +
                  getTimeZoneAbbreviation(),
              })
              .then((data) => {
                if (data.success) {
                  dispatch(attendScheduleShowHandler({}));
                } else {
                  enqueueSnackbar(data.message, {
                    variant: "error",
                  });
                }
              });
            dispatch(loginModalHandler(false));
          } else {
            dispatch(loginModalHandler(false));
          }
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const handleTogglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);

  const btnStyle = isFormfilled
    ? {
        backgroundColor: "#E50914",
        color: "white",
      }
    : {
        backgroundColor: "#E3E3E3",
        color: "black",
      };
  return (
    <>
      {loginSteps === 1 ? (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="container">
            <div className=" d-flex align-items-center justify-content-center">
              <div
                className={cx(
                  "outerBox",
                  "bg-white",
                  "p-4",
                  "position-relative"
                )}
              >
                <div className={cx("landscapeMode")}>
                  <div className="d-flex align-items-center justify-content-center mb-4 landscape-mb-4">
                    <h2 className="text-center fw-bold">Login</h2>
                    <div
                      onClick={props.onHide}
                      className={cx(
                        "position-absolute",
                        "closeIcon",
                        "cursor-pointer"
                      )}
                    >
                      <img className="img-fluid" src={CloseIcon} />
                    </div>
                  </div>
                  <Form onSubmit={handleSubmit}>
                    <InputGroup
                      size="lg"
                      className="border border-dark rounded mb-3 landscape-mb-3"
                    >
                      <FormControl
                        id="email"
                        placeholder="Use email"
                        aria-label="use email"
                        aria-describedby="use email"
                        className="border-white"
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      <InputGroup.Text
                        id="use email"
                        className="fs-6 bg-white border-white"
                      >
                        <img src={MailIcon} />
                      </InputGroup.Text>
                    </InputGroup>
                    <InputGroup
                      size="lg"
                      className="border border-dark rounded mb-3 landscape-mb-3"
                    >
                      <FormControl
                        id="pass"
                        placeholder="Password"
                        aria-label="Password"
                        aria-describedby="Password"
                        className="border-white"
                        type={isPasswordVisible ? "text" : "password"}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      <InputGroup.Text
                        id="Password"
                        className="fs-6 bg-white border-white"
                        onClick={handleTogglePasswordVisibility}
                      >
                        <img src={isPasswordVisible ? EyeLine : EyeIcon} />
                      </InputGroup.Text>
                    </InputGroup>
                    <Button
                      className="w-100 socialBtn fw-bold"
                      variant="contained"
                      size="lg"
                      type="submit"
                      style={btnStyle}
                      disabled={!isFormfilled}
                    >
                      Login
                    </Button>
                    <div
                      className="d-block text-center small text-dark my-4 landscape-my-4 cursor-pointer"
                      onClick={() => {
                        dispatch(loginModalHandler(false));
                        dispatch(resetPasswordModalHandler(true));
                      }}
                    >
                      <u>Forgot Password?</u>
                    </div>
                    <p className="text-center mb-2 small mt-5 landscape-mt-5">
                      Don't have an account
                    </p>
                    <div
                      className="h6 d-block text-danger text-center fw-bold cursor-pointer"
                      onClick={() => {
                        dispatch(signupModalHandler(true));
                        dispatch(loginModalHandler(false));
                      }}
                    >
                      Create New Account
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : loginSteps === 2 ? (
        <EditShow
          backdrop="static"
          show={loginModal}
          onHide={() => dispatch(loginModalHandler(false))}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default MyVerticallyCenteredModal;
