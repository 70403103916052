/** @format */

import React, { useRef, useState } from "react";
import classNames from "classnames/bind";
import CloseIcon from "../../../../images/cross.svg";
import styles from "../../../User/ResetPassword/resetPassword.module.css";
import { Form, InputGroup, FormControl, Button, Modal } from "react-bootstrap";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { leaveLiveMeetingHandler } from "../../../../store/slices/user";
import { Navigate, useNavigate } from "react-router-dom";

const cx = classNames.bind(styles);

const LeaveLive = ({
  showid,
  backdrop,
  show,
  onHide,
  handleEndMeetingForAllNow,
}) => {
  let props = {
    showid,
    backdrop,
    show,
    onHide,
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //Refs

  //States
  const [thirdButton, setThirdButton] = useState(false);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="container">
        <div className=" d-flex align-items-center justify-content-center">
          <div
            className={cx("outerBox", "bg-white", "p-4", "position-relative")}
          >
            <h4>Do you really want to leave?</h4>
            <div className={cx("landscapeMode")}>
              <div className="d-flex align-items-center justify-content-center mb-4 landscape-mb-4 cursor-pointer">
                <div
                  className={cx("position-absolute", "closeIcon")}
                  onClick={() => {
                    dispatch(leaveLiveMeetingHandler(false));
                    setThirdButton(false);
                  }}
                >
                  <img
                    className="img-fluid"
                    width="16px"
                    src={CloseIcon}
                    alt="cross"
                  />
                </div>
              </div>

              <Button
                className="w-100 btn btn-danger btn-sm fw-bold mt-3 d-none"
                variant="contained"
                size="lg"
                type="submit"
              >
                End for all in 30 minutes
              </Button>

              <Button
                className="w-100 btn btn-danger btn-sm fw-bold mt-3"
                variant="contained"
                size="lg"
                type="submit"
                onClick={() => {
                  localStorage.removeItem("liveMeetingShowId");
                  dispatch(leaveLiveMeetingHandler(false));
                  navigate(`/admin/show-detail/${showid}`);
                }}
              >
                Leave
              </Button>
              {thirdButton === true ? (
                <Button
                  className="w-100 btn btn-light text-danger border-danger btn-sm fw-bold mt-3"
                  variant="contained"
                  size="lg"
                  type="submit"
                  onClick={handleEndMeetingForAllNow}
                >
                  End for all now
                </Button>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <button
                    className="dots_btn m-3 d-none"
                    onClick={() => setThirdButton(true)}
                  >
                    <div className="dots_div">...</div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default LeaveLive;
