import React, { useState } from "react";
import classNames from "classnames/bind";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import css from "./addViaEmail.module.css";
import CloseIcon from "./../../images/close.svg";
import { Icon } from "@iconify/react";
const AddViaEmail = ({
  show,
  setAddViaEmailModal,
  handleEnterKey,
  handleEmailchange,
  emailField,
  styles,
  emailsList,
}) => {
  const cx = classNames.bind(css);
  let props = {
    show,
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="container">
        <div className=" d-flex align-items-center justify-content-center">
          <div
            className={cx(
              "outerBox",
              "bg-white",
              "p-4",
              "position-relative",
              "rounded"
            )}
          >
            <div className={cx("landscapeMode")}>
              <div className="d-flex align-items-center justify-content-center mb-4 landscape-mb-4">
                <h4 className="">Add via email address</h4>
                <div
                  onClick={props.onHide}
                  className={cx(
                    "position-absolute",
                    "closeIcon",
                    "cursor-pointer"
                  )}
                >
                  <img
                    className="img-fluid"
                    src={CloseIcon}
                    onClick={() => setAddViaEmailModal(false)}
                  />
                </div>
              </div>
              <input
                name="type"
                id=""
                className={`col-12  ${css.modalInput}`}
                onKeyPress={handleEnterKey}
                onChange={handleEmailchange}
                value={emailField}
                placeholder="Enter Email Address"
              />
              <div className={`col-12 mt-3 ${css.numberList}`}>
                {emailsList.map((email, index) => (
                  <div className={`${css.contactList}`}>
                    <Icon
                      icon={`emojione:letter-${email[0].toLowerCase()}`}
                      className="mr-2 col-1 text-right p-0"
                      width="30"
                      height="30"
                    />
                    <label className=" text-white   text-left col-7 m-0 p-0 ">
                      {email}
                    </label>

                    <input
                      className={`col-3 ${css.modalCheck} text-left`}
                      type="checkbox"
                      id={index}
                      name={index}
                      defaultChecked
                    />
                  </div>
                ))}
              </div>
              <button
                type="button"
                className={`btn text-white bg-primary me-2 px-5 py-2 col-12 rounded ${css.contactInvite}`}
                onClick={() => setAddViaEmailModal(false)}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddViaEmail;
