/** @format */

import React from "react";
import classNames from "classnames/bind";
import CloseIcon from "../../../images/cross.svg";
import styles from "./resetPasswordNew.module.css";
import { Form, Button } from "react-bootstrap";

const cx = classNames.bind(styles);
const ResetPasswordNew = () => {
  return (
    <div className="container">
      <div className=" d-flex align-items-center justify-content-center">
        <div className={cx("outerBox", "bg-white", "p-4", "position-relative")}>
          <div className={cx("landscapeMode")}>
            <div className="d-flex align-items-center justify-content-center mb-4 landscape-mb-4">
              <h2 className="text-center fw-bold">Reset Password</h2>
              <a href="#!" className={cx("position-absolute", "closeIcon")}>
                <img className="img-fluid" src={CloseIcon} />
              </a>
            </div>
            <Form>
              <div className="mb-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter 6 digit code"
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="New Password"
                />
              </div>
              <Button
                className="w-100 btn btn-danger btn-sm fw-bold"
                variant="contained"
                size="lg"
                type="submit"
              >
                Reset Password
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPasswordNew;
