import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// packages
import classNames from "classnames/bind";
import { Form, Button, Modal } from "react-bootstrap";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

//assets
import styles from "./otpModal.module.css";
import { userService } from "../../../../_services";
import {
    abbreviatedSignupModalHandler,
    login,
} from "../../../../store/slices/user";
const cx = classNames.bind(styles);

const OtpModal = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();
    const [otp, setOtp] = useState("");
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [isFormFilled, setIsFormFilled] = useState(false);
    const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
    const [isResendingOtp, setIsResendingOtp] = useState(false);

    const handleContine = (e) => {
        if (isFormFilled) {
            setIsFormSubmitting(true);
            setIsVerifyingOtp(true);
            userService
                .verifyOtp({
                    email: props.loginData.email,
                    otp: otp,
                })
                .then((data) => {
                    setIsVerifyingOtp(false);
                    setIsFormSubmitting(false);
                    if (data.success) {
                        userService
                            .registerNewUser(props.loginData)
                            .then((data) => {
                                if (data.success) {
                                    enqueueSnackbar("New User Successfully registered", {
                                        variant: "success",
                                    });
                                    localStorage.setItem("user", JSON.stringify(data.data));
                                    dispatch(login(data.data));
                                    navigate(`/create-meeting`);

                                    //   navigate(`/conference/pre-join/${url}/${token}`);
                                } else if (data.sucess === 0) {
                                    enqueueSnackbar("User already exhist. Please Sign in", {
                                        variant: "info",
                                    });
                                    navigate(`/signin`);
                                } else {
                                    enqueueSnackbar("Some Server error occured", {
                                        variant: "error",
                                    });
                                }
                            })
                            .catch((error) => {
                                enqueueSnackbar(error, {
                                    variant: "error",
                                });
                            });
                    } else {
                        enqueueSnackbar(data.message, {
                            variant: "error",
                        });
                    }
                })
                .catch((error) => {
                    enqueueSnackbar(error, {
                        variant: "error",
                    });
                });
        }
    };

    const handleOtp = (e) => {
        setOtp(e.target.value);
    };

    useEffect(() => {
        if (otp.length >= 4) {
            setIsFormFilled(true);
        } else {
            setIsFormFilled(false);
        }
    }, [otp]);

    const handleResendOtp = () => {
        document.getElementById("otp").value = "";
        setIsResendingOtp(true);
        userService
            .resendOtp({
                email: props.loginData.email,
            })
            .then((data) => {
                setIsResendingOtp(false);
                if (data.success) {
                    enqueueSnackbar(data.message, {
                        variant: "success",
                    });
                } else {
                    enqueueSnackbar(data.message, {
                        variant: "error",
                    });
                }
            })
            .catch((error) => {
                enqueueSnackbar(error, {
                    variant: "error",
                });
            });
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className="container">
                <div className=" d-flex align-items-center justify-content-center">
                    <div
                        className={cx(
                            "outerBoxRegister",
                            "bg-white",
                            "px-5",
                            "position-relative",
                            "py-5 rounded"
                        )}
                    >
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h5 className="m-0">Enter OTP</h5>
                            <div
                                onClick={props.onHide}
                                className="mobileClose inviteCross"
                            >
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 26 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M26 1.53183L24.4682 0L13 11.4682L1.53183 0L0 1.53183L11.4682 13L0 24.4682L1.53183 26L13 14.5318L24.4682 26L26 24.4682L14.5318 13L26 1.53183Z"
                                        fill="rgba(255,255,255,1)"
                                    />
                                </svg>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-7 col-sm-8 col-12 m-auto text-center">
                            <p className=" mb-4 landscape-mb-4">
                                Your code was sent to{" "}
                                <b className="text-blue">{props.loginData.email}</b>
                            </p>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    id="otp"
                                    className="border border-dark rounded"
                                    type="number"
                                    placeholder="Enter Code"
                                    value={otp}
                                    onChange={handleOtp}
                                />
                            </Form.Group>
                            <p className="">
                                This code will expire in 5 minutes.
                            </p>
                            {isVerifyingOtp && (
                                <div className="d-block text-blue mt-4 landscape-mt-4 landscape-mb-4">
                                    <span>Verifying...</span>
                                </div>
                            )}
                            {isResendingOtp && (
                                <div className="d-block text-blue mt-4 landscape-mt-4 landscape-mb-4">
                                    <span>Resending...</span>
                                </div>
                            )}
                            {!isVerifyingOtp && !isResendingOtp && (
                                <div
                                    className="d-block text-blue mt-4 landscape-mt-4 landscape-mb-4 cursor-pointer"
                                    onClick={handleResendOtp}
                                >
                                    <span>Resend Code</span>
                                </div>
                            )}

                            <Button
                                className={`${styles.continueButton} mt-4 mb-5 socialBtn  btn py-2 px-4 ${isFormFilled && "btn-danger text-light"
                                    }`}
                                variant="muted"
                                onClick={handleContine}
                                disabled={isFormSubmitting}
                            >
                                {isFormSubmitting ? `Loading...` : `Continue`}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default OtpModal;
