/** @format */

import React, { useRef, useState } from "react";
import classNames from "classnames/bind";
import MailIcon from "../../../images/emailIcon.svg";
import CloseIcon from "../../../images/cross.svg";
import styles from "./resetPassword.module.css";
import { Form, InputGroup, FormControl, Button, Modal } from "react-bootstrap";
import Global from "../../../config/Global";
import { useSnackbar } from "notistack";
import logo from "../../../images/logo.svg";
import PasswordForm from "../PasswordForm/PasswordForm";
import {
  loginModalHandler,
  resetPasswordModalHandler,
} from "../../../store/slices/user";
import { useDispatch } from "react-redux";

const cx = classNames.bind(styles);

const ResetPassword = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  //Refs
  const emailRef = useRef();
  //States
  const [filled, setFilled] = useState(false);
  const [otpForm, showOtpForm] = useState(false);
  const [email, setEmail] = useState("");
  const baseUrl = Global.baseUrl;
  //Functions
  const passwordReset = async () => {
    const response = await fetch(`${baseUrl}/user/password-reset-otp`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    });
    const data = await response.json();
    if (data.success === 0) {
      enqueueSnackbar("Email Sent", {
        variant: "success",
      });
      showOtpForm(true);
    } else if (data.success === 1) {
      enqueueSnackbar(data.message, {
        variant: "success",
      });
      showOtpForm(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    passwordReset();
  };

  const changeButtonColor = () => {
    if (emailRef.current.value !== "") {
      setFilled(true);
    } else {
      setFilled(false);
    }
  };

  const btnStyle = filled
    ? {
        backgroundColor: "#E50914",
        color: "white",
      }
    : {
        backgroundColor: "#E3E3E3",
        color: "black",
      };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="container">
        <div className=" d-flex align-items-center justify-content-center">
          <div
            className={cx("outerBox", "bg-white", "p-4", "position-relative")}
          >
            <div className={cx("landscapeMode")}>
              <div className="d-flex align-items-center justify-content-center mb-4 landscape-mb-4">
                <h2 className="text-center fw-bold">Reset Password</h2>
                <div
                  className={cx("position-absolute", "closeIcon")}
                  onClick={() => {
                    dispatch(resetPasswordModalHandler(false));
                    showOtpForm(false);
                  }}
                >
                  <img className="img-fluid" src={CloseIcon} alt="cross" />
                </div>
              </div>
              {otpForm ? (
                <PasswordForm email={email} />
              ) : (
                <Form onSubmit={handleSubmit}>
                  <InputGroup
                    size="lg"
                    className="border border-dark rounded mb-3 landscape-mb-3"
                  >
                    <FormControl
                      id="email"
                      placeholder="Enter email address"
                      aria-label="use email"
                      aria-describedby="use email"
                      className="border-white"
                      type="email"
                      name="email"
                      ref={emailRef}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyUp={changeButtonColor}
                      autoComplete="off"
                    />
                    <InputGroup.Text
                      id="use email"
                      className="fs-6 bg-white border-white"
                    >
                      <img src={MailIcon} />
                    </InputGroup.Text>
                  </InputGroup>
                  <Button
                    className="w-100 btn btn-danger btn-sm fw-bold"
                    variant="contained"
                    size="lg"
                    type="submit"
                  >
                    Email me a Code
                  </Button>
                  <p className="text-center mb-2 small mt-5 landscape-mt-5">
                    Remember your password?
                  </p>
                  <div
                    className="h6 d-block text-danger text-center fw-bold cursor-pointer"
                    onClick={() => {
                      dispatch(loginModalHandler(true));
                      dispatch(resetPasswordModalHandler(false));
                      //   navigate("/login", {
                      //     state: {
                      //       email: location.state?.email || "",
                      //     },
                      //   });
                    }}
                  >
                    Log In
                  </div>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ResetPassword;
