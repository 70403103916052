import React from "react";

export const MicIcon3 = ({enabled}) => {
    return <>
        {enabled ? (
            <svg
                width="24"
                height="26"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17.9995 4.5C16.8061 4.5 15.6615 4.97411 14.8175 5.81802C13.9736 6.66193 13.4995 7.80653 13.4995 9V15C13.4995 16.1935 13.9736 17.3381 14.8175 18.182C15.6615 19.0259 16.8061 19.5 17.9995 19.5C19.193 19.5 20.3376 19.0259 21.1815 18.182C22.0254 17.3381 22.4995 16.1935 22.4995 15V9C22.4995 7.80653 22.0254 6.66193 21.1815 5.81802C20.3376 4.97411 19.193 4.5 17.9995 4.5ZM17.9995 1.5C18.9844 1.5 19.9597 1.69399 20.8697 2.0709C21.7796 2.44781 22.6064 3.00026 23.3028 3.6967C23.9993 4.39314 24.5517 5.21993 24.9286 6.12987C25.3055 7.03982 25.4995 8.01509 25.4995 9V15C25.4995 16.9891 24.7094 18.8968 23.3028 20.3033C21.8963 21.7098 19.9887 22.5 17.9995 22.5C16.0104 22.5 14.1028 21.7098 12.6962 20.3033C11.2897 18.8968 10.4995 16.9891 10.4995 15V9C10.4995 7.01088 11.2897 5.10322 12.6962 3.6967C14.1028 2.29018 16.0104 1.5 17.9995 1.5V1.5ZM4.58203 16.5H7.60453C7.96797 18.9972 9.21834 21.28 11.1269 22.9309C13.0354 24.5818 15.4745 25.4903 17.998 25.4903C20.5215 25.4903 22.9606 24.5818 24.8692 22.9309C26.7777 21.28 28.0281 18.9972 28.3915 16.5H31.4155C31.0745 19.5431 29.7095 22.3799 27.5443 24.5453C25.3792 26.7107 22.5426 28.0761 19.4995 28.4175V34.5H16.4995V28.4175C13.4562 28.0764 10.6192 26.7111 8.45379 24.5457C6.28838 22.3803 4.92315 19.5433 4.58203 16.5V16.5Z"
                    fill="black"
                />
            </svg>
        ) : (
            <svg
                width="24"
                height="26"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17.9995 4.5C16.8061 4.5 15.6615 4.97411 14.8175 5.81802C13.9736 6.66193 13.4995 7.80653 13.4995 9V15C13.4995 16.1935 13.9736 17.3381 14.8175 18.182C15.6615 19.0259 16.8061 19.5 17.9995 19.5C19.193 19.5 20.3376 19.0259 21.1815 18.182C22.0254 17.3381 22.4995 16.1935 22.4995 15V9C22.4995 7.80653 22.0254 6.66193 21.1815 5.81802C20.3376 4.97411 19.193 4.5 17.9995 4.5ZM17.9995 1.5C18.9844 1.5 19.9597 1.69399 20.8697 2.0709C21.7796 2.44781 22.6064 3.00026 23.3028 3.6967C23.9993 4.39314 24.5517 5.21993 24.9286 6.12987C25.3055 7.03982 25.4995 8.01509 25.4995 9V15C25.4995 16.9891 24.7094 18.8968 23.3028 20.3033C21.8963 21.7098 19.9887 22.5 17.9995 22.5C16.0104 22.5 14.1028 21.7098 12.6962 20.3033C11.2897 18.8968 10.4995 16.9891 10.4995 15V9C10.4995 7.01088 11.2897 5.10322 12.6962 3.6967C14.1028 2.29018 16.0104 1.5 17.9995 1.5V1.5ZM4.58203 16.5H7.60453C7.96797 18.9972 9.21834 21.28 11.1269 22.9309C13.0354 24.5818 15.4745 25.4903 17.998 25.4903C20.5215 25.4903 22.9606 24.5818 24.8692 22.9309C26.7777 21.28 28.0281 18.9972 28.3915 16.5H31.4155C31.0745 19.5431 29.7095 22.3799 27.5443 24.5453C25.3792 26.7107 22.5426 28.0761 19.4995 28.4175V34.5H16.4995V28.4175C13.4562 28.0764 10.6192 26.7111 8.45379 24.5457C6.28838 22.3803 4.92315 19.5433 4.58203 16.5V16.5Z"
                    fill="#E50914"
                />
                <rect
                    x="2.85938"
                    y="2.12109"
                    width="3"
                    height="39.8705"
                    transform="rotate(-45 2.85938 2.12109)"
                    fill="#E50914"
                />
            </svg>
        )}
    </>
}