/** @format */

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Container, Modal, Typography, Box } from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import Home from "./Pages/Home/Home";
import MainProfile from "./Pages/MainProfile/MainProfile";
import EditProfile from "./Pages/EditProfile/EditProfile";
import ShowCategory from "./Pages/ShowCategory/ShowCategory";
import Notifications from "./Pages/Notifications/Notifications";
import Privacy from "./Pages/Privacy/Privacy";
import Signup from "./Pages/User/Signup/Signup";
import Login from "./Pages/User/Login/Login";
import Register from "./Pages/User/Register/Register";
import RegisterOtp from "./Pages/User/RegisterOtp/RegisterOtp";
import RegisterBirthday from "./Pages/User/RegisterBirthday/RegisterBirthday";
import RegisterLocation from "./Pages/User/RegisterLocation/RegisterLocation";
import RegisterAvatar from "./Pages/User/RegisterAvatar/RegisterAvatar";
import ResetPassword from "./Pages/User/ResetPassword/ResetPassword";
import ResetPasswordNew from "./Pages/User/ResetPasswordNew/ResetPasswordNew";
import RegisterAboutInfo from "./Pages/User/RegisterAboutInfo/RegisterAboutInfo";
import Profile from "./Pages/User/Profile/Profile";
import GuestRoute from "./PreJoinRoute";
import ChangePassword from "./";
import CreateShow from "./Pages/Admin/CreateShow/CreateShow";
import CreateSeries from "./Pages/Admin/CreateSeries/CreateSeries";
import CreateEpisode from "./Pages/Admin/CreateEpisode/CreateEpisode";
import ScheduleShow from "./Pages/Admin/ScheduleShow/ScheduleShow";
import HomeSetup from "./Pages/Admin/HomeSetup/HomeSetup";
import ShowList from "./Pages/Admin/ShowList/ShowList";
import PreJoin from "./Pages/Admin/PreJoin/PreJoin";
import AdminRouteRoute from "./AdminRoute";
import ShowDetail from "./Pages/Admin/ShowDetail/ShowDetail";
import GridMode from "./Pages/Admin/GridMode/GridMode";
//
import ScheduleMeeting from "./Pages/HookZApp/ScheduleMeeting/ScheduleMeeting";
import JoinAMeeting from "./Pages/HookZApp/JoinAMeeting/JoinAMeeting";
import CreateAmeeting from "./Pages/HookZApp/CreateAmeeting/CreateAmeeting";
import JoinAcall from "./Pages/HookZApp/JoinAcall/JoinAcall";

//
import { useEffect, useState, useLayoutEffect } from "react";
import MyVerticallyCenteredModal from "./Pages/User/Modals/LoginPopUp/LoginPopUp";
import { useDispatch, useSelector } from "react-redux";
import {
  abbreviatedSignupModalHandler,
  loginModalHandler,
  programEndsModalHandler,
  resetPasswordModalHandler,
  signupModalHandler,
  socialEmailModalHandler,
} from "./store/slices/user";
import SignUpPopUp from "./Pages/User/Modals/SignUpPop/SignUpPopUp";
import AbbreviatedSignUp from "./Pages/User/AbbreviatedSignUp/AbbreviatedSignUp";
import RotateImg from "./images/mRotate.svg";
import "./App.css";
import { isMobile } from "react-device-detect";
import { Redirect } from "react-router-dom";

import _ from "lodash";
import RedirectRouteRoute from "./RedirectRoute";
import { LiveVideoNewTop } from "./Pages/Admin/liveVideo/liveVideoNew/LiveVideoNewTop";
import { LiveVideoNewBot } from "./Pages/Admin/liveVideo/liveVideoNew/LiveVideoNewBot";
import SocialEmail from "./Pages/User/Modals/SocialEmail/SocialEmail";
import PrivacyPolicy from "./Pages/Admin/Footer/PrivacyPolicy";
import TermsConditions from "./Pages/Admin/Footer/TermsConditions";
import UsersList from "./Pages/Admin/UsersList/UsersList";
import ManageEmailPreference from "./Pages/User/ManageEmailPreference/ManageEmailPreference";
import ProgramEnds from "./Pages/Admin/liveVideo/GridMode/ProgramEndModal";
import SignIn from "./Pages/HookZApp/SignIn/SignIn";
import SignUp from "./Pages/HookZApp/SignUp/SignUp";
import ProtectedRoute from "./ProtectedRoute";
import LoggedInRoute from "./LoggedInRoute";
import PreJoinRoute from "./PreJoinRoute";
import UpcomingMeetings from "./Pages/HookZApp/UpcomingMeetings/UpcomingMeetings";
import PrivateMeetingJoin from "./Pages/HookZApp/PrivateMeetingJoin/PrivateMeetingJoin";
import GuestLogin from "./Pages/HookZApp/GuestLogin/GuestLogin";

const theme = createTheme({
  palette: {
    primary: {
      light: "#335a73",
      main: "#003151",
      dark: "#002238",
    },
    secondary: {
      light: "#335a73",
      main: "#003151",
      dark: "#002238",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

function App() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showPortraitModal, setShowPortraitModal] = useState(false);
  useEffect(() => {
    const updateSize = () => {
      if (isMobile && window.innerWidth < window.innerHeight) {
        setShowPortraitModal(false);
      } else {
        setShowPortraitModal(false);
      }
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const {
    user,
    loginModal,
    signupModal,
    resetPasswordModal,
    abbreviatedSignupModal,
    socialEmailModal,
  } = useSelector((state) => state.user);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="xl" className={classes.root}>
          <Modal open={showPortraitModal}>
            <Box className="modalBoxStyle">
              <div>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  To view OurPrime, please rotate your device to landscape mode.
                </Typography>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <img width="80" src={RotateImg} />
                </div>
              </div>
            </Box>
          </Modal>

          {/* <Route
                path="/:room"
                element={
                  <PrivateRoute>
                    <PresenterOld />
                  </PrivateRoute>
                }
              /> */}
          {/* <Route path="/home" element={<Home />} />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <MainProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-profile"
              element={
                <PrivateRoute>
                  <EditProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/show-category/:categoryId"
              element={<ShowCategory />}
            />
            <Route path="/show-category" element={<ShowCategory />} />
            <Route
              path="/notifications"
              element={
                <PrivateRoute>
                  <Notifications />
                </PrivateRoute>
              }
            /> */}

          {/* <Route
                path="/changePassword"
                element={
                  <PrivateRoute>
                    <ChangePassword />
                  </PrivateRoute>
                }
              /> */}
          {/* <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} /> */}
          {/* <Route path="/register" element={<Register />} />
              <Route path="/register-otp" element={<RegisterOtp />} />
              <Route path="/register-birthday" element={<RegisterBirthday />} />
              <Route path="/register-location" element={<RegisterLocation />} />
              <Route path="/register-avatar" element={<RegisterAvatar />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/reset-password-new"
                element={<ResetPasswordNew />}
              />
              <Route
                path="/register-about-info"
                element={<RegisterAboutInfo />}
              /> */}

          {/* <Route
              path="/profile/pro"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route path="/register" element={<Register />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route
              path="/admin/create-show"
              element={
                <AdminRouteRoute>
                  <CreateShow />
                </AdminRouteRoute>
              }
            />
            <Route
              path="/admin/show/:id"
              element={
                <AdminRouteRoute>
                  <CreateShow />
                </AdminRouteRoute>
              }
            />
            <Route
              path="/admin/show/:id/redirect"
              element={
                <RedirectRouteRoute>
                  <CreateShow />
                </RedirectRouteRoute>
              }
            />

            <Route path="/admin/create-series" element={<CreateSeries />} />
            <Route path="/admin/create-episode" element={<CreateEpisode />} />
            <Route
              path="/admin/schedule-show/:showId"
              element={<ScheduleShow />}
            />
            <Route
              path="/admin/home-setup"
              element={
                <AdminRouteRoute>
                  <HomeSetup />
                </AdminRouteRoute>
              }
            />
            <Route
              path="/admin/show-list"
              element={
                <AdminRouteRoute>
                  <ShowList />
                </AdminRouteRoute>
              }
            />
            <Route
              path="/admin/users-list"
              element={
                <AdminRouteRoute>
                  <UsersList />
                </AdminRouteRoute>
              }
            /> */}
          {/* <Route path="/admin/show-detail/:showId" element={<ShowDetail />} /> */}
          {/* <Route path="/conference/pre-call" element={<Presenter />} /> */}
          {/* <Route path="/conference/pre" element={<Precall />} /> */}
          {/*  />
            <Route
              path="/conference/live/bot/:showId"
              element={<LiveVideoNewBot />}
            />
            <Route path="/conference/pre-join" element={<PreJoin />} />
            <Route
              path="/conference/pre-join/:meetingId"
              element={<PreJoin />}
            />

            <Route path="/conference/grid-mode" element={<GridMode />} />

            <Route
              path="/admin/schedule-show/:showId/"
              element={
                <AdminRouteRoute>
                  <ScheduleShow />
                </AdminRouteRoute>
              }
            />

            <Route path="/manage-emails" element={<ManageEmailPreference />} /> */}

          {/*  */}
          <Routes>
            <Route
              exact
              path="/"
              element={
                <LoggedInRoute>
                  <Navigate
                    replace
                    to="/signin
                "
                  />
                </LoggedInRoute>
              }
            />
            <Route
              path="/signup"
              element={
                <LoggedInRoute>
                  <SignUp />{" "}
                </LoggedInRoute>
              }
            />

            <Route
              path="/signin"
              element={
                <LoggedInRoute>
                  <SignIn />
                </LoggedInRoute>
              }
            />
            <Route
              path="/schedule-meeting/"
              element={
                <ProtectedRoute>
                  <ScheduleMeeting />
                </ProtectedRoute>
              }
            />
            <Route
              path="/upcoming-meetings/"
              element={
                <ProtectedRoute>
                  <UpcomingMeetings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/schedule-meeting/:token"
              element={
                // <ProtectedRoute>
                <ScheduleMeeting />
                // </ProtectedRoute>
              }
            />
            {/* <Route path="/join-meeting" element={<JoinAMeeting />} /> */}

            <Route
              path="/create-meeting"
              element={
                <ProtectedRoute>
                  <CreateAmeeting />
                </ProtectedRoute>
              }
            />
            <Route
              path="/guest-login/:meetingId/:email"
              element={
                <LoggedInRoute>
               <GuestLogin />
               </LoggedInRoute>
              }
            />
            {/* <Route
              path="/create-meeting/:token"
              element={
                <ProtectedRoute>
                  <CreateAmeeting />
                </ProtectedRoute>
              }
            /> */}

            {/* <Route path="/join-call" element={<JoinAcall />} />
            <Route path="/join-call/:meetingId" element={<JoinAcall />} /> */}

            {/* <Route path="/conference/pre-join" element={<PreJoin />} /> */}

            <Route
              path="/conference/pre-join/:meetingId"
              element={
                <PreJoinRoute>
                  <PreJoin />
                </PreJoinRoute>
              }
            />
            <Route
              path="/conference/pre-join/:meetingId/:token"
              element={
                // <ProtectedRoute>
                <PreJoin />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/conference/live"
              element={
                <PreJoinRoute>
                  <LiveVideoNewTop />
                  </PreJoinRoute>
              }
            />
            <Route
              path="/private-meeting/:meetingId"
              element={
                <ProtectedRoute>
                  <PrivateMeetingJoin />
                </ProtectedRoute>
              }
            />
             {/* <Route
              path="/private-meeting/:meetingId/:token"
              element={
                  <PrivateMeetingJoin />
              } 
            />*/}
            <Route
              path="/conference/live/:token"
              element={
                // <ProtectedRoute>
                <LiveVideoNewTop />
                // </ProtectedRoute>
              }
            />
            {/* <Route
              exact
              path="*"
              element={
                <Navigate
                  replace
                  to="/signup
                "
                />
              }
            /> */}
            {/* 
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/termsofService" element={<TermsConditions />} /> */}
            {/*  */}
            <Route
              exact
              path="*"
              element={
                <Navigate replace to={user ? "/upcoming-meetings" : "/signup"} />
              }
            />

          </Routes>

          <MyVerticallyCenteredModal
            backdrop="static"
            show={loginModal}
            onHide={() => dispatch(loginModalHandler(false))}
          />
          <SignUpPopUp
            backdrop="static"
            show={signupModal}
            onHide={() => dispatch(signupModalHandler(false))}
          />
          <SocialEmail
            backdrop="static"
            show={socialEmailModal}
            onHide={() => dispatch(socialEmailModalHandler(false))}
          />
          <ResetPassword
            backdrop="static"
            show={resetPasswordModal}
            onHide={() => dispatch(resetPasswordModalHandler(false))}
          />
          <AbbreviatedSignUp
            backdrop="static"
            show={abbreviatedSignupModal}
            onHide={() => dispatch(abbreviatedSignupModalHandler(false))}
          />
        </Container>
      </ThemeProvider>
    </>
  );
}
export default App;
