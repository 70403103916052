import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// redux
import { logout } from "../../../store/slices/user";

// packages
import _ from "lodash";

// assests
import logo from "./../images/logo.svg";
import caret from "./../images/logo.svg";
// import { setLogin } from "../../../store/slices/uptalk";
import { useNavigate } from "react-router-dom";
import css from "./header.module.css";
import { Button } from "react-bootstrap";
const Header = ({ executeHowItWorks, pathname }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isHamburgerMenuVisible, setIsHamburgerMenuVisible] = useState(false);

  const toggleModal = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <header className="d-none d-sm-block">
      <nav
        className="navbar navbar-expand-lg shadow header "
        style={{ zIndex: 1000 }}
      >
        <div className="container-fluid d-flex px-lg-5 px-md-5 px-ms-4 px-4">
          <a
            href={`/`}
            className="navbar-brand me-lg-5 me-md-5 me-sm-0 me-0 logo"
          >
            <img width="120" src={logo} alt="" />
          </a>
          <div className="ms-auto py-1">
            <div className="d-flex align-items-center">
              {pathname === "/" && (
                <>
                  <p
                    className="text-light me-lg-5 me-md-0 me-sm-0 me-0 d-xl-block d-lg-block d-sm-none d-none cursor-pointer"
                    onClick={() => {
                      setIsHamburgerMenuVisible(false);
                      executeHowItWorks();
                    }}
                  >
                    How It Works
                  </p>
                  <p
                    className="text-light me-lg-5 me-md-0 me-sm-0 me-0  d-xl-block d-lg-block d-sm-none d-none cursor-pointer"
                    onClick={() => navigate("/therapists")}
                  >
                    Meet Our Therapists
                  </p>
                </>
              )}
            </div>
          </div>

          <div className="" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item dropdown position-relative cursor-pointer">
                <div className="d-flex align-items-center">
                  {user ? (
                    <>
                      <div
                        className={`headRound  text-white d-flex align-items-center justify-content-center small ${css.profileName}`}
                        onClick={toggleModal}
                      >
                        {user.profile_image ? (
                          <img
                            className="img-fluid rounded-circle"
                            src={user.profile_image}
                            width="100px"
                            alt={user.full_name}
                          />
                        ) : (
                          `${user?.first_name}`
                        )}
                      </div>
                    </>
                  ) : (
                    <Button
                      type="button"
                      className={`btn-sm px-4 py-2 fs-7 ${css.loginButton}`}
                      onClick={() => navigate("/signin")}
                    >
                      Sign In
                    </Button>
                  )}
                </div>
                <div
                  className={`box  arrow-top shadow-sm position-absolute mt-2 ${
                    !showTooltip && "d-none"
                  } ${css.dropdown} `}
                >
                  <div className="d-flex">
                    <div className="w-100">
                      <p className="text-white fs-12 mb-0">
                        {user?.first_name} {user?.last_name}
                      </p>
                      <p className="text-white mb-0 ">
                        {user?.email?.length > 25
                          ? user?.email?.substr(0, 25) + "..."
                          : user?.email}
                      </p>
                    </div>
                  </div>

                  <ul className="ps-0 mt-3 list-unstyled">
                    <li className="fs-12">
                      <span
                        className="text-white"
                        onClick={() => {
                          dispatch(logout());
                          toggleModal();
                        }}
                      >
                        Logout
                      </span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
export default Header;
