/** @format */

// import config from 'config';
import { GlobalConfig } from "../../config";
import { authHeader, authHeaderFormData } from "../../_helpers";
import axios from "axios";

// redux
import {
  setShowRecordingsUploadAxiosRequests,
  setShowImagesUploadAxiosRequests,
} from "../../store/slices/connection";

const CancelToken = axios.CancelToken;
let axiosUploadTrailerCancelExecuter;
// let axiosUploadRecordingCancelExecuter;

export const showService = {
  cancelUploadTrailer,
  add,
  uploadVideo,
  deleteVideo,
  uploadImage,
  deleteImage,
  addComplete,
  deleteComplete,
  addCompleteOptional,
  searchHosts,
  inviteHost,
  hideUnhideHost,
  sendEmailToHost,
  deleteHost,
  addScheduleShow,
  get,
  addStreams,
  getStreams,
  addOrRemoveAttendee,
  getScheduledShowOnDate,
  getMyScheduleShows,
  getAllContinueWatching,
  createContinueWatching,
  getShowWatchedTime,
  getMyAttendingShows,
  getProfileInfo,
  updateProfileInfo,
  createRoom,
  getHeroShow,
  markShowAsHero,
  getShowHosts,
  getShowTitleAndDescription,
  getOldPassword,
  getCategoryShows,
  removeShowScheduledDateTime,
  addShowEpisode,
  getShowEpisodes,
  getShowViaShowId,
  getMasterShow,
  getUsersList,
  getUserDetails,
  editHomePageStrip,
};

function add(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${GlobalConfig.baseUrl}/admin/show/add`, requestOptions).then(
    handleResponse
  );
}

function uploadVideo(formData, reduxDisptch, uniqueIndex) {
  return axios({
    method: "post",
    url: `${GlobalConfig.baseUrl}/admin/show/add-video`,
    data: formData,
    headers: authHeaderFormData(),
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      for (var pair of formData.entries()) {
        if (pair[0] === "type") {
          if (pair[1] === "1") {
            axiosUploadTrailerCancelExecuter = c;
          } else if (pair[1] === "2") {
            let item = {};
            item.id = uniqueIndex;
            item.callingFunction = c;
            reduxDisptch(setShowRecordingsUploadAxiosRequests(item));
          }
        }
      }
    }),
  }).then(handleAxioResponse);
  // return axios
  //   .post(`${GlobalConfig.baseUrl}/admin/show/add-video`, formData, {
  //     headers: authHeaderFormData(),
  //     cancelToken: cancelSource.token,
  //   })
  //   .then(handleAxioResponse);
}

function cancelUploadTrailer() {
  axiosUploadTrailerCancelExecuter();
}

function deleteVideo(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/delete-video`,
    requestOptions
  ).then(handleResponse);
}

function uploadImage(formData, reduxDisptch, uniqueIndex) {
  return axios({
    method: "post",
    url: `${GlobalConfig.baseUrl}/admin/show/add-image`,
    data: formData,
    headers: authHeaderFormData(),
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter

      let item = {};
      item.id = uniqueIndex;
      item.callingFunction = c;
      reduxDisptch(setShowImagesUploadAxiosRequests(item));
    }),
  }).then(handleAxioResponse);
}

function deleteImage(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/delete-image`,
    requestOptions
  ).then(handleResponse);
}

function searchHosts(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/search-hosts`,
    requestOptions
  ).then(handleResponse);
}

function inviteHost(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/add-host`,
    requestOptions
  ).then(handleResponse);
}

function hideUnhideHost(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/hide-unhide-host`,
    requestOptions
  ).then(handleResponse);
}

function sendEmailToHost(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/send-email-to-host`,
    requestOptions
  ).then(handleResponse);
}

function deleteHost(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/delete-host`,
    requestOptions
  ).then(handleResponse);
}

function addComplete(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/add-complete`,
    requestOptions
  ).then(handleResponse);
}

function deleteComplete(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/delete-complete`,
    requestOptions
  ).then(handleResponse);
}

function addCompleteOptional(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/add-complete-optional`,
    requestOptions
  ).then(handleResponse);
}

function addScheduleShow(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/schedule`,
    requestOptions
  ).then(handleResponse);
}

function get(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/get/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getScheduledShowOnDate(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/get-schedule-shows-on-date`,
    requestOptions
  ).then(handleResponse);
}

function getMyScheduleShows(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/get-my-schedule-shows`,
    requestOptions
  ).then(handleResponse);
}

function getAllContinueWatching(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/get-all-continue-watching`,
    requestOptions
  ).then(handleResponse);
}

function createContinueWatching(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/create-continue-watching`,
    requestOptions
  ).then(handleResponse);
}

function getShowWatchedTime(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/get-show-watched-time`,
    requestOptions
  ).then(handleResponse);
}

function getMyAttendingShows(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/get-my-attending-shows`,
    requestOptions
  ).then(handleResponse);
}

function getProfileInfo(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/get-profile-info`,
    requestOptions
  ).then(handleResponse);
}

function updateProfileInfo(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/update-profile-info`,
    requestOptions
  ).then(handleResponse);
}

function getOldPassword(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/change-password-edit-profile`,
    requestOptions
  ).then(handleResponse);
}

function addOrRemoveAttendee(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/add-or-remove-attendee`,
    requestOptions
  ).then(handleResponse);
}

function addStreams(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/add-streams`,
    requestOptions
  ).then(handleResponse);
}

function getStreams(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/get-streams`,
    requestOptions
  ).then(handleResponse);
}

function getUsersList(data) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/users-list`,
    requestOptions
  ).then(handleResponse);
}

function getUserDetails(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/get-user-info`,
    requestOptions
  ).then(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // location.reload(true);
      }

      // const error = (data && data.message) || response.statusText;
      const error = data || response.statusText;
      if (error.hasOwnProperty("message")) {
        return Promise.reject(error.message);
      } else {
        let errorArray = Object.values(error.errors);
        return Promise.reject(errorArray[0]); // show first error
      }

      // errorArray.map((val) => {
      //   return Promise.reject(val);
      // });
    }
    return data;
  });
}

function createRoom(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${GlobalConfig.baseUrl}/user/create-room`, requestOptions).then(
    handleResponse
  );
}

function getHeroShow() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/user/show/get-hero-show`,
    requestOptions
  ).then(handleResponse);
}

function markShowAsHero(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/mark-show-hero`,
    requestOptions
  ).then(handleResponse);
}

function getShowHosts(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/get-hosts-of-show`,
    requestOptions
  ).then(handleResponse);
}

function getShowTitleAndDescription(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/get-show-title-and-description`,
    requestOptions
  ).then(handleResponse);
}

function handleAxioResponse(response) {
  return new Promise((resolve, reject) => {
    const { data, status } = response;
    if (!(status === 200)) {
      if (status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // location.reload(true);
      }
      // const error = (data && data.message) || response.statusText;
      const error = data || response.statusText;
      if (error.hasOwnProperty("message")) {
        reject(error.message);
      } else {
        let errorArray = Object.values(error.errors);
        reject(errorArray[0]); // show first error
      }
      // errorArray.map((val) => {
      //   return Promise.reject(val);
      // });
    } else {
      resolve(data);
    }
  });
}

function getCategoryShows(data, page, offset) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/get-category-shows?page=${page}&offset=${offset}`,
    requestOptions
  ).then(handleResponse);
}

function removeShowScheduledDateTime(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/delete-schedule`,
    requestOptions
  ).then(handleResponse);
}

function addShowEpisode(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/add-show-episode`,
    requestOptions
  ).then(handleResponse);
}

function getShowViaShowId(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/get-show-via-showid`,
    requestOptions
  ).then(handleResponse);
}

function getShowEpisodes(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/get-show-episodes`,
    requestOptions
  ).then(handleResponse);
}

function getMasterShow(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/show/get-master-show`,
    requestOptions
  ).then(handleResponse);
}

function editHomePageStrip(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/admin/edit-homepage-strip`,
    requestOptions
  ).then(handleResponse);
}
