import Constants from "../config/constants";
import { useDispatch, useSelector } from "react-redux";

import {
  isLoggedIn,
  loggedUser,
  jwtToken,
  clearLocalStorage,
} from "./authService";
import {
  generateUserJid,
  generateGroupJid,
  getNickNameFromJid,
  printStanza,
  textToXml,
  getMimeTypefromExtension,
  getRandomString
} from "../Utils/helper";
import { parseMessage, parseMUCMessage } from "../Utils/parser";
import store from "../store/index";
import sizzle from "sizzle";
import {
  setXmppConnectionStatus,
  // setXmppUserPresence,
  setXmppUserRecentChats,
  // setDefaultRightSideWindowScreenStatus,
  // setXmppUserChatOpen,
  // setXmppUserChatHistory,
  setXmppUserMessageSend,
  setXmppUserMessageReceived,
  // setScrollChatToBottom,
  // setXmppUserMarkerSent,
  // setXmppUserMarkerReceived,
  setXmppSpotlightMessageReceived,
  setXmppUserMessagePagination,
  setIsXmppFetchingChatHistoryOnShowDetail,
  setChatMessagesLoader,
} from "../store/slices/xmppConnection";
import $ from "jquery";
import _, { findIndex, find, now, isUndefined } from "lodash";
const { Strophe, $msg, $pres, $iq } = window;

let isIncomingGroupMessagesHandlerAdded = false;


export const APP = {};

export const xmppConnect = (jid = "", password = "") => {

  if (jid == "" && password == "") {
    jid = loggedUser().username;
    password = Constants.XMPPDEFAULTUSERPASSWORD //jwtToken();
  }
  console.log('jid :', jid, ' paswowrd', password)
  if (_.isNull(jid) || _.isUndefined(jid)) return;

  APP.connection = new Strophe.Connection(Constants.XMPP_URL, {
    keepalive: true,
  });
  jid = generateUserJid(jid, true, getRandomString(6));
  console.log('app connection', APP.connection)
  console.log('user ', Constants.XMPPDEFAULTUSER, ' pass ', Constants.XMPPDEFAULTUSERPASSWORD)
  APP.connection.connect(Constants.XMPPDEFAULTUSER + `@xmppprod.hookzapp.com/${getRandomString(6)}`, Constants.XMPPDEFAULTUSERPASSWORD, function (status) {
    store.dispatch(setXmppConnectionStatus(status));
    switch (status) {
      case Strophe.Status.CONNECTED:
        APP.bare_jid = Strophe.getBareJidFromJid(APP.connection.jid);
        handlePings();
        break;
      case Strophe.Status.ATTACHED:
        break;

      case Strophe.Status.DISCONNECTED:
        store.dispatch(setXmppConnectionStatus(undefined));
        break;

      case Strophe.Status.AUTHFAIL:
        // clearLocalStorage();
        break;

      case Strophe.Status.CONNECTING:
        break;

      case Strophe.Status.DISCONNECTING:
        break;

      case Strophe.Status.AUTHENTICATING:
        break;

      case Strophe.Status.ERROR:
      case Strophe.Status.CONNFAIL:
        break;

      default:
        break;
    }
  });
};

export const xmppDisconnect = () => {
  APP.connection.disconnect();
};

export const sendPresence = () => {
  APP.connection.send($pres().c("priority").t(5).up().tree());
};

export const subscribePresence = () => {
  APP.connection.addHandler(
    function (presence) {
      let presence_type = $(presence).attr("type");
      let from = Strophe.getBareJidFromJid($(presence).attr("from"));
      let status = "";
      if (presence_type != "error") {
        if (presence_type === "unavailable") {
          status = "offline";
        } else {
          var show = $(presence).find("show").text();
          if (show === "chat" || show === "") {
            status = "online";
          } else if (show === "away") {
            status = "away";
          } else {
            status = "";
          }
        }
      }
      const presenceObj = {
        jid: from,
        status: status,
        type: presence_type || null,
        time: new Date().toISOString(),
      };
      // store.dispatch(setXmppUserPresence(presenceObj));
      return true;
    },
    null,
    "presence"
  );
  // });
};

export const getRecentChats = () => {
  let temp = [];
  APP.connection.inbox.query({
    onMessage: async function (message) {
      console.log('parseMessage(message).spotlight', parseMessage(message).spotlight);
      if (parseMessage(message).spotlight) {
        return true;
      }
      if (parseMessage(message).type == Constants.XMPP_STANZA_TYPES.CHAT) {
        console.log('XMPP_USER_RECENT_CHATS ');
        console.log("XmppUserRecentChats stanza ", message);
        // store.dispatch(setXmppUserRecentChats(parseMessage(message)));
      } else {
        store.dispatch(setXmppUserRecentChats(parseMUCMessage(message)));
      }
      //temp.push(createObjectForRecentChat(message));

      return true;
    },
    onComplete: function (iq) {
      //store.dispatch(setXmppUserRecentChats(temp));
    },
  });
};

export const subscribeIncomingChatMessages = () => {
  APP.connection.addHandler(
    async function (message) {
      console.log("received : ", printStanza(message));
      // let omemomsg = await testOmemoDecrypt(message);
      // console.log("omemomsg: ",omemomsg);
      console.log("messages decryptFetchAndProcessStanza ", message);
      const parsedMessage = parseMessage(message);
      // console.log("parsedMessage : ",parsedMessage);
      store.dispatch(setXmppUserMessageReceived(parsedMessage));
      if (parsedMessage.is_markable === true) {
        sendMarker(parsedMessage, "received");
        sendMarker(parsedMessage, "displayed");
      }
      /* if(parsedMessage.is_marker){
        store.dispatch(setXmppUserMarkerReceived(parsedMessage));
      } */
      _scrollChatToBottom(parseMessage(message));
      return true;
    },
    null,
    "message",
    "chat"
  );
};

export const subscribeIncomingGroupMessages = () => {
  if (!isIncomingGroupMessagesHandlerAdded) {
    isIncomingGroupMessagesHandlerAdded = true;
    APP.connection.addHandler(
      function (message) {
        // console.log('subscribeIncomingGroupMessages ', message)
        const parsedMessage = parseMUCMessage(message);

        if (parsedMessage.spotlight) {
          store.dispatch(setXmppSpotlightMessageReceived(parsedMessage));
        } else {
          let redux_existing_msg_data =
            store.getState().xmppConnection.XMPP_USER_RECENT_CHATS;
          // console.log('redux_existing_msg_data subscribeIncomingGroupMessages', redux_existing_msg_data)
          // console.log('redux_existing_msg_data subscribeIncomingGroupMessages parsedMessage', parsedMessage)

          let temp = [];
          redux_existing_msg_data.forEach(function (data, index) {
            temp.push(data);
          });
          temp.push(parsedMessage);

          store.dispatch(setXmppUserMessageReceived(temp));
        }
        // /* if (parsedMessage.is_markable === true) {
        //   sendMarker(parsedMessage, "received");
        //   sendMarker(parsedMessage, "displayed");
        // } */

        _scrollChatToBottom(parsedMessage);
        return true;
      },
      null,
      "message",
      Constants.XMPP_STANZA_TYPES.GROUP
    );
  }
};

export const getChatHistory = (chat) => {
  // console.log("getChatHistory", chat);
  let isAlreadyComplete = chat?.chatHistoryPaginationData?.complete || false;
  if (isAlreadyComplete) {
    return;
  }
  store.dispatch(setChatMessagesLoader(1));
  let temp = [];
  let temp_stanza = [];
  let markers = [];
  let lastMsgId = chat?.chatHistoryPaginationData?.first || "";
  let localstorage_messages_count = 50;
  if (
    localstorage_messages_count > chat?.chatHistoryPaginationData?.rest_messages
  ) {
    localstorage_messages_count =
      chat?.chatHistoryPaginationData?.rest_messages;
  }
  // console.log("localstorage_messages_count", localstorage_messages_count);
  /*
  if (!lastMsgId) {
    let localstorage_messages = localStorage.getItem('subscribeIncomingGroupMessages')
    if (localstorage_messages) {
      localstorage_messages_count = Object.entries(JSON.parse(localstorage_messages)).length
    }
  }else{
    localStorage.removeItem('subscribeIncomingGroupMessages');
  }
  console.log('localstorage_messages_count', localstorage_messages_count)
  */
  let withJid = chat.element_jid;
  // console.log("getChatHistory withJid", chat);
  // console.log("APP.connection", APP);
  APP.connection.mam.query(APP.bare_jid, {
    with: withJid,
    before: lastMsgId,
    max: localstorage_messages_count,
    onMessage: async function (message) {
      // console.log("chathistory message ", message);
      try {
        // console.log("chat.type : ", chat.type);
        if (chat.type == Constants.XMPP_STANZA_TYPES.CHAT) {
          // console.log(message);
          const parsedMessage = parseMessage(message);
          temp_stanza.push(message);
          // console.log("history parsedMessage : ",parsedMessage)
          // temp.push(parsedMessage);
          store.dispatch(setChatMessagesLoader(2));
        } else {
          // console.log("getChatHistory onMessage", message);
          // console.log("parseMUCMessage(message) ", parseMUCMessage(message));
          temp.push(parseMUCMessage(message));
        }
        return true;
      } catch (e) {
        store.dispatch(setChatMessagesLoader(3));
        // console.log("Error While fetching history messages ", e);
        // return false;
      }
    },
    onComplete: async function (response) {
      // console.log("under onComplete response : ", response);
      // console.log("oncomplete temp: ", temp);

      let redux_existing_msg_data =
        store.getState().xmppConnection.XMPP_USER_RECENT_CHATS;
      // console.log(
      //   "redux_existing_msg_data default state",
      //   redux_existing_msg_data
      // );
      if (redux_existing_msg_data.length) {
        // let merge_request = true;
        // console.log("redux_existing_msg_data loop temp", temp);
        // console.log("redux_existing_msg_data loop", redux_existing_msg_data);
        // redux_existing_msg_data.forEach(function (data, index) {
        //   temp.push(data);
        // });
        // console.log("redux_existing_msg_data loop", temp);

        // console.log("merging object", temp);
        let totalMsgs = [...temp, ...redux_existing_msg_data];
        let uniqueTotalMsgs = totalMsgs.filter(
          (v, i, a) => a.findIndex((v2) => v2.msgid === v.msgid) === i
        );
        store.dispatch(
          setXmppUserMessageReceived(uniqueTotalMsgs)
        );
      } else {
        store.dispatch(setXmppUserMessageReceived(temp));
      }
      let stanza = sizzle(`[xmlns="urn:xmpp:mam:2"]`, response).pop();
      let complete = stanza.getAttribute("complete") || false;
      let first = stanza.querySelector("first")?.textContent;
      let rest_messages = stanza.querySelector("first")?.getAttribute("index");
      // console.log("rest_messages : ", rest_messages);
      let last = stanza.querySelector("last")?.textContent;
      let total = stanza.querySelector("count")?.textContent;
      // console.log("complete, first, last, total", complete, first, last, total);
      store.dispatch(
        setXmppUserMessagePagination({
          complete: complete,
          first: first,
          rest_messages: rest_messages,
          last: last,
          total: total,
        })
      );
      store.dispatch(setIsXmppFetchingChatHistoryOnShowDetail(false));
      if (lastMsgId == "") {
        _scrollChatToBottom(chat);
      }
      store.dispatch(setChatMessagesLoader(4));
    },
  });
};
export const timeSince = (date) => {
  console.log('timesince ', date)
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " min";
  }
  return Math.floor(seconds) + " sec";
}

export const sendMessage = async (chat, inputValue) => {
  let id = new Date().getTime();
  var stanza = $msg({
    from: APP.connection.jid,
    to: chat.element_jid,
    type: chat.type,
    id: id,
  })
    .c("body")
    .t(inputValue)
    .up()
  if (chat.isSpotlight) {
    stanza.c("spotlight", { xmlns: "urn:xmpp:receipts" })
      .up()
      .c("no-store", {
        xmlns: "urn:xmpp:hints",
      }).up()
      .c("no-storage", {
        xmlns: "urn:xmpp:hints",
      }).up()
  }
  if (chat.first_name) {
    stanza.c("user_detail", { xmlns: "urn:xmpp:receipts", ...chat })
      .up()
  }
  stanza.c("message_time")
    .t(new Date().getTime())
    .up()
  if (chat.type == Constants.XMPP_STANZA_TYPES.CHAT) {
    stanza.c("request", { xmlns: "urn:xmpp:receipts" })
      .up()
      .c("markable", { xmlns: "urn:xmpp:chat-markers:0" })
      .up()
  }

  // stanza.c("origin-id", { xmlns: "urn:xmpp:sid:0", id: id })
  //   .up()
  //   .c("expires", { xmlns: "com.ourprime.message-expiry.0", id: id })
  //   .up()
  //   .c("thumb", { xmlns: "jabber:thumb", thumbUrl: "" })
  //   .up()
  //   // .c("company_code", { xmlns: "jabber:message:companycode" })
  //   // .t("VNC")
  //   // .up()
  //   .c("message_type", { xmlns: "jabber:message:type" })
  //   .t(0)
  //   .up()
  //   .up();
  let parsedMessage = parseMessage(textToXml(stanza.toString()));
  parsedMessage.message = inputValue;

  APP.connection.send(stanza);
  if (chat.type == Constants.XMPP_STANZA_TYPES.CHAT) {
    // store.dispatch(setXmppUserMessageSend(parseMessage(stanza)));
  }
  // _scrollChatToBottom(chat);
};

export const sendMediaMessage = async (chat, data) => {
  let id = new Date().getTime();
  let stanza_id = getRandomString(12);
  var stanza = $msg({
    from: APP.connection.jid,
    to: chat.element_jid,
    type: chat.type,
    id: id,
  })
    .c("body")
    .t(data.url + "!!!" + data.name)
    .up()
  if (chat.type == Constants.XMPP_STANZA_TYPES.CHAT) {
    stanza.c("request", { xmlns: "urn:xmpp:receipts" })
      .up()
      .c("markable", { xmlns: "urn:xmpp:chat-markers:0" })
      .up()
  }
  stanza.c("origin-id", { xmlns: "urn:xmpp:sid:0", id: id })
    .up()
    .c("expires", { xmlns: "com.scrambleim.message-expiry.0", at: "0" })
    // .c("expires", { xmlns: "com.daakia.message-expiry.0", at: "0" })
    .up()
    .c("store", { xmlns: "urn:xmpp:hints" })
    .up()
    .c("encryption", { xmlns: "urn:xmpp:eme:0", name: "OMEMO", namespace: "eu.siacs.conversations.axolotl" })
    .up()
    .c("message_type", { xmlns: "jabber:message:type" })
    .t(
      data.attachmentType === "image"
        ? Constants.MESSAGE_TYPES.IMAGE
        : Constants.MESSAGE_TYPES.AUDIO_VIDEO_DOC
    )
    .up()
    .c("thumb", { xmlns: "jabber:thumb", thumbUrl: data?.thumbUrl })
    .up()
    .c("stanza-id", { xmlns: "urn:xmpp:sid:0", by: chat.element_jid, id: stanza_id })
    .up()
    .up();
  let parsedMessage = parseMessage(textToXml(stanza.toString()));
  parsedMessage.message = data.url + "!!!" + data.name;
  console.log(stanza.toString());
  APP.connection.send(stanza);
  if (chat.type == Constants.XMPP_STANZA_TYPES.CHAT) {


    // let bodyTag = textToXml(stanza).getElementsByTagName('body')[0];
    // if(!isUndefined(bodyTag)){
    //     let childNode = bodyTag.childNodes[0];
    //     if(!isUndefined(childNode)){
    //       console.log("Node stanza updated",stanza);
    //         childNode.nodeValue = data.url + "!!!" + data.name;
    //     }
    // }

    // store.dispatch(setXmppUserMessageSend(parseMessage(textToXml(stanza))));
  }
  _scrollChatToBottom(chat);
};

export const sendMarker = (attrs, type) => {
  const stanza = $msg({
    from: APP.connection.jid,
    id: new Date().getTime(),
    to: attrs.element_jid,
    type: attrs.type,
  }).c(type, { xmlns: 'urn:xmpp:chat-markers:0', id: attrs.msgid }).up().c("store", { xmlns: "urn:xmpp:hints" }).up();
  console.log(stanza.toString())
  APP.connection.send(stanza);
  // store.dispatch(setXmppUserMessageSend(parseMessage(textToXml(stanza))));
};

export const sendTypingStatus = (chat, type = 'composing') => {
  let stanza = $msg({
    to: chat.element_jid,
    from: APP.connection.jid,
    type: chat.type,
  })
    .c(type, {
      xmlns: "http://jabber.org/protocol/chatstates",
    }).up()
    .c("no-store", {
      xmlns: "urn:xmpp:hints",
    }).up()
    .c("no-storage", {
      xmlns: "urn:xmpp:hints",
    }).up()
    .up();
  console.log(stanza.toString());
  APP.connection.send(stanza);
};
export const createGroup = (groupName, description, membersArray) => {
  return new Promise((resolve, reject) => {
    try {
      let groupJid = getRandomString(16) + Constants.GROUP_JID_POSTFIX;
      var iq = $iq({
        type: "set",
        id: Math.floor(Math.random() * 9999999999),
        to: groupJid
      }).c("query", { xmlns: "urn:xmpp:muclight:0#create" })
        .c("configuration")
        .c("created_by").t(loggedUser().phone_number).up()
        .c("created_at").t(now().toString()).up()
        .c("hide_numbers").t("false").up()
        .c("members_max_count").t("500").up()
        .c("admin_can_post_only").t("false").up()
        .c("is_forward_allowed").t("false").up()
        .c("public_group").t("false").up()
        .c("public_can_post_text").t("false").up()
        .c("post_as_anonymous").t("false").up()
        .c("group_creator_jid").t(APP.bare_jid).up()
        .c("roomname").t(groupName).up()
        .c("room_description").t(description).up()
        .up()
        .c("occupants");
      for (let contact of membersArray) {
        iq.c("user", { affiliation: "member" }).t(generateUserJid(contact)).up()
      }
      iq.up()
        .up()
        .up();
      console.log('groupCreate ', iq.toString())

      APP.connection.sendIQ(iq, function (success) {
        resolve(success);
      }, function (fail) {
        console.log(" error: ", fail);
        reject(fail);
      });
    } catch (e) {
      console.log(" error: ", e);
      reject(e);
    }
  })
}

export const _openChat = (chat) => {
  // console.log("under _openChat");
  // store.dispatch(setDefaultRightSideWindowScreenStatus(true));
  // store.dispatch(setXmppUserChatOpen(chat));
  // _scrollChatToBottom(chat);
};

const handlePings = () => {
  APP.connection.ping.addPingHandler(function (ping) {
    APP.connection.ping.pong(ping);
    return true;
  });
};

const createObjectForRecentChat = (messageStanza) => {
  const parsedMessage = parseMessage(messageStanza);
  const {
    element_jid,
    msgid,
    time,
    type,
    unread_count,
    body,
    message,
    message_type,
    from,
  } = parsedMessage;
  return {
    ...{},
    contact: {},
    messages: [],
    element_jid,
    msgid,
    time,
    type,
    unread_count,
    body,
    message,
    message_type,
    from,
  };
};

export const _scrollChatToBottom = (chat) => {
  // const { element_jid } = chat;
  let chats = store.getState().xmppConnection.XMPP_USER_RECENT_CHATS;
  // console.log('scroll chats ', chats)
  // let index = findIndex(chats, { element_jid });
  // if (index !== -1) {
  //   let scroll = chats[index]?.isOpen || false;
  //   if (scroll) {
  //     // store.dispatch(setScrollChatToBottom());
  //   }
  // }
};

export const getMediaUrl = (data) => {
  return data.message.split("!!!")[0];
};

export const getThumbUrl = (data) => {
  console.log("getThumbUrl data : ", data);
  return data.thumbUrl;
};

export const getImageUrl = (data) => {
  console.log("getImageUrl data : ", data);
  return convertUrlIfEncrypted(data.message.split("!!!")[0]);
};

export const convertUrlIfEncrypted = (url) => {
  if (url.indexOf('aesgcm:') != '-1') {
    url = Constants.API_URL + '/decrypt?url=' + encodeURIComponent(url)
  }
  return url;
}

export const getMessageType = (message) => {
  if (message.message_type === Constants.MESSAGE_TYPES.TEXT) {
    return Constants.MESSAGE_TYPES.TEXT;
  }
  if (message.message_type === Constants.MESSAGE_TYPES.IMAGE) {
    return Constants.MESSAGE_TYPES.IMAGE;
  }
  if (message.message_type === Constants.MESSAGE_TYPES.AUDIO_VIDEO_DOC) {
    let defaultType = "document";
    const url = getMediaUrl(message);
    const extension = url.split(/[#?]/)[0].split(".").pop().trim();
    let mimeType = getMimeTypefromExtension(extension);
    if (mimeType) {
      mimeType = mimeType.split("/")[0];
      if (mimeType == "audio" || mimeType == "video") {
        defaultType = mimeType;
      }
    }
    return defaultType;
  }
};

export const resetIsIncomingGroupMessagesHandlerAdded = () => {
  isIncomingGroupMessagesHandlerAdded = false;
}