/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { showService } from "../../_services/admin";
// Slice
const slice = createSlice({
  name: "user",
  initialState: {
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
    loginModal: false,
    signupModal: false,
    socialEmailModal: false,
    programEndsModal: false,
    signupSteps: 1,
    loginSteps: 1,
    resetPasswordModal: false,
    abbreviatedSignupModal: false,
    abbreviatedSignup: false,
    changePhotoModal: false,
    editShowRedirect: {},
    editShowNavigation: false,
    changePasswordModal: false,
    userDetails: {},
    showInviteFriendsPopUp: false,
    leaveLiveMeetingPopUp: false,
    inviteFriendsPopUpScheduleId: 0,
    attendScheduleShow: {},
  },
  reducers: {
    registerPayload: (state, action) => {
      state.registerPayload = { ...state.registerPayload, ...action.payload };
    },
    emptyRegisterPayload: (state, action) => {
      state.registerPayload = {};
    },
    loginSuccess: (state, action) => {
      state.user = action.payload;
    },
    logoutSuccess: (state, action) => {
      state.user = null;
    },
    loginModalStatus: (state, action) => {
      state.loginModal = action.payload;
    },
    signupModalStatus: (state, action) => {
      state.signupModal = action.payload;
    },
    socialEmailModalStatus: (state, action) => {
      state.socialEmailModal = action.payload;
    },
    programEndsModalStatus: (state, action) => {
      state.programEndsModal = action.payload;
    },
    abbreviatedSignupModalStatus: (state, action) => {
      state.abbreviatedSignupModal = action.payload;
    },
    abbreviatedSignupStatus: (state, action) => {
      state.abbreviatedSignup = action.payload;
    },
    signupStepsStatus: (state, action) => {
      state.signupSteps = action.payload;
    },
    loginStepsStatus: (state, action) => {
      state.loginSteps = action.payload;
    },
    resetPasswordStatus: (state, action) => {
      state.resetPasswordModal = action.payload;
    },
    ChangePasswordStatus: (state, action) => {
      state.ChangePasswordStatus = action.payload;
    },
    changePhotoStatus: (state, action) => {
      state.changePhotoModal = action.payload;
    },
    editShowRedirectStatus: (state, action) => {
      state.editShowRedirect = action.payload;
    },
    editShowNavigationStatus: (state, action) => {
      state.editShowNavigation = action.payload;
    },
    editUserProfileStatus: (state, action) => {
      state.user = action.payload;
    },
    userDetailsStatus: (state, action) => {
      state.userDetails = action.payload;
    },
    showInviteFriendsPopUpStatus: (state, action) => {
      state.showInviteFriendsPopUp = action.payload;
    },
    leaveLivePopUpStatus: (state, action) => {
      state.leaveLiveMeetingPopUp = action.payload;
    },
    inviteFriendsPopUpScheduleIdStatus: (state, action) => {
      state.inviteFriendsPopUpScheduleId = action.payload;
    },
    attendScheduleShowPayload: (state, action) => {
      state.attendScheduleShow = action.payload;
    },
  },
});
export default slice.reducer;
// Actions
const {
  registerPayload,
  emptyRegisterPayload,
  loginSuccess,
  logoutSuccess,
  loginModalStatus,
  signupModalStatus,
  signupStepsStatus,
  resetPasswordStatus,
  abbreviatedSignupModalStatus,
  abbreviatedSignupStatus,
  changePhotoStatus,
  editShowRedirectStatus,
  editShowNavigationStatus,
  loginStepsStatus,
  editUserProfileStatus,
  ChangePasswordStatus,
  userDetailsStatus,
  socialEmailModalStatus,
  showInviteFriendsPopUpStatus,
  leaveLivePopUpStatus,
  inviteFriendsPopUpScheduleIdStatus,
  attendScheduleShowPayload,
  programEndsModalStatus,
} = slice.actions;

export const registerPayloadData = (data) => async (dispatch) => {
  try {
    // const res = await api.post('/api/auth/login/', { username, password })
    const isEmpty = Object.keys(data).length === 0;
    if (isEmpty) {
      dispatch(emptyRegisterPayload());
      localStorage.removeItem("user");
    } else {
      console.log("registerPayloadData ", data);
      dispatch(registerPayload(data));
    }
  } catch (e) {
    return console.error(e.message);
  }
};

export const login = (data) => async (dispatch) => {
  try {
    dispatch(loginSuccess(data));
  } catch (e) {
    return console.error(e.message);
  }
};

export const loginModalHandler = (data) => async (dispatch) => {
  try {
    dispatch(loginModalStatus(data));
  } catch (e) {
    return console.error(e.message);
  }
};

export const signupModalHandler = (data) => async (dispatch) => {
  try {
    dispatch(signupModalStatus(data));
  } catch (e) {
    return console.error(e.message);
  }
};
export const socialEmailModalHandler = (data) => async (dispatch) => {
  try {
    dispatch(socialEmailModalStatus(data));
  } catch (e) {
    return console.error(e.message);
  }
};

export const abbreviatedSignupModalHandler = (data) => async (dispatch) => {
  try {
    dispatch(abbreviatedSignupModalStatus(data));
  } catch (e) {
    return console.error(e.message);
  }
};

export const abbreviatedSignupHandler = (data) => async (dispatch) => {
  try {
    dispatch(abbreviatedSignupStatus(data));
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetPasswordModalHandler = (data) => async (dispatch) => {
  try {
    dispatch(resetPasswordStatus(data));
  } catch (e) {
    return console.error(e.message);
  }
};
export const ChangePasswordModalHandler = (data) => async (dispatch) => {
  try {
    dispatch(ChangePasswordStatus(data));
  } catch (e) {
    return console.error(e.message);
  }
};

export const signupStepsHandler = (data) => async (dispatch) => {
  try {
    dispatch(signupStepsStatus(data));
  } catch (e) {
    return console.error(e.message);
  }
};

export const loginStepsHandler = (data) => async (dispatch) => {
  try {
    dispatch(loginStepsStatus(data));
  } catch (e) {
    return console.error(e.message);
  }
};

export const changePhotoModalHandler = (data) => async (dispatch) => {
  try {
    dispatch(changePhotoStatus(data));
  } catch (e) {
    return console.error(e.message);
  }
};

export const logout = () => async (dispatch) => {
  try {
    // const res = await api.post('/api/auth/logout/')
    localStorage.removeItem("user");
    return dispatch(logoutSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const editShowRedirectHandler = (data) => async (dispatch) => {
  try {
    if (data === "none") {
      dispatch(editShowRedirectStatus({}));
    } else {
      showService.getShowTitleAndDescription({ show_id: data }).then((data) => {
        if (data.success) {
          dispatch(editShowRedirectStatus(data.data));
        }
      });
    }
  } catch (e) {
    return console.error(e.message);
  }
};

export const editShowNavigationHandler = (data) => async (dispatch) => {
  try {
    dispatch(editShowNavigationStatus(data));
  } catch (e) {
    return console.error(e.message);
  }
};

export const editUserProfileHandler = (data) => async (dispatch) => {
  try {
    dispatch(editUserProfileStatus(data));
  } catch (e) {
    return console.error(e.message);
  }
};

export const userDetailsHandler = (data) => async (dispatch) => {
  try {
    dispatch(userDetailsStatus(data));
  } catch (e) {
    return console.error(e.message);
  }
};

export const showInviteFriendsPopUpHandler = (data) => async (dispatch) => {
  try {
    dispatch(showInviteFriendsPopUpStatus(data));
  } catch (e) {
    return console.error(e.message);
  }
};

export const leaveLiveMeetingHandler = (data) => async (dispatch) => {
  try {
    dispatch(leaveLivePopUpStatus(data));
  } catch (e) {
    return console.error(e.message);
  }
};

export const inviteFriendsPopUpScheduleIdHandler =
  (data) => async (dispatch) => {
    try {
      dispatch(inviteFriendsPopUpScheduleIdStatus(data));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const attendScheduleShowHandler = (data) => async (dispatch) => {
  try {
    dispatch(attendScheduleShowPayload(data));
  } catch (e) {
    return console.error(e.message);
  }
};

export const programEndsModalHandler = (data) => async (dispatch) => {
  try {
    dispatch(programEndsModalStatus(data));
  } catch (e) {
    return console.error(e.message);
  }
};
