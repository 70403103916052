export const USER_ROLE = 2;
export const ADMIN_ROLE = 1;
export default {
  XMPP_HOST: process.env.REACT_APP_XMPP_HOST,
  get XMPP_URL() {
    return (
      process.env.REACT_APP_XMPP_PROTOCOL +
      "://" +
      this.XMPP_HOST +
      ":" +
      process.env.REACT_APP_XMPP_PORT +
      "/http-bind"
    );
  },
  get JID_POSTFIX() {
    return "@" + this.XMPP_HOST;
  },
  get XMPPDEFAULTUSER() {
    return process.env.REACT_APP_XMPP_DEFAULT_USER;
  },
  get XMPPDEFAULTUSERPASSWORD() {
    return process.env.REACT_APP_XMPP_DEFAULT_USER_PASSWORD;
  },
  get GROUP_JID_POSTFIX() {
    return "@" + "muclight." + this.XMPP_HOST;
  },
  APPNAME: process.env.REACT_APP_APPNAME,
  XMPP_STANZA_TYPES: {
    CHAT: "chat",
    GROUP: "groupchat",
  },
  MESSAGE_TYPES: {
    TEXT: "0",
    IMAGE: "1",
    AUDIO_VIDEO_DOC: "2",
  },
  ATTACHMENT_TYPES: {
    IMAGE: "image",
    AUDIO: "audio",
    VIDEO: "video",
    DOCUMENT: "document",
  },
};
