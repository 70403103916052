import React from "react";

export const VideoIcon = ({ enabled }) => {
  return (
    <>
      {!enabled ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 37 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1"
            y="3.12109"
            width="3"
            height="46.0302"
            transform="rotate(-45 1 3.12109)"
            fill="#36B3D0"
          />
          <path
            d="M26 13.8L33.8195 8.325C33.9319 8.24618 34.0639 8.19975 34.2009 8.19075C34.3379 8.18176 34.4747 8.21055 34.5965 8.27399C34.7183 8.33743 34.8203 8.43309 34.8915 8.55053C34.9626 8.66797 35.0002 8.80269 35 8.94V27.06C35.0002 27.1973 34.9626 27.332 34.8915 27.4495C34.8203 27.5669 34.7183 27.6626 34.5965 27.726C34.4747 27.7894 34.3379 27.8182 34.2009 27.8092C34.0639 27.8003 33.9319 27.7538 33.8195 27.675L26 22.2V28.5C26 28.8978 25.842 29.2794 25.5607 29.5607C25.2794 29.842 24.8978 30 24.5 30H3.5C3.10218 30 2.72064 29.842 2.43934 29.5607C2.15804 29.2794 2 28.8978 2 28.5V7.5C2 7.10218 2.15804 6.72064 2.43934 6.43934C2.72064 6.15804 3.10218 6 3.5 6H24.5C24.8978 6 25.2794 6.15804 25.5607 6.43934C25.842 6.72064 26 7.10218 26 7.5V13.8ZM26 18.5385L32 22.7385V13.26L26 17.46V18.537V18.5385ZM5 9V27H23V9H5ZM8 12H11V15H8V12Z"
            fill="#36B3D0"
          />
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 33 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24 7.8L31.8195 2.325C31.9319 2.24618 32.0639 2.19975 32.2009 2.19075C32.3379 2.18176 32.4747 2.21055 32.5965 2.27399C32.7183 2.33743 32.8203 2.43309 32.8915 2.55053C32.9626 2.66797 33.0002 2.80269 33 2.94V21.06C33.0002 21.1973 32.9626 21.332 32.8915 21.4495C32.8203 21.5669 32.7183 21.6626 32.5965 21.726C32.4747 21.7894 32.3379 21.8182 32.2009 21.8092C32.0639 21.8003 31.9319 21.7538 31.8195 21.675L24 16.2V22.5C24 22.8978 23.842 23.2794 23.5607 23.5607C23.2794 23.842 22.8978 24 22.5 24H1.5C1.10218 24 0.720644 23.842 0.43934 23.5607C0.158035 23.2794 0 22.8978 0 22.5V1.5C0 1.10218 0.158035 0.720645 0.43934 0.43934C0.720644 0.158036 1.10218 0 1.5 0H22.5C22.8978 0 23.2794 0.158036 23.5607 0.43934C23.842 0.720645 24 1.10218 24 1.5V7.8ZM24 12.5385L30 16.7385V7.26L24 11.46V12.537V12.5385ZM3 3V21H21V3H3ZM6 6H9V9H6V6Z"
            fill="white"
          />
        </svg>
      )}
    </>
  );
};
