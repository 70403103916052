/** @format */

import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  abbreviatedSignupHandler,
  abbreviatedSignupModalHandler,
  login,
  loginModalHandler,
  registerPayloadData,
} from "../../../store/slices/user";
import classNames from "classnames/bind";
import styles from "../Register/register.module.css";
import UserIcon from "../../../images/userIcon.svg";
import CloseIcon from "../../../images/cross.svg";
import emailBIcon from "../../../images/emailBlackIcon.svg";
import eyeBIcon from "../../../images/eyeBlackIcon.svg";
import HidePassword from "../../../images/HidePassword.svg";
import { Form, InputGroup, FormControl, Button, Modal } from "react-bootstrap";
import { useSnackbar } from "notistack";

import { userService } from "../../../_services";

const cx = classNames.bind(styles);

const AbbreviatedSignUp = (props) => {
  // const location = useLocation();
  // const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [isFormfilled, setIsFormfilled] = useState(false);
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });
  const [passwordShown, setPasswordShown] = useState(false);

  useEffect(() => {
    if (
      values.email !== "" &&
      values.password !== "" &&
      values.first_name !== "" &&
      values.last_name !== ""
    ) {
      setIsFormfilled(true);
    } else {
      setIsFormfilled(false);
    }
  }, [values]);

  const togglePasswordVisibility = () => setPasswordShown(!passwordShown);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    userService.check({ email: values.email }).then((data) => {
      if (!data.data.isRegistered) {
        userService
          .register(values)
          .then((data) => {
            dispatch(login(data));
            dispatch(abbreviatedSignupModalHandler(false));
            dispatch(abbreviatedSignupHandler(true));
          })
          .catch((error) => {
            enqueueSnackbar(error, {
              variant: "error",
            });
          });
      } else {
        return enqueueSnackbar("Email already registered", {
          variant: "error",
        });
      }
    });
  };

  const btnStyle = isFormfilled
    ? {
        backgroundColor: "#E50914",
        color: "white",
      }
    : {
        backgroundColor: "#E3E3E3",
        color: "black",
      };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="container">
          <div className="d-flex align-items-center justify-content-center">
            <div
              className={cx(
                "outerBoxRegister",
                "bg-white",
                "px-5",
                "position-relative",
                "py-lg-5 py-md-4 py-sm-0 py-0"
              )}
            >
              <div
                onClick={props.onHide}
                className="position-absolute closeIconRegister"
              >
                <img className="img-fluid" src={CloseIcon} alt="cross" />
              </div>
              <div className={"registerLandscape"}>
                <div className="d-flex align-items-center justify-content-center mb-4 landscape-mb-4 mt-5 landscape-mt-5">
                  <h2 className="text-center fw-bold">Sign Up</h2>
                </div>
                <Form onSubmit={handleSubmit}>
                  <h1 className="small fw-bold mb-3 landscape-mb-3">
                    Basic Info
                  </h1>
                  <div className="row gx-3">
                    <div className="col-sm-6">
                      <InputGroup
                        size="lg"
                        className="mb-3 border border-dark rounded"
                      >
                        <FormControl
                          placeholder="First Name"
                          aria-label="First Name"
                          aria-describedby="First Name"
                          className="border-white"
                          type="text"
                          name="first_name"
                          ref={firstNameRef}
                          value={values.first_name}
                          onChange={handleChange}
                          //   onKeyUp={changeButtonColor}
                        />
                        <InputGroup.Text
                          id="First Name"
                          className="fs-6 bg-white border-white"
                        >
                          <img
                            className="img-fluid"
                            src={UserIcon}
                            alt="user"
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="col-sm-6">
                      <InputGroup
                        size="lg"
                        className="mb-3 border border-dark rounded"
                      >
                        <FormControl
                          placeholder="Last Name"
                          aria-label="Last Name"
                          ref={lastNameRef}
                          aria-describedby="Last Name"
                          className="border-white"
                          type="text"
                          name="last_name"
                          value={values.last_name}
                          onChange={handleChange}
                          //   onKeyUp={changeButtonColor}
                        />
                        <InputGroup.Text
                          id="Last Name"
                          className="fs-6 bg-white border-white"
                        >
                          <img
                            className="img-fluid"
                            src={UserIcon}
                            alt="user"
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="col-sm-6">
                      <InputGroup
                        size="lg"
                        className="mb-3 border border-dark rounded"
                      >
                        <FormControl
                          placeholder="Email"
                          aria-label="Email"
                          ref={emailRef}
                          aria-describedby="Email"
                          className="border-white"
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          //   onKeyUp={changeButtonColor}
                        />
                        <InputGroup.Text
                          id="Email"
                          className="fs-6 bg-white border-white"
                        >
                          <img
                            className="img-fluid"
                            src={emailBIcon}
                            alt="mail"
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="col-sm-6">
                      <InputGroup
                        size="lg"
                        className="mb-3 border border-dark rounded"
                      >
                        <FormControl
                          id="passwordField"
                          placeholder="Password"
                          aria-label="Password"
                          ref={passwordRef}
                          aria-describedby="Password"
                          className="border-white"
                          type={passwordShown ? "text" : "password"}
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          //   onKeyUp={changeButtonColor}
                        />
                        <InputGroup.Text
                          id="togglePassword"
                          className="fs-6 bg-white border-white"
                          onClick={togglePasswordVisibility}
                        >
                          <img
                            src={passwordShown ? eyeBIcon : HidePassword}
                            alt="hidden"
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                  </div>
                  <div className="col-sm-3 m-auto mb-5 landscape-mb-5">
                    <Button
                      className="mt-4 fw-bold socialBtn w-100"
                      variant="muted"
                      size="lg"
                      type="submit"
                      style={btnStyle}
                      disabled={!isFormfilled}
                    >
                      Join Now
                    </Button>
                  </div>
                  <p
                    className={cx(
                      "small",
                      "text-center",
                      "pt-4",
                      "landscape-pt-4",
                      "landscape-mb-0"
                    )}
                  >
                    Already have an account?{" "}
                    <span
                      className=""
                      onClick={() => {
                        dispatch(loginModalHandler(true));
                        dispatch(abbreviatedSignupModalHandler(false));
                        //   navigate("/login", {
                        //     state: {
                        //       email: location.state?.email || "",
                        //     },
                        //   });
                      }}
                    >
                      <u className="text-danger fw-bold cursor-pointer">
                        Log In
                      </u>
                    </span>
                  </p>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default AbbreviatedSignUp;
