/* eslint-disable no-restricted-globals */
/**
 * /* @format
 *
 * @format
 */

import React, { useEffect, useState } from "react";
import { Link, useHistory, useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./users.module.css";
import textDot from "../images/textDot.svg";
import actionImg from "../images/actionDot.svg";
import _ from "lodash";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { showService } from "../../../_services/admin";
import { useSnackbar } from "notistack";
import { ADMIN_ROLE } from "../../../config/constants";
import OutsideClickHandler from "react-outside-click-handler";
import CloseIcon from "../../../images/cross.svg";
import { setUseProxies } from "immer";
import noProfile from "../../../images/noProfile.png";
const cx = classNames.bind(styles);

const Users = ({ prop }) => {
  console.log(prop);
  let user = JSON.parse(localStorage.getItem("user"));
  // const { userDropdownStatus, setUserDropdownStatus, ref2 } = useOutsideAlerter2(true)
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dropdown, setDropdown] = useState(null);
  const [getUser, setGetUser] = useState({});
  const [usersId, setUsersId] = useState();
  const clickHandler = (index) => {
    // console.log('index :>> ', index);
    setDropdown((prev) => {
      // console.log(prev)
      return prev === index ? null : index;
    });
    // console.log('clicked', index);
  };
  // const handleShowDropdown = () => {
  //     console.log(userDropdownStatus)
  //     setUserDropdownStatus(!userDropdownStatus)
  // }

  //modal start
  const useStyles = makeStyles((theme) =>
    createStyles({
      modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
      },
      paper: {
        backgroundColor: theme.palette.background.paper,
        border: "none",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        height: "80%",
        width: "60%",
        position: "relative",
        overflowY: "auto",
      },
      useDetails: {
        width: "50%",
        marginLeft: "50px",
        height: "40%",
      },
      userDetailImg: {
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        objectFit: "cover",
      },
      img: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      },
    })
  );
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //modal end
  const imageSrc = (adminShowImages) => {
    const hero_image = adminShowImages.find((item) => {
      return item.is_hero === 1;
    });
    if (_.isUndefined(hero_image)) {
      return "src/images/default-image.jpeg";
    }
    return hero_image?.hero_url;
  };

  const timeString12hr = (timeString) => {
    let time = timeString;
    if (timeString.lengtd > 8) {
      time = timeString.slice(11, 19);
    }
    // const time = timeString.slice(11, 19);
    return new Date("1970-01-01T" + time + "Z").toLocaleTimeString("en-US", {
      timeZone: "UTC",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });
  };

  const currentDate = () => {
    const todayDateArray = new Date().toISOString().slice(0, 10).split("-");
    const year = todayDateArray[0];
    const montd = todayDateArray[1];
    const day = todayDateArray[2];
    const todayDate = `${day}/${montd}/${year}`;
    return todayDate;
  };

  const createdTime = (time) => {
    return `${new Date(time).toLocaleDateString()} ${timeString12hr(time)}`;
  };

  const sortDates = (scheduleDates) => {
    const dates = [];
    scheduleDates.map((obj) => {
      dates.push(
        new Date(obj.date).toLocaleDateString() + " " + timeString12hr(obj.time)
      );
    });
    dates.sort(function (a, b) {
      return new Date(a) - new Date(b);
    });
    return dates;
  };

  const upcomingDate = (scheduleDates) => {
    const tempArr = [];
    const dates = sortDates(scheduleDates);
    dates.map((date) => {
      tempArr.push(date.slice(0, 10));
    });
    const todayDate = currentDate();
    const dateTime = dates[tempArr.indexOf(todayDate) + 1];
    return dateTime;
  };

  const pastDate = (scheduleDates) => {
    const tempArr = [];
    const dates = sortDates(scheduleDates);
    dates.map((date) => {
      tempArr.push(date.slice(0, 10));
    });
    const todayDate = currentDate();
    const dateTime =
      dates[
        tempArr.indexOf(todayDate) === 0 ? 0 : tempArr.indexOf(todayDate) - 1
      ];
    return dateTime;
  };

  const handleShowDelete = (showId) => {
    if (confirm("Are you sure you want to perform tdis action?")) {
      showService
        .deleteComplete({ show_id: showId })
        .tden((data) => {
          if (data.success) {
          } else {
            // seterrorShowDetail(true);
            enqueueSnackbar(data.message, {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            variant: "error",
          });
        });
    }
  };

  const handleUserDetail = (user) => {
    setOpen(true);
    console.log("user", user);
    setGetUser(user);
  };

  return (
    <>
      <tr className="cursor-pointer" onClick={() => handleUserDetail(prop)}>
        {/* <div key={prop.id} className="css-table-row1 my-4 cursor-pointer"> */}
        {/* <div className="position-relative pt-4">
          <div
            className="tableImg"
            // style={{ background: `url(${imageSrc(prop.AdminShowImages)})` }}
          ></div>
        </div> */}

        <td
          scope="row"
          className="mb-lg-0 mb-md-0 mb-sm-2 mb-2"
          // to={`/admin/show-detail/${prop.id}`}
          // target="_blank"
        >
          <p className="mb-0">
            {/* <span className="text-secondary me-2">ID: #{prop.id}</span> */}
            <a href="#!" className="text-blue">
              {prop.show_url}
            </a>
          </p>
          {prop.is_incomplete === 1 && (
            <p className="mb-0">
              <span className="text-danger">Incomplete</span>
              {/* <img className="img-fluid mx-2" src={textDot} alt="dot" />
                     <span className="text-white">{item.description}</span> */}
            </p>
          )}
        </td>
        <td className="mb-lg-0 mb-md-0 mb-sm-2 mb-2">
          <p className="text-white mb-0">{prop?.first_name || "---"}</p>
        </td>
        <td className="mb-lg-0 mb-md-0 mb-sm-2 mb-2">
          {/* {!_.isEmpty(prop.AdminShowSchedules) ? (
            <>
              <p className="mb-0 text-white">
                Upcoming : {upcomingDate(prop.AdminShowSchedules)}
              </p>
              <p className="mb-0 text-danger">
                Past : {pastDate(prop.AdminShowSchedules)}
              </p>
            </>
          ) : null} */}

          <p className="mb-0 text-white">{prop?.last_name || "---"}</p>
        </td>
        <td className="mb-lg-0 mb-md-0 mb-sm-2 mb-2">
          <div className="d-flex align-items-center justify-content-start px-0">
            <p className="mb-0 text-white">{prop?.email}</p>
          </div>
        </td>

        <td>
          <h6 className="mb-0 text-white">---</h6>
        </td>
        <td>
          <h6 className="mb-0 text-white">---</h6>
        </td>
        {/* </div> */}
      </tr>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h4 className="text-center fw-bold mt-1 landscape-mt-0">
                User Info
              </h4>
              <div className={cx("closeIcon")}>
                <img
                  className="img-fluid"
                  src={CloseIcon}
                  alt="cross"
                  onClick={handleClose}
                />
              </div>
              <div className={classes.img}>
                <img
                  className={classes.userDetailImg}
                  src={getUser?.profile_image || noProfile}
                  alt=""
                />
              </div>

              <div className="d-flex width-100% justify-content-evenly mt-4">
                <div className={classes.useDetails}>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <h6>First Name</h6>
                      <p>{getUser?.first_name || "---"}</p>
                    </div>
                    <div>
                      <h6>Last Name</h6>
                      <p>{getUser?.last_name || "---"}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between m-1">
                    <div>
                      <h6>Date of Birth</h6>
                      <p>{getUser?.dob || "---"}</p>
                    </div>
                  </div>
                  <div>
                    <h6>Gender</h6>
                    <p>{getUser?.gender || "---"}</p>
                  </div>
                </div>
                <div className={classes.useDetails}>
                  <div>
                    <h6>Email address</h6>
                    <p>{getUser?.email || "---"}</p>
                  </div>
                  <div>
                    <h6>State</h6>
                    <p>{getUser?.state || "---"}</p>
                  </div>
                  <div>
                    <h6>Zip Code</h6>
                    <p>{getUser?.zipcode || "---"}</p>
                  </div>
                  <div>
                    <h6>URL</h6>
                    <p>---</p>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    </>
  );
};
export default Users;
