import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import Header from "../header/header";
import Footer from "./Footer";
const useStyles = makeStyles((theme) =>
  createStyles({
    a: {
      borderBottom: "1px solid blue",
    },
    MainHead: {
      position: "sticky",
      top: 0,
      zIndex: "10",
      backgroundColor: "#000",
    },
    MainList: {
      marginLeft: "50px",
      marginRight: "50px",
    },
    Container: {
      //   display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      //   border: "2px solid white",
      height: "100%",
      color: "white",
      fontFamily: "Netflix Sans",
    },
    SubContainer: {
      margin: "0px 50px",
    },
    Header: {
      color: "white",
      textDecoration: "underline",
      textAlign: "center",
      marginTop: "50px",
      marginBottom: "10px",
    },
    HeaderBox: {
      marginBottom: "50px",
    },
    subHeading: {
      color: "white",
      justifyContent: "flex-start",

      fontFamily: "Netflix Sans",
    },

    website: {
      margin: "0px 5px",
      textDecoration: "underline",
    },
    listHeading: {
      fontWeight: "bold",
      textDecoration: "underline",
      fontStyle: "italic",
    },
    subListItemsHeading: {
      textDecoration: "underline",
      fontWeight: "400",
    },
  })
);
const PrivacyPolicy = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.MainHead}>
        <Header />
      </Box>
      <Box className={classes.Container}>
        <Box className={classes.SubContainer}>
          <Box className={classes.HeaderBox}>
            <Typography variant="h3" className={classes.Header}>
              PRIVACY NOTICE <br />
            </Typography>
            <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
              Last Updated: 15 July 2022
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.subHeading}>
              <span style={{ fontSize: "30px" }}>W</span>elcome to the OurPrime
              community! At OurPrime Inc. (“OurPrime”, “we”, “us” or “our”), we
              value your privacy and trust. This Privacy Notice provides you
              with an important overview about how we handle your information,
              who we share it with, and what your rights and choices are with
              respect to information we process about you when you interact with
              our platform, either through our website at
              <a
                href="https://www.ourprime.com/home"
                className={classes.website}
                target="_blank"
              >
                www.ourprime.com
              </a>
              , or through our mobile applications (collectively, we refer to
              these as our “Services”). We encourage you to read our Privacy
              Notice so you can use our Services in the way that’s right for
              you. Our Services may contain hyperlinks to websites operated by
              others whose privacy practices may differ from ours. Our Privacy
              Notice only applies to OurPrime’s own Services and we encourage
              you to review the privacy notices of any other website you are
              redirected to from our Services as we have no control over
              information that is submitted to, or collected by, these
              third-parties.
              <br />
              <br />
              If we update our Privacy Notice, we will post the updated Privacy
              Notice on this page and update the “Last Updated” date. We
              encourage you to review our Privacy Notice periodically for
              changes. If we make material changes, we will notify you in
              advance. You will have the opportunity to review the revised
              Privacy Notice before you choose to continue using our Services.
            </Typography>
            <br />
            <br />
          </Box>
        </Box>

        <Box className={classes.MainList}>
          <ol>
            <li>
              <Typography className={classes.listHeading}>
                WHAT INFORMATION WE COLLECT ABOUT YOU AND HOW IT IS COLLECTED
              </Typography>
              <Typography>
                {" "}
                Your Activity and Information You Provide: The information we
                collect and process about you depends on how you use our
                Services. Some information is required for our Services to
                function properly while other information is optional, but
                without it, your experience of our Services might be impacted
                and/or limited:
              </Typography>
              <br />
              <ul>
                <li>
                  In order to join our email list to be notified of the launch
                  of our Services, you need to provide an email address. If you
                  do not provide your email address we will not be able to send
                  you the email notices you request.
                </li>
                <br />

                <li>
                  In order to set up an account to use our Services, you need to
                  provide us with an email address and password. If you do not
                  provide this information, we will not be able to create an
                  account for you to use our Services.
                </li>
                <br />

                <li>
                  When you interact with content provided through our Services,
                  we will collect information about the types of content you
                  provide, view or interact with, and how you interact with it.
                </li>
                <br />

                <li>
                  When you provide content to our Services, through submitting
                  written text, through the camera feature, or through our audio
                  features, we will collect the information you submit, which
                  may include your image and voice recording.
                </li>
                <br />

                <li>
                  If you contact our customer care team, we will collect your
                  name, email address, and any other information you choose to
                  provide us in the content of your message to respond to your
                  inquiry.
                </li>
              </ul>
              <br />
              <Typography>
                <span
                  style={{ textDecoration: "underline", fontWeight: "700" }}
                >
                  Payment Information:
                </span>{" "}
                <span> </span>
                Some of our Services require payment. When you purchase any of
                our Services, you provide us or our payment processor with
                information, such as your debit or credit card number or other
                financial information. We use a PCI/DSS-compliant payment
                processing service to which OurPrime subscribes to process your
                credit card transactions. OurPrime does not, itself, process or
                store the card information. You must be 18 or older to authorize
                a purchase through our Services.
              </Typography>
              <br />
              <Typography>
                <span
                  style={{ textDecoration: "underline", fontWeight: "700" }}
                >
                  {" "}
                  Information About You Provided by Others:
                </span>{" "}
                <span> </span>
                We may receive information about you from third parties, such
                as:
              </Typography>
              <br />
              <ul>
                <li>
                  Other users may provide information about you through their
                  use of our Services. For example, if another user contacts us
                  about your behavior on our Services we may collect information
                  about you from that user for investigation purposes.
                </li>
                <br />
                <li>
                  You may be able to use your login credentials for your other
                  social media account to create and log into your OurPrime
                  account. We offer this login option as a convenience to you
                  but logging in through a third-party account is not required
                  in order to use our Services
                </li>
              </ul>
              <br />
              <Typography>
                <span
                  style={{ textDecoration: "underline", fontWeight: "700" }}
                >
                  App, Browser and Device information:
                </span>
                <span> </span>
                When you interact with our Services, we may automatically
                collect information about your use of our Services and the
                devices you use to access our Services, including your IP
                address, device type, device ID, Internet service provider,
                general location information, page views, a date/time stamp,
                browser type, referring/exit URLs, search terms used on our
                Services, operating system, and similar device and usage
                information.
              </Typography>
              <br />
              <Typography>
                <span
                  style={{ textDecoration: "underline", fontWeight: "700" }}
                >
                  Cookies:
                </span>
                <span> </span>
                We use and may allow others to use cookies and similar
                technologies (i.e., web beacons, pixels) to recognize you and/or
                your devices. A cookie is a small piece of data that your
                internet browser places on your device so that it can be
                recognized when it returns in the future. We use cookies and
                similar technologies to secure our Services, remember your
                preferences, and personalize content for your future visits.
                Most browsers automatically accept cookies, but you can usually
                refuse cookies, or selectively accept certain cookies, by
                adjusting the preferences in your browser. If you turn off or
                refuse cookies, there may be some features of our Services that
                will not be available to you, and some portions of our Services
                may not display properly or be less personalized. You will not
                be able to opt-out of any cookies or other technologies that are
                “strictly necessary” for our Services to function.
              </Typography>
              <br />
              <Typography>
                <span
                  style={{ textDecoration: "underline", fontWeight: "700" }}
                >
                  Do-Not-Track Signals:
                </span>
                <span> </span>
                Your browser may allow you to set a “Do Not Track” preference.
                We currently do not respond to “Do Not Track” or similar
                signals.
              </Typography>
              <br />
            </li>
            <br />
            <br />
            <li>
              <Typography className={classes.listHeading}>
                HOW WE USE YOUR INFORMATION
              </Typography>

              <Typography>
                We primarily use your information to provide, personalize and
                improve the Services you have requested. In addition, we may use
                your information to:
              </Typography>
              <br />
              <ul>
                <li>
                  Promote the safety, security and integrity of our Services. We
                  may use your information to verify accounts and activity as
                  well as to help detect and prevent issues relating to
                  security, fraud, and inappropriate activity on our Services,
                  including investigating or addressing alleged misbehavior. In
                  addition, we may use your information to Protect OurPrime’s
                  legal rights, including enforcing or exercising our rights
                  under this Privacy Notice or our Terms.
                </li>
                <br />
                <li>
                  Develop, research and test improvements to our Services. For
                  example, we use your information to see if our Services are
                  working properly and troubleshoot issues. We may also use your
                  information to analyze trends in connection with usage of the
                  Services or to test out new products and features for our
                  Services.
                </li>
                <br />

                <li>
                  Personalize the features and content. We may use your
                  information to customize the content we may send or display on
                  the Services to personalize your experience
                </li>
                <br />

                <li>
                  Communicate with you by email, phone, social media or mobile
                  device about products or services that we think may interest
                  you (unless you have indicated to us that you do not wish to
                  receive such information). Please see section 6 regarding your
                  choices and right to opt out of OurPrime’s marketing messages.
                </li>
                <br />

                <li>
                  Meet our legal compliance and regulatory duties, including
                  assisting law enforcement.
                </li>
              </ul>
              <br />
              <Typography>
                We do not sell any of your information to anyone. We do not
                disclose your contact information to third parties for their own
                marketing purposes. We also require our service providers to
                follow rules about how they can and cannot use and disclose the
                information about you that we provide. In some cases, we
                de-identify or aggregate information or anonymize it so that it
                no longer identifies you and use such for any purpose.
              </Typography>
              <br />
            </li>
            <br />
            <br />
            <li>
              <Typography className={classes.listHeading}>
                HOW WE SHARE YOUR INFORMATION
              </Typography>

              <Typography>
                In general, we share your information as follows:
              </Typography>
              <br />
              <ul>
                <li>
                  <span className={classes.subListItemsHeading}>
                    {" "}
                    Other Users:
                  </span>
                  <span> </span>
                  The information you choose to share on your profile will be
                  viewable to other users of our Services. Please be careful
                  about the information you choose to share through our Services
                  and understand that this content may become publicly viewable
                  since neither you nor we can control what others do with the
                  information you share through our Services. If you choose to
                  limit the audience for all or part of your profile or for
                  certain content or information about you, then it will be
                  visible according to your settings
                </li>
                <br />
                <li>
                  <span className={classes.subListItemsHeading}>
                    Our Service Providers:
                  </span>
                  <span> </span>
                  We use third parties to help us operate and improve our
                  services. These third parties assist us with various tasks,
                  including data hosting and maintenance, analytics, customer
                  care, marketing, advertising, payment processing and security
                  operations. All of our service providers and partners must
                  agree to strict confidentiality obligations.
                </li>
                <br />
                <li>
                  <span className={classes.subListItemsHeading}>
                    {" "}
                    For Corporate Transactions:
                  </span>
                  <span> </span>
                  We may transfer your information if we are involved in a
                  merger, sale, acquisition, divestiture, restructuring,
                  reorganization, dissolution, bankruptcy or other change of
                  ownership or control, including without limitation, during the
                  course of any due diligence process
                </li>
                <br />
                <li>
                  <span className={classes.subListItemsHeading}>
                    When Required by Law:
                  </span>
                  <span> </span>
                  This may include if we need to comply with a subpoena,
                  bankruptcy proceedings, or similar legal process, or in
                  response to lawful requests by public authorities.
                </li>
                <br />
                <li>
                  <span className={classes.subListItemsHeading}>
                    To Enforce Legal Rights:
                  </span>
                  <span> </span>
                  We may also share information to investigate, prevent, or take
                  other action regarding illegal activity, suspected fraud,
                  violation of our Terms or legal rights, or other wrongdoing.
                </li>
                <br />
                <li>
                  <span className={classes.subListItemsHeading}>
                    With Your Consent or at Your Request:
                  </span>
                  <span> </span>
                  We may ask for your consent to share your information with
                  third parties. In any such case, we will make it clear why we
                  want to share the information
                </li>
                <br />
              </ul>
            </li>
            <br />
            <br />
            <li>
              <Typography className={classes.listHeading}>
                HOW YOUR INFORMATION IS STORED
              </Typography>

              <Typography>
                We control and operate the Services from the U.S. Our Privacy
                Notice and practices are governed by the laws of the U.S., which
                may differ from privacy laws in your state or home country. By
                submitting your personal information to us through the Services,
                you consent to the transfer of your personal information to any
                country and its use and disclosure in accordance with applicable
                U.S. federal and state laws and with this Privacy Notice. We
                maintain industry standard administrative, technical, and
                physical safeguards to protect your personal information from
                loss, misuse, unauthorized access, disclosure, alteration and
                destruction. With that said, no method of data transmission
                online is completely secure and we cannot guarantee its absolute
                security. In general, we will only retain your information for
                the time necessary to realize our legitimate business purposes
                and to comply with the law. We may retain your information in
                order to prevent, investigate, or identify possible wrongdoing
                in connection with our Services or to comply with legal
                obligations. We may also maintain residual copies of your
                personal information in our backup systems.
              </Typography>
            </li>
            <br />
            <br />
            <li>
              <Typography className={classes.listHeading}>
                {" "}
                ACCESSING AND CORRECTING YOUR INFORMATION
              </Typography>

              <Typography>
                With respect to the collection of your information, if you do
                not want your information collected, please do not submit it to
                us. If you have already submitted information and would like to
                review, correct or remove it from our records, you can make
                these changes directly by editing your profile on our Services.
                If you have any questions or need our assistance to access,
                correct, or delete your information, please contact us.
              </Typography>
            </li>
            <br />
            <br />
            <li>
              <Typography className={classes.listHeading}>
                OPTING OUT OF MARKETING MESSAGES
              </Typography>

              <Typography>
                We may send you marketing communications, about our services
                that may interest you. You can opt out of our marketing
                communications at any time by selecting the “unsubscribe” link
                in the e-mail or contacting us. Please note that some
                non-marketing communications are not subject to general opt-out,
                such as communications relating to services we are providing
                you, sales transactions, software updates and other support
                related information, patches and fixes.
              </Typography>
            </li>
            <br />
            <br />

            <li>
              <Typography className={classes.listHeading}>
                CONTACTING US.
              </Typography>

              <Typography>
                If you have any questions or comments regarding this Privacy
                Notice, please contact us at:{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    (window.location = "mailto:privacy@ourprime.com")
                  }
                >
                  privacy@ourprime.com.
                </span>
              </Typography>
            </li>
          </ol>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
