
export const FullScreenIcon2 = ({enabled}) => {
    return <>{enabled ? (
            <svg
                width="24"
                height="24"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M27 10.5H33V13.5H24V4.5H27V10.5ZM12 13.5H3V10.5H9V4.5H12V13.5ZM27 25.5V31.5H24V22.5H33V25.5H27ZM12 22.5V31.5H9V25.5H3V22.5H12Z"
                    fill="#E50914"
                />
            </svg>
        ) : (
            <svg
                width="24"
                height="24"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M30 4.5H33V13.5H30V7.5H24V4.5H30ZM6 4.5H12V7.5H6V13.5H3V4.5H6ZM30 28.5V22.5H33V31.5H24V28.5H30ZM6 28.5H12V31.5H3V22.5H6V28.5Z"
                    fill="black"
                />
            </svg>
        )}
    </>
}