import React from "react";

const PaginationPreviousArrow = ({ moveToPreviousPage, page }) => {
  return (
    <span
      className={`page-link previous bg-muted d-flex align-items-center justify-content-center me-2 ${
        page === 1 && "d-none"
      }`}
      aria-label="Previous"
      onClick={() => moveToPreviousPage(page - 1)}
    >
      <span aria-hidden="true">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 7.432 13"
        >
          <path
            id="Icon_ionic-ios-arrow-back"
            data-name="Icon ionic-ios-arrow-back"
            d="M13.491,12.692,18.41,7.777a.929.929,0,1,0-1.316-1.312l-5.573,5.569a.927.927,0,0,0-.027,1.281l5.6,5.608a.929.929,0,0,0,1.316-1.312Z"
            transform="translate(-11.251 -6.194)"
            fill="#ffffff"
          />
        </svg>
      </span>
    </span>
  );
};

export default PaginationPreviousArrow;
