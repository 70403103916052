/** @format */

import { useEffect, useState } from "react";
import { LiveVideoNew } from "./LiveVideoNew";
import { VideoFetcher } from "../VideoFetcher";
import { preCallActions } from "../../../../store/slices/precall";
import { useDispatch, useSelector } from "react-redux";
import { showService } from "../../../../_services/admin";
import { useLocation, useParams } from "react-router-dom";
import InviteFriendsPopUp from "../../InviteFriendsPopUp/InviteFriendsPopUp";
import { userService } from "../../../../_services";
import { useSnackbar } from "notistack";
import {
  abbreviatedSignupModalHandler,
  login,
} from "../../../../store/slices/user";
import { isMobile } from "react-device-detect";
import RotateImg from "../../../../images/mRotate.svg";
import { Container, Modal, Typography, Box } from "@material-ui/core";

export const LiveVideoNewTop = (props) => {
  const [isReloading, setIsReloading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isHost, setIsHost] = useState(false);
  const [showPortraitModal, setShowPortraitModal] = useState(false);

  const location = useLocation();
  const showId = location.state?.showId;

  useEffect(() => {
    if (isReloading) {
      window.setTimeout(() => {
        setIsReloading(false);
      }, 1500);
    }
  }, [isReloading]);

  useEffect(() => {
    setIsAdmin(true);
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
    const handleShowPortraitModal = () => {
      if (isMobile && window.innerWidth < window.innerHeight) {
        setShowPortraitModal(true);
      } else {
        setShowPortraitModal(false);
      }
    };
    handleShowPortraitModal();
    // showService
    //   .get(location.state?.showId)
    //   .then((data) => {
    //     if (data.data.AdminShowHosts.length > 0) {
    //       if (user.role === 1) {
    //         setIsAdmin(true);
    //       }
    //       let host = data.data.AdminShowHosts.find((val) => {
    //         return val.host_id === user.id && val.is_attendee === 0;
    //       });
    //       if (!!host) {
    //         setIsHost(true);
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // return () => {
    //   VideoFetcher.getInstance().destroy();
    //   dispatch(preCallActions.setLocalMicStream(null));
    //   dispatch(preCallActions.setLocalVideoStream(null));
    // };
    window.addEventListener("resize", handleShowPortraitModal);
    return () => window.removeEventListener("resize", handleShowPortraitModal);
  }, []);

  if (isReloading) {
    return (
      <div style={{ height: "100vh", width: "100vw" }}>
        <div
          className={
            "d-flex column justify-content-center justify-items-center"
          }
          style={{ color: "white", marginTop: "48vh" }}
        >
          <div>
            <center>Reloading...</center>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Modal open={showPortraitModal}>
        <Box className="modalBoxStyle">
          <div>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Please rotate your device to landscape mode.
            </Typography>
            <div className="d-flex align-items-center justify-content-center mt-3">
              <img width="80" src={RotateImg} />
            </div>
          </div>
        </Box>
      </Modal>
      <LiveVideoNew
        showId={showId}
        isHost={isHost}
        isAdmin={isAdmin}
        setIsReloading={setIsReloading}
        {...props}
      />
    </>
  );
};
