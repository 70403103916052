import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";

const REFRESH_FRAMES = 1;
var img = new Image;
img.src = "/bg-mirror.png";

export class BackgroundBlur {
    static instance;
    constructor(videoWidth=600, videoHeight=360) {
        this.canvas = document.createElement("canvas");
        this.canvasCtx = this.canvas.getContext("2d");
        this.canvas.setAttribute("width", `${videoWidth}px`)
        this.canvas.setAttribute("height", `${videoHeight}px`)
        this.selfieSegmentation = new SelfieSegmentation({locateFile: (file) => {
            return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
        }});
        this.selfieSegmentation.setOptions({
            modelSelection:0,
            effect:"background"
        });
        this.selfieSegmentation.initialize()
        this.selfieSegmentation.onResults((results) => {
            this.recalcBackground(results);
        });
        this.frameCount = 0;
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new BackgroundBlur();
        }
        return this.instance
    }

    recalcBackground(results) {
        this.currentMask = results.segmentationMask;
        this.writeFrame(results.image);
    }

    writeFrame(image) {
        if (this.canvas && this.canvasCtx && this.currentMask) {
            if (this.type === "blur") {
                this.canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);

                this.canvasCtx.globalCompositeOperation = "source-over";
                this.canvasCtx.filter = "none";
                this.canvasCtx.drawImage(this.currentMask, 0, 0, this.canvas.width, this.canvas.height);

                this.canvasCtx.globalCompositeOperation = "source-in";
                this.canvasCtx.filter = "none";
                this.canvasCtx.drawImage(image, 0, 0, this.canvas.width, this.canvas.height);

                this.canvasCtx.globalCompositeOperation = "destination-over"
                this.canvasCtx.filter = "blur(10px)";
                this.canvasCtx.drawImage(image, 0, 0, this.canvas.width, this.canvas.height);
            } else {
                this.canvasCtx.save();
                this.canvasCtx.filter = "none";

                this.canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);
                this.canvasCtx.drawImage(this.currentMask, 0, 0, this.canvas.width, this.canvas.height);

                this.canvasCtx.globalCompositeOperation = 'source-out';
                this.canvasCtx.drawImage(img, 0, 0, this.canvas.width, this.canvas.height)

                this.canvasCtx.globalCompositeOperation = 'destination-atop';
                this.canvasCtx.drawImage(image, 0, 0, this.canvas.width, this.canvas.height);

                this.canvasCtx.restore();
            }
        }
    }

    async processFrame() {
        if (!this.selfieSegmentation || !this.active || !this.videoElement){
            return;
        }
        if (!this.videoElement.paused && this.videoElement.currentTime !== this.videoTime) {
            this.videoTime = this.videoElement.currentTime;
            await this.selfieSegmentation.send({image: this.videoElement})
        }
        requestAnimationFrame(this.processFrame.bind(this));
    }

    start(video, type) {
        console.error("start")
        const videoElement = video.cloneNode();
        videoElement.autoplay = true;
        videoElement.srcObject = video.srcObject.clone();
        this.active = true;
        this.videoElement = videoElement;
        this.videoTime = 0;
        this.videoFrame = 0;
        this.type = type;

        requestAnimationFrame(this.processFrame.bind(this))
        const stream = this.canvas.captureStream();
        this.track = stream.getTracks()[0];
        return this.track
    }

    stop() {
        this.active = false;
        this.track?.stop();
        delete this.track;
        if (this.videoElement) {
            const videoTrack = this.videoElement?.srcObject?.getVideoTracks()[0];
            videoTrack?.stop();
            this.videoElement.srcObject = null
            this.videoElement.load();
        }
        delete this.videoElement;
        this.videoTime = 0;
        this.videoFrame = 0;
        this.currentMask = null;
    }
}