import { Unsubscribe } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userService } from "../../../_services";
import Header from "../../Admin/header/header";
import backArrow from "../../Admin/images/back_arrow.svg";
import "./manageemailpreference.module.css";

function ManageEmailPreference() {
  const { enqueueSnackbar } = useSnackbar();
  const [unSubscribeAll, setUnSubscribeAll] = useState({
    attending_reminders: false,
    new_show_reminders: false,
  });
  const [loadingEmailPreferences, setLoadingEmailPreferences] = useState(true);
  const [errorEmailPreferences, setErrorEmailPreferences] = useState(false);
  const [emailPreferences, setEmailPreferences] = useState(false);
  const [values, setValues] = useState({});
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    getEmailPreferences();
  }, []);

  const getEmailPreferences = () => {
    userService
      .getEmailPreferences({
        user_id: user.id,
      })
      .then((data) => {
        if (data.success) {
          setEmailPreferences(data.data);
          setLoadingEmailPreferences(false);
          if (
            data.data.new_show_reminders === 0 &&
            data.data.attending_reminders === 0
          ) {
            setUnSubscribeAll({
              new_show_reminders: true,
              attending_reminders: true,
            });
          } else if (
            data.data.new_show_reminders !== 0 &&
            data.data.attending_reminders === 0
          ) {
            setUnSubscribeAll({
              new_show_reminders: false,
              attending_reminders: true,
            });
          } else if (
            data.data.new_show_reminders === 0 &&
            data.data.attending_reminders !== 0
          ) {
            setUnSubscribeAll({
              new_show_reminders: true,
              attending_reminders: false,
            });
          }
          setValues({
            user_id: user.id,
            attending_reminders: data.data.attending_reminders,
            new_show_reminders: data.data.new_show_reminders,
          });
        } else {
          setErrorEmailPreferences(true);
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const editEmailPreferences = () => {
    userService
      .editEmailPreferences(values)
      .then((data) => {
        if (data.success) {
          enqueueSnackbar("Changes Updated", {
            variant: "success",
          });
        } else {
          setErrorEmailPreferences(true);
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: Number(event.target.value),
    });
  };

  return (
    <div>
      <Header />
      <main>
        <div className="container mt-4">
          <div className="d-flex align-items-center mb-3">
            <img className="img-fluid" src={backArrow} alt="arrow" />
            <h1 className="text-white ps-2 display-4 fw-600">
              Manage Email Preferences
            </h1>
          </div>
          <div className="bg-dark-grey p-sm-5 p-4 rounded">
            {loadingEmailPreferences ? (
              <div className="text-center my-5">
                <div className="lds-spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : errorEmailPreferences ? (
              <h3 className="mt-3 text-center" style={{ color: "white" }}>
                Response not found
              </h3>
            ) : (
              emailPreferences && (
                <>
                  <div className="col-xl-6 col-lg-8 col-md-11 col-sm-12 col-12">
                    <div className="mb-5">
                      <h6 className="text-white mb-3">
                        Be the first to know about exciting new programs
                      </h6>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="1"
                          name="new_show_reminders"
                          checked={values.new_show_reminders === 1}
                          onChange={handleChange}
                        />
                        <span className="text-white">Daily</span>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="new_show_reminders"
                          value="2"
                          checked={values.new_show_reminders === 2}
                          onChange={handleChange}
                        />
                        <span className="text-white">Weekly</span>
                      </div>
                      {unSubscribeAll.new_show_reminders && (
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="new_show_reminders"
                            value="0"
                            checked={values.new_show_reminders === 0}
                            onChange={handleChange}
                          />
                          <span className="text-white">Don’t tell me</span>
                        </div>
                      )}
                    </div>
                    <div className="mb-4">
                      <h6 className="text-white mb-3">
                        Send me reminders about events I’m attending
                      </h6>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="attending_reminders"
                          value="1"
                          checked={values.attending_reminders === 1}
                          onChange={handleChange}
                        />
                        <span className="text-white">
                          15 minutes before event
                        </span>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="attending_reminders"
                          value="2"
                          checked={values.attending_reminders === 2}
                          onChange={handleChange}
                        />
                        <span className="text-white">1 hour before event</span>
                      </div>
                      {unSubscribeAll.attending_reminders && (
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="attending_reminders"
                            value="0"
                            checked={values.attending_reminders === 0}
                            onChange={handleChange}
                          />
                          <span className="text-white">
                            Don’t send reminders
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="my-4 d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-danger btn-sm py-1 px-3"
                      onClick={editEmailPreferences}
                    >
                      SAVE CHANGES
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm text-white py-1 px-3"
                      style={{ backgroundColor: "#595959" }}
                      onClick={() => {
                        setUnSubscribeAll({
                          attending_reminders: true,
                          new_show_reminders: true,
                        });
                        setValues({
                          user_id: user.id,
                          attending_reminders: 0,
                          new_show_reminders: 0,
                        });
                      }}
                    >
                      Unsubscribe all
                    </button>
                  </div>
                  <p className="text-white">
                    <span className="fw-bold">Note:</span> It may take up to 48
                    hours for your changes to be applied. We’ll always continue
                    to send you messages regarding your account or orders.
                  </p>
                </>
              )
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default ManageEmailPreference;
