// /** @format */

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// // TODO: Replace the following with your app's Firebase project configuration
// // See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyCmtQx2xWLIxwCavoqAir3lYWe-5ledBog",
  authDomain: "ourprime-35ce9.firebaseapp.com",
  projectId: "ourprime-35ce9",
  storageBucket: "ourprime-35ce9.appspot.com",
  messagingSenderId: "1077525104888",
  appId: "1:1077525104888:web:66980ae9bbeeee0e64d30d",
  measurementId: "G-5E3WBWJMXS",
};

// // Initialize Firebase
const app = initializeApp(firebaseConfig);

// // Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export default auth;



