import classNames from "classnames/bind";
import styles from "../../../User/ResetPassword/resetPassword.module.css";
import { Modal } from "react-bootstrap";

const cx = classNames.bind(styles);

const ProgramEnds = ({ showid, backdrop, show, onHide }) => {
  let props = {
    showid,
    backdrop,
    show,
    onHide,
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="container">
        <div className=" d-flex align-items-center justify-content-center text-center">
          <div
            className={cx("outerBox", "bg-white", "p-4", "position-relative")}
          >
            <div className={cx("landscapeMode")}>
              <div className="d-flex align-items-center justify-content-center mb-2 landscape-mb-4">
                <h4>Program Ends...</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ProgramEnds;
