/** @format */

import { Navigate } from "react-router-dom";

function PreJoinRoute({ children }) {
  const isLoggedIn = !!JSON.parse(localStorage.getItem("guest")) || !!JSON.parse(localStorage.getItem("user"))  ;
  return isLoggedIn ? children : <Navigate to="/" />;
}

export default PreJoinRoute;
