import React, { useState } from "react";
import styles from "./dropdownRecurrences.module.css";
import { Icon } from "@iconify/react";

const DropdownRecurrences = ({
  recurrence,
  setRecurrence,
  setCustomRecModal,
}) => {
  const [isDropdownReccurence, setDropdownRecurrence] = useState(false);
  const options = [
    { key: 0, value: "Does not Repeat" },
    { key: 1, value: "Custom" },
  ];

  return (
    <div className={styles.dropdown}>
      <div
        className={styles.dropdown_btn}
        onClick={(e) => setDropdownRecurrence(!isDropdownReccurence)}
      >
        {recurrence.value}
        <Icon icon="akar-icons:chevron-down" color="#858585" />
      </div>
      {isDropdownReccurence && (
        <div className={styles.dropdown_content}>
          {options.map((option) => (
            <div
              className={styles.dropdown_item}
              onClick={() => {
                // setType(option);
                setDropdownRecurrence(false);
                setRecurrence(option);
                option.key && setCustomRecModal(true);
              }}
            >
              {option.value}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownRecurrences;
