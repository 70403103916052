/** @format */

import React, { useEffect, useRef, useState } from "react";
import Header from "../Admin/header/header";
import Footer from "../Admin/Footer/Footer";
import InviteFriendsPopUp from "../Admin/InviteFriendsPopUp/InviteFriendsPopUp";
import { useSnackbar } from "notistack";
import { commonService, showService } from "../../_services/admin";
import ShowTile from "./ShowTile";
import MyScheduledShowTile from "./MyScheduledShowTile";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import VideoJS from "../Admin/ShowDetail/VideoJS";
import {
  getFormattedDateTime,
  getTimeZoneAbbreviation,
  timeDistance,
} from "../../_helpers";
import {
  editShowNavigationHandler,
  editShowRedirectHandler,
  loginModalHandler,
  showInviteFriendsPopUpHandler,
  inviteFriendsPopUpScheduleIdHandler,
  attendScheduleShowHandler,
} from "../../store/slices/user";
import ShowAllCategories from "./ShowAllCategories";
import { Button, Modal } from "react-bootstrap";
import AliceCarousel from "react-alice-carousel";
import { makeStyles } from "@material-ui/core";
import "react-alice-carousel/lib/alice-carousel.css";
import { Helmet } from "react-helmet";
import CloseIcon from "../Admin/images/close.svg";
import { userService } from "../../_services";
const { Strophe } = window;

let selected_start_date = null;
let selected_end_date = null;
let scheduledShowsClick = 1;
let timesScheduledShowsFetched = 0;

const Home = () => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    user,
    editShowNavigation,
    editShowRedirect,
    showInviteFriendsPopUp,
    attendScheduleShow,
  } = useSelector((state) => state.user);
  //States
  let interval = useRef();
  const trailerRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [recordsFromDate, setRecordsFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [loadingHeroShow, setLoadingHeroShow] = useState(true);
  const [errorHeroShow, setErrorHeroShow] = useState(false);
  const [heroShow, setHeroShow] = useState();
  const [loadingAllCategories, setLoadingAllCategories] = useState(true);
  const [errorAllCategories, setErrorAllCategories] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [largeTitle, setLargeTitle] = useState(false);
  const [scheduleShow, setscheduleShow] = useState();
  const [timerDays, setTimerDays] = useState(0);
  const [timerHours, setTimerHours] = useState(0);
  const [timerMinutes, setTimerMinutes] = useState(0);
  const [timerSeconds, setTimerSeconds] = useState(0);
  const [showStatusButton, setShowStatusButton] = useState(false);
  const [trailerMuted, settrailerMuted] = useState(false);
  const [loadingScheduledShowsOnDate, setLoadingScheduledShowsOnDate] =
    useState(true);
  const [errorScheduledShowsOnDate, setErrorScheduledShowsOnDate] =
    useState(false);
  const [scheduledShowsOnDate, setScheduledShowsOnDate] = useState();
  const [currentScheduledShowAttendee, setCurrentScheduledShowAttendee] =
    useState(false);
  const [loadingMyScheduledShows, setLoadingMyScheduledShows] = useState(true);
  const [errorMyScheduledShows, setErrorMyScheduledShows] = useState(false);
  const [myScheduledShows, setMyScheduledShows] = useState();
  const [loadingContinueWatching, setLoadingContinueWatching] = useState(true);
  const [errorContinueWatching, setErrorContinueWatching] = useState(false);
  const [continueWatching, setContinueWatching] = useState();
  const [scheduleShowId, setscheduleShowId] = useState();
  const [playTrailer, setplayTrailer] = useState(false);
  const [playVOD, setplayVOD] = useState(false);
  const [trailerUrl, settrailerUrl] = useState();
  const [vodUrl, setvodUrl] = useState();
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [showScheduleId, setShowScheduleId] = useState(0);
  const [showId, setShowId] = useState();
  const [
    heroShowUnAttendConfirmationModal,
    setHeroShowUnAttendConfirmationModal,
  ] = useState(false);
  const [scheduleShowModal, setScheduleShowModal] = useState(false);
  const [clickedScheduleShowScheduleId, setClickedScheduleShowScheduleId] =
    useState();
  const [clickedScheduleShowId, setClickedScheduleShowId] = useState();
  const [scheduleShowUnAttendTitle, setScheduleShowUnAttendTitle] = useState();
  const [myScheduleShowUnAttendTitle, setMyScheduleShowUnAttendTitle] =
    useState();
  const [homepageStrip, setHomePageStrip] = useState();

  //UseEffect's
  useEffect(() => {
    let initialDate = moment().format("YYYY-MM-DD");
    const initialStartDate = moment(`${initialDate} 00:00:00`).utc().format();
    const initialEndDate = moment(`${initialDate} 23:59:59`).utc().format();
    getScheduledShowsOnDate(initialStartDate, initialEndDate);
    getHeroShow();
    getCategoryWithScheduledShowOrCategory();
    getHomePageStrip();
    if (!_.isNull(user)) {
      getMyScheduleShows();
      getAllContinueWatching();
    }
  }, [user, attendScheduleShow?.is_attendee === 1]);

  useEffect(() => {
    if (editShowNavigation === true) {
      navigate(`/admin/show/${editShowRedirect.id}`);
      dispatch(editShowNavigationHandler(false));
      dispatch(editShowRedirectHandler("none"));
    }
  }, [editShowNavigation]);

  useEffect(() => {
    if (!_.isUndefined(heroShow)) {
      if (!_.isNull(heroShow.trailer_url) && !_.isEmpty(heroShow.trailer_url)) {
        settrailerUrl(heroShow.trailer_url);
      }
      heroShow.title.length > 75 ? setLargeTitle(true) : setLargeTitle(false);
      scheduledShow();
    }
  }, [heroShow]);

  useEffect(() => {
    if (!_.isUndefined(scheduleShow) || !_.isEmpty(scheduleShow)) {
      setscheduleShowId(scheduleShow.id);
      handleCurrentScheduledAttend(scheduleShow.id);
      // console.log("scheduledMeetingFriends :>> ", scheduleShow);
      startTimer();
      return () => {
        clearInterval(interval.current);
      };
    }
  }, [scheduleShow]);

  // Functions

  const airingOnDateFormat = () => {
    let day = moment(`${scheduleShow.utc_datetime}`).format("llll");
    day = day.substring(0, 3);
    let date = moment(`${scheduleShow.utc_datetime}`).format("l");
    date = date.substring(0, date.lastIndexOf("/"));
    let time = moment(`${scheduleShow.utc_datetime}`).format("LT");
    return (
      <p className="text-white h5 mb-0 me-3">
        {
          <>
            {day} {date} {time}
          </>
        }
      </p>
    );
  };

  const handleTrailerMute = () => {
    console.log("trailerRef :>> ", trailerRef);
    if (trailerRef.current.muted) {
      // if muted, show mute image
      trailerRef.current.muted = false;
      settrailerMuted(false);
    } else {
      // if not muted, show not muted image
      trailerRef.current.muted = true;
      settrailerMuted(true);
    }
  };

  const scheduledShow = () => {
    const show = heroShow.AdminShowSchedules.find((item) => {
      return item.is_completed === 0;
    });
    setscheduleShow(show);
    if (_.isUndefined(show)) {
      setShowStatusButton(true);
      // if (!_.isNull(heroShow.trailer_url) && !_.isEmpty(heroShow.trailer_url)) {
      //   setplayTrailer(true);
      // }
    }
  };

  const renderPrimaryHostName = (hosts) => {
    const primaryHosts = hosts.find((item) => {
      return item.is_primary === 1;
    });
    return primaryHosts?.UserBasicDetail?.full_name
      ? primaryHosts?.UserBasicDetail?.full_name
      : "";
  };

  const renderImageOrInitials = (hosts) => {
    const primaryHosts = hosts.find((item) => {
      return item.is_primary === 1;
    });
    if (!_.isUndefined(primaryHosts)) {
      return (
        <>
          {!_.isNull(primaryHosts.UserBasicDetail.profile_image) &&
          !_.isEmpty(primaryHosts.UserBasicDetail.profile_image) ? (
            <img
              className="img-fluid"
              src={primaryHosts.UserBasicDetail.profile_image}
              alt="user"
            />
          ) : (
            <span className="text-white">
              {!_.isNull(primaryHosts.UserBasicDetail.first_name) &&
                !_.isNull(primaryHosts.UserBasicDetail.last_name) && (
                  <>
                    <span>
                      {primaryHosts.UserBasicDetail.first_name.charAt(0)}
                      {primaryHosts.UserBasicDetail.last_name.charAt(0)}
                    </span>
                  </>
                )}
            </span>
          )}
        </>
      );
    }
  };

  const startTimer = () => {
    const countdownDate = new Date(`${scheduleShow.utc_datetime}`).getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        // stop our timer
        clearInterval(interval.current);
        setShowStatusButton(true);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
        setShowStatusButton(true);
      }
    }, 1000);
  };

  const imageSrc = (adminShowImages) => {
    const hero_image = adminShowImages?.find((item) => {
      return item.is_hero === 1;
    });
    if (_.isUndefined(hero_image)) {
      return "src/images/default-image.jpeg";
    }
    return hero_image?.hero_url;
  };

  const formatTimer = (seconds, minutes) => {
    let secondsString;
    let minutesString;
    if (seconds < 10) {
      secondsString = "0" + seconds.toString();
      // console.log('secondsString :>> ', secondsString);
    }
    if (minutes < 10) {
      minutesString = "0" + minutes.toString();
      // console.log('minutesString :>> ', minutesString);
    }
    if (seconds >= 10) {
      secondsString = seconds.toString();
      // console.log('secondsString :>> ', secondsString);
    }
    if (minutes >= 10) {
      minutesString = minutes.toString();
      // console.log('minutesString :>> ', minutesString);
    }
    return (
      <>
        <p className="text-white h5 mb-0 me-3">Starting In</p>
        <span className="bgTransCounter p-2 rounded text-white fw-bold">
          {!!minutesString ? minutesString.charAt(0) : ""}
        </span>
        <span className="bgTransCounter p-2 rounded text-white fw-bold ms-1">
          {!!minutesString ? minutesString.charAt(1) : ""}
        </span>
        <span className="p-2 text-white fw-bold">:</span>
        <span className="bgTransCounter p-2 rounded text-white fw-bold">
          {!!secondsString ? secondsString.charAt(0) : ""}
        </span>
        <span className="bgTransCounter p-2 rounded text-white fw-bold ms-1">
          {!!secondsString ? secondsString.charAt(1) : ""}
        </span>
      </>
    );
  };

  const handleJoinEarly = (scheduled_time) => {
    if (_.isNull(user)) {
      dispatch(loginModalHandler(true));
    } else {
      if (!currentScheduledShowAttendee) {
        UnAttendHeroShow(scheduled_time);
      }
      const showId = heroShow.id.toString();
      navigate("/conference/pre-join", {
        state: {
          showId,
        },
      });
    }
  };

  const handleCurrentScheduledAttend = (scheduledShowId) => {
    if (!_.isNull(user)) {
      const alreadyAttendee = heroShow?.friendsAttending?.find((item) => {
        return item.UserBasicDetail?.email === user.email;
      });
      if (!_.isUndefined(alreadyAttendee)) {
        setCurrentScheduledShowAttendee(true);
      }
    } else {
      setCurrentScheduledShowAttendee(false);
    }
  };

  const renderPlayTrailer = () => {
    if (!_.isEmpty(heroShow.trailer_url)) {
      return (
        <span className="d-flex align-items-center">
          <button
            type="button"
            className="btn text-light btn-sm px-3 me-1 mb-0 fw-bold cursor-pointer"
            style={{ background: "rgba(84,83,83,0.4)" }}
            onClick={() => {
              setplayTrailer(true);
              settrailerUrl(heroShow.trailer_url);
            }}
          >
            <span>Play Trailer</span>
          </button>
        </span>
      );
    } else {
      return <></>;
    }
  };

  const renderPlayVOD = () => {
    const vod = heroShow.AdminMedia.find((item) => {
      return item.is_vod === 1;
    });
    if (!_.isEmpty(vod)) {
      return (
        <span className="d-flex align-items-center">
          <button
            type="button"
            className="btn text-danger btn-sm bg-light px-3 me-1 mb-0 fw-bold cursor-pointer"
            onClick={() => {
              if (!_.isNull(user)) {
                navigate(`/admin/show-detail/${heroShow.id}`, {
                  state: {
                    from: "homehero",
                  },
                });
              } else {
                dispatch(loginModalHandler(true));
              }
            }}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 29 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.39844 29.5C5.39844 30.324 6.3392 30.7944 6.99844 30.3L22.3318 18.8C22.8651 18.4 22.8651 17.6 22.3318 17.2L6.99844 5.7C6.3392 5.20557 5.39844 5.67595 5.39844 6.5V29.5Z"
                fill="red"
              />
            </svg>
            Play Recording
          </button>
        </span>
      );
    } else {
      return <></>;
    }
  };

  const timeStatus = (datetime) => {
    const distance = timeDistance(datetime);

    if (distance > 0) {
      let scheduleShowStats = moment(`${datetime}`).format("LT");
      return <p className="text-white h5 mb-0 me-3">{scheduleShowStats}</p>;
      // <span className="ms-3 text-white">{scheduleShowStats}</span>;
    } else {
      const scheduleShowStats = moment(`${datetime}`).fromNow();
      return <p className="text-white h5 mb-0 me-3">{scheduleShowStats}</p>;
    }
  };

  //API's
  const getHeroShow = () => {
    showService
      .getHeroShow()
      .then((data) => {
        if (data.success) {
          setHeroShow(data.data);
          setLoadingHeroShow(false);
        } else {
          setErrorHeroShow(true);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const getHomePageStrip = () => {
    userService
      .getHomePageStrip()
      .then((data) => {
        if (data.success) {
          setHomePageStrip(data.data.value);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const getScheduledShowsOnDate = (startDate, endDate) => {
    setLoadingScheduledShowsOnDate(true);
    showService
      .getScheduledShowOnDate({
        start_date: startDate,
        end_date: endDate,
      })
      .then((data) => {
        if (data.success) {
          if (data.data.length > 0) {
            setScheduledShowsOnDate(data.data);
            setLoadingScheduledShowsOnDate(false);
          } else {
            let fetchNextOrBackScheduledShowsBtn;
            if (scheduledShowsClick === 1) {
              fetchNextOrBackScheduledShowsBtn = document.getElementById(
                "fetch-next-scheduled-shows"
              );
            } else if (scheduledShowsClick === -1) {
              fetchNextOrBackScheduledShowsBtn = document.getElementById(
                "fetch-prev-scheduled-shows"
              );
            }
            timesScheduledShowsFetched++;
            if (timesScheduledShowsFetched <= 7) {
              fetchNextOrBackScheduledShowsBtn.click();
            } else {
              timesScheduledShowsFetched = 0;
              setScheduledShowsOnDate([]);
              setLoadingScheduledShowsOnDate(false);
            }
          }
        } else {
          setErrorScheduledShowsOnDate(true);
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const getMyScheduleShows = () => {
    showService
      .getMyScheduleShows({
        user_id: user.id,
      })
      .then((data) => {
        if (data.success) {
          setMyScheduledShows(data.data);
          setLoadingMyScheduledShows(false);
        } else {
          setErrorMyScheduledShows(true);
          // enqueueSnackbar(data.message, {
          //   variant: "error",
          // });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const getAllContinueWatching = () => {
    showService
      .getAllContinueWatching({
        user_id: user.id,
      })
      .then((data) => {
        if (data.success) {
          setContinueWatching(data.data);
          setLoadingContinueWatching(false);
        } else {
          setErrorContinueWatching(true);
          // enqueueSnackbar(data.message, {
          //   variant: "error",
          // });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const showUnAttendConfirmationModal = (showScheduleId, showId, showTitle) => {
    setMyScheduleShowUnAttendTitle(showTitle);
    setShowScheduleId(showScheduleId);
    setShowId(showId);
    setShowConfirmationBox(true);
  };

  const getCategoryWithScheduledShowOrCategory = () => {
    commonService
      .getCategoryWithScheduledShowOrCategory()
      .then((data) => {
        if (data.success) {
          setAllCategories(data.data);
          setLoadingAllCategories(false);
        } else {
          setErrorAllCategories(true);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const handleSchedulesOnDate = (count) => {
    let date;
    if (count > 0) {
      scheduledShowsClick = 1;
      date = moment(recordsFromDate).add(1, "days").format("YYYY-MM-DD");
    } else {
      scheduledShowsClick = -1;
      date = moment(recordsFromDate).subtract(1, "days").format("YYYY-MM-DD");
    }
    console.log("date", date);
    setRecordsFromDate(date);
    const startDate = moment(`${date} 00:00:00`).utc().format();
    const endDate = moment(`${date} 23:59:59`).utc().format();
    selected_start_date = startDate;
    selected_end_date = endDate;
    getScheduledShowsOnDate(startDate, endDate);
  };

  const scheduleDateFormatting = () => {
    const day = moment(recordsFromDate).format("dddd");
    var date = moment(recordsFromDate).format("l");
    date = date.substring(0, date.lastIndexOf("/"));
    return (
      <h2 className="text-white fw-bold px-4">
        {day} {date}
      </h2>
    );
  };

  const UnAttendHeroShow = (scheduled_time) => {
    const payload = scheduled_time
      ? {
          show_id: heroShow.id,
          user_id: user.id,
          show_schedule_id: scheduleShowId,
          scheduled_time:
            getFormattedDateTime(scheduled_time) +
            " " +
            getTimeZoneAbbreviation(),
        }
      : {
          show_id: heroShow.id,
          user_id: user.id,
          show_schedule_id: scheduleShowId,
        };
    console.log("payload :>> ", payload);
    showService
      .addOrRemoveAttendee(payload)
      .then((data) => {
        if (data.success) {
          if (_.isEmpty(data)) {
            setCurrentScheduledShowAttendee(true);
          } else {
            setCurrentScheduledShowAttendee(false);
          }
          getHeroShow();
          getMyScheduleShows();
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
    setHeroShowUnAttendConfirmationModal(false);
  };

  const onConfirm = () => {
    showService
      .addOrRemoveAttendee({
        show_id: showId,
        user_id: user.id,
        show_schedule_id: showScheduleId,
      })
      .then((data) => {
        if (data.success) {
          getMyScheduleShows();
          getHeroShow();
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
    setShowConfirmationBox(false);
  };

  const truncate = (str) =>
    str?.length > 45 ? `${str.substring(0, 42)}...` : str;

  const handleScheduledShowUnAttend = (scheduleId, showId, showTitle) => {
    setScheduleShowUnAttendTitle(showTitle);
    setClickedScheduleShowScheduleId(scheduleId);
    setClickedScheduleShowId(showId);
    setScheduleShowModal(true);
  };

  const handleScheduledShowUnAttendOnConfirm = () => {
    const date = moment().format("YYYY-MM-DD");
    showService
      .addOrRemoveAttendee({
        show_id: clickedScheduleShowId,
        user_id: user.id,
        show_schedule_id: clickedScheduleShowScheduleId,
      })
      .then((data) => {
        if (data.success) {
          getScheduledShowsOnDate(
            selected_start_date || moment(`${date} 00:00:00`).utc().format(),
            selected_end_date || moment(`${date} 23:59:59`).utc().format()
          );
          getMyScheduleShows();
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
    setScheduleShowModal(false);
  };

  //Continue to watch horizontal button slider
  // var w = document.documentElement.clientWidth || window.innerWidth;
  // console.log(w);
  const createItems = (length, [handleClick]) => {
    let deltaX = 0;
    let difference = 0;
    const swipeDelta = 20;

    return Array.from({ length }).map((item, i) => (
      <div
        data-value={i + 1}
        className="item"
        onMouseDown={(e) => (deltaX = e.pageX)}
        onMouseUp={(e) => (difference = Math.abs(e.pageX - deltaX))}
        onClick={() => difference < swipeDelta && handleClick(i)}
      >
        <span className="item-inner" />
      </div>
    ));
  };
  const [activeIndex, setActiveIndex] = useState(0);
  // const [items] = useState(createItems(5, [setActiveIndex]));
  const [mainAnimation, setMainAnimation] = useState(false);
  const slidePrev = () => setActiveIndex(activeIndex - 1);
  const slideNext = () => {
    setActiveIndex(activeIndex + 1);
    setMainAnimation(true);
  };
  const syncActiveIndex = ({ item }) => setActiveIndex(item);
  // const listRef = useRef();
  // const divWidth = parseInt(continueWatching?.length) * 268;
  // const [slideNumber, setSlideNumber] = useState(0);
  // // const [isMoved, setIsMoved] = useState(false);
  // // const [clickLimit, setClickLimit] = useState(window.innerWidth / 500);
  // const handleClick = (direction) => {
  //   let distance = listRef.current.getBoundingClientRect().x - 48;

  //   if (direction === "left" && slideNumber > 0) {
  //     listRef.current.style.transform = `translateX(${804 + distance}px)`;
  //     setSlideNumber(slideNumber - 1);
  //   }
  //   if (direction === "right" && slideNumber <= continueWatching.length / 6) {
  //     listRef.current.style.transform = `translateX(${-804 + distance}px)`;
  //     setSlideNumber(slideNumber + 1);
  //   }
  // };

  const handleShowInviteFriendsPopUp = (scheduleShowId) => {
    dispatch(showInviteFriendsPopUpHandler(true));
    dispatch(inviteFriendsPopUpScheduleIdHandler(scheduleShowId));
  };

  //carousel Start
  const useStyles = makeStyles(() => ({
    carousel: {
      display: "flex !important",
      overflowX: "auto",
    },
    carouselItem: {
      display: "flex !important",
      justifyContent: "space-between",
      transition: "all 1s ease",
    },
  }));
  const classes = useStyles();
  const responsive = {
    0: {
      items: 1,
    },
    300: {
      items: 4,
    },
  };

  let continueWatchingItems = continueWatching?.map((item, index) => {
    return (
      <div className={classes.carouselItem}>
        <div className="continueWFixed mb-4 mx-2" key={index}>
          <div
            className=" continueSec rounded-20"
            style={{
              background: `url(${imageSrc(item?.AdminShow?.AdminShowImages)})`,
            }}
          >
            <div className="p-3 position-absolute episodeContent">
              <div className="d-flex align-items-center">
                <button
                  type="button"
                  className="btn btn-danger btn-sm py-1 px-3"
                  onClick={() => {
                    if (!_.isNull(user)) {
                      navigate(`/admin/show-detail/${item.show_id}`, {
                        state: {
                          from: "homecontinuewatching",
                        },
                      });
                    } else {
                      dispatch(loginModalHandler(true));
                    }
                  }}
                >
                  <svg
                    className="me-1"
                    width="7"
                    height="10"
                    viewBox="0 0 7 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.0625 9.5L6.9375 5L0.0625 0.5V9.5Z"
                      fill="white"
                    />
                  </svg>
                  Play
                </button>
                <p
                  className="text-white mb-0 ms-2 cursor-pointer"
                  onClick={() => {
                    navigate(`/admin/show-detail/${item.show_id}`);
                  }}
                >
                  More info
                </p>
              </div>
              <h6 className="text-white fw-bold mt-2 mb-1 continueHeadHeight">
                {truncate(item?.AdminShow?.title)}
              </h6>
              {/* <p className="text-white mb-0">Episodes #23</p> */}
              <div className="d-flex align-items-center justify-content-end mt-2">
                <div className="progress customProgress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width:
                        item.completed_time !== 0
                          ? `${(item.completed_time / item.total_time) * 100}` +
                            "%"
                          : `100%`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center mt-3">
            <div className="me-1 w-30">
              <div className="img-25 rounded-circle overflow-h m-auto bg-accent-blue d-flex align-items-center justify-content-center">
                {/* {!_.isNull(
                item.AdminShow.AdminShowHosts[0]
                  ?.UserBasicDetail.profile_image
              ) &&
              !_.isEmpty(
                item.AdminShow.AdminShowHosts[0]
                  ?.UserBasicDetail.profile_image
              ) ? (
                <img
                  className="img-fluid"
                  src={
                    item.AdminShow.AdminShowHosts[0]
                      ?.UserBasicDetail.profile_image
                  }
                  alt="img"
                />
              ) : (
                <span className="text-white">
                  {!_.isNull(
                    item.AdminShow.AdminShowHosts[0]
                      ?.UserBasicDetail.first_name
                  ) &&
                    !_.isNull(
                      item.AdminShow.AdminShowHosts[0]
                        ?.UserBasicDetail.last_name
                    ) && (
                      <>
                        <span
                          style={{ fontSize: "11px" }}
                        >
                          {item.AdminShow.AdminShowHosts[0]?.UserBasicDetail.first_name.charAt(
                            0
                          )}
                          {item.AdminShow.AdminShowHosts[0]?.UserBasicDetail.last_name.charAt(
                            0
                          )}
                        </span>
                      </>
                    )}
                </span>
              )} */}
                {renderImageOrInitials(item?.AdminShow?.AdminShowHosts)}
              </div>
            </div>
            <p className="text-secondary mb-0 small mx-1">Hosted By</p>
            <p className="text-white mb-0 small">
              {renderPrimaryHostName(item?.AdminShow?.AdminShowHosts)}
            </p>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      <Helmet>
        <title>OurPrime-The Community Center For Experienced Adults</title>
      </Helmet>
      <Header />

      {/* HeroShow Modal */}

      {showInviteFriendsPopUp && <InviteFriendsPopUp />}

      <Modal show={heroShowUnAttendConfirmationModal}>
        <Modal.Header className="modalStyle">
          <h5>Do you wish to no longer attend "{heroShow?.title}"?</h5>
        </Modal.Header>
        <Modal.Footer className="modalStyle">
          <Button
            variant="secondary"
            onClick={() => setHeroShowUnAttendConfirmationModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={() => UnAttendHeroShow(null)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Schedule Shows Modal */}

      <Modal show={scheduleShowModal}>
        <Modal.Header className="modalStyle">
          <h5>
            Do you wish to no longer attend "{scheduleShowUnAttendTitle}"?
          </h5>
        </Modal.Header>
        <Modal.Footer className="modalStyle">
          <Button
            variant="secondary"
            onClick={() => setScheduleShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleScheduledShowUnAttendOnConfirm}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <main>
        <div className="w-100">
          {homepageStrip && (
            <div className="homepageStrip">
              <p>{homepageStrip}</p>
            </div>
          )}

          {loadingHeroShow ? (
            <div className="text-center my-5">
              <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : errorHeroShow ? (
            <h3 className="mt-3 text-center" style={{ color: "white" }}>
              Response not found
            </h3>
          ) : (
            <div
              className={`yogaBanner  ${
                playVOD || playTrailer
                  ? "windowH ratio ratio-16x9 p-0"
                  : "homePageBnr"
              }`}
              style={{
                backgroundImage:
                  playTrailer || playVOD
                    ? `none`
                    : `url(${imageSrc(heroShow.AdminShowImages)})`,
              }}
            >
              <div className="d-flex align-items-center position-relative h-100">
                <div className="container-fluid px-lg-5 px-md-5 px-ms-4 px-4 d-flex align-items-center">
                  <>
                    {playTrailer ? (
                      <div className="bannerVideo">
                        <video
                          width="100%"
                          height="100%"
                          autoPlay
                          onEnded={() => {
                            setplayTrailer(false);
                            settrailerMuted(false);
                          }}
                          ref={trailerRef}
                          onClick={() => {
                            setplayTrailer(false);
                            settrailerMuted(false);
                          }}
                        >
                          <source src={trailerUrl} type="video/mp4" />
                        </video>
                        <div
                          className="speakerIcon"
                          onClick={handleTrailerMute}
                        >
                          {trailerMuted ? (
                            <svg
                              viewBox="0 0 256 256"
                              height="25px"
                              width="25px"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect fill="none" height="256" width="256" />
                              <path
                                d="M218.9,77.1a71.9,71.9,0,0,1,0,101.8"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              />
                              <line
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                                x1="80"
                                x2="80"
                                y1="88"
                                y2="168"
                              />
                              <path
                                d="M190.6,105.4a31.9,31.9,0,0,1,0,45.2"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              />
                              <line
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                                x1="48"
                                x2="208"
                                y1="40"
                                y2="216"
                              />
                              <path
                                d="M152,154.4V224L80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80l6.8-5.3"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              />
                              <polyline
                                fill="none"
                                points="112.2 63 152 32 152 106.8"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              />
                            </svg>
                          ) : (
                            <svg
                              viewBox="0 0 256 256"
                              height="25px"
                              width="25px"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect fill="none" height="256" width="256" />
                              <path
                                d="M218.9,77.1a71.9,71.9,0,0,1,0,101.8"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              />
                              <path
                                d="M80,168H32a8,8,0,0,1-8-8V96a8,8,0,0,1,8-8H80l72-56V224Z"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              />
                              <line
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                                x1="80"
                                x2="80"
                                y1="88"
                                y2="168"
                              />
                              <path
                                d="M190.6,105.4a31.9,31.9,0,0,1,0,45.2"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="16"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    ) : playVOD ? (
                      <div className="bannerVideo">
                        <VideoJS
                          options={{
                            controls: true,
                            autoplay: true,
                            responsive: true,
                            sources: [
                              {
                                src: vodUrl,
                                type: "video/mp4",
                              },
                            ],
                          }}
                        />
                        <div
                          className="crossIcon"
                          onClick={() => setplayVOD(false)}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.0018 4.99997C14.8455 4.84374 14.6336 4.75598 14.4126 4.75598C14.1917 4.75598 13.9797 4.84374 13.8235 4.99997L10.0018 8.82164L6.18013 4.99997C6.02386 4.84374 5.81194 4.75598 5.59097 4.75598C5.37 4.75598 5.15807 4.84374 5.0018 4.99997C4.84558 5.15624 4.75781 5.36817 4.75781 5.58914C4.75781 5.81011 4.84558 6.02203 5.0018 6.1783L8.82347 9.99997L5.0018 13.8216C4.84558 13.9779 4.75781 14.1898 4.75781 14.4108C4.75781 14.6318 4.84558 14.8437 5.0018 15C5.15807 15.1562 5.37 15.244 5.59097 15.244C5.81194 15.244 6.02386 15.1562 6.18013 15L10.0018 11.1783L13.8235 15C13.9797 15.1562 14.1917 15.244 14.4126 15.244C14.6336 15.244 14.8455 15.1562 15.0018 15C15.158 14.8437 15.2458 14.6318 15.2458 14.4108C15.2458 14.1898 15.158 13.9779 15.0018 13.8216L11.1801 9.99997L15.0018 6.1783C15.158 6.02203 15.2458 5.81011 15.2458 5.58914C15.2458 5.36817 15.158 5.15624 15.0018 4.99997Z"
                              fill="#FFF"
                            />
                          </svg>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                  <div
                    className={`col-xl-6 col-lg-8 col-md-12 col-sm-12 col-12 ${
                      (playVOD || playTrailer) && "d-none"
                    }`}
                  >
                    <h1
                      className={`text-white fw-bold mob_h1 ${
                        largeTitle && "h3"
                      }`}
                    >
                      {heroShow.title}
                    </h1>
                    <div className="d-flex align-items-center mt-lg-4 mt-md-3 mt-sm-3 mt-3 mb-3 pt-lg-2 pt-md-0 pt-sm-0 pt-0">
                      <div className="flex-shrink-0">
                        <div className="img-35 rounded-circle overflow-h me-3 bg-accent-blue d-flex align-items-center justify-content-center">
                          {renderImageOrInitials(heroShow?.AdminShowHosts)}
                        </div>
                      </div>
                      <div className="w-100">
                        <p className="text-secondary small mb-0">Hosted by</p>
                        <p className="text-white mb-0 small">
                          {renderPrimaryHostName(heroShow?.AdminShowHosts)}
                        </p>
                      </div>
                    </div>
                    <p
                      className={`text-white lh-base  pe-lg-5 ${
                        largeTitle && "small"
                      }`}
                    >
                      {heroShow.description}
                    </p>
                    <div className="d-flex align-items-center mb-lg-4 mb-md-2 mb-sm-0 mb-0 pb-2">
                      <img
                        className="img-fluid catHeight"
                        src={
                          heroShow?.AdminShowCategories[0]?.AdminCategory
                            ?.red_icon_file
                        }
                        alt="categoryIcon"
                      />
                      <p className="text-white ms-2 mb-0">
                        {heroShow?.AdminShowCategories[0]?.AdminCategory?.title}
                      </p>
                    </div>
                    {/* <h5 className="fw-light text-white mb-3">
                      Tuesday, 4/22 3:30 PM
                    </h5> */}
                    <>
                      <>
                        {/* Timer Date Live Below 3 Buttons */}
                        {_.isUndefined(scheduleShow) ? (
                          <></>
                        ) : timerDays > 0 ||
                          timerHours > 0 ||
                          (timerDays === 0 &&
                            timerHours === 0 &&
                            timerMinutes >= 15) ? (
                          <div
                            className={`d-flex align-items-center my-3 ${
                              !showStatusButton && `opacity-0`
                            }`}
                          >
                            <p className="text-white h5 mb-0 me-3 fw-light">
                              Airing on :
                            </p>
                            {airingOnDateFormat()}
                          </div>
                        ) : timerDays === 0 &&
                          timerHours === 0 &&
                          timerMinutes === 0 &&
                          timerSeconds === 0 ? (
                          <div
                            className={`d-flex align-items-center mb-lg-3 mb-md-2 mb-sm-2 mb-2 ${
                              !showStatusButton && `opacity-0`
                            }`}
                          >
                            <svg
                              width="10px"
                              height="10px"
                              viewBox="0 0 72 72"
                              id="emoji"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="color">
                                <circle
                                  cx="36"
                                  cy="36.0001"
                                  r="28"
                                  fill="#d22f27"
                                />
                              </g>
                              <g id="line">
                                <circle
                                  cx="36"
                                  cy="36.0001"
                                  r="28"
                                  fill="none"
                                  stroke="#000"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                              </g>
                            </svg>
                            <p className="text-white h5 mb-0 mx-2 fw-light">
                              Live :
                            </p>
                            {timeStatus(scheduleShow.utc_datetime)}
                          </div>
                        ) : (
                          <div
                            className={`d-flex align-items-center my-3 ${
                              !showStatusButton && `opacity-0`
                            }`}
                          >
                            {formatTimer(timerSeconds, timerMinutes)}
                          </div>
                        )}
                      </>
                      <div
                        className={`d-flex align-items-center ${
                          !showStatusButton && `opacity-0`
                        }`}
                      >
                        {_.isUndefined(scheduleShow) ? (
                          <></>
                        ) : timerDays > 0 ||
                          timerHours > 0 ||
                          (timerDays === 0 &&
                            timerHours === 0 &&
                            timerMinutes >= 15) ? (
                          <button
                            id="i-attend"
                            type="button"
                            className={`btn ${
                              currentScheduledShowAttendee
                                ? "bg-white text-danger"
                                : "btn-danger"
                            } btn-sm px-4 me-1`}
                            onClick={() => {
                              if (!_.isNull(user)) {
                                // handleScheduledShowAttend(
                                //   `${scheduleShow.id}`
                                // );
                                if (currentScheduledShowAttendee) {
                                  setHeroShowUnAttendConfirmationModal(true);
                                } else {
                                  UnAttendHeroShow(scheduleShow.utc_datetime);
                                }
                              } else {
                                dispatch(
                                  attendScheduleShowHandler({
                                    id: scheduleShow.id,
                                    show_id: scheduleShow.show_id,
                                    scheduled_time: getFormattedDateTime(
                                      scheduleShow.utc_datetime
                                    ),
                                    show_title: heroShow.title,
                                    is_attendee: 0,
                                  })
                                );
                                dispatch(loginModalHandler(true));
                              }
                            }}
                          >
                            {currentScheduledShowAttendee
                              ? "Attending"
                              : "I'll Attend"}
                          </button>
                        ) : timerDays === 0 &&
                          timerHours === 0 &&
                          timerMinutes === 0 &&
                          timerSeconds === 0 ? (
                          <button
                            type="button"
                            className="btn btn-danger btn-sm px-4 me-1"
                            onClick={() =>
                              handleJoinEarly(scheduleShow.utc_datetime)
                            }
                          >
                            Join Now
                            <svg
                              className="ms-1"
                              width="9"
                              height="8"
                              viewBox="0 0 9 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.75 4.0001C8.74696 3.67129 8.61447 3.35691 8.38125 3.1251L5.7 0.437605C5.5829 0.321198 5.42449 0.255859 5.25938 0.255859C5.09426 0.255859 4.93585 0.321198 4.81875 0.437605C4.76017 0.495707 4.71367 0.564832 4.68194 0.640994C4.65021 0.717156 4.63388 0.798847 4.63388 0.881354C4.63388 0.963862 4.65021 1.04555 4.68194 1.12171C4.71367 1.19788 4.76017 1.267 4.81875 1.3251L6.875 3.3751H0.625C0.45924 3.3751 0.300269 3.44095 0.183058 3.55816C0.0658481 3.67537 0 3.83434 0 4.0001C0 4.16586 0.0658481 4.32483 0.183058 4.44204C0.300269 4.55925 0.45924 4.6251 0.625 4.6251H6.875L4.81875 6.68135C4.70106 6.79821 4.63461 6.95704 4.63403 7.12289C4.63344 7.28874 4.69876 7.44803 4.81563 7.56572C4.93249 7.68341 5.09131 7.74986 5.25717 7.75044C5.42302 7.75103 5.58231 7.68571 5.7 7.56885L8.38125 4.88135C8.61599 4.64801 8.7486 4.33109 8.75 4.0001Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-danger btn-sm px-4 me-1"
                            onClick={handleJoinEarly}
                          >
                            Join Early
                            <svg
                              className="ms-1"
                              width="9"
                              height="8"
                              viewBox="0 0 9 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.75 4.0001C8.74696 3.67129 8.61447 3.35691 8.38125 3.1251L5.7 0.437605C5.5829 0.321198 5.42449 0.255859 5.25938 0.255859C5.09426 0.255859 4.93585 0.321198 4.81875 0.437605C4.76017 0.495707 4.71367 0.564832 4.68194 0.640994C4.65021 0.717156 4.63388 0.798847 4.63388 0.881354C4.63388 0.963862 4.65021 1.04555 4.68194 1.12171C4.71367 1.19788 4.76017 1.267 4.81875 1.3251L6.875 3.3751H0.625C0.45924 3.3751 0.300269 3.44095 0.183058 3.55816C0.0658481 3.67537 0 3.83434 0 4.0001C0 4.16586 0.0658481 4.32483 0.183058 4.44204C0.300269 4.55925 0.45924 4.6251 0.625 4.6251H6.875L4.81875 6.68135C4.70106 6.79821 4.63461 6.95704 4.63403 7.12289C4.63344 7.28874 4.69876 7.44803 4.81563 7.56572C4.93249 7.68341 5.09131 7.74986 5.25717 7.75044C5.42302 7.75103 5.58231 7.68571 5.7 7.56885L8.38125 4.88135C8.61599 4.64801 8.7486 4.33109 8.75 4.0001Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        )}
                        {renderPlayVOD()}
                        {renderPlayTrailer()}
                        <a
                          href={`/admin/show-detail/${heroShow.id}`}
                          className="btn text-light btn-sm px-3 me-1 mb-0 fw-bold cursor-pointer"
                          style={{ background: "rgba(84,83,83,0.4)" }}
                        >
                          More Info
                        </a>
                      </div>
                    </>

                    {!_.isUndefined(scheduleShowId) && (
                      <>
                        {heroShow?.friendsAttending?.length > 0 && (
                          <p
                            className={`text-danger small mt-4 pt-2 ${
                              !showStatusButton && `opacity-0`
                            }`}
                          >
                            Attending
                          </p>
                        )}
                        <div
                          className={`d-flex flex-wrap cursor-pointer ${
                            !showStatusButton && `opacity-0`
                          }`}
                          onClick={() => {
                            if (!_.isNull(user)) {
                              handleShowInviteFriendsPopUp(scheduleShowId);
                            } else {
                              dispatch(loginModalHandler(true));
                            }
                          }}
                        >
                          {heroShow?.friendsAttending?.length > 0 &&
                            (heroShow?.friendsAttending?.length > 4
                              ? heroShow?.friendsAttending
                                  ?.slice(0, 4)
                                  .map((item) => {
                                    return (
                                      <div key={item.id} className="me-2 w-55">
                                        <div className="img-44 rounded-circle m-auto bg-accent-blue d-flex align-items-center justify-content-center">
                                          {!_.isNull(
                                            item.UserBasicDetail.profile_image
                                          ) &&
                                          !_.isEmpty(
                                            item.UserBasicDetail.profile_image
                                          ) ? (
                                            <img
                                              className="img-fluid"
                                              src={
                                                item.UserBasicDetail
                                                  .profile_image
                                              }
                                              alt="user"
                                            />
                                          ) : (
                                            <span className="text-white">
                                              {!_.isNull(
                                                item.UserBasicDetail.first_name
                                              ) &&
                                                !_.isNull(
                                                  item.UserBasicDetail.last_name
                                                ) && (
                                                  <>
                                                    <span>
                                                      {item.UserBasicDetail.first_name.charAt(
                                                        0
                                                      )}
                                                      {item.UserBasicDetail.last_name.charAt(
                                                        0
                                                      )}
                                                    </span>
                                                  </>
                                                )}
                                            </span>
                                          )}
                                        </div>
                                        <p className="text-white mb-0 mt-2 text-center text-truncate small">
                                          {item.UserBasicDetail.first_name}
                                        </p>
                                      </div>
                                    );
                                  })
                              : heroShow?.friendsAttending?.map((item) => {
                                  return (
                                    <div key={item.id} className="me-2 w-55">
                                      <div className="img-44 rounded-circle m-auto bg-accent-blue d-flex align-items-center justify-content-center">
                                        {!_.isNull(
                                          item.UserBasicDetail?.profile_image
                                        ) &&
                                        !_.isEmpty(
                                          item.UserBasicDetail?.profile_image
                                        ) ? (
                                          <img
                                            className="img-fluid"
                                            src={
                                              item.UserBasicDetail
                                                ?.profile_image
                                            }
                                            alt="user"
                                          />
                                        ) : (
                                          <span className="text-white">
                                            {!_.isNull(
                                              item.UserBasicDetail?.first_name
                                            ) &&
                                              !_.isNull(
                                                item.UserBasicDetail?.last_name
                                              ) && (
                                                <>
                                                  <span>
                                                    {item.UserBasicDetail?.first_name.charAt(
                                                      0
                                                    )}
                                                    {item.UserBasicDetail?.last_name.charAt(
                                                      0
                                                    )}
                                                  </span>
                                                </>
                                              )}
                                          </span>
                                        )}
                                      </div>
                                      <p className="text-white mb-0 mt-2 text-center text-truncate small">
                                        {item.UserBasicDetail?.first_name}
                                      </p>
                                    </div>
                                  );
                                }))}
                          {heroShow?.friendsAttending?.length > 4 && (
                            <div className="me-2 w-55">
                              <div className="bg-danger img-44 rounded-circle m-auto d-flex align-items-center justify-content-center">
                                <span className="text-white small">
                                  +{heroShow?.friendsAttending?.length - 4}
                                </span>
                              </div>
                            </div>
                          )}
                          {/* <div className="me-1 w-55">
                            <div className="bg-danger img-44 rounded-circle m-auto d-flex align-items-center justify-content-center">
                              <span className="text-white">+5</span>
                            </div>
                          </div> */}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <div className="speakerR w-55 position-absolute">
            <div className="bg-trans-b img-44 rounded-circle m-auto d-flex align-items-center justify-content-center">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 7.22002L6.603 10H3V14H6.603L10 16.78V7.22002ZM5.889 16H2C1.73478 16 1.48043 15.8947 1.29289 15.7071C1.10536 15.5196 1 15.2652 1 15V9.00002C1 8.73481 1.10536 8.48045 1.29289 8.29291C1.48043 8.10538 1.73478 8.00002 2 8.00002H5.889L11.183 3.66802C11.2563 3.60797 11.3451 3.56996 11.4391 3.55841C11.5331 3.54687 11.6284 3.56227 11.714 3.60282C11.7996 3.64337 11.872 3.7074 11.9226 3.78745C11.9732 3.86751 12.0001 3.9603 12 4.05502V19.945C12.0001 20.0397 11.9732 20.1325 11.9226 20.2126C11.872 20.2926 11.7996 20.3567 11.714 20.3972C11.6284 20.4378 11.5331 20.4532 11.4391 20.4416C11.3451 20.4301 11.2563 20.3921 11.183 20.332L5.89 16H5.889ZM19.406 20.134L17.99 18.718C18.938 17.8745 19.6964 16.8397 20.2152 15.6817C20.734 14.5237 21.0015 13.2689 21 12C21.0012 10.6661 20.7054 9.34867 20.1339 8.14339C19.5624 6.93811 18.7296 5.87526 17.696 5.03202L19.116 3.61202C20.3345 4.64357 21.3132 5.9285 21.9841 7.37722C22.6549 8.82593 23.0016 10.4035 23 12C23 15.223 21.614 18.122 19.406 20.134ZM15.863 16.591L14.441 15.169C14.9265 14.7957 15.3196 14.3158 15.5899 13.7663C15.8602 13.2167 16.0006 12.6124 16 12C16 10.57 15.25 9.31502 14.12 8.60802L15.559 7.16902C16.3165 7.72621 16.9321 8.45387 17.3562 9.29314C17.7802 10.1324 18.0008 11.0597 18 12C18 13.842 17.17 15.49 15.863 16.591Z"
                  fill="#fff"
                />
              </svg>
            </div>
          </div> */}
        </div>
        <div className="bg-dark-grey py-lg-0 py-sm-4 py-4">
          <div className="container-fluid px-lg-5 px-md-5 px-ms-4 px-4 py-lg-5 py-md-0 py-sm-0 py-0">
            <div className="d-flex align-items-center">
              <div
                className="circleWArrow bg-disabled img-55 rounded-circle d-flex align-items-center justify-content-center"
                id="fetch-prev-scheduled-shows"
                onClick={() => handleSchedulesOnDate(-1)}
              >
                <svg
                  width="10"
                  height="22"
                  viewBox="0 0 14 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.832 24.6673L1.16537 13.0007L12.832 1.33398"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="dayDate">{scheduleDateFormatting()}</div>
              <div
                className="circleWArrow bg-disabled img-55 rounded-circle d-flex align-items-center justify-content-center"
                id="fetch-next-scheduled-shows"
                onClick={() => handleSchedulesOnDate(1)}
              >
                <svg
                  width="10"
                  height="22"
                  viewBox="0 0 14 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.16797 1.33268L12.8346 12.9993L1.16797 24.666"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            {loadingScheduledShowsOnDate ? (
              <div className="text-center my-5">
                <div className="lds-spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : errorScheduledShowsOnDate ? (
              <h3 className="mt-3 text-center" style={{ color: "white" }}>
                Response not found
              </h3>
            ) : scheduledShowsOnDate?.length ? (
              <div className="row mb-lg-5 mb-md-0 mb-sm-0 mb-0">
                {scheduledShowsOnDate.map((item) => {
                  return (
                    <ShowTile
                      key={item.id}
                      prop={item}
                      user={user}
                      getScheduledShowsOnDate={getScheduledShowsOnDate}
                      getMyScheduleShows={getMyScheduleShows}
                      selected_start_date={selected_start_date}
                      selected_end_date={selected_end_date}
                      handleScheduledShowUnAttend={handleScheduledShowUnAttend}
                      handleShowInviteFriendsPopUp={
                        handleShowInviteFriendsPopUp
                      }
                    />
                  );
                })}
              </div>
            ) : (
              <h3 className="mt-3 text-center" style={{ color: "white" }}>
                No Scheduled Shows
              </h3>
            )}
          </div>
        </div>
        <div className="bg-dark py-lg-0 py-sm-4 py-4">
          <div className="container-fluid px-lg-5 px-md-5 px-ms-4 px-4 py-lg-5 py-md-0 py-sm-0 py-0">
            <div className="d-flex align-items-center mb-4">
              <h2 className="text-white fw-bold">Categories</h2>
              <a href="/show-category">
                <button
                  type="button"
                  className="btn btn-danger btn-sm py-1 small ms-3"
                  // onClick={() => navigate('/show-category')}
                >
                  Explore All
                </button>
              </a>
            </div>
            {loadingAllCategories ? (
              <div className="text-center my-5">
                <div className="lds-spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : errorAllCategories ? (
              <h3 className="mt-3 text-center" style={{ color: "white" }}>
                Response not found
              </h3>
            ) : (
              allCategories.length > 0 && (
                <div className="row">
                  {allCategories.map((item, index) => {
                    return <ShowAllCategories key={index} prop={item} />;
                  })}
                </div>
              )
            )}
          </div>
        </div>
        {!_.isNull(user) && (
          <>
            {loadingMyScheduledShows ? (
              <div className="text-center my-5">
                <div className="lds-spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : errorMyScheduledShows ? (
              <h1 style={{ color: "white" }}>Response not found</h1>
            ) : (
              myScheduledShows.length > 0 && (
                <div className="bg-dark-grey">
                  <div className="container-fluid px-lg-5 px-md-5 px-ms-4 px-4  py-lg-5 py-md-0 py-sm-0 py-0">
                    <h2 className="text-white fw-bold">My Schedule</h2>
                    <div className="row">
                      <Modal show={showConfirmationBox}>
                        <Modal.Header className="modalStyle">
                          <h5>
                            Do you wish to no longer attend "
                            {myScheduleShowUnAttendTitle}"?
                          </h5>
                        </Modal.Header>
                        <Modal.Footer className="modalStyle">
                          <Button
                            variant="secondary"
                            onClick={() => setShowConfirmationBox(false)}
                          >
                            Cancel
                          </Button>
                          <Button variant="danger" onClick={onConfirm}>
                            Confirm
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      {myScheduledShows.map((item) => {
                        return (
                          <MyScheduledShowTile
                            key={item.id}
                            prop={item}
                            showUnAttendConfirmationModal={
                              showUnAttendConfirmationModal
                            }
                            handleShowInviteFriendsPopUp={
                              handleShowInviteFriendsPopUp
                            }
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              )
            )}
          </>
        )}
        {!_.isNull(user) && (
          <>
            {loadingContinueWatching ? (
              <div className="text-center my-5">
                <div className="lds-spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : errorContinueWatching ? (
              <h1 style={{ color: "white" }}>Response not found</h1>
            ) : (
              continueWatching.length > 0 && (
                <div className="container-fluid px-lg-5 px-md-5 px-ms-4 px-4  py-lg-5 py-md-0 py-sm-0 py-0">
                  <div className="d-flex align-items-center justify-content-between mb-5">
                    <h2 className="text-white fw-bold mb-0">
                      Continue Watching
                    </h2>
                    <div className="d-flex align-items-center">
                      <div
                        className="circleWArrow bg-disabled img-55 rounded-circle d-flex align-items-center justify-content-center mx-2"
                        // onClick={() => handleClick("left")}
                        onClick={slidePrev}
                      >
                        <svg
                          width="10"
                          height="22"
                          viewBox="0 0 14 26"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.832 24.6673L1.16537 13.0007L12.832 1.33398"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div
                        className="circleWArrow bg-disabled img-55 rounded-circle d-flex align-items-center justify-content-center mx-2"
                        // onClick={() => handleClick("right")}
                        onClick={slideNext}
                      >
                        <svg
                          width="10"
                          height="22"
                          viewBox="0 0 14 26"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.16797 1.33268L12.8346 12.9993L1.16797 24.666"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className={classes.carousel}>
                    <AliceCarousel
                      mouseTracking
                      disableDotsControls
                      responsive={responsive}
                      items={continueWatchingItems}
                      activeIndex={activeIndex}
                      onSlideChanged={syncActiveIndex}
                      disableButtonsControls
                      animationDuration={1500}
                    />
                  </div>

                  {/* <div className=" row col-sm-12  mainContainerSlider"></div> */}
                </div>
              )
            )}
          </>
        )}
        {/* <div className="bg-dark-grey">
          <div className="container-fluid px-lg-5 px-md-5 px-ms-4 px-4 py-5">
            <h2 className="text-white fw-bold">Trending</h2>
            <div className="row">
              {myScheduledShows.map((item) => {
                return <MyScheduledShowTile key={item.id} prop={item} />;
              })}
            </div>
          </div>
        </div> */}
      </main>
      <Footer />
    </div>
  );
};
export default Home;
