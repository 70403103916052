/** @format */

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import React, { createRef } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import { Provider } from "react-redux";
import "./fonts/netflix-sans/NetflixSansRegular.ttf";
import { SnackbarProvider } from "notistack";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://63045646165041e99050d5b046bb6bd6@o1357884.ingest.sentry.io/6644649",
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,
// });
Sentry.init({
  dsn: "https://51ef4a860b76447090da4872686a7505@o4504206696906752.ingest.sentry.io/4504206699331584",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});
const notistackRef = createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      action={(key) => (
        <CancelOutlinedIcon
          style={{
            cursor: "pointer",
          }}
          onClick={onClickDismiss(key)}
        />
      )}
      hideIconVariant={true}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SnackbarProvider>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
