/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { _, findIndex, find, isUndefined } from "lodash";

export const XMPP_CONNECTION_STATUS = "XMPP_CONNECTION_STATUS";
export const XMPP_USER_PRESENCE = "XMPP_USER_PRESENCE";
export const XMPP_USER_RECENT_CHATS = "XMPP_USER_RECENT_CHATS";
export const XMPP_RIGHT_SIDE_WINDOW_STATUS = "XMPP_RIGHT_SIDE_WINDOW_STATUS";
export const XMPP_USER_CHAT_OPEN = "XMPP_USER_CHAT_OPEN";
export const XMPP_USER_CHAT_HISTORY = "XMPP_USER_CHAT_HISTORY";
export const BROWSER_NOTIFICATION_STATUS = "BROWSER_NOTIFICATION_STATUS";
export const XMPP_USER_MESSAGE_SEND = "XMPP_USER_MESSAGE_SEND";
export const XMPP_USER_MESSAGE_RECEIVED = "XMPP_USER_MESSAGE_RECEIVED";
export const XMPP_USER_SCROLL_CHAT_TO_BOTTOM = "XMPP_USER_SCROLL_CHAT_TO_BOTTOM";
export const XMPP_USER_MARKER_SENT = "XMPP_USER_MARKER_SENT";
export const XMPP_USER_MARKER_RECEIVED = "XMPP_USER_MARKER_RECEIVED";
export const STORE_USERS_DATA = "STORE_USERS_DATA";
export const XMPP_USER_DETAILS_FETCHED = "XMPP_USER_DETAILS_FETCHED";
export const STORE_GROUPS_DATA = "STORE_GROUPS_DATA";

// Slice
const slice = createSlice({
  name: "xmppConnection",
  initialState: {
    xmppStatus: 6,
    xmppStanza: {},
    xmppSpotLightStanza: [],
    XMPP_USER_RECENT_CHATS: [],
    chatMessagesLoader: 0,
    hasChatMessage: 0,
    currentGroupJID: "",
    scrollChatToBottom: "",
    isXmppFetchingChatHistoryOnShowDetail: false
  },
  reducers: {
    setXmppConnectionStatusReducer: (state, action) => {
      state.xmppStatus = action.payload;
    },
    setIsXmppFetchingChatHistoryOnShowDetailReducer: (state, action) => {
      state.isXmppFetchingChatHistoryOnShowDetail = action.payload;
    },
    setXmppUserMessageReceivedReducer: (state, action) => {
      state.xmppStanza = action.payload;
    },
    setXmppUserRecentChatsPaginationReducer: (state, action) => {
      state.recentChatPagination = action.payload;
    },
    setChatMessagesLoaderReducer: (state, action) => {
      state.chatMessagesLoader = action.payload;
    },
    setHasChatMessageReducer: (state, action) => {
      state.hasChatMessage = action.payload;
    },
    setCurrentGroupReducer: (state, action) => {
      state.currentGroupJID = action.payload;
    },
    setScrollChatToBottomReducer: (state, action) => {
      state.scrollChatToBottom = action.payload;
    },
    setXmppUserRecentChatsReducer: (state = [], action) => {
      // console.log("action.type", action)
      // console.log("action state.XMPP_USER_RECENT_CHATS ", state);
      switch (action.payload.type) {
        case XMPP_USER_RECENT_CHATS: {
          let { element_jid, unread_count, type } = action.payload.payload;
          // console.log("element_jid, unread_count, type", element_jid, unread_count, type);
          // console.log('state', state);
          let temp = { element_jid, messages: [], unread_count, type };
          // console.log('temp :', temp);
          temp.messages.push(action.payload.payload);
          // console.log('after temp push :', temp);
          // state.XMPP_USER_RECENT_CHATS.push(temp)
          state.XMPP_USER_RECENT_CHATS = temp.messages[0]

          /*
          let localstorage_subscribeIncomingGroupMessages = localStorage.getItem('subscribeIncomingGroupMessages')
          console.log("setXmppUserRecentChatsReducer localstorage_subscribeIncomingGroupMessages in ", JSON.parse(localstorage_subscribeIncomingGroupMessages))
          if (localstorage_subscribeIncomingGroupMessages) {
            localstorage_subscribeIncomingGroupMessages = JSON.parse(localstorage_subscribeIncomingGroupMessages)

            state.XMPP_USER_RECENT_CHATS = localstorage_subscribeIncomingGroupMessages

            // state.XMPP_USER_RECENT_CHATS = [action.payload.payload, ...localstorage_subscribeIncomingGroupMessages]

          }
          //  else {
          //   state.XMPP_USER_RECENT_CHATS = [action.payload.payload]
          // }
          */
          console.log('after state.XMPP_USER_RECENT_CHATS :', state.XMPP_USER_RECENT_CHATS);
          // return state;
        }
        default:
          return state;
      }
    },
    setXmppSpotlightMessageReceivedReducer: (state, action) => {
      // console.log(action)
      state.xmppSpotLightStanza = action.payload;
    },
    setXmppGroupUsersMessageReducer: (state = [], action) => {
      // console.log(action)
      // state.chatMessages = action.payload;
      let { element_jid } = action.payload;
      let index = findIndex(state, {
        element_jid
      });
      if (index === -1) {
        let temp = {
          element_jid: element_jid,
          messages: [],
          type: action.payload.type,
          unread_count: 0
        }
        state.push(temp);
        index = findIndex(state, {
          element_jid
        });
      }
      state[index].messages.push(action.payload);
      // state[index].messages.map(message=>{
      //   if(!isUndefined(find(state[index].messages,{marker_id:message.msgid,marker_type:'received'}))){
      //     message.marker_status = 'received'
      //   }
      //   if(!isUndefined(find(state[index].messages,{marker_id:message.msgid,marker_type:'displayed'}))){
      //     message.marker_status = 'displayed'
      //   }
      //   return message;
      // })
      state.sort(function (b, a) {
        /* return b.message_time - a.message_time */
        return a.messages[a.messages.length - 1].time < b.messages[b.messages.length - 1].time ? -1 : a.messages[a.messages.length - 1].time > b.messages[b.messages.length - 1].time ? 1 : 0;
        //return (a.messagesdate < b.date) ? -1 : ((a.date > b.date) ? 1 : 0)
      });
      return [...state];
    },
  },
});
export default slice.reducer;
// Actions
const {
  setXmppConnectionStatusReducer,
  setIsXmppFetchingChatHistoryOnShowDetailReducer,
  setXmppUserMessageReceivedReducer,
  setXmppSpotlightMessageReceivedReducer,
  setXmppUserRecentChatsReducer,
  setXmppGroupUsersMessageReducer,
  setXmppUserRecentChatsPaginationReducer,
  setChatMessagesLoaderReducer,
  setHasChatMessageReducer,
  setCurrentGroupReducer,
  setScrollChatToBottomReducer,
} = slice.actions;

export const setXmppConnectionStatus = (data) => async (dispatch) => {
  try {
    dispatch(setXmppConnectionStatusReducer(data));
  } catch (error) {
    console.log("Something Went Wrong", error);
  }
};

export const setIsXmppFetchingChatHistoryOnShowDetail = (data) => async (dispatch) => {
  try {
    dispatch(setIsXmppFetchingChatHistoryOnShowDetailReducer(data));
  } catch (error) {
    console.log("Something Went Wrong", error);
  }
};

export const setXmppSpotlightMessageReceived = (data) => async (dispatch) => {
  try {
    dispatch(setXmppSpotlightMessageReceivedReducer({ chatHistoryPaginationData: data }));
  } catch (error) {
    console.log("Something Went Wrong", error);
  }
};

export const setXmppUserMessageReceived = (data) => async (dispatch) => {
  try {
    dispatch(setXmppUserRecentChatsReducer({ type: XMPP_USER_RECENT_CHATS, payload: data }));
  } catch (error) {
    console.log("Something Went Wrong", error);
  }
};

export const setXmppUserMessagePagination = (data) => async (dispatch) => {
  try {
    dispatch(setXmppUserRecentChatsPaginationReducer(data));
  } catch (error) {
    console.log("Something Went Wrong", error);
  }
};

export const setXmppGroupMessageSend = (data) => (dispatch) => {
  try {
    dispatch(setXmppGroupUsersMessageReducer(data));
  } catch (error) {
    console.log("Something Went Wrong", error);
  }
};
export const setXmppUserRecentChats = (data) => (dispatch) => {
  try {
    // console.log("setXmppUserRecentChats : ", data);
    dispatch(setXmppUserRecentChatsReducer({ type: XMPP_USER_RECENT_CHATS, payload: data }));
  } catch (error) {
    console.log("Something Went Wrong", error);
  }
};

export const setChatMessagesLoader = (data) => (dispatch) => {
  try {
    dispatch(setChatMessagesLoaderReducer(data));
  } catch (error) {
    console.log("Something Went Wrong", error);
  }
};

export const setHasChatMessage = (data) => async (dispatch) => {
  try {
    dispatch(setHasChatMessageReducer(data));
  } catch (error) {
    console.log("Something Went Wrong", error);
  }
};

export const setCurrentGroup = (data) => async (dispatch) => {
  try {
    dispatch(setCurrentGroupReducer(data));
  } catch (error) {
    console.log("Something Went Wrong", error);
  }
};

export const setScrollChatToBottom = (data) => async (dispatch) => {
  try {
    dispatch(setScrollChatToBottomReducer(data));
  } catch (error) {
    console.log("Something Went Wrong", error);
  }
};


// export const setXmppUserPresence = (data) => async (dispatch) => {
//   try {
//       dispatch(setXmppUserPresenceReducer(data));
//   } catch (error) {
//   console.log("Something Went Wrong", error);
//   }
// };
