/** @format */

import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import MailIcon from "../../../images/emailIcon.svg";
import CloseIcon from "../../../images/cross.svg";
import EyeIcon from "../../../images/eye_password.svg";
import EyeLine from "../../../images/EyeLine.svg";
import styles from "./login.module.css";
import { Form, InputGroup, FormControl, Button } from "react-bootstrap";
import { useSnackbar } from "notistack";

import { userService } from "../../../_services";

const cx = classNames.bind(styles);

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isFormfilled, setIsFormfilled] = useState(false);
  const [values, setValues] = useState({
    email: location.state?.email || "",
    password: "",
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    if (values.email !== "" && values.password !== "") {
      setIsFormfilled(true);
    } else {
      setIsFormfilled(false);
    }
  }, [values]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    userService
      .login({
        email: values.email,
        password: values.password,
      })
      .then((data) => {
        if (data.success) {
          console.log(data.data);
          localStorage.setItem("user", JSON.stringify(data.data));
          navigate("/home");
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const handleTogglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);

  const btnStyle = isFormfilled
    ? {
        backgroundColor: "#E50914",
        color: "white",
      }
    : {
        backgroundColor: "#E3E3E3",
        color: "black",
      };

  return (
    <div className="container">
      <div className=" d-flex align-items-center justify-content-center">
        <div className={cx("outerBox", "bg-white", "p-4", "position-relative")}>
          <div className={cx("landscapeMode")}>
            <div className="d-flex align-items-center justify-content-center mb-4 landscape-mb-4">
              <h2 className="text-center fw-bold">Login</h2>
              <a href="#!" className={cx("position-absolute", "closeIcon")}>
                <img className="img-fluid" src={CloseIcon} />
              </a>
            </div>
            <Form onSubmit={handleSubmit}>
              <InputGroup
                size="lg"
                className="border border-dark rounded mb-3 landscape-mb-3"
              >
                <FormControl
                  id="email"
                  placeholder="Use email"
                  aria-label="use email"
                  aria-describedby="use email"
                  className="border-white"
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <InputGroup.Text
                  id="use email"
                  className="fs-6 bg-white border-white"
                >
                  <img src={MailIcon} />
                </InputGroup.Text>
              </InputGroup>
              <InputGroup
                size="lg"
                className="border border-dark rounded mb-3 landscape-mb-3"
              >
                <FormControl
                  id="pass"
                  placeholder="Password"
                  aria-label="Password"
                  aria-describedby="Password"
                  className="border-white"
                  type={isPasswordVisible ? "text" : "password"}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <InputGroup.Text
                  id="Password"
                  className="fs-6 bg-white border-white"
                  onClick={handleTogglePasswordVisibility}
                >
                  <img src={isPasswordVisible ? EyeLine : EyeIcon} />
                </InputGroup.Text>
              </InputGroup>
              <Button
                className="w-100 socialBtn fw-bold"
                variant="contained"
                size="lg"
                type="submit"
                style={btnStyle}
                disabled={!isFormfilled}
              >
                Login
              </Button>
              <a
                className="d-block text-center small text-dark my-4 landscape-my-4"
                href="/reset-password"
              >
                <u>Forgot Password?</u>
              </a>
              <p className="text-center mb-2 small mt-5 landscape-mt-5">
                Don't have an account
              </p>
              <a
                className="h6 d-block text-danger text-center fw-bold cursor-pointer"
                onClick={() => {
                  navigate("/signup", {
                    state: {
                      email: location.state?.email || "",
                    },
                  });
                }}
              >
                Create New Account
              </a>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
