import React from "react";
import css from "./footer.module.css";
import iosSvg from "./../images/iosDownload.svg";
import psDownload from "./../images/googleDownload.svg";
const Footer = () => {
  return (
    <footer className={`${css.footer} row mt-5`}>
      <div className="col-xs-12 col-lg-8">
        <p className={`${css.footerTagline} ml-xs-0 ml-lg-5 `}>
          Download the Hook<span>Z</span>App, Its Free
        </p>
      </div>
      <div className={`col-xs-12 col-lg-4 ${css.imgLinksDiv} `}>
        <img src={psDownload} alt="" />
        <img src={iosSvg} alt="" />
      </div>
    </footer>
  );
};

export default Footer;
