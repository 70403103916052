/* eslint-disable no-restricted-globals */
/**
 * /* @format
 *
 * @format
 */

import React, { useState } from "react";
import { Link, useHistory, useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./show.module.css";
import textDot from "../images/textDot.svg";
import actionImg from "../images/actionDot.svg";
import _ from "lodash";
import { useOutsideAlerter2 } from "./OutsideAlert.js";
import { showService } from "../../../_services/admin";
import { useSnackbar } from "notistack";
import { ADMIN_ROLE } from "../../../config/constants";
import OutsideClickHandler from "react-outside-click-handler";
const cx = classNames.bind(styles);

const Show = ({ prop }) => {
  let user = JSON.parse(localStorage.getItem("user"));
  // const { userDropdownStatus, setUserDropdownStatus, ref2 } = useOutsideAlerter2(true)
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dropdown, setDropdown] = useState(null);
  const clickHandler = (index) => {
    // console.log('index :>> ', index);
    setDropdown((prev) => {
      // console.log(prev)
      return prev === index ? null : index;
    });
    // console.log('clicked', index);
  };
  // const handleShowDropdown = () => {
  //     console.log(userDropdownStatus)
  //     setUserDropdownStatus(!userDropdownStatus)
  // }

  const imageSrc = (adminShowImages) => {
    const hero_image = adminShowImages.find((item) => {
      return item.is_hero === 1;
    });
    if (_.isUndefined(hero_image)) {
      return "src/images/default-image.jpeg";
    }
    return hero_image?.hero_url;
  };

  const timeString12hr = (timeString) => {
    let time = timeString;
    if (timeString.length > 8) {
      time = timeString.slice(11, 19);
    }
    // const time = timeString.slice(11, 19);
    return new Date("1970-01-01T" + time + "Z").toLocaleTimeString("en-US", {
      timeZone: "UTC",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });
  };

  const currentDate = () => {
    const todayDateArray = new Date().toISOString().slice(0, 10).split("-");
    const year = todayDateArray[0];
    const month = todayDateArray[1];
    const day = todayDateArray[2];
    const todayDate = `${day}/${month}/${year}`;
    return todayDate;
  };

  const createdTime = (time) => {
    return `${new Date(time).toLocaleDateString()} ${timeString12hr(time)}`;
  };

  const sortDates = (scheduleDates) => {
    const dates = [];
    scheduleDates.map((obj) => {
      dates.push(
        new Date(obj.date).toLocaleDateString() + " " + timeString12hr(obj.time)
      );
    });
    dates.sort(function (a, b) {
      return new Date(a) - new Date(b);
    });
    return dates;
  };

  const upcomingDate = (scheduleDates) => {
    const tempArr = [];
    const dates = sortDates(scheduleDates);
    dates.map((date) => {
      tempArr.push(date.slice(0, 10));
    });
    const todayDate = currentDate();
    const dateTime = dates[tempArr.indexOf(todayDate) + 1];
    return dateTime;
  };

  const pastDate = (scheduleDates) => {
    const tempArr = [];
    const dates = sortDates(scheduleDates);
    dates.map((date) => {
      tempArr.push(date.slice(0, 10));
    });
    const todayDate = currentDate();
    const dateTime =
      dates[
        tempArr.indexOf(todayDate) === 0 ? 0 : tempArr.indexOf(todayDate) - 1
      ];
    return dateTime;
  };

  const handleShowDetail = (showId) => {
    // navigate(`/admin/show-detail/${showId}`);
    window.open(`/admin/show-detail/${showId}`, "_blank");
  };

  const handleShowDelete = (showId) => {
    if (confirm("Are you sure you want to perform this action?")) {
      showService
        .deleteComplete({ show_id: showId })
        .then((data) => {
          if (data.success) {
          } else {
            // seterrorShowDetail(true);
            enqueueSnackbar(data.message, {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            variant: "error",
          });
        });
    }
  };

  return (
    <>
      <div key={prop.id} className="css-table-row my-4 cursor-pointer">
        <div className="position-relative pt-4">
          <div
            className="tableImg"
            style={{ background: `url(${imageSrc(prop.AdminShowImages)})` }}
          ></div>
        </div>

        <div
          className="mb-lg-0 mb-md-0 mb-sm-2 mb-2"
          onClick={() => handleShowDetail(prop.id)}
          // to={`/admin/show-detail/${prop.id}`}
          // target="_blank"
        >
          <p className="mb-0">
            <span className="text-secondary me-2">ID: #{prop.id}</span>
            <a href="#!" className="text-blue">
              {prop.show_url}
            </a>
          </p>
          <h5 className="text-white mb-0">{prop.title}</h5>
          {prop.is_incomplete === 1 && (
            <p className="mb-0">
              <span className="text-danger">Incomplete</span>
              {/* <img className="img-fluid mx-2" src={textDot} alt="dot" />
                    <span className="text-white">{item.description}</span> */}
            </p>
          )}
        </div>

        <div className="mb-lg-0 mb-md-0 mb-sm-2 mb-2">
          {!_.isEmpty(prop.AdminShowSchedules) ? (
            <>
              <p className="mb-0 text-white">
                Upcoming : {upcomingDate(prop.AdminShowSchedules)}
              </p>
              <p className="mb-0 text-danger">
                Past : {pastDate(prop.AdminShowSchedules)}
              </p>
            </>
          ) : null}
          <p className="mb-0 text-white">
            Submitted : {createdTime(prop.created_at)}
          </p>
        </div>
        <div className="mb-lg-0 mb-md-0 mb-sm-2 mb-2">
          {!_.isEmpty(prop.AdminShowHosts) ? (
            prop.AdminShowHosts.map((item) => {
              if (item.is_primary === 1) {
                return (
                  <div
                    key={item.id}
                    className="d-flex align-items-center px-sm-2 px-0"
                  >
                    <div className="flex-shrink-0 px-sm-2 px-0">
                      <div className="img-35 rounded-circle px-0 bg-accent-blue d-flex align-items-center justify-content-center">
                        {!_.isNull(item.UserBasicDetail.profile_image) &&
                        !_.isEmpty(item.UserBasicDetail.profile_image) ? (
                          <img
                            className="img-fluid rounded-circle"
                            // src={
                            //   item.profile_image !== undefined
                            //     ? item.profile_image
                            //     : item.UserBasicDetail.profile_image
                            // }
                            src={item.UserBasicDetail.profile_image}
                            alt="host"
                          />
                        ) : (
                          <span className="text-white">
                            {!_.isNull(item.UserBasicDetail.first_name) &&
                              !_.isNull(item.UserBasicDetail.last_name) && (
                                <>
                                  <span>
                                    {item.UserBasicDetail.first_name.charAt(0)}
                                    {item.UserBasicDetail.last_name.charAt(0)}
                                  </span>
                                </>
                              )}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="w-100">
                      <div className="d-flex align-items-center justify-content-start px-0">
                        <p className="mb-0 text-white">
                          {item.first_name !== undefined
                            ? item.first_name + " " + item.last_name
                            : item.UserBasicDetail.first_name +
                              " " +
                              item.UserBasicDetail.last_name}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <>
              <p>No Host</p>
            </>
          )}
        </div>
        <div>
          <div
            className="show_dropdown position-relative cursor-pointer"
            onClick={() => clickHandler(prop.id)}
          >
            <img src={actionImg} alt="three dots" />
            <div
              className={cx(
                "actionPop",
                "arrow-top",
                "bg-white",
                "p-4",
                "rounded"
              )}
              style={
                dropdown !== prop.id
                  ? { position: "absolute", display: "none" }
                  : { position: "absolute", display: "block" }
              }
            >
              {user.role === ADMIN_ROLE ? (
                <ul className="list-unstyled p-0 mb-0">
                  <li className="mb-2">
                    <Link
                      to={`/admin/show/${prop.id}`}
                      className="d-block link text-dark"
                    >
                      Edit Show
                    </Link>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <Link to="/home" className="link text-dark">
                      + New Schedule Time
                    </Link>
                  </li>
                  <li className="mb-2 ">
                    <Link
                      to={`/admin/schedule-show/${prop.id}`}
                      className="link text-dark"
                    >
                      Edit Schedule
                    </Link>
                  </li>
                  <li
                    onClick={() => handleShowDelete(prop.id)}
                    className="link text-dark d-flex align-items-center"
                  >
                    Delete
                  </li>
                </ul>
              ) : (
                <ul className="list-unstyled p-0 mb-0">
                  <li>
                    <Link
                      to={`/admin/show/${prop.id}`}
                      className="d-block link text-dark"
                      // target="_blank"
                    >
                      Edit Show
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Show;
