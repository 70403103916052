import React, { useLayoutEffect, useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RoomClient from "../../../../lib/RoomClientOld";

// packages
import classNames from "classnames/bind";

// assets
import textDot from "../../images/textDot.svg";
import {Signaling} from "../../../../Services/socketService";

const cx = classNames;

export const VideoMobile = ({ currentEncoding, profile_image, id, htmlElement, firstName, lastName, micEnabled, cameraEnabled, isHost, isAdmin, spotlight, handleToggleMic, isChatHidden, index}) => {
    //create refs
    const videoWrapperRef = useRef();
    const [videoEl, setVideoEl] = useState(null);
    const avatarRef = useRef();
    const imageRef = useRef();
    const isActive = currentEncoding !== 2;

    //set video track
    useEffect(() => {
        let el;
        if (videoWrapperRef.current && htmlElement && cameraEnabled) {
            el = htmlElement;
            videoWrapperRef.current.innerHTML = "";
            videoWrapperRef.current.appendChild(htmlElement);
                htmlElement.play();
            if (id === Signaling.getInstance().socket?.id) {
                htmlElement.style.cssText = "width:100%;height:100%;transform:scaleX(-1);-webkit-transform:scaleX(-1);";
            }  else {
                htmlElement.style.cssText = "width:100%;height:100%;transform:scaleX(1);-webkit-transform:scaleX(1);";
            }
        } else {
            videoWrapperRef.current.innerHTML = "";
            const emptyEl = document.createElement("video");
            el = emptyEl;
            videoWrapperRef.current.appendChild(emptyEl);
            if (id === Signaling.getInstance().socket?.id) {
                emptyEl.style.cssText = "width:100%;height:100%;transform:scaleX(-1);-webkit-transform:scaleX(-1);";
            }  else {
                emptyEl.style.cssText = "width:100%;height:100%;transform:scaleX(1);-webkit-transform:scaleX(1);";
            }
        }
        setVideoEl(el);
    }, [htmlElement, cameraEnabled]);

    useEffect(() => {
        if (!videoEl) {
            return;
        }

        const handleResize = () => {
            const height = window.innerHeight / 4
            const width = height * 16 / 9;
            videoEl.style.height = `${
                height
            }px`;
            videoEl.style.width = `${
                width
            }px`;

            if (avatarRef && avatarRef.current) {
                avatarRef.current.style.position = "absolute";
                avatarRef.current.style["top"] = (1/6) * height + "px";
                avatarRef.current.style["left"] = (1/3) * 16 * height / (9) + "px";
            }

            if (imageRef && imageRef.current) {
                imageRef.current.style.width = 16 * height / (9 * 3) + "px";
            }

        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [isChatHidden, videoEl])

    return (
        <div key={index} className="col align-items-center">
            <div className="videoThumb">
                <div ref={videoWrapperRef}></div>
                <div style={{visibility: !(isActive && cameraEnabled) ? "" : "hidden"}} ref={avatarRef}>
                    <img
                        ref={imageRef}
                        className="img-fluid rounded-circle"
                        src={profile_image || `https://ui-avatars.com/api/?name=${firstName} ${lastName}`}
                        alt="user"
                    />
                </div>
                <div className="videoGThumb position-absolute">
                    <div className="videoContT d-flex justify-content-end p-2">
                        {(isHost || isAdmin) && <div className="micLiveGV d-flex align-items-center justify-content-center">
                            <svg
                                onClick={() => {
                                    if (id === Signaling.getInstance().socket.id) {
                                        handleToggleMic();
                                    } else {
                                        // ask to mute everyone else
                                    }
                                }}
                                width="15"
                                height="20"
                                viewBox="0 0 22 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M10.9983 2.50033C10.0701 2.50033 9.17984 2.86907 8.52346 3.52545C7.86708 4.18183 7.49833 5.07207 7.49833 6.00033V10.667C7.49833 11.5952 7.86708 12.4855 8.52346 13.1419C9.17984 13.7982 10.0701 14.167 10.9983 14.167C11.9266 14.167 12.8168 13.7982 13.4732 13.1419C14.1296 12.4855 14.4983 11.5952 14.4983 10.667V6.00033C14.4983 5.07207 14.1296 4.18183 13.4732 3.52545C12.8168 2.86907 11.9266 2.50033 10.9983 2.50033ZM10.9983 0.166992C11.7644 0.166992 12.5229 0.317876 13.2307 0.611028C13.9384 0.904181 14.5814 1.33386 15.1231 1.87554C15.6648 2.41721 16.0945 3.06027 16.3876 3.76801C16.6808 4.47574 16.8317 5.23428 16.8317 6.00033V10.667C16.8317 12.2141 16.2171 13.6978 15.1231 14.7918C14.0292 15.8857 12.5454 16.5003 10.9983 16.5003C9.45124 16.5003 7.96751 15.8857 6.87354 14.7918C5.77958 13.6978 5.165 12.2141 5.165 10.667V6.00033C5.165 4.45323 5.77958 2.9695 6.87354 1.87554C7.96751 0.781574 9.45124 0.166992 10.9983 0.166992ZM0.5625 11.8337H2.91333C3.19601 13.7759 4.16851 15.5514 5.65294 16.8355C7.13736 18.1195 9.03446 18.8261 10.9972 18.8261C12.9599 18.8261 14.857 18.1195 16.3414 16.8355C17.8258 15.5514 18.7983 13.7759 19.081 11.8337H21.433C21.1677 14.2005 20.1061 16.4069 18.4221 18.0911C16.7381 19.7753 14.5318 20.8373 12.165 21.1028V25.8337H9.83167V21.1028C7.46466 20.8375 5.25808 19.7757 3.57387 18.0915C1.88966 16.4072 0.827815 14.2007 0.5625 11.8337Z"
                                    fill={micEnabled ? "white" : "red"}
                                />
                            </svg>
                        </div>}
                    </div>
                    <div className="videoContB p-2 d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                            <p className="text-white fw-bold mb-0 w-100 text-truncate">
                                {`${firstName} ${lastName}`} {!isActive && "(Connecting)"}
                            </p>
                        </div>
                        {(isHost || isAdmin ) && <svg
                            onClick={() => {
                                if (spotlight === id) {
                                    RoomClient.getInstance().spotlight(null);
                                } else {
                                    RoomClient.getInstance().spotlight(id);
                                }
                            }}
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.4037 6.74117L11.0912 5.42873L2.4462 14.0738V15.3862H3.75864L12.4037 6.74117ZM13.7161 5.42873L15.0286 4.11629L13.7161 2.80385L12.4037 4.11629L13.7161 5.42873ZM4.52717 17.2426H0.589844V13.3043L13.0599 0.834258C13.234 0.660251 13.47 0.5625 13.7161 0.5625C13.9622 0.5625 14.1983 0.660251 14.3723 0.834258L16.9981 3.46007C17.1722 3.63413 17.2699 3.87017 17.2699 4.11629C17.2699 4.36241 17.1722 4.59845 16.9981 4.77251L4.5281 17.2426H4.52717Z"
                                fill={spotlight === id ? "red" : "white"}
                            />
                        </svg>}
                    </div>
                </div>
            </div>
        </div>
    );
};
